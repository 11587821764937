import {
  select, setProp, setProps, withProps, createStore,
} from '@ngneat/elf';
import {
  getActiveEntity, getActiveEntities,
  getAllEntities,
  getEntity,
  hasEntity,
  resetActiveIds,
  selectActiveEntity,
  selectActiveEntities, setActiveIds,
  selectAllEntities, setActiveId,
  setEntities,
  updateEntities,
  upsertEntities,
  withActiveId,
  withActiveIds,
  withEntities,
} from '@ngneat/elf-entities';
import {
  createRequestsStatusOperator,
  selectIsRequestPending,
  selectRequestStatus,
  updateRequestStatus,
  withRequestsStatus,
  StatusState,
} from '@ngneat/elf-requests';
import { map } from 'rxjs';
import {
  GpbiRecipe,
  GpdsSchedule,
  GpdsScheduleDb,
  GpLayer, GpLayerMap, GpRecipe, GpSchedule, GpStatDataset, GpTerritories,
} from '../../api/types';
import { ResponseError } from '../../models/common';
import { TotalCountProps } from '../../types/shared';

interface RecipesProps extends TotalCountProps {
  updatePeriods: string[];
  activeUpdatePeriod: string | null;
}

const recipesStore = createStore(
  { name: 'recipes' },
  withEntities<GpRecipe | GpbiRecipe>(),
  withActiveId(),
  withRequestsStatus<'recipes'>(),
  withProps<RecipesProps>({ totalCount: 0, updatePeriods: [], activeUpdatePeriod: null }),
);

const recipeLayersStore = createStore(
  { name: 'recipeLayers' },
  withEntities<GpLayerMap | GpdsScheduleDb, 'uName'>({ idKey: 'uName' }),
  withActiveIds(),
  withProps<{ orderedUNamePrefixes: [string, string][] }>({
    orderedUNamePrefixes: [],
  }),
);

const territoryStore = createStore(
  { name: 'recipeTerritories' },
  withEntities<GpTerritories>(),
  withActiveId(),
);

const recipeMarketLayersStore = createStore(
  { name: 'recipeMarketLayers' },
  withEntities<GpLayer | GpStatDataset>(),
  withActiveIds(),
  withRequestsStatus<'recipeMarketLayers'>(),
  withProps<{ totalCount: number }>({
    totalCount: 0,
  }),
);

export interface ScheduleLayerData {
  id: string;
  title: string;
  uName: string;
}

interface SelectedLayerSchedules {
  id: string;
  title: string;
  selected: ScheduleLayerData | null;
  quarterData: {
    title?: string | null;
    releaseDate?: number | null;
  } | null;
  layerAvailableSchedules: ScheduleLayerData[];
}

export enum LayerAvailabilityEnum {
  Acquired,
  Available,
  Unavailable,
}

export interface ScheduleWithLayer {
  id: string;
  layerOrDataset: GpLayer | GpStatDataset;
  schedule?: GpSchedule | GpdsSchedule;
  availability: LayerAvailabilityEnum,
}

const selectedLayerSchedulesStore = createStore(
  { name: 'selectedLayerSchedules' },
  withEntities<SelectedLayerSchedules>(),
  withProps<{ onlyFreshEnabled: boolean; initialLayers: SelectedLayerSchedules[] }>({
    onlyFreshEnabled: false,
    initialLayers: [],
  }),
);

const scheduleWithLayerStore = createStore(
  { name: 'selectedLayerSchedules' },
  withEntities<ScheduleWithLayer>(),
  withRequestsStatus<'selectedLayerSchedules'>(),
);

export const trackRecipesRequestsStatus = createRequestsStatusOperator(recipesStore);

export const recipes$ = recipesStore.pipe(selectAllEntities());
export const recipesTotalCount$ = recipesStore.pipe(select((state) => state.totalCount));
export const recipesRequestPending$ = recipesStore.pipe(selectIsRequestPending('recipes'));
export const activeRecipe$ = recipesStore.pipe(selectActiveEntity());
export const recipesRequestStatus$ = recipesStore.pipe(selectRequestStatus('recipes'));
export const activeRecipeTitle$ = recipesStore.pipe(selectActiveEntity())
  .pipe(map((res) => res?.title));

export const recipeLayers$ = recipeLayersStore.pipe(selectAllEntities());
export const activeRecipeLayers$ = recipeLayersStore.pipe(selectActiveEntities());

export const recipeTerritory$ = territoryStore.pipe(selectAllEntities());
export const activeRecipeTerritory$ = territoryStore.pipe(selectActiveEntity());
// eslint-disable-next-line @typescript-eslint/no-unsafe-return
export const getActiveTerritoryId = () => territoryStore.query((state) => state.activeId);
export const getActiveTerritory = () => territoryStore.query(getActiveEntity());

export const marketLayers$ = recipeMarketLayersStore.pipe(selectAllEntities());
export const recipeMarketLayersRequestPending$ = recipeMarketLayersStore.pipe(
  selectIsRequestPending('recipeMarketLayers'),
);

export const scheduleWithLayer$ = scheduleWithLayerStore
  .pipe(selectAllEntities());
export const getMissingScheduleWithLayer = () => scheduleWithLayerStore.query(getAllEntities())
  .filter((x) => x.availability === LayerAvailabilityEnum.Available);
export const missingSchedulesWithLayers$ = scheduleWithLayerStore.pipe(selectAllEntities())
  .pipe(map((layer) => layer.filter((x) => x.availability === LayerAvailabilityEnum.Available)));
export const getScheduleWithLayer = () => scheduleWithLayerStore.query(getAllEntities());

export const unavailableSchedulesWithLayers$ = scheduleWithLayerStore.pipe(selectAllEntities())
  .pipe(map((layer) => layer.filter((x) => x.availability === LayerAvailabilityEnum.Unavailable)));
export const scheduleWithLayerRequestStatus$ = scheduleWithLayerStore.pipe(
  selectRequestStatus('selectedLayerSchedules'),
);

export const recipeUpdatePeriods$ = recipesStore.pipe(select((state) => state.updatePeriods));
// eslint-disable-next-line max-len
export const activeRecipeUpdatePeriod$ = recipesStore.pipe(select((state) => state.activeUpdatePeriod));

function parseUNames(recipe: GpRecipe | GpbiRecipe) {
  const res = {
    prefixes: new Set<[string, string]>(),
    updatePeriods: new Set<string>(),
  };

  if ('layers' in recipe && recipe.layers?.length) {
  // TODO: bg layers required ???
    recipe.layers
      .forEach((l) => l.ov
        .forEach((ovLayer) => ovLayer.layer
          .filter((layer): layer is GpLayerMap => layer.__typename === 'GPLayerMap')
          .forEach(({ uName }) => {
            const [pr1, pr2, , updatePeriod] = uName.split('_').slice(0, 4);
            res.prefixes.add([pr1, pr2]);
            res.updatePeriods.add(updatePeriod);
          })));
  }
  if ('datasetsList' in recipe && recipe.datasetsList.length) {
    recipe.datasetsList[0].dslist.forEach((element) => {
      const [pr1, pr2, , updatePeriod] = element.ds[0].uName.split('_').slice(0, 4);
      res.prefixes.add([pr1, pr2]);
      res.updatePeriods.add(updatePeriod);
    });
  }
  return {
    // @ts-expect-error
    prefixes: [...res.prefixes] as [string, string][],
    // @ts-expect-error
    updatePeriods: [...res.updatePeriods] as string[],
  };
}

export function setActiveRecipeLayers() {
  const activeTerritory = territoryStore.query(getActiveEntity())?.fcode;
  const { activeUpdatePeriod } = recipesStore.state;
  const ids = recipeLayersStore
    .query(getAllEntities())
    .filter((el) => (
      activeTerritory ? el.uName.split('_').slice(0, 4)[2] === activeTerritory : el
    ))
    .filter((el) => (
      activeUpdatePeriod ? el.uName.split('_').slice(0, 4)[3] === activeUpdatePeriod : el
    ))
    .map((el) => el.uName);
  if (ids.length) {
    recipeLayersStore.update(setActiveIds(ids));
  } else {
    recipeLayersStore.update(resetActiveIds());
  }
}

export function setRecipes(documents: GpRecipe[], totalCount: number) {
  recipesStore.update(
    setEntities(documents),
    setProps({
      totalCount,
    }),
    updateRequestStatus('recipes', 'success'),
  );
}

export function setRecipesRequestError(error: unknown) {
  recipesStore.update(
    updateRequestStatus('recipes', 'error', error as ResponseError),
  );
}

export const setRecipesRequestStatus = (status: Exclude<StatusState['value'], 'error'>) => {
  recipesStore.update(
    updateRequestStatus('recipes', status),
  );
};

export function hasRecipe(id: string) {
  return recipesStore.query(hasEntity(id));
}

export function upsertRecipe(recipe: GpRecipe | GpbiRecipe) {
  recipesStore.update(upsertEntities(recipe));
}

export function setActiveRecipe(id: string) {
  recipesStore.update(setActiveId(id));
  const recipe = recipesStore.query(getEntity(id));
  if (!recipe) {
    return;
  }
  const { prefixes, updatePeriods } = parseUNames(recipe);
  recipesStore.update(
    setProp('updatePeriods', updatePeriods),
  );
  recipeLayersStore.update(
    setProp('orderedUNamePrefixes', prefixes),
  );
}

export function getActiveRecipe() {
  return recipesStore.query(getActiveEntity());
}

export function getRecipe(id: string) {
  return recipesStore.query(getEntity(id));
}

export function setRecipeMapLayers(mapLayers: GpLayerMap[] | GpdsScheduleDb[]) {
  recipeLayersStore.update(
    setEntities(mapLayers),
  );
}

export function setTerritories(territories: GpTerritories[]) {
  if (territories) {
    territoryStore.update(setEntities(territories));
  }
}

export function setActiveRecipeTerritory(territoryId: string) {
  territoryStore.update(setActiveId(territoryId));
  setActiveRecipeLayers();
}

export function getAllRecipeTerritories() {
  return territoryStore.query(getAllEntities());
}

export function setMarketLayers(layers: GpLayer[] | GpStatDataset[] | undefined) {
  if (layers) {
    recipeMarketLayersStore.update(setEntities(layers));
  }
}

export function getRecipeActiveLayerMaps() {
  return recipeLayersStore.query(getActiveEntities());
}

export const resetscheduleWithLayerStore = () => {
  scheduleWithLayerStore.reset();
};

export const resetChosenRecipeLayers = () => {
  recipeMarketLayersStore.reset();
  recipeLayersStore.update(resetActiveIds());
  territoryStore.reset();
  selectedLayerSchedulesStore.reset();
  scheduleWithLayerStore.reset();
  recipesStore.update((state) => ({ ...state, updatePeriods: [], activeUpdatePeriod: null }));
};

export const changeSelectedLayerSchedules = (layerId: string, selected: ScheduleLayerData) => {
  selectedLayerSchedulesStore.update(
    updateEntities(layerId, { selected }),
  );
};

export const setSelectedLayerSchedulesTrue = (schedules: ScheduleWithLayer[]) => {
  scheduleWithLayerStore.update(
    setEntities(schedules),
  );
};

export const setSelectedLayerSchedulesRequestStatus = (status: Exclude<StatusState['value'], 'error'>) => {
  scheduleWithLayerStore.update(
    updateRequestStatus('selectedLayerSchedules', status),
  );
};

export const createUnamePrefixes = (withUpdatePeriod: boolean): string[] => {
  const uNames = recipeLayersStore.query((state) => state.orderedUNamePrefixes);
  const fcode = territoryStore.query(getActiveEntity())?.fcode;
  const updatePeriod = recipesStore.query((state) => state.activeUpdatePeriod);

  return uNames.map((uName) => {
    const res = [...uName];
    if (fcode) {
      res.push(fcode);
    }
    if (withUpdatePeriod && updatePeriod) {
      res.push(updatePeriod);
    }
    return res.join('_');
  });
};

export function setActiveUpdatePeriod(period: string | null) {
  recipesStore.update(
    setProp('activeUpdatePeriod', period),
  );
  setActiveRecipeLayers();
}

export function getActiveUpdatePeriod() {
  return recipesStore.query((state) => state.activeUpdatePeriod);
}

export function updatePeriods(updatePeriods: string[]) {
  const res = new Set<string>();
  if (updatePeriods.length) {
    updatePeriods
      .forEach((uName) => {
        res.add(uName.split('_').slice(0, 4)[3]);
      });
  }
  recipesStore.update(
    setProp('updatePeriods', Array.from(res).sort()),
  );
}

export function getLatestPeriod() {
  return recipesStore.query((state) => state.updatePeriods)
    .find((el, _, arr) => (arr.every((item) => item <= el))) || null;
}

export const setRecipeMarketLayersRequestStatus = (status: Exclude<StatusState['value'], 'error'>) => {
  recipeMarketLayersStore.update(
    updateRequestStatus('recipeMarketLayers', status),
  );
};
