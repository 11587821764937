import {
  Box, Typography,
} from '@mui/material';
import { FC } from 'react';

import CloseIcon from '@mui/icons-material/Close';

import { GpRubric } from '../../api/types';
import {
  removeTempRubric,
  setTempRubrics,
  setActiveRubrics,
} from '../../state/filters/filters.repository';
import ButtonsApplyCancel from '../ModalProjectTabs/common/ButtonsApplyCancel';

import styles from './RubricsModal.module.scss';

interface RubricsSideBodyProps {
  singleRubrics: GpRubric[];
}

export const RubricsSideBody: FC<RubricsSideBodyProps> = ({ singleRubrics }) => {
  const removeLayer = (rubric: GpRubric) => {
    removeTempRubric(rubric.id);
  };

  return (
    <Box className={styles.rubricsSideBodyWrapper}>
      {
        singleRubrics.length ? (
          singleRubrics
            .map((r, i) => (
              <Box className={styles.rubricsSideBodyItem}>
                { /* eslint-disable-next-line react/no-array-index-key */ }
                <Typography fontWeight="500" variant="body1" key={i}>
                  {`${i + 1}.`}
                </Typography>
                <Typography className={styles.rubricsSideBodyText} variant="body1" key={r.id}>
                  {` ${r.title}`}
                </Typography>
                <CloseIcon onClick={() => removeLayer(r)} className={styles.rubricsSideBodyClose} />
              </Box>
            ))
        ) : null
      }
    </Box>
  );
};

interface RubricsSideFooterProps {
  activeRubrics: GpRubric[],
  singleRubrics: GpRubric[],
  clearSelectedRubric: () => void,
  handleCloseModal: () => void,
}

export const RubricsSideFooter: FC<RubricsSideFooterProps> = ({
  activeRubrics,
  singleRubrics,
  clearSelectedRubric,
  handleCloseModal,
}) => {
  const applyChanges = () => {
    setActiveRubrics(singleRubrics
      .map((r) => r.id));
    handleCloseModal();
  };

  const denyChanges = () => {
    setTempRubrics(activeRubrics);
    clearSelectedRubric();
  };

  return (
    <ButtonsApplyCancel
      disabledApply={false}
      disabledCancel={false}
      onApply={applyChanges}
      onCancel={denyChanges}
    />
  );
};
