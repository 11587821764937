import { Box, Drawer, Backdrop } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import PurchaseStepper from '../../blocks/PurchaseStepper';

import {
  CallbackFn, DrawerContentEnum, DrawerContext, IDrawerContext, Variant,
} from '../../drawerContext';
import RecipeChooseLayers from './Recipe/ChooseLayers';
import AuthForm from '../../components/auth-form/auth-form';
import SignUpForm from '../../components/signup-form/signup-form';
import Cart from '../../components/Cart/Cart';

const chooseRecipeLayerSteps = ['Войти', 'Выбрать компоненты рецепта'];

const DrawerMenuWrapper = ({ children }: { children: JSX.Element }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [onDrawerClose, setOnDrawerClose] = useState<CallbackFn>();
  const [drawerContent, setDrawerContent] = useState(DrawerContentEnum.LOGIN);
  const [drawerVariant, setDrawerVariant] = useState<Variant>('temporary');

  const openDrawer = useCallback(
    (content: DrawerContentEnum, callbackFn?: CallbackFn, variant?: Variant) => {
      setDrawerContent(content);
      setOnDrawerClose(callbackFn);
      setDrawerVariant(variant ?? 'temporary');
      setDrawerOpen(true);
    },
    [],
  );

  const closeDrawer = useCallback(() => {
    setDrawerOpen(false);
    onDrawerClose?.();
  }, [onDrawerClose]);

  const providerContext = useMemo<IDrawerContext>(
    () => ({
      isOpen: drawerOpen,
      content: drawerContent,
      variant: drawerVariant,
      open: openDrawer,
      close: closeDrawer,
      setContent: setDrawerContent,
    }),
    [],
  );

  const renderDrawerContent = (param: DrawerContentEnum) => {
    switch (param) {
      case DrawerContentEnum.REGISTER:
        return (
          <Box sx={{
            position: 'relative', paddingInline: '40px', maxWidth: '450px', mt: '32px',
          }}
          >
            <SignUpForm />
          </Box>
        );
      case DrawerContentEnum.LOGIN:
        return (
          <Box sx={{ position: 'relative', paddingInline: '40px', mt: '96px' }}>
            <AuthForm
              onCompleted={closeDrawer}
              toRegister={() => setDrawerContent(DrawerContentEnum.REGISTER)}
            />
          </Box>
        );
      case DrawerContentEnum.CHOOSE_RECIPE_LAYERS:
        return (
          <PurchaseStepper
            steps={chooseRecipeLayerSteps}
          >
            <RecipeChooseLayers />
          </PurchaseStepper>
        );
      case DrawerContentEnum.CART:
        return (
          <Box sx={{
            position: 'relative',
            paddingInline: '20px',
            mt: '40px',
            mb: '30px',
            pl: '40px',
            height: '100%',
            maxWidth: '450px',
          }}
          >
            <Cart />
          </Box>
        );
      default:
        return (<div />);
    }
  };

  return (
    <>
      <DrawerContext.Provider value={providerContext}>
        {children}
        <Drawer
          data-testid="Drawer"
          anchor="right"
          open={drawerOpen}
          onClose={() => closeDrawer()}
          variant={drawerVariant}
          hideBackdrop={false}
          PaperProps={{ sx: { minWidth: '450px', bgcolor: 'primary.dark' } }}
        >
          {renderDrawerContent(drawerContent)}
        </Drawer>
      </DrawerContext.Provider>

      <Backdrop
        open={drawerOpen}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
      />
    </>
  );
};

export default DrawerMenuWrapper;
