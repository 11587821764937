import { FC, useEffect, useRef } from 'react';
import { Stack } from '@mui/material';
import PageBreadcrumbs from '../../../../blocks/PageBreadcrumbs/PageBreadcrumbs';
import { createSideMarkup } from '../../../../utils/functions';
import styles from './HelpLandingToc.module.scss';

interface HelpLandingTocProps {
  title: string;
  text: string;
}

const HelpLandingToc:FC<HelpLandingTocProps> = ({ text, title }) => {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    queueMicrotask(() => {
      divRef.current?.querySelectorAll(':is(li > ul, li > ol)')
        .forEach((element) => {
          const parentElement = element.closest('li');
          if (parentElement) {
            parentElement.classList.add(styles.landingTocInnerList);
          }
        });

      divRef.current?.querySelectorAll('li > p')
        .forEach((element) => {
          const parentElement = element.closest('li');
          if (parentElement) {
            const countLine = parentElement.getBoundingClientRect().height
              / parseInt(window.getComputedStyle(parentElement).lineHeight, 10);
            if (countLine > 1) {
              parentElement.classList.add(styles.landingTocInnerList);
            }
          }
        });
    });
  }, [divRef.current]);

  return (
    <Stack>
      <PageBreadcrumbs
        links={[{ title, to: '/help' }]}
      />
      <div
        ref={divRef}
        className={styles.landingToc}
        dangerouslySetInnerHTML={createSideMarkup(text)}
      />
    </Stack>
  );
};

export default HelpLandingToc;
