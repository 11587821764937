import { FC, PropsWithChildren } from 'react';
import { useTabContext } from '@mui/lab';
import { Box } from '@mui/material';

interface TabProps {
  value: string,
  className: string,
}

export const TabPanel: FC<PropsWithChildren<TabProps>> = ({ children, value, className }) => {
  const { value: contextValue } = useTabContext() || {};
  return (
    <Box className={className} sx={{ display: value === contextValue ? 'block' : 'none' }} key={value}>
      {children}
    </Box>
  );
};
