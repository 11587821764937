import { useEffect, useState } from 'react';
import { useObservable } from '@ngneat/react-rxjs';
import styles from './BI.module.scss';
import { embedDashboard, EmbedDashboardParams } from '../../superset-sdk';
import { activeProject$, updateBiProject, step$ } from '../../state/project/project.repository';
import { GpbiProject, GpProjectGn } from '../../api/types';
import {
  ActionsEnum, fetchGuestTokenFromBackend, refetchProjectGn, biStartProcess,
} from './biHelpers';
import Loader from '../../components/Loader/Loader';
import { bIUri$ } from '../../state/context/auth.repository';

function BI() {
  const [loading, setLoading] = useState(true);
  const [localProject, setLocalProject] = useState(false);
  const [savedProjectGn, setSavedProjectGn] = useState<GpProjectGn>();
  const [activeProjectGn] = useObservable(activeProject$);
  const [step] = useObservable(step$);
  const [biUri] = useObservable(bIUri$);

  async function getBiCfgId() {
    const project = activeProjectGn.project[0] as GpbiProject;

    if (!project.biCfg.id) {
      let newProject = activeProjectGn.project[0] as GpbiProject;
      let tryCount: number = 0;
      await biStartProcess(project.id, ActionsEnum.Create);
      while (!newProject.biCfg.id && tryCount < 10) {
        // eslint-disable-next-line no-await-in-loop
        newProject = await refetchProjectGn(activeProjectGn.id);
        tryCount += 1;
      }

      if (tryCount >= 10) {
        throw new Error();
      }
      updateBiProject(project, 'biCfg', newProject);
      return newProject.biCfg;
    }

    return project.biCfg;
  }

  async function doStuff() {
    const biCfg = await getBiCfgId();
    const cfg = {
      ...biCfg,
      supersetDomain: biUri,
      mountPoint: document.getElementById('bi-container')!,
      fetchGuestToken: () => fetchGuestTokenFromBackend(biCfg.id!),
      debug: true,
    } as EmbedDashboardParams;

    setLoading(false);
    await embedDashboard(cfg);
  }

  useEffect(() => {
    if (activeProjectGn) setLocalProject(true);
  }, [activeProjectGn]);

  useEffect(() => {
    if (
      step === 0
      && savedProjectGn
      && JSON.stringify(savedProjectGn) !== JSON.stringify(activeProjectGn)
    ) {
      void doStuff();
    }
    if (step === 0) {
      setSavedProjectGn(activeProjectGn);
    }
  }, [step === 0]);

  useEffect(() => {
    if (localProject) void doStuff();
  }, [biUri, localProject]);

  return (
    <>
      {
        loading && <Loader id="progress-bi" />
      }
      <div id="bi-container" className={styles['bi-container']} />
    </>
  );
}

export default BI;
