import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Stack, Typography } from '@mui/material';
import { useObservable } from '@ngneat/react-rxjs';
import UserSection from '../../components/UserSection';
import AuthForm from '../../components/auth-form/auth-form';
import { user$ } from '../../state/context/auth.repository';
import styles from './PurchaseStepper.module.scss';
import './PurchaseStepper.scss';

interface PurchaseStepperProps {
  steps: Array<string>;
  children: JSX.Element;
}

const Separator = () => (
  <Stack direction="row" spacing={1.5} alignItems="center">
    {
      Array.from(Array(16).keys()).map(
        // eslint-disable-next-line react/no-array-index-key
        (_, i) => <Box key={i} className={styles.separator} />,
      )
    }
  </Stack>
);

export default function PurchaseStepper({ steps, children }: PurchaseStepperProps) {
  const [user] = useObservable(user$);
  const [activeStep, setActiveStep] = React.useState(
    user ? 1 : 0,
  );

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // eslint-disable-next-line consistent-return
  const renderActiveStep = (activeStep: number) => {
    switch (activeStep) {
      case 0:
        return (
          <Box sx={{ position: 'relative' }}>
            <AuthForm
              onCompleted={handleNext}
              toRegister={() => null}
            />
          </Box>
        );
      default:
        return <div>{children}</div>;
    }
  };

  return (
    <Stack p="18px 40px" height="100%">
      <Box ml="auto" height="46px">
        {
          user && (<UserSection onLogout={handleReset} projectOpen={false} />)
        }
      </Box>

      <Stack mt={4} sx={{ width: '100%', flex: 1 }}>
        <Stepper activeStep={activeStep} sx={{ mb: 6 }} connector={<Separator />}>
          {steps.map((label) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps} className="step">
                  <Typography variant="body1" color="#fff">{label}</Typography>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {renderActiveStep(activeStep)}
      </Stack>
    </Stack>
  );
}
