import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Collapse, FormGroup, ListItemButton, Typography,
} from '@mui/material';
import { memo, useState } from 'react';
import styles from './RecipeCatalog.module.scss';

interface RecipeFilterProps {
  children: React.ReactNode;
  title: string;
}

const RecipeFilter = ({ children, title }: RecipeFilterProps) => {
  const [open, setOpen] = useState(false);

  const handleFilterClick = () => setOpen(!open);

  return (
    <FormGroup>
      <ListItemButton
        className={styles.itemButton}
        onClick={handleFilterClick}
      >

        <Typography mt={1} variant="body2" color="text.primary">
          {title}
        </Typography>
        <Typography mt={1} variant="body2" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit className={styles.collapse}>
        {
          children
        }
      </Collapse>
    </FormGroup>
  );
};

export default memo(RecipeFilter);
