import {
  Button, FormControlLabel, Radio, RadioProps, Stack, styled,
} from '@mui/material';

export const SideMenuButton = styled(Button)(({ theme }) => ({
  '&': {
    width: '100%',
    height: '40px',
    maxWidth: '150px',
    color: '#fff',
    textTransform: 'none',
    borderRadius: '20px',
    backgroundColor: theme.palette.primary.main,
  },
  '&.Mui-disabled': {
    opacity: 0.4,
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 20,
  height: 20,
  backgroundColor: '#fff',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.primary.main,
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(57,75,89,.5)',
  },
}));

export const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  backgroundColor: '#fff',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage: 'radial-gradient(#fff,#fff 6px,transparent 50%)',
    content: '""',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const FormControlLabelStyled = styled(FormControlLabel)(() => ({
  padding: '16px',
  marginInline: 0,
  border: '1px solid rgba(255, 255, 255, .2)',
  borderRadius: '20px',
}));

export const StackStyled = styled(Stack)(() => ({
  padding: '16px',
  border: '1px solid rgba(255, 255, 255, .2)',
  borderRadius: '20px',
}));

export const ButtonStyled = styled(Button)(({ theme }) => ({
  '&': {
    width: '100%',
    maxWidth: '150px',
    color: '#fff',
    textTransform: 'none',
    borderRadius: '20px',
    backgroundColor: theme.palette.primary.main,
  },
  '&.Mui-disabled': {
    opacity: 0.4,
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const BpRadio = (props: RadioProps) => (
  <Radio
    sx={{
      '&:hover': {
        bgcolor: 'transparent',
      },
    }}
    disableRipple
    color="default"
    checkedIcon={<BpCheckedIcon />}
    icon={<BpIcon />}
    {...props}
  />
);

export const RadioStyled = styled(Radio)(() => ({
  '&': {
    color: '#fff',
  },
  '&.Mui-disabled': {
    color: 'rgba(255, 255, 255, 0.2)',
  },
}));
