import { Button, ButtonProps } from '@mui/material';
import styles from './ActionButton.module.scss';

export interface ActionButtonProps extends ButtonProps {
  dark?: boolean;
}

function ActionButton({ children, ...props } : ActionButtonProps) {
  const {
    variant, dark, ...other
  } = props;

  const resVariant = variant ?? 'contained';
  return (
    <Button
      className={ dark ? styles.darkbutton : styles.button }
      variant={resVariant}
      {...other}
    >
      { children }
    </Button>
  );
}

export default ActionButton;
