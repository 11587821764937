import { devTools } from '@ngneat/elf-devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  Box, Snackbar,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import './App.scss';
import { useObservable } from '@ngneat/react-rxjs';
import { useTranslation } from 'react-i18next';
import { deepFreeze, elfHooks } from '@ngneat/elf';
import useServiceWorker from './services/useServiceWorker';
import { VERSION } from './utils/version';
import packageJson from '../package.json';
import VersionDialog from './components/VersionDialog/VersionDialog';
import Base from './layouts/Base';
import DrawerMenuWrapper from './feature/DrawerMenu';
import InitUserGuard from './feature/Guards/InitUserGuard/InitUserGuard';
import { activeProjectWithRequestResult$ } from './state/project/project.repository';
import ModalProjectWrapper from './blocks/ModalProject/ModalProject';

devTools();

const addVersionOverlayListener = (setOpen: () => void, open: boolean) => {
  document.addEventListener('keydown', (event: KeyboardEvent) => {
    if (!open && event.altKey && event.code === 'KeyH') {
      setOpen();
    }
  });
};

/* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call */
elfHooks.registerPreStateInit((state) => deepFreeze(state));
elfHooks.registerPreStoreUpdate((_, state) => deepFreeze(state));
/* eslint-enable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call */

function App() {
  const { t } = useTranslation('common');

  const [version] = useState(VERSION || packageJson.version);
  const [open, setOpen] = useState(false);

  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);

  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState<string>();

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [result] = useObservable(activeProjectWithRequestResult$);

  useEffect(() => {
    if (result.isError) {
      const msg = process.env.NODE_ENV === 'production'
        ? t('unable_to_save_project')
        : result.error.message as string;
      setErrorSnackbarMessage(msg);
    }
    setErrorSnackbarOpen(result.isError);
  }, [result]);

  const { waitingWorker, showReload, reloadPage } = useServiceWorker();

  useEffect(() => {
    setUpdateDialogOpen(showReload && !!waitingWorker);
  }, [waitingWorker, showReload, reloadPage]);

  useEffect(() => {
    addVersionOverlayListener(handleClickOpen, open);
  }, []);

  return (
    <Box sx={{ backgroundColor: 'primary.dark' }} className="App">
      <div id="root-portal" />
      <Router>
        <InitUserGuard>
          <DrawerMenuWrapper>
            <ModalProjectWrapper>
              <Base />
            </ModalProjectWrapper>
          </DrawerMenuWrapper>
        </InitUserGuard>

      </Router>

      <VersionDialog
        version={version}
        open={open}
        onClose={handleClose}
        updateReady={updateDialogOpen}
        updateCallback={reloadPage}
      />

      <Snackbar
        open={errorSnackbarOpen}
        message={errorSnackbarMessage}
        onClick={() => setErrorSnackbarOpen(false)}
      />
    </Box>
  );
}

export default App;
