import {
  TextField, FormControl, FormHelperText, Typography,
} from '@mui/material';

import styles from './FormInput.module.scss';

interface FormInputProps {
  placeholder: string;
  onChange?: () => void;
  value?: string;
  helperText?: string | undefined;
  id?: string;
  inputType?: string;
  name?: string;
}

const FormInput = ({
  placeholder, onChange, value, helperText, id, inputType, name,
}: FormInputProps) => (
  <FormControl>
    <TextField
      error={Boolean(helperText)}
      placeholder={placeholder}
      type={inputType ?? 'text'}
      className={styles.textField}
      variant="outlined"
      onChange={onChange}
      value={value || ''}
      aria-describedby="component-helper-text"
      data-testid={id}
      name={name}
    />

    {
      helperText
      && (
      <FormHelperText id="component-helper-text" color="error" data-testid={`${id || 'input'}Error`}>
        <Typography variant="body2" component="span" color="error">
          {helperText}
        </Typography>
      </FormHelperText>
      )
    }

  </FormControl>
);

export default FormInput;
