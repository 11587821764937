import {
  Box, Button, Grid, Stack, Typography, useTheme,
} from '@mui/material';
import { useNavigate, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RecipeIcon } from '../../../../assets/images/recipe.svg';
import { ReactComponent as CartIcon } from '../../../../assets/images/cart.svg';
import { ReactComponent as LayerIcon } from '../../../../assets/images/layer.svg';
import { getProductByLayerIdAsync } from '../../../../state/geoData/products.actions';
import styles from './UiCard.module.scss';
import { createSideMarkup } from '../../../../utils/functions';

interface UiCardProps {
  type: 'recipe' | 'layer';
  title: string;
  annotation: string;
  price?: number;
  id: string;
  territory?: string;
}

const UiCard = (
  {
    type,
    title,
    annotation,
    id,
    price,
    territory,
  }: UiCardProps,
) => {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const navigate = useNavigate();

  const clickBuyButton = async () => {
    if (type === 'recipe') {
      void navigate(generatePath('/recipes/:recipeId', { recipeId: id }));
    } else {
      const productId = await getProductByLayerIdAsync(id);
      void navigate(generatePath('/marketplace/:productId', { productId }));
    }
  };

  return (
    <Grid item xs={1} minHeight="290px">
      <Stack className={styles.stack}>
        <Stack
          direction="column"
          gap="20px"
          height="100%"
        >
          <Box sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
          }}
          >
            <Stack direction="row" gap="5px">
              <Typography
                variant="body2"
                color={`${theme.palette.primary.dark}40`}
              >
                {type === 'recipe'
                  ? t('recipe.self')
                  : t('layer.self')}
              </Typography>
              {type === 'recipe'
                ? <RecipeIcon />
                : <LayerIcon stroke={`${theme.palette.primary.dark}40`} fill="transparent" />}
            </Stack>
          </Box>
          <Typography variant="subtitle1" color="primary.dark" flexGrow={1}>
            { title }
          </Typography>
          <Typography
            variant="body2"
            color={`${theme.palette.primary.dark}50`}
            dangerouslySetInnerHTML={createSideMarkup(annotation)}
          />
          <Stack
            className={styles.ui}
          >
            <Stack
              direction="row"
              alignItems="flex-end"
              gap="10px"
            >
              <Stack direction="column">
                {type === 'layer'
                && (
                <Typography
                  variant="body2"
                  color={`${theme.palette.primary.dark}40`}
                >
                  { territory || '' }
                </Typography>
                )}
                <Typography variant="subtitle1" color="primary.main">
                  { price || t('marketplace.free') }
                </Typography>
              </Stack>
            </Stack>
            <Button
              className={styles.button}
              onClick={clickBuyButton}
            >
              <CartIcon stroke={theme.palette.primary.main} />
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Grid>
  );
};

export default UiCard;
