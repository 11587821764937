import { useEffect, useState } from 'react';
import { useObservable } from '@ngneat/react-rxjs';
import { useTranslation } from 'react-i18next';
import {
  Collapse, FormGroup, ListItemButton, Typography, Divider, Stack,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { IFilter } from '../../models/filters';
import Filter from '../Filter/Filter';
import Search from '../Search/SearchFilter';
import styles from './FilterGroup.module.scss';
import { fetchFilters } from '../../state/filters/filters.effects';
import { productsRequestPending$ } from '../../state/geoData/products.repository';
import { projectsRequestPending$ } from '../../state/project/project.repository';
import { layerDatasetSchedulesPending$ } from '../../state/acl/acl.repository';

interface FilterGroupProps {
  title: string,
  open: boolean,
  onClick: () => void,
  filters: IFilter[],
  activeFiltersIds: string[],
  onFilterChange: (active: boolean, id: string) => void,
  filterType: string,
  dataTestId: string
}

function FilterGroup({
  title, open, onClick, filters, activeFiltersIds, onFilterChange, dataTestId, filterType,
}: FilterGroupProps) {
  const scrollable = filters.length > 10;
  const [hasSearch, setHasSearch] = useState<boolean>(false);
  const [expandEnd, setExpandEnd] = useState(false);
  const [search, setSearch] = useState('');
  const [productsRequestPending] = useObservable(productsRequestPending$, { initialValue: true });
  const [projectsRequestPending] = useObservable(projectsRequestPending$, { initialValue: true });
  const [layerDatasetSchedulesPending] = useObservable(
    layerDatasetSchedulesPending$,
    { initialValue: true },
  );

  const { t } = useTranslation('common');

  useEffect(() => {
    if (!hasSearch && filters.length > 0) {
      setHasSearch(scrollable);
    }
  }, [scrollable]);

  const getNestedFilters = (parentFilter: IFilter) => {
    const children = filters.filter((f) => f.parent === parentFilter.id);
    return children.length
      ? children
      : undefined;
  };

  const getActiveNestedFiltersIds = (parentFilter: IFilter) => {
    const nestedFilters = getNestedFilters(parentFilter);
    if (!nestedFilters) {
      return undefined;
    }

    return activeFiltersIds
      .filter((id) => nestedFilters
        .map((f) => f.id)
        .includes(id));
  };

  const isDisabled = productsRequestPending
    || projectsRequestPending
    || layerDatasetSchedulesPending;

  return (
    <FormGroup>
      <ListItemButton
        onClick={onClick}
        className={styles.listItemButton}
        data-testid={dataTestId}
      >
        <Typography mt={1} variant="body1">
          {title}
        </Typography>
        { open
          ? <ExpandLess className={styles.expandIcon} />
          : <ExpandMore className={styles.expandIcon} />}
      </ListItemButton>
      <Collapse
        addEndListener={() => setExpandEnd(true)}
        in={open}
        timeout="auto"
        unmountOnExit
        className={styles.collapse}
      >
        <Divider className={styles.divider} />
        { hasSearch
          ? (
            <Search
              placeholder={t('layer.filter_search')}
              defaultValue={search}
              handleInputChange={(search) => {
                void fetchFilters(filterType, search);
                setSearch(search);
              }}
            />
          )
          : null}
        <Stack
          className={styles.filtersContainer}
          onScroll={ scrollable
            ? (event) => {
              const { target } = (event as unknown) as { target: HTMLElement };
              const lastElement = target.lastChild as HTMLElement;
              const targetEnd = Math.round(target.getBoundingClientRect().bottom);
              const listEnd = Math.round(lastElement.getBoundingClientRect().bottom);
              if (expandEnd && targetEnd === listEnd) {
                void fetchFilters(filterType, search);
              }
            }
            : undefined}
        >
          {
            filters
              .filter((filter) => !filter.parent)
              .map((filter) => (
                <Filter
                  id={filter.id}
                  key={filter.id}
                  title={filter.title}
                  active={activeFiltersIds.includes(filter.id)}
                  onChange={onFilterChange}
                  nestedFilters={getNestedFilters(filter)}
                  activeNestedFiltersIds={getActiveNestedFiltersIds(filter)}
                  scrollable={scrollable}
                  disabled={isDisabled}
                />
              ))
          }
        </Stack>
      </Collapse>
    </FormGroup>
  );
}

export default FilterGroup;
