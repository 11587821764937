import {
  Box, Button, InputLabel, Select, Slider, Stack, Switch, Typography,
} from '@mui/material';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import FullscreenRoundedIcon from '@mui/icons-material/FullscreenRounded';
import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ProjectPrice from '../../components/ProjectPrice';
import styles from './SideCalculation.module.scss';

function SideCalculation() {
  const [value, setValue] = React.useState<number[]>([20, 37]);
  const { t } = useTranslation('common');

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };
  return (
    <Stack p="40px" spacing={2} height="100%">
      <Link to="/map">
        <Button className={styles.button} variant="text" startIcon={<ArrowBackIosNewRoundedIcon color="secondary" />}>
          { t('map.back') }
        </Button>
      </Link>

      <Typography variant="body2" color="secondary">
        { t('map.calc_area') }
      </Typography>

      <Box mt={2} className={styles.box}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="body2" color="secondary">
            { t('map.calc_area') }
          </Typography>

          <Stack direction="row" alignItems="center">
            <FullscreenRoundedIcon color="primary" />
            <Typography variant="body2" color="secondary">3 268 м²</Typography>
          </Stack>
        </Stack>
      </Box>

      <Box>
        <Typography variant="title2" color="secondary">Выбрать атрибут из списка</Typography>

        <Box mt={1}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" sx={{ color: 'secondary.main' }}>Выберите слой, например</InputLabel>
            <Select
              className={styles.select}
              label="Выберите модель"
            >
              <MenuItem value={10}>Слой 1</MenuItem>
              <MenuItem value={20}>Слой 2</MenuItem>
              <MenuItem value={30}>Слой 3</MenuItem>
            </Select>
          </FormControl>
        </Box>

      </Box>

      <Box mt={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="title2" color="secondary">Атрибут-слайдер</Typography>

          <Typography variant="title2" color="secondary">500 — 2 500</Typography>
        </Stack>

        <Box mt={1} p="4px 12px" className={styles.box}>
          <Slider
            size="small"
            getAriaLabel={() => 'range'}
            value={value}
            onChange={handleChange}
            valueLabelDisplay="auto"
          />
        </Box>

        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="title2" color="secondary">Атрибут с полем</Typography>

          <Typography variant="title2" color="secondary">100</Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="title2" color="secondary">Атрибут с переключателем</Typography>

          <Switch />
        </Stack>
      </Box>

      <div className={styles['price-container']}>
        <Box mt="auto" width="100%">
          <ProjectPrice
            to="/map"
            buttonLabel="Рассчитать"
          />
        </Box>
      </div>
    </Stack>
  );
}

export default SideCalculation;
