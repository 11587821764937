// eslint-disable-next-line max-classes-per-file
import * as LOriginal from 'leaflet';
import {
  ControlOptions, Map, LayerOptions, Layer, TileLayer,
} from 'leaflet';
import '@geoman-io/leaflet-geoman-free';
import { PluginFormula } from '../../models/formula-layer';

export interface ControlOptionsExtended extends ControlOptions {
  [key: string]: any;
}

export interface LayerOptionsExtended extends LayerOptions {
  [key: string]: any;
}

export interface FormulaLayer extends LOriginal.Layer {
  updateFormula(obj: { formula: PluginFormula }): void;
  getCanvas(): HTMLElement;
}

export interface LayerListStyler extends LOriginal.Control.Layers {
  addTo(map: typeof L.LeafletMap): this;
  addTo(map: Map): this;
  // TODO: no unknown
  updateLayers: (layerObj: unknown) => void;
  initLayer: (config: unknown, showWarns: boolean) => TileLayer | null;
}

export interface Multipuncture {
  getMultipuncture(layerObj: {}, latlng: LOriginal.LatLng): {
    [key: string]: any;
    L: { [key: string]: number[]; }
  };
}

export interface LayerExtended extends JSX.Element {
  _platforma_id: string;
}

export class MapControl {
  options: ControlOptionsExtended;

  container!: HTMLElement;

  link!: HTMLElement;

  constructor(options: ControlOptionsExtended) {
    this.options = options;
  }

  /* eslint-disable  */
  setDrawing(arg0: boolean) {}
  on(arg0: string, arg1: (args?: unknown) => void) {}
  setIchProp(args: any) {}
  /* eslint-enable  */
}

export interface LeafletMap extends Map {
  addControl(control: MapControl): Lextended.Control;
  addControl(control: LOriginal.Control): this;
  eachLayer(fn: (layer: Layer) => void, context?: any): this;
  eachLayer(fn: (layer: LayerExtended) => void, context?: any): LeafletMap;
  addLayer(layer: FormulaLayer): this;
  removeLayer(layer: FormulaLayer): this;
  state: {
    [key: string]: any;
  }
}

declare module Lextended {

  namespace PM {
    export class Toolbar {
      static LayerToExtendJSON(value: unknown): unknown;

      static createLayersFromJSON(value: unknown): unknown;

      static enable(): void;
    }
  }

  namespace Control {
    let options: ControlOptionsExtended;
    export class Measurement {
      static extend<T extends object>(props: T): {
        new(...args: any[]): T
      } & typeof MapControl;
    }
    export class Isochrone {
      container: HTMLElement;
      static extend<T extends object>(props: T): {
        new(...args: any[]): T
      } & typeof MapControl;
    }
  }

  namespace Multipuncture {
    let options: ControlOptionsExtended;
  }

  export class Control {
    options: ControlOptionsExtended;
    constructor(options: ControlOptionsExtended);
  }

  export class LeafletMap extends Map {
    addControl(control: Control): this;
    addLayer(layer: FormulaLayer): this;
    removeLayer(layer: FormulaLayer): this;
  }

  export class FormulaLayer extends LOriginal.Layer {
    constructor(options: LayerOptionsExtended);
    updateFormula(obj: { formula: PluginFormula }): void;
    bringToFront(): this;
    getCanvas(): HTMLElement;
  }

  export class LayerListStyler extends LOriginal.Control.Layers {
    addTo(map: typeof L.LeafletMap): this;

    addTo(map: Map): this;

    updateLayers: (layerObj: unknown) => void;

    initLayer: (config: unknown, showWarns: boolean) => TileLayer | null;

    constructor(options: ControlOptionsExtended);
  }

  export class Multipuncture {
    options: ControlOptionsExtended;

    constructor(options: ControlOptionsExtended);

    getMultipuncture(layerObj: {}, latlng: LOriginal.LatLng): {
      [key: string]: any;
      L: { [key: string]: number[]; }
    };
  }

  export namespace Util {
    export class Toggler extends LOriginal.Control {
      static registerTool: (tool: typeof L.PM.Toolbar) => void;

      static disableAll: () => void;

      static enableAll: () => void;
    }
  }

  export namespace tileLayer {
    export function canvas(uri: string, options: LayerOptionsExtended): TileLayer;
  }
}

export const L: (typeof Lextended & typeof LOriginal) = LOriginal as any;

export interface ToolBarButtonEvent {
  text: string;
}
export interface ToolBarActionEvent {
  text: string;
}
