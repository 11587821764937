import { useContext, useEffect, useState } from 'react';
import {
  Box, Typography, TextField, Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useObservable } from '@ngneat/react-rxjs';
import {
  activeOrder$,
  activeOrderLayerSchedules$,
  orderDiscountPercent$,
  orderLayerSchedules$,
  orderPrice$,
  promocode$,
  setPromocode,
} from '../../state/order';
import { productTypes$ } from '../../state/filters/filters.repository';
import { fetchProductTypes } from '../../state/filters/filters.effects';
import PaymentButton from '../../blocks/PaymentButton/PaymentButton';
import ScheduleTable from '../ScheduleTable/ScheduleTable';
import { ordersRequestPending$ } from '../../state/order/order.repository';
import { DrawerContext } from '../../drawerContext';
import styles from './Cart.module.scss';
import Loader from '../Loader/Loader';

function Cart() {
  const { t } = useTranslation('common');

  const [orderItems] = useObservable(orderLayerSchedules$);
  const [orderPrice] = useObservable(orderPrice$);
  const [orderDiscountPercent] = useObservable(orderDiscountPercent$);
  const [productTypes] = useObservable(productTypes$);

  const [promocode] = useObservable(promocode$);
  const [order] = useObservable(activeOrder$);
  const [activeLayerSchedules] = useObservable(activeOrderLayerSchedules$);
  const [ordersRequestPending] = useObservable(ordersRequestPending$);

  const [recipeEmail, setRecipeEmail] = useState('');
  const [recipeEmailError, setRecipeEmailError] = useState(false);
  const [localPromocode, setLocalPromocode] = useState('');

  const drawerContext = useContext(DrawerContext);

  useEffect(() => {
    if (!productTypes.length) {
      void fetchProductTypes();
    }
  }, []);

  useEffect(() => {
    const timeOut = setTimeout(() => void setPromocode(localPromocode), 500);
    return () => clearTimeout(timeOut);
  }, [localPromocode]);

  useEffect(() => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
    const isEmailValid = !!recipeEmail && regex.test(recipeEmail.replace(/\s/g, ''));
    setRecipeEmailError(!isEmailValid);
  }, [recipeEmail]);

  const cart = () => (
    <Stack height="100%" display="flex" flexDirection="column">
      <Box flexGrow="1" display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between" mb="20px">
          <Box display="flex">
            <ArrowBackOutlinedIcon
              onClick={() => drawerContext.close()}
              className={styles.IconBack}
            />
            <Typography variant="h4" className={styles.color}>{t('cart.cart')}</Typography>

            <Box className={styles.quantity}>
              <Typography variant="accentTitle1" className={styles.color}>{orderItems.length}</Typography>
            </Box>
          </Box>
          <CloseIcon data-testid="closeCartIcon" onClick={() => drawerContext.close()} className={styles.IconClose} />
        </Box>
        <ScheduleTable items={orderItems} discount={orderDiscountPercent} />
      </Box>
      <Box>
        <Box position="relative" sx={{ pt: '7px' }}>
          <TextField
            data-testid="emailInputField"
            className={styles.textField}
            value={recipeEmail || ''}
            onChange={(e) => setRecipeEmail(e.target.value)}
            placeholder={t('cart.recipe_email')}
            autoComplete="off"
            fullWidth
            error={!recipeEmail || recipeEmailError}
          />
          {recipeEmail && (
            <Typography
              className={styles.label}
              variant="body2"
            >
              { t('side.email') }
            </Typography>
          )}
        </Box>
        <Box position="relative" sx={{ pt: '7px' }}>
          <TextField
            data-testid="promocodeInputField"
            className={styles.textField}
            value={localPromocode || ''}
            onChange={(e) => setLocalPromocode(e.target.value)}
            placeholder={t('cart.promocode_sales')}
            autoComplete="off"
            fullWidth
          />
          {localPromocode && (
            <Typography
              className={styles.label}
              variant="body2"
            >
              { t('cart.promocode') }
            </Typography>
          )}
        </Box>
        <Box className={styles.sum}>
          <Box>
            <Typography className={styles.color} variant="body2">
              {activeLayerSchedules.length}
              {t('cart.position')}
            </Typography>
            <Typography data-testid="totalPriceText" className={styles.color} variant="h3">
              {`${orderPrice ?? '---'}₽`}
            </Typography>
          </Box>
          <PaymentButton
            orderId={order!.id}
            orderItems={activeLayerSchedules}
            productTypes={productTypes}
            codeText={promocode}
            discount={orderDiscountPercent}
            email={recipeEmailError ? '' : recipeEmail}
          />
        </Box>
      </Box>
    </Stack>
  );

  return (
    <Box className={styles.progress}>
      { ordersRequestPending
        ? <Loader id="progress-cart" />
        : cart() }
    </Box>
  );
}

export default Cart;
