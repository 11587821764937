import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { ChangeEvent } from 'react';
import checkboxImg from '../../../../assets/images/checkboxLight.svg';
import checkboxHoverImg from '../../../../assets/images/checkbox-hover.svg';
import checkedImg from '../../../../assets/images/checked-checkbox.svg';
import checkedHoverImg from '../../../../assets/images/checked-checkbox-hover.svg';
import styles from './RubricFilter.module.scss';
import { GpRubric } from '../../../../api/types';

interface Props {
  filter: GpRubric,
  checked: boolean,
  indeterminate?: boolean | undefined,
  isHover: boolean,
  onChange: ((event: ChangeEvent<HTMLInputElement>, checked: boolean) => void) | undefined,
}

function RubricFormContolLabel({
  filter,
  checked,
  indeterminate,
  isHover,
  onChange,
}: Props) {
  return (
    <FormControlLabel
      key={filter.id}
      className={styles.formControlLabel}
      control={(
        <Checkbox
          className={styles.checkbox}
          icon={<img src={isHover ? checkboxHoverImg : checkboxImg} alt="" />}
          checkedIcon={<img src={isHover ? checkedHoverImg : checkedImg} alt="" />}
          indeterminateIcon={<img src={isHover ? checkedHoverImg : checkedImg} alt="" />}
          value={checked}
          checked={checked}
          indeterminate={indeterminate}
          onChange={onChange}
        />
      )}
      label={(
        <Typography
          className={
            filter.parent
              ? styles.rubricTitle
              : styles.rubricTitleRoot
          }
        >
          {filter.title}
        </Typography>
      )}
      labelPlacement="end"
    />
  );
}

export default RubricFormContolLabel;
