import { styled, TextField } from '@mui/material';

const ProjectNameTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '&': {
      width: '100%',
      borderRadius: '20px',
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: '1.2rem',
      lineHeight: '1.5rem',
      color: '#fff',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
    },
    '& input': {
      padding: '12px 14px',
    },
    '& fieldset': {
      borderColor: '#fff',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#fff',
      borderWidth: '1px',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-error fieldset': {
      borderColor: theme.palette.error.main,
    },
    '& .MuiSvgIcon-root': {
      color: '#fff',
    },
  },
}));

export default ProjectNameTextField;
