import LayersIcon from '@mui/icons-material/Layers';
import { ProductType, ProductTypeId } from '../../../../../models/product';
import { ReactComponent as LayerIcon } from '../../../../../assets/images/layerIcon.svg';
import { ReactComponent as ReportIcon } from '../../../../../assets/images/reportIcon.svg';
import { ReactComponent as RecipeIcon } from '../../../../../assets/images/recipeIcon.svg';
import { ReactComponent as DataIcon } from '../../../../../assets/images/dataIcon.svg';
import { ReactComponent as BIIcon } from '../../../../../assets/images/chart.svg';
import { ReactComponent as GeoIcon } from '../../../../../assets/images/global.svg';
import { ProjectTypeIds } from '../../../../../models/project';
import {
  GpLayer, GpStatDataset, GpProductTypes,
} from '../../../../../api/types';
import { LayerType } from '../../../../../components/AclViews/AclViewsHelpers';

export function getProductIcon(id: string) {
  switch (id) {
    case ProductTypeId.Layer:
      return <LayerIcon color="primary" fontSize="small" />;
    case ProductTypeId.StatDataset:
      return <DataIcon color="primary" fontSize="small" />;
    case ProductTypeId.Report:
      return <ReportIcon color="primary" fontSize="small" />;
    case ProductTypeId.Recipe:
      return <RecipeIcon color="primary" fontSize="small" />;
    case ProjectTypeIds.GPBIProject:
      return <BIIcon color="primary" fontSize="small" />;
    case ProjectTypeIds.GPProject:
      return <GeoIcon color="primary" fontSize="small" />;
    case ProductTypeId.CommercialService:
    case ProductTypeId.GeoModel:
    default:
      return <LayersIcon color="primary" fontSize="small" />;
  }
}

export function getValuesName(id: string) {
  switch (id) {
    case ProductTypeId.Report:
      return 'marketplace.instruments';
    case ProductTypeId.Recipe:
      return 'marketplace.ingredients';
    default:
      return 'marketplace.actual_periods';
  }
}

export function getProductType(product: GpLayer | GpStatDataset, productTypes: GpProductTypes[]) {
  return ('layer' in product.schedules[0]
    ? productTypes.find((type) => type.id === ProductTypeId.Layer)
    : productTypes.find((type) => type.id === ProductTypeId.StatDataset)) as GpProductTypes;
}

export function getProductName(productType: LayerType | undefined) {
  switch (productType) {
    case ProductType.Layer:
      return 'Геоданные';
    case ProductType.StatDataset:
      return 'Стат. данные';
    default:
      return null;
  }
}

export function getAclProductIcon(productType: LayerType | undefined) {
  switch (productType) {
    case ProductType.Layer:
      return <LayerIcon color="primary" fontSize="small" />;
    case ProductType.StatDataset:
      return <DataIcon color="primary" fontSize="small" />;
    default:
      return null;
  }
}
