import {
  TextField, Stack, Typography, IconButton,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useObservable } from '@ngneat/react-rxjs';
import { ReactComponent as Create } from '../../assets/images/edit.svg';
import {
  activeProject$, getCreatedProjectId, resetCreatedProjectId,
} from '../../state/project/project.repository';
import { PROJECT_NAME_FROM_RECIPE_REGEX } from '../../utils/constants';
import styles from './ProjectTitle.module.scss';
import { updateBiProjectProp, updateGeoProjectProp } from '../../state/project/project.actions';

function ProjectTitle() {
  const [activeProject] = useObservable(activeProject$);
  const [isCreated, setIsCreated] = useState(false);
  const [isEdit, setEditor] = useState(false);
  const [isProjectReady, setIsProjectReady] = useState(false);
  const project = activeProject?.project?.[0];

  const { control, handleSubmit, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      title: PROJECT_NAME_FROM_RECIPE_REGEX.test(project?.title) ? '' : project?.title || '',
    },
  });

  useEffect(() => {
    if (!isProjectReady && activeProject) {
      setIsProjectReady(true);
    }
    if (activeProject && activeProject.id === getCreatedProjectId()) {
      setIsCreated(true);
      resetCreatedProjectId();
    }
  }, [activeProject]);

  useEffect(() => {
    if (isProjectReady && activeProject) {
      setValue(
        'title',
        PROJECT_NAME_FROM_RECIPE_REGEX.test(project?.title) ? '' : project?.title,
      );
    }
  }, [isProjectReady, project?.title]);
  const onSubmit = async (data: { title: string }) => {
    if ('title' in data && project?.title !== data.title && data.title) {
      if ('layers' in project.recipe[0]) {
        void updateGeoProjectProp(data.title.trim(), 'title');
      } else {
        void updateBiProjectProp(data.title.trim(), 'title');
      }
    }
    setIsCreated(false);
    setEditor(false);
  };

  return (
    <Stack
      sx={{
        height: '100%', display: 'flex', flexDirection: 'column',
      }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      onBlur={handleSubmit(onSubmit)}
    >
      {
        (isCreated || isEdit) && (
        <Controller
          name="title"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              className={styles.TextField}
              hiddenLabel
              data-testid="projectTitle"
              name="title"
              focused
              autoFocus
              value={value}
              onChange={onChange}
              sx={{ width: '360px', ml: '20px' }}
              placeholder={
                PROJECT_NAME_FROM_RECIPE_REGEX.test(project?.title)
                  ? project?.title.slice(3, -3)
                  : project?.title
              }
            />
          )}
        />
        )
      }
      {
        (!isCreated && !isEdit) && (
        <Stack className={styles.title}>
          <Typography variant="body1" fontSize="20px" fontWeight="600" style={{ wordBreak: 'break-all' }}>
            {
              PROJECT_NAME_FROM_RECIPE_REGEX.test(project?.title)
                ? project?.title.slice(3, -3)
                : project?.title
            }
          </Typography>
          <IconButton onClick={() => setEditor(true)} sx={{ height: '40px' }} data-testid="TitleEditButton">
            <Create />
          </IconButton>
        </Stack>
        )
      }
    </Stack>
  );
}

export default ProjectTitle;
