import ErrorScreen from './pages/ErrorScreen/ErrorScreen';

interface FallBackProps {
  error: Error;
  eventId: string | null;
  resetError(): void;
}

const FallbackComponent = ({
  error, eventId, resetError,
}: FallBackProps) => (
  <ErrorScreen onClick={() => {
    const popstateHandler = () => {
      resetError();
      window.removeEventListener('popstate', popstateHandler);
    };
    window.addEventListener('popstate', popstateHandler);
    window.history.go(-1);
    // eslint-disable-next-line no-console
    console.error('source', eventId, error);
  }}
  />
);

export default FallbackComponent;
