import { LatLng } from 'leaflet';
import { GpProject } from '../api/types';
import { updateProjectPositionAndZoom } from '../state/project/project.actions';
import { LeafletMap } from '../types/leaflet';

const usePositionsave = (map: LeafletMap, activeProject: GpProject): void => {
  if (activeProject?.markersCfg?.position) {
    const { position } = activeProject.markersCfg;
    map.panTo(new LatLng(position.lat, position.lng));
  }

  if (activeProject?.markersCfg?.zoom) {
    map.setZoom(activeProject.markersCfg.zoom);
  }

  map.on('moveend', (evt) => {
    const center = (evt.sourceTarget as LeafletMap).getCenter();
    const zoom = (evt.sourceTarget as LeafletMap).getZoom();

    void updateProjectPositionAndZoom(center, zoom);
  });
};

export default usePositionsave;
