import { useState } from 'react';
import { PluginFormula } from '../models/formula-layer';
import { GpLayerFormula } from '../api/types';
import { L, FormulaLayer } from '../types/leaflet';
import { DEFAULT_PALETTE } from '../utils/constants';
import loadScript from './script.service';

interface UseFormulaReturnValue {
  formulaLayer: FormulaLayer | null;
  loadFormula: (
    lgroup: { [key: string]: any; },
    activeFormula: GpLayerFormula,
  ) => void;
}

const FORMULA_PLUGIN_PATH = '/static/leaflet/v2/leaflet-formula-layer-min.js';
const RENDER_WASM_PATH = '/static/leaflet/v2/render.wasm';

export const gpFormulaToPluginFormula = (
  activeFormula: GpLayerFormula,
): PluginFormula => {
  const formula = JSON.parse(JSON.stringify(activeFormula)) as GpLayerFormula;
  const layers: { [key: string]: number[]; } = {};
  formula.L.forEach((l) => { layers[l.title] = [l.min, l.max, l.weight]; });
  const newFormula: PluginFormula = {
    L: layers,
    M: activeFormula.M,
    O: activeFormula.O,
    I: activeFormula.I,
    P: DEFAULT_PALETTE,
  };
  return newFormula;
};

export const useFormulaLayer = (): UseFormulaReturnValue => {
  const [formulaLayer, setFormula] = useState<FormulaLayer | null>(null);

  const loadFormula = (
    layerGroup: { [key: string]: any; },
    activeFormula: GpLayerFormula,
  ) => {
    loadScript(FORMULA_PLUGIN_PATH, () => {
      const pluginFormula = gpFormulaToPluginFormula(activeFormula);

      const formulaLayerControl: FormulaLayer = new L.FormulaLayer({
        debug: true,
        formula: pluginFormula,
        layerGroup,
        paletteVisible: false,
        wasmUrl: RENDER_WASM_PATH,
        zIndex: 1000,
      });

      setFormula(formulaLayerControl);
    });
  };

  return { formulaLayer, loadFormula };
};
