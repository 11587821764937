import { useState, useEffect } from 'react';

interface SynthMouseEvent extends MouseEvent {
  layerX: number,
  layerY: number,
}

function useRubricsBody() {
  const [leftBlock, setLeftBlock] = useState<HTMLElement>();
  const [centralBlock, setCentralBlock] = useState<HTMLElement>();
  const [mainWrapper, setMainWrapper] = useState<HTMLElement>();

  useEffect(() => {
    if (leftBlock && centralBlock && mainWrapper) {
      const { width } = mainWrapper.getBoundingClientRect();
      const gap = window.innerWidth * 0.025;
      const dragHandler = (event: MouseEvent) => {
        if (
          leftBlock.getBoundingClientRect().width >= 210
          && centralBlock.getBoundingClientRect().width >= 210
        ) {
          const leftX = event.clientX - mainWrapper.getBoundingClientRect().x;
          const rightX = width - leftX;
          (leftBlock.parentElement as HTMLElement).style.width = `${String(Math.max(Math.min(leftX, width - 230), 230))}px`;
          // eslint-disable-next-line max-len
          (centralBlock.parentElement as HTMLElement).style.width = `${String(Math.max(Math.min(rightX, width - 230), 230))}px`;
          event.preventDefault();
        }
      };

      mainWrapper.addEventListener('mousedown', ((event: SynthMouseEvent) => {
        if (
          Math.abs(
            event.pageX - gap - leftBlock.getBoundingClientRect().width,
          ) < 5
        ) {
          mainWrapper.addEventListener('mousemove', dragHandler);
          event.preventDefault();
        }
      }) as (e: MouseEvent) => void);

      mainWrapper.addEventListener('mousemove', ((event: SynthMouseEvent) => {
        setTimeout(() => {
          if (
            Math.abs(
              event.pageX - gap - leftBlock.getBoundingClientRect().width,
            ) < 5
          ) {
            mainWrapper.style.cursor = 'col-resize';
          } else {
            mainWrapper.style.cursor = 'default';
          }
        });
      }) as (e: MouseEvent) => void);

      document.addEventListener('mouseup', () => {
        mainWrapper.removeEventListener('mousemove', dragHandler);
      });
    }
  }, [leftBlock, centralBlock, mainWrapper]);

  useEffect(() => {
    setLeftBlock(document.querySelector('[class*="rubricsBodyLeftBlock"') as HTMLElement);
    setCentralBlock(document.querySelector('[class*="rubricsBodyCentralBlock"') as HTMLElement);
    setMainWrapper(document.querySelector('[class*="rubricsBodyWrapper"]') as HTMLElement);
  }, []);
}

export default useRubricsBody;
