import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardContent,
  Typography,
  Stack,
  styled,
  CardProps,
  useTheme,
} from '@mui/material';
import { createSideMarkup } from '../../../../utils/functions';
import bg1 from '../../../../assets/images/feature-card/bg-1.svg';
import bg2 from '../../../../assets/images/feature-card/bg-2.svg';
import bg3 from '../../../../assets/images/feature-card/bg-3.svg';
import bg4 from '../../../../assets/images/feature-card/bg-4.svg';
import bg5 from '../../../../assets/images/feature-card/bg-5.svg';
import bg6 from '../../../../assets/images/feature-card/bg-6.svg';

import styles from './FeatureCard.module.scss';

const bgArray: string[] = [bg1, bg2, bg3, bg4, bg5, bg6];

const getRandomBg = (): string => {
  const randomIndex = Math.floor(Math.random() * (bgArray.length));
  return bgArray[randomIndex];
};

const CardStyled = styled(Card)((props: CardProps & { bgimage: string }) => ({
  backgroundImage: `url(${props.bgimage})`,
}));

export interface FeatureCardProps {
  title?: string;
  description: string;
  isStageCard?: boolean;
  stageNumber?: number;
  isRecipeValueCard?: boolean;
}

const FeatureCard = ({
  title,
  description,
  isStageCard,
  stageNumber,
  isRecipeValueCard,
}: FeatureCardProps) => {
  const [background, setBackground] = useState<string>('');
  const { t } = useTranslation('common');
  const theme = useTheme();

  useEffect(() => {
    setBackground(getRandomBg());
  }, []);

  return (
    <CardStyled bgimage={background} className={styles.card}>
      <CardContent
        className={styles.cardContent}
      >
        { !isRecipeValueCard
          ? (
            <Stack direction="column" height="100%">
              {isStageCard
                && (
                  <Typography
                    variant="title2"
                    color="text.disabled"
                    textAlign="right"
                  >
                    {`${t('recipe.stage')}${stageNumber as number}`}
                  </Typography>
                )}
              <Typography variant="featureCardTitle" color="primary.dark" mt="30px">
                { title }
              </Typography>
              {isStageCard
                ? (
                  <Typography
                    variant="textCard"
                    dangerouslySetInnerHTML={createSideMarkup(description)}
                    className={styles.description}
                  />
                )
                : (
                  <Typography
                    variant="textCard"
                    color={`${theme.palette.primary.dark}50`}
                    maxWidth="155px"
                    className={styles.description}
                  >
                    {description}
                  </Typography>
                )}
            </Stack>
          )
          : (
            <>
              <Typography variant="featureCardTitle" color="primary.dark" className={styles.title}>
                {title}
              </Typography>
              <Typography
                variant="textCard"
                className={styles.description}
                dangerouslySetInnerHTML={createSideMarkup(description)}
                tabIndex={-1}
              />
            </>
          )}
      </CardContent>
    </CardStyled>
  );
};

export default FeatureCard;
