import { LatLngBoundsLiteral } from 'leaflet';
import { ApolloQueryResult } from '@apollo/client';
import wkt from 'wkt';
import i18next from 'i18next';
import { GpCmsPage, GpLayerFormula, GpuiLayer } from '../api/types';
import {
  DEFAULT_HEAD_META, DEFAULT_HEAD_TITLE,
  PROJECT_NAME_FROM_RECIPE_REGEX,
} from './constants';
import { tryGetResponseData } from '../state/common';

declare global {
  interface Window {
    ym: (arg1: number, arg2: string, arg3: string) => void;
  }
}

export function createSideMarkup(htmlString: string | undefined) {
  return { __html: htmlString || '' };
}

export const convertCmsUpdateDateToISO = (cmsupdatedate: string | null) => (
  cmsupdatedate
    ? new Date(+cmsupdatedate).toISOString()
    : null
);

export const wrapRecipeNameToProjectName = (recipeName: string): string => `<_<${recipeName}>_>`;

export const randomInt = (
  min: number,
  max: number,
): number => Math.floor(Math.random() * (max - min + 1) + min);

export const isGPUILayer = (
  layer: GpLayerFormula | GpuiLayer | undefined,
): layer is GpuiLayer => !!layer && (layer as GpuiLayer).layer !== undefined;

export const resultingValueMapper = (value: number): string => {
  if (value > 199) return 'Нет';
  let res = ((200 - value) / 2);

  const temp = Math.floor(res);
  res -= temp;

  return res >= 0.5 ? String(temp + 0.5) : `${temp}.0`;
};

export const moveArrayItem = <T>(
  arr: T[],
  fromIndex: number,
  toIndex: number,
) => {
  const arrCopy = arr.slice();
  const itemRemoved = arrCopy.splice(fromIndex, 1);
  arrCopy.splice(toIndex, 0, itemRemoved[0]);
  return arrCopy;
};

export const sortArrayByIds = <T extends { [key: string]: any; }> (
  array: T[], ids: string[], key: keyof T) => {
  const sortHelper: { [key: string]: number; } = {};
  ids.forEach((id, i) => { sortHelper[id] = i; });

  return array.slice().sort((a, b) => sortHelper[a[key]] - sortHelper[b[key]]);
};

export function isWebGLSupported(): boolean {
  if (window?.WebGLRenderingContext !== undefined) {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    return canvas.getContext('webgl2') != null;
  }
  return false;
}

export function sendMetricReachGoal(tagName: string) {
  if (window.ym) window.ym(90942759, 'reachGoal', tagName);
}

type GeoJsonPolygon = { type?: string, coordinates?: [number, number][][] };

export function getBoundsFromWKT(geometryWKT: string): LatLngBoundsLiteral {
  // @ts-ignore
  const geoJson: GeoJsonPolygon | null = wkt.parse(geometryWKT);
  if (!geoJson) {
    throw new Error("Couldn't parse bounds WKT");
  }
  if (geoJson.type !== 'Polygon') {
    throw new Error(`Type of bounds wkt should be "Polygon" not "${geoJson.type ?? 'undefined'}"`);
  }
  if (!geoJson.coordinates?.[0]) {
    throw new Error("Can't get coordinates");
  }
  if (geoJson.coordinates[0].length < 4) {
    throw new Error(`Expected at least 4 points, got ${geoJson.coordinates[0].length}`);
  }

  let minLat = Number.POSITIVE_INFINITY;
  let minLng = Number.POSITIVE_INFINITY;
  let maxLat = Number.NEGATIVE_INFINITY;
  let maxLng = Number.NEGATIVE_INFINITY;

  geoJson.coordinates[0].forEach(([lng, lat]) => {
    minLat = Math.min(lat, minLat);
    minLng = Math.min(lng, minLng);
    maxLat = Math.max(lat, maxLat);
    maxLng = Math.max(lng, maxLng);
  });

  return [[minLat, minLng], [maxLat, maxLng]];
}

export function setTitleAndMeta(page: GpCmsPage | null) {
  const defaultContent = i18next.t(DEFAULT_HEAD_META, { ns: 'common' });
  const content = page?.htmlDescription ? page.htmlDescription : defaultContent;
  const meta = document.querySelector("meta[name='description']");

  document.title = page?.title ? page.title : DEFAULT_HEAD_TITLE;

  if (meta) {
    meta.setAttribute('content', content);
  } else {
    const meta = document.createElement('meta');
    meta.setAttribute('name', 'description');
    meta.setAttribute('content', content);
    document.head.appendChild(meta);
  }
}

export const getCaseOfCount = (count: number, arrStr: string[]): string => {
  const cases = [2, 0, 1, 1, 1, 2];
  return arrStr[count % 100 > 4 && count % 100 < 20 ? 2 : cases[(count % 10 < 5) ? count % 10 : 5]];
};

export const createTooltip = (title: string, dir: string, e: HTMLElement) => {
  const tooltip = document.createElement('span');
  e.addEventListener('mouseover', () => {
    if (tooltip.parentElement !== e
        && !e.classList.contains('disabled')
        && !e.parentElement?.parentElement?.classList.contains('active')
        && !e.classList.contains('icon-active')) {
      tooltip.innerHTML = title;
      tooltip.classList.add('leaflet-tooltip', `tooltip-${dir}`);
      e.title = '';
      e.append(tooltip);
    }
  });

  e.addEventListener('mouseout', () => {
    tooltip.remove();
    e.title = title;
  });
};

export function removeSpecialChar(str: string) {
  return PROJECT_NAME_FROM_RECIPE_REGEX.test(str) ? str.slice(3, -3) : str;
}

export function getUniqueId(): string {
  const stringArr = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 4; i++) {
    // eslint-disable-next-line no-bitwise
    const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    stringArr.push(S4);
  }
  return stringArr.join('-');
}

interface IGpRes<T> {
  list: {
    data: Array<T>,
    hasMore: boolean,
  },
}

type IQuery<T> = (...args: any[]) => Promise<ApolloQueryResult<T>>;

type IQProp<T = {}> = { [propKey: string]: T };

export function queriesWrapper<T>(query: IQuery<IQProp>, qPage: number, qLimit: number)
  : (...args: any[]) => Promise<IQProp<IGpRes<T>>> {
  const resQuery = async (...args: Parameters<IQuery<IQProp>>) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const firstQueryResult = tryGetResponseData(await query(...args, qPage, qLimit)) as IQProp<IGpRes<T>>;
    const GpRes = firstQueryResult[Object.keys(firstQueryResult)[0]];
    const serverLimit = GpRes.list.data.length;

    if (qLimit > serverLimit && GpRes.list.hasMore) {
      const page = (qPage * qLimit) / serverLimit;
      let newLimit = serverLimit;

      const res = JSON.parse(JSON.stringify(firstQueryResult)) as IQProp<IGpRes<T>>;

      const promisesList: Promise<ApolloQueryResult<IQProp<{}>>>[] = [];
      let count = 1;
      while ((serverLimit * count) < qLimit) {
        let newPage = page + count;
        if ((serverLimit * (count + 1)) > qLimit) {
          newLimit = qLimit - (serverLimit * count);
          newPage *= serverLimit / newLimit;
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const queryPromise = query(...args, newPage, newLimit);
        promisesList.push(queryPromise);
        count += 1;
      }

      const otherQueriesResult = (await Promise.all(promisesList)).map(
        (el) => (tryGetResponseData(el) as IQProp<IGpRes<T>>)[Object.keys(firstQueryResult)[0]],
      );

      otherQueriesResult.forEach((el) => el.list.data.flat().forEach((el) => {
        (res[Object.keys(res)[0]]).list.data.push(el as T);
      }));

      return res;
    }
    return firstQueryResult;
  };
  return resQuery as (...args: any) => Promise<IQProp<IGpRes<T>>>;
}

// eslint-disable-next-line no-promise-executor-return
export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
