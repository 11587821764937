import { Button } from '@mui/material';
import { useObservable } from '@ngneat/react-rxjs';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { GpOrderItem, OrderStatuses, GpProductTypes } from '../../api/types';
import { getPaymentGatewayUrl, getPaymentRedirectUrl } from '../../state/context/auth.repository';
import { orderPrice$, updateOrderStatus, updateProductPackagingPrice } from '../../state/order';
import { sendMetricReachGoal } from '../../utils/functions';
import styles from './PaymentButton.module.scss';

export interface PaymentOptions {
  orderId: string;
  orderItems: GpOrderItem[];
  productTypes: GpProductTypes[],
  codeText: string;
  discount: number | null;
  email: string | null;
}

class CartItem {
  name: string;

  price: number | undefined;

  quantity: number = 1;

  tax: string = 'vat20';

  sum: number | undefined;

  item_type: string = 'service';

  payment_type: string = 'full';

  constructor(name: string, basePrice: number | undefined, discount: number | null) {
    this.name = name;
    if (basePrice) {
      const itemPrice = discount ? (basePrice * (100 - discount)) / 100 : basePrice;
      this.price = itemPrice;
      this.sum = itemPrice;
    }
  }
}

function PaymentButton(
  {
    orderId, orderItems, productTypes, discount, email,
  }: PaymentOptions,
) {
  const { t } = useTranslation('common');
  const [orderPrice] = useObservable(orderPrice$);
  const paymentUrl = getPaymentGatewayUrl();
  const formRef = useRef<HTMLFormElement>(null);

  const handleUpdateOrderStatus = useCallback(() => {
    sendMetricReachGoal('paymentButton');
    void updateOrderStatus(OrderStatuses.Pending);
    void updateProductPackagingPrice();
    const form = formRef.current;

    if (form) {
      form.action = paymentUrl;
      form.method = 'POST';
      form.submit();
    }
  }, []);

  const cart: CartItem[] = orderItems.map(
    (orderItem) => {
      const productType = productTypes
        .find((type) => type.model === orderItem.productCategory?.[0].__typename)?.name ?? 'Товар';

      return new CartItem(
        `${productType.toString()}: ${orderItem.productCategory?.[0].title}`,
        orderItem.productPackaging?.[0].price,
        discount,
      );
    },
  );
  const cartString = JSON.stringify(cart);
  const paymentRedirectUrl = `${getPaymentRedirectUrl()}?orderId=${orderId}`;

  return (
    <form ref={formRef}>
      <input type="hidden" name="sum" value={orderPrice!} />
      <input type="hidden" name="orderid" value={orderId} />
      <input type="hidden" name="cart" value={cartString} />
      <input type="hidden" name="client_email" value={email || ''} />
      <input type="hidden" name="service_name" value={ t('layer.service_name') } />
      { paymentRedirectUrl && <input type="hidden" name="user_result_callback" value={paymentRedirectUrl} /> }
      <Button
        disabled={!orderItems.length || !email}
        type="button"
        className={styles.button}
        variant="contained"
        color="primary"
        data-testid="paymentButton"
        onClick={handleUpdateOrderStatus}
      >
        { t('layer.buy') }
      </Button>
    </form>
  );
}

export default PaymentButton;
