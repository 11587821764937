import {
  Typography,
} from '@mui/material';
import { useObservable } from '@ngneat/react-rxjs';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useCreateNewProject from '../../../../hooks/useCreateNewProject';
import {
  missingSchedulesWithLayers$,
  unavailableSchedulesWithLayers$,
  recipeLayers$,
  getActiveRecipe,
  activeRecipe$,
  resetscheduleWithLayerStore,
} from '../../../../state/geoData/recipes.repository';
import { fetchAclAsync } from '../../../../state/project/project.actions';
import { addItemsToActiveOrder } from '../../../../state/order';
import { SideMenuButton } from '../../ui/RadioSelect';
import { DrawerContentEnum, DrawerContext } from '../../../../drawerContext';
import { ProjectType } from '../../../../models/project';
import { availableLayers$ } from '../../../../state/acl/acl.repository';

const CreateProjectButton = () => {
  const { t } = useTranslation('common');
  const [missingSchedulesWithLayers] = useObservable(missingSchedulesWithLayers$);
  const [unavailableSchedulesWithLayers] = useObservable(unavailableSchedulesWithLayers$);
  const [recipeLayers] = useObservable(recipeLayers$);
  const [availableLayers] = useObservable(availableLayers$);
  const [activeRecipe] = useObservable(activeRecipe$);
  const createNewProject = useCreateNewProject();
  const drawerContext = useContext(DrawerContext);
  const projectType = activeRecipe?.__typename === 'GPRecipe'
    ? ProjectType.GPProject
    : ProjectType.GPBIProject;
  useEffect(() => {
    if (!availableLayers.length) {
      void fetchAclAsync();
    }
  }, []);

  return (
    <SideMenuButton
      data-testid="PurchaseLayerFormPayButton"
      disabled={!recipeLayers.length || unavailableSchedulesWithLayers.length > 0}
      onClick={async () => {
        if (!missingSchedulesWithLayers.length) {
          const recipe = getActiveRecipe();
          if (recipe) {
            await createNewProject(projectType, recipe);
            resetscheduleWithLayerStore();
          }
          drawerContext.close();
        } else {
          const arr = missingSchedulesWithLayers.map((x) => ({
            product: x.layerOrDataset,
            schedule: x.schedule!,
          }));
          await addItemsToActiveOrder(...arr);
          drawerContext.setContent(DrawerContentEnum.CART);
        }
      }}
    >
      <Typography variant="title2" color="#fff">
        { !missingSchedulesWithLayers.length ? t('recipe.create_project_by_recipe') : t('recipe.buy_the_missing_layers') }
      </Typography>
    </SideMenuButton>
  );
};

export default CreateProjectButton;
