import {
  Typography,
  Box,
  Stack,
} from '@mui/material';

import styles from './HeaderWithCount.module.scss';

interface HeaderWithCountProps {
  text: string;
  count: string;
}

function HeaderWithCount(props: HeaderWithCountProps) {
  const { text, count } = props;

  return (
    <Stack alignItems="center" direction="row">
      <Box>
        <Typography className={styles.header} variant="h4">
          {text}
        </Typography>
        <Typography className={styles.availableCount} variant="accentTitle1">
          {count}
        </Typography>
      </Box>
    </Stack>
  );
}

export default HeaderWithCount;
