import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LeafletMap, L, Multipuncture } from '../types/leaflet';
import { MULTIPUNCTURE_URL } from '../utils/constants';
import loadScript from './script.service';

interface UseStylerReturnValue {
  multipuncture: Multipuncture | null;
  loadMultipuncture: (e: LeafletMap) => void;
}

const MULTIPUNCTURE_PLUGIN_PATH = '/static/leaflet/leaflet-multipuncture-min.js';

const useMultipuncture = (): UseStylerReturnValue => {
  const [multipuncture, setMultipuncture] = useState<Multipuncture | null>(null);
  const { t } = useTranslation('common');

  const loadMultipuncture = (e: LeafletMap) => {
    loadScript(MULTIPUNCTURE_PLUGIN_PATH, () => {
      const mpc = new L.Multipuncture({
        visible: false,
        map: e,
        mpUrl: MULTIPUNCTURE_URL,
        title: t('map.show_values'),
      });
      setMultipuncture(mpc);
    });
  };

  return { multipuncture, loadMultipuncture };
};

export default useMultipuncture;
