import { Box } from '@mui/material';

import styles from './PageTitle.module.scss';
import { createSideMarkup } from '../../utils/functions';

interface PageTitleProps {
  pageTitle: string;
}

function PageTitle({ pageTitle }: PageTitleProps) {
  return (
    <Box data-testid="BoxTitle" className={styles.boxTitle}>
      <p
        data-testid="pageTitle"
        className={styles.pageTitle}
        dangerouslySetInnerHTML={createSideMarkup(pageTitle)}
      />
    </Box>
  );
}

export default PageTitle;
