import { GpLayerFormulaIn, GpLayerFormulaItem, GpuiLayerIn } from '../api/types';
import { FormulaPalette } from '../models/formula-layer';

export const API_GEOSERVER_URL = 'http://www.dev.pbd.ai';

export const API_GEOSERVER_WMS_URL: string = 'http://www.dev.pbd.ai/geoserver/gwc/service/wms?';

export const BASE_API_URL = 'http://www.dev.pbd.ai';

export const SECOND_API_URL = 'http://cms.dev.pbd.ai';

export const GRAPHQL_CMS_URL: string = '/api/cms/graphql';

export const GRAPHQL_BASKET_URL: string = '/api/basket/graphql';

export const GRAPHQL_PAYMENT_URL: string = '/api/checkpayment/graphql';

export const GRAPHQL_GEO_SERVER_FEATURE_URL: string = '/api/gs-utils/graphql';

export const STATIC_MEDIA_SERVER: string = 'https://geo-cms-test.storage.yandexcloud.net/';

export const MULTIPUNCTURE_URL: string = 'http://localhost:3000/geoapi/mp';

export const STATIC_LEAFLET_URL: string = 'http://www.dev.pbd.ai/static/leaflet';

export const MARKETPLACE_PAGE_SIZE_COUNT = 21;

export const PROJECTS_PAGE_SIZE_COUNT = 21;

export const DEFAULT_HEAD_TITLE = 'GEO.Platfoma';

export const DEFAULT_HEAD_META = 'page.description';

export const DATE_FORMATTER = new Intl.DateTimeFormat(
  'ru',
  {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  },
);

export const BASE_BG_LAYER_UNAME = 'BG_FULL_OSM_WMS_RUS';
export const RESULTING_LAYER_ID = 'RES';

export const NEW_LAYER_CSV_TEMPLATE_NAME = 'new_layer_template.csv';

export const PROD_ENV = 'prod';
export const STAGE_ENV = 'stage';
export const DEV_ENV = 'dev';

// eslint-disable-next-line no-nested-ternary
export const APP_ENV = process.env.REACT_APP_ENV === STAGE_ENV ? STAGE_ENV
  : process.env.NODE_ENV === 'production' ? PROD_ENV : DEV_ENV;

export const PROJECT_NAME_FROM_RECIPE_REGEX = /^<_<(.+)>_>$/i;

export const FormulaMethodsWasm = [
  ['min', 20],
  ['avg', 21],
  ['max', 22],
];

export const FormulaMethodsWebGl = [
  ['min', 30],
  ['avg', 31],
  ['max', 32],
];

export const DEFAULT_DATE_TIME = 315000000000000;

export type ENV = typeof PROD_ENV | typeof STAGE_ENV | typeof DEV_ENV;

export const HTTP_REGEX = /^(http|https):\/\//;

export const FRESH_LAYER_INDICATOR = 'Q4';

export const BASE_LAYER_INDICATOR = 'BG_';

export const GUEST_USERNAME = 'guest';
export const GUEST_PASSWORD = 'guest';

export const DEFAULT_FORMULA_VALUE = {
  L: [],
  M: 30,
  O: 200,
  I: false,
  P: {
    title: 'PALETTE',
  },
};

export const DEFAULT_FORMULA_LAYER = {
  id: RESULTING_LAYER_ID,
  g: 1,
  h: 1,
  layerEm: [DEFAULT_FORMULA_VALUE] as unknown as GpLayerFormulaIn[],
  layer: [],
  o: 1,
  on: false,
  tmpLayerTypename: 'GPLayerFormula',
} as GpuiLayerIn;

export const DEFAULT_FORMULA_ITEM = {
  id: RESULTING_LAYER_ID,
  title: RESULTING_LAYER_ID,
  min: 50,
  max: 200,
  weight: 1,
} as GpLayerFormulaItem;

export const DEFAULT_PALETTE: FormulaPalette = {
  points: [
    ['rgb(255, 255, 255)', 0],
    ['rgb(0, 180, 255)', 0.5],
    ['rgb(0, 0, 255)', 1],
  ],
  steps: 256,
};

export const EMPTY_PROJECT_TITLE = 'Без названия';
export const EMPTY_BI_PROJECT_TITLE = 'Без названия BI';

export const biCfgIn = {
  title: EMPTY_BI_PROJECT_TITLE,
  biCfg: {
    id: '',
    dashboardUiConfig: {
      hideTitle: true,
      filters: {
        expanded: true,
      },
    },
  },
};

export const RECIPE_MARKET_LAYERS_PAGE_SIZE = 25;

export const MARKETPLACE_FILTERS_SIZE_COUNT = 12;
