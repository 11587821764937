import { Observable, map } from 'rxjs';
import { createEffectFn } from '@ngneat/effects';
import { updateRequestStatus } from '@ngneat/elf-requests';
import { tryGetResponseData } from '../common';
import {
  createUnamePrefixes,
  getActiveRecipe,
  getActiveUpdatePeriod,
  getAllRecipeTerritories,
  getRecipe,
  getRecipeActiveLayerMaps,
  hasRecipe,
  LayerAvailabilityEnum,
  resetChosenRecipeLayers,
  ScheduleWithLayer,
  setActiveRecipe,
  setActiveRecipeTerritory,
  setActiveUpdatePeriod,
  setMarketLayers,
  setRecipeMapLayers,
  setRecipes,
  setRecipesRequestError,
  setSelectedLayerSchedulesTrue,
  setTerritories,
  upsertRecipe,
  updatePeriods,
  getLatestPeriod,
  setSelectedLayerSchedulesRequestStatus,
  setRecipesRequestStatus,
  setRecipeMarketLayersRequestStatus,
} from './recipes.repository';
import { fetchLayersForRecipe } from './recipes.service';
import * as api from '../../services/geo-api.service';
import {
  GpLayer,
  GpLayerMap,
  GpRecipe,
  GpuiLayer,
  GpTerritories,
  GpdsScheduleDb,
  GpLayerPoi,
  GpbiRecipe,
  GpStatDataset,
  GpuiDataset,
  GpSchedule,
  GpdsSchedule,
} from '../../api/types';
import { getAllAclLayersOrDatasets } from '../acl/acl.repository';
import { fetchAclAsync } from '../project/project.actions';

enum RecipeType {
  GpRecipe = 'GPRecipe',
  GpbiRecipe = 'GPBIRecipe',
}

export async function fetchRecipes(durationFilters: [number, number][]) {
  const res = await api.getRecipes(durationFilters);

  try {
    const { data: recipes, totalCount } = tryGetResponseData(res).GPRecipe.list;
    setRecipes(recipes as GpRecipe[], totalCount);
  } catch (e) {
    setRecipesRequestError(e);
    throw e;
  }
}

async function fetchRecipe(id: string) {
  try {
    let recipe;
    const res = await api.getRecipe(id);
    recipe = tryGetResponseData(res).GPRecipe.get as GpRecipe;
    if (!recipe) {
      const res = await api.getBiRecipe(id);
      recipe = tryGetResponseData(res).GPBIRecipe.get as GpbiRecipe;
    }
    upsertRecipe(recipe);
  } catch (e) {
    setRecipesRequestError(e);
    throw e;
  }
}

async function fetchTerritories() {
  const res = await api.getTerritoryList();

  try {
    const territories = tryGetResponseData(res).GPTerritories.list.data as GpTerritories[];
    setTerritories(territories);
    return territories;
  } catch (e) {
    setRecipesRequestError(e);
    throw e;
  }
}

export type ElfReqStatus = 'success' | 'pending' | 'idle';

function getDefaultTerritory(recipeId: string): GpTerritories | undefined {
  const recipe = getRecipe(recipeId);
  let firstElement: GpdsScheduleDb | GpLayerMap | GpLayerPoi;
  if (recipe && 'layers' in recipe) {
    [ firstElement ] = recipe.layers[0].ov[0].layer;
  } else {
    [ firstElement ] = recipe?.datasetsList[0].dslist[0].ds as GpdsScheduleDb[];
  }
  if (!('uName' in firstElement)) {
    return undefined;
  }

  const layerTerritory = firstElement.uName.split('_')[2];

  const territories = getAllRecipeTerritories();
  return territories.find((t) => layerTerritory === t.fcode);
}

export const setRecipesRequestStatusEffect = createEffectFn(
  (status$: Observable<ElfReqStatus>) => status$.pipe(
    map((status) => updateRequestStatus('recipes', status)),
  ),
);

const createScheduleWithLayer = (
  activeRecipe: GpRecipe | GpbiRecipe,
  layersForRecipe: GpLayer[] | GpStatDataset[],
): ScheduleWithLayer[] => {
  let defaultRecipeLayers: GpdsScheduleDb[] | GpLayerMap[];
  if ('layers' in activeRecipe) {
    defaultRecipeLayers = activeRecipe.layers
      .reduce<GpuiLayer[]>((acc, val) => acc.concat(val.ov), [])
      .reduce<GpLayerMap[]>((acc, val) => acc
      .concat(val.layer.filter((l): l is GpLayerMap => l.__typename === 'GPLayerMap')), []) ?? [];
  } else {
    defaultRecipeLayers = activeRecipe.datasetsList
      .reduce<GpuiDataset[]>((acc, val) => acc.concat(val.dslist), [])
      .reduce<GpdsScheduleDb[]>((acc, val) => acc.concat(val.ds), []) ?? [];
  }

  const recipeLayersForTerritory = getRecipeActiveLayerMaps();
  const availableLayersUnames = getAllAclLayersOrDatasets().map((layer) => layer.uName);
  const scheduleWithLayer: ScheduleWithLayer[] = [];
  defaultRecipeLayers.forEach((defaultLayer) => {
    const uNamePrefix = defaultLayer.uName.split('_').slice(0, 2).join('_');
    const uNamePostfix = defaultLayer.uName.split('_').slice(4)[0];
    let layerAlternative = recipeLayersForTerritory.find(
      (l) => (l.uName.startsWith(uNamePrefix) && l.uName.endsWith(uNamePostfix)),
    );

    if (!layerAlternative) {
      layerAlternative = recipeLayersForTerritory.find(
        (l) => l.uName.startsWith(uNamePrefix),
      );
    }

    let layerOrDataset;
    let schedule;
    if (layersForRecipe.length) {
      if ('layer' in layersForRecipe[0].schedules[0]) {
        layerOrDataset = (layersForRecipe as GpLayer[]).find((l) => l.schedules
          .some((s) => (s.layer.uName.startsWith(uNamePrefix)
            && s.layer.uName.endsWith(uNamePostfix))));

        if (!layerOrDataset) {
          layerOrDataset = (layersForRecipe as GpLayer[]).find((l) => l.schedules
            .some((s) => (s.layer.uName.startsWith(uNamePrefix))));
        }
      } else {
        layerOrDataset = (layersForRecipe as GpStatDataset[]).find((l) => l.schedules
          .some((s) => s.dataset.uName.startsWith(uNamePrefix)
            && s.dataset.uName.endsWith(uNamePostfix)));

        if (!layerOrDataset) {
          layerOrDataset = (layersForRecipe as GpStatDataset[]).find((l) => l.schedules
            .some((s) => (s.dataset.uName.startsWith(uNamePrefix))));
        }
      }
    }
    if (!layerAlternative || !layerOrDataset) {
      scheduleWithLayer.push({
        id: defaultLayer.id,
        layerOrDataset: {
          title: defaultLayer.title.split('-')[0].trim(),
        } as GpLayer,
        availability: LayerAvailabilityEnum.Unavailable,
      });
      return;
    }

    const isMissing = !availableLayersUnames.includes(layerAlternative.uName);
    if ('layer' in layerOrDataset.schedules[0]) {
      schedule = (layerOrDataset.schedules as GpSchedule[])
        .find((s) => s.layer.uName === layerAlternative?.uName);
    } else {
      schedule = (layerOrDataset.schedules as GpdsSchedule[])
        .find((s) => s.dataset.uName === layerAlternative?.uName);
    }

    const scheduleForRecipe: ScheduleWithLayer = {
      id: layerOrDataset.id,
      layerOrDataset,
      schedule,
      availability: isMissing
        ? LayerAvailabilityEnum.Available
        : LayerAvailabilityEnum.Acquired,
    };
    scheduleWithLayer.push(scheduleForRecipe);
  });
  return scheduleWithLayer;
};

async function getLayersOrDatasets(uNames: string[]) {
  const activeRecipe = getActiveRecipe();
  if (activeRecipe?.__typename === RecipeType.GpRecipe) {
    const layers = await fetchLayersForRecipe(uNames);
    return layers;
  }
  const res = await api.fetchDataSets(uNames);
  const datasets = tryGetResponseData(res).GPStatDataset.list.data as GpStatDataset[];
  return datasets;
}

export async function getDatasets(uNames: string[]) {
  setRecipeMarketLayersRequestStatus('pending');
  const activeRecipe = getActiveRecipe();
  if (activeRecipe?.__typename === RecipeType.GpRecipe) {
    setRecipeMarketLayersRequestStatus('success');
    return null;
  }
  const res = await api.fetchDataSets(uNames);
  const datasets = tryGetResponseData(res).GPStatDataset.list.data as GpStatDataset[];
  setRecipeMarketLayersRequestStatus('success');
  return datasets;
}

async function getLayersForRecipe() {
  const uNames = getRecipeActiveLayerMaps().map((el) => el.uName.split('_').splice(0, 3).join('_'));
  const layersForRecipe = await getLayersOrDatasets(uNames);
  const activeUpdatePeriod = getActiveUpdatePeriod();
  if (activeUpdatePeriod && layersForRecipe.length) {
    if ('layer' in layersForRecipe[0].schedules[0]) {
      return (layersForRecipe as GpLayer[])
        .filter((l) => (l.schedules).some((s) => s.layer.uName.split('_')[3] === activeUpdatePeriod));
    }
    return (layersForRecipe as GpStatDataset[])
      .filter((l) => (l.schedules).some((s) => s.dataset.uName.split('_')[3] === activeUpdatePeriod));
  }
  return layersForRecipe;
}

async function updateLayers() {
  setSelectedLayerSchedulesRequestStatus('pending');
  const layersForRecipe = await getLayersForRecipe();

  const activeRecipe = getActiveRecipe();
  await fetchAclAsync();
  if (activeRecipe) {
    const scheduleWithLayer = createScheduleWithLayer(activeRecipe, layersForRecipe);
    setSelectedLayerSchedulesTrue(scheduleWithLayer);
  }
  setSelectedLayerSchedulesRequestStatus('success');
}

export const setActiveRecipeTerritoryAsync = async (
  territoryId: string,
): Promise<void> => {
  setActiveRecipeTerritory(territoryId);
  await updateLayers();
};

export async function setActiveRecipeUpdatePeriod(period: string | null) {
  setActiveUpdatePeriod(period);
  await updateLayers();
}

async function fetchLayerOrScheduleDb(uNamesKey: string[]) {
  const activeRecipe = getActiveRecipe();
  if (activeRecipe?.__typename === RecipeType.GpRecipe) {
    const res = await api.getLayerMapList(uNamesKey);
    return tryGetResponseData(res).GPLayerMap.list.data as GpLayerMap[];
  }
  const res = await api.getScheduleDb(uNamesKey);
  return tryGetResponseData(res).GPDSScheduleDB.list.data as GpdsScheduleDb[];
}

export const setActiveRecipeAsync = async (id: string): Promise<void> => {
  setRecipesRequestStatus('pending');
  if (!hasRecipe(id)) {
    await fetchRecipe(id);
  }
  resetChosenRecipeLayers();
  setActiveRecipe(id);
  const uNames = createUnamePrefixes(true);
  if (uNames.length) {
    const layers = await fetchLayerOrScheduleDb(uNames);
    setRecipeMapLayers(layers);
    updatePeriods(layers.map((l) => l.uName));
    setActiveUpdatePeriod(getLatestPeriod());

    await fetchTerritories();
    const defaultTerritory = getDefaultTerritory(id);
    if (defaultTerritory) {
      setActiveRecipeTerritory(defaultTerritory.id);
    }
    await updateLayers();
  }
  const layersForRecipe = await getLayersForRecipe();
  setMarketLayers(layersForRecipe);
  setRecipesRequestStatus('success');
};
