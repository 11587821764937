import {
  GpbiProject,
  GpProject,
  GpuiDataset,
  GpuiLayer,
  GpLayerMap,
  GpdsScheduleDb,
  GpRecipe,
  GpbiRecipe,
} from '../../../../api/types';

export const getLayersFromProject = (project: GpProject | GpbiProject):
GpuiLayer[] | GpuiDataset[] | null => {
  if (project.__typename === 'GPProject') {
    return project.recipe[0].layers[0].ov.filter((layer) => layer.tmpLayerTypename === 'GPLayerMap');
  }

  if (project.__typename === 'GPBIProject') {
    return project.recipe?.[0].datasetsList[0].dslist;
  }
  return null;
};

const getLayerMapFromUiLayer = (ui: GpuiLayer[]) => ui
  .filter((l) => l.tmpLayerTypename === 'GPLayerMap')
  .map((l) => (l.layer[0] as GpLayerMap));

const getDatasetsFromUiDataset = (ui: GpuiDataset[]) => ui
  .map((d) => d.ds[0]);

export const getLayerMapDatasetFromRecipes = (recipes: (GpRecipe | GpbiRecipe)[]) => recipes
  .flatMap<(GpLayerMap | GpdsScheduleDb)>((p) => (
  'layers' in p
    ? getLayerMapFromUiLayer(
      [...p.layers[0].bg, ...p.layers[0].ov],
    )
    : getDatasetsFromUiDataset(
      p.datasetsList[0].dslist,
    )
));
