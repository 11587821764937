import {
  Button, Stack, Divider, Typography,
} from '@mui/material';
import { useContext } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';

import { useObservable } from '@ngneat/react-rxjs';
import { BASE_LAYERS_DROPPABLE, OVERLAY_LAYERS_DROPPABLE } from '../../feature/MapPage/constants';
import { moveLayerToDifferentType, moveLayerWithin } from '../../state/project/project.actions';
import { LayerType } from '../../models/layer';
import { baseLayers$, overlayLayers$ } from '../../state/project/project.repository';
import LayerList from '../../feature/MapPage/LayerList';
import { modalContext, ModalContextEnum } from '../../blocks/ModalProject/ModalProject';
import styles from './SideLayerList.module.scss';

function SideLayerList() {
  const openContext = useContext(modalContext);

  const { t } = useTranslation('common');

  const [baseLayers] = useObservable(baseLayers$);
  const [overlayLayers] = useObservable(overlayLayers$);

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result;
    if (
      !destination
      || ((destination.droppableId === source.droppableId)
      && (destination?.index === source.index))
    ) return;

    if (source.droppableId === destination.droppableId) {
      switch (destination.droppableId) {
        case BASE_LAYERS_DROPPABLE:
          void moveLayerWithin({
            layerType: LayerType.BG,
            convertLayerType: false,
            fromIndex: result.source.index,
            toIndex: destination.index,
            layerId: result.draggableId,
          });
          break;
        case OVERLAY_LAYERS_DROPPABLE:
          void moveLayerWithin({
            layerType: LayerType.OV,
            convertLayerType: false,
            fromIndex: result.source.index,
            toIndex: destination.index,
            layerId: result.draggableId,
          });
          break;
        default:
          break;
      }
    } else if (source.droppableId !== destination?.droppableId) {
      switch (destination.droppableId) {
        case BASE_LAYERS_DROPPABLE:
          void moveLayerToDifferentType({
            layerType: LayerType.BG,
            convertLayerType: true,
            toIndex: destination.index,
            layerId: result.draggableId,
          });
          break;
        case OVERLAY_LAYERS_DROPPABLE:
          void moveLayerToDifferentType({
            layerType: LayerType.OV,
            convertLayerType: true,
            toIndex: destination.index,
            layerId: result.draggableId,
          });
          break;
        default:
          break;
      }
    }
  };

  return (
    <Stack className={styles.sideLayerList}>
      <Button
        data-testid="addLayers"
        className={styles.button}
        variant="text"
        endIcon={<AddIcon />}
        onClick={() => { openContext.setOpen(ModalContextEnum.PROJECT_DATA); }}
      >
        {t('layer.attach')}
      </Button>
      <Divider sx={{ borderColor: 'side.light' }} />

      <Typography variant="body1" fontSize="16px" fontWeight="600" sx={{ padding: '10px 16px', ml: '20px' }}>
        {t('layer.geo_layer')}
      </Typography>

      <Divider sx={{ borderColor: 'side.light' }} />

      <DragDropContext onDragEnd={onDragEnd}>
        <Stack className={styles.sideLayerListContainer}>
          <LayerList layers={overlayLayers} layerType={LayerType.OV} />
          <Divider sx={{ borderColor: 'side.light' }} />
          <Typography variant="body1" fontSize="16px" fontWeight="600" sx={{ padding: '10px 16px', ml: '20px' }}>
            {t('layer.maps')}
          </Typography>
          <Divider sx={{ borderColor: 'side.light' }} />
          <LayerList layers={baseLayers} layerType={LayerType.BG} />
        </Stack>
      </DragDropContext>
    </Stack>
  );
}

export default SideLayerList;
