import {
  Typography, Accordion, AccordionDetails, Stack, Divider, Box, Button, ClickAwayListener,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import React from 'react';
import { createPortal } from 'react-dom';

import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import { useObservable } from '@ngneat/react-rxjs';
import styles from './UserMenu.module.scss';
import {
  accounts$, activeAccount$, getImagesUrlPrefix, user$,
} from '../../state/context/auth.repository';
import { logout } from '../../state/context/auth.actions';
import { GpCustomerProfile } from '../../api/types';

interface UserMenuProps {
  onMenuClick: () => void;
  activeOrganization: string;
  handleAccountChange: (event: React.MouseEvent<HTMLElement>, orgValue: string) => void;
  onLogout?: () => void;
  projectOpen: boolean;
}

function UserMenu({
  onMenuClick, activeOrganization, handleAccountChange, onLogout, projectOpen,
}: UserMenuProps) {
  const { t } = useTranslation('common');
  const [user] = useObservable(user$);
  const [account] = useObservable(activeAccount$);
  const [accounts] = useObservable(accounts$);

  const callHandleAccountChange = (event: React.MouseEvent<HTMLElement>, orgValue: string) => {
    handleAccountChange(event, orgValue);
  };

  function renderMenuItem(acc: GpCustomerProfile) {
    return (
      <Button
        className={styles.button}
        onClick={(e: React.MouseEvent<HTMLElement>) => (activeOrganization === acc.id
          ? (() => { e.stopPropagation(); })()
          : callHandleAccountChange(e, acc.id))}
        sx={{ textAlign: 'left' }}
        key={`button_${acc.id}`}
      >
        <Box
          p="0"
          key={`box_${acc.id}`}
        >
          <Typography variant="headmenu" component="p">{acc.gpOrganization?.name}</Typography>
          <Typography variant="description" sx={{ opacity: 0.5, letterSpacing: '-0.03em', color: '#061136' }}>
            {`${t('side.tariff')} ${acc.tariffPlan.title}`}
          </Typography>
        </Box>
        {activeOrganization === acc.id
          ? <CheckIcon sx={{ width: '16px', height: '16px', alignSelf: 'flex-start' }} />
          : null}
      </Button>
    );
  }

  const userMenu = (account
    ? (
      <ClickAwayListener onClickAway={() => onMenuClick()}>
        <Accordion className={styles.accordion} data-testid="userInfoBlock" expanded onClick={() => onMenuClick()}>
          <AccordionDetails sx={{ p: '8px 0 0' }}>

            <Stack px="8px" direction="row" justifyContent="flex-end" alignItems="center" style={{ cursor: 'pointer' }}>
              <Stack>
                <Typography variant="title1">{user?.name}</Typography>
                <Typography variant="subtitle3" align="right" sx={{ opacity: '0.8' }}>
                  {account.gpOrganization?.name}
                </Typography>
              </Stack>

              <img
                className={styles.image}
                src={user?.avatar ? `${getImagesUrlPrefix()}${user.avatar}` : '/profile-image.png'}
                alt="user"
              />
            </Stack>

            <Box mt={1.5}>
              <Divider />
            </Box>

            <Stack>
              {projectOpen
                ? renderMenuItem(account)
                : accounts.map((acc) => renderMenuItem(acc))}
            </Stack>

            <Box mt={1.5}>
              <Divider />
            </Box>

            <Box
              p="12px 16px 16px"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                void logout();
                if (onLogout) onLogout();
              }}
            >
              <Stack spacing={2}>
                <Stack
                  spacing={1}
                  direction="row"
                  alignItems="center"
                >
                  <LogoutIcon sx={{ opacity: 0.5 }} />

                  <Typography data-testid="signOut" variant="headmenu">{t('side.signOut')}</Typography>
                </Stack>
              </Stack>
            </Box>

          </AccordionDetails>
        </Accordion>
      </ClickAwayListener>
    ) : (null)
  );

  return createPortal(
    userMenu,
    document.getElementById('root-portal')!,
  );
}

export default UserMenu;
