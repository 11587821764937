import {
  useEffect,
  useContext,
  useState,
  useRef,
  RefObject,
  useCallback,
} from 'react';
import {
  Box,
  Button,
  Stack,
  Typography,
  Collapse,
  useTheme,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useObservable } from '@ngneat/react-rxjs';
import {
  marketLayers$,
  recipesRequestStatus$,
  recipeMarketLayersRequestPending$,
} from '../../state/geoData/recipes.repository';
import { ReactComponent as ArrowLeftIcon } from '../../assets/images/arrowLeft.svg';
import { ReactComponent as TimerIcon } from '../../assets/images/timer.svg';
import { ReactComponent as LayerIcon } from '../../assets/images/layer.svg';
import { DrawerContentEnum, DrawerContext } from '../../drawerContext';
import { createSideMarkup, getCaseOfCount } from '../../utils/functions';
import { ResponseError } from '../../models/common';
import { getImagesUrlPrefix, isGuestMode$ } from '../../state/context/auth.repository';
import FeatureCard from '../../feature/Generals/components/FeatureCard';
import ProductsList from '../../feature/Recipe/components/ProductsList';
import SimilarProducts from '../../feature/Generals/components/SimilarProducts';
import PageBreadcrumbs from '../../blocks/PageBreadcrumbs/PageBreadcrumbs';
import RecipeInfo from './RecipeInfo';
import styles from './Recipe.module.scss';
import { activeProduct$ } from '../../state/geoData/products.repository';
import { setActiveProduct } from '../../state/geoData/products.actions';
import { GpbiRecipe, GpStatDataset, ProductUnion } from '../../api/types';
import Loader from '../../components/Loader/Loader';
import { ProductTypeId } from '../../models/product';
import { setActiveRecipeAsync, getDatasets } from '../../state/geoData/recipes.effects';

const Recipe = () => {
  const { t } = useTranslation('common');
  const [isOpenAccordion, setOpenAccordion] = useState<boolean>(true);
  const [datasetList, setDatasetList] = useState<GpStatDataset[] | null>(null);
  const [activeProduct] = useObservable(activeProduct$);
  const [layerList] = useObservable(marketLayers$);
  const refFeatureCardContainer = useRef<HTMLDivElement>(null);
  const refHeading = useRef<HTMLDivElement>(null);
  const [recipesRequestStatus] = useObservable(recipesRequestStatus$);
  const [recipeMarketLayersRequestPending] = useObservable(
    recipeMarketLayersRequestPending$,
    { initialValue: true },
  );
  const [isGuestMode] = useObservable(isGuestMode$);
  const drawerContext = useContext(DrawerContext);
  const { productId } = useParams<string>();
  const [showFooter, setShowFooter] = useState<boolean>(false);
  const theme = useTheme();

  const product = activeProduct?.product[0];

  const getDatasetList = useCallback(async (recipe: GpbiRecipe) => {
    const UNameKeys = new Set(recipe.datasetsList[0].dslist
      .flatMap((element) => element.ds[0].uName.split('_', 3).join('_')));
    const data = await getDatasets(Array.from(UNameKeys));
    setDatasetList(data);
  }, []);

  const recipeBreadcrumbs = [
    { title: product?.title || '', to: `/marketplace/${productId!}` },
  ];

  const handleScrollContainer = () => {
    if (refHeading.current) {
      const { bottom } = refHeading.current.getBoundingClientRect();
      if (bottom < 0) {
        setShowFooter(true);
      } else {
        setShowFooter(false);
      }
    }
  };

  useEffect(() => {
    const container = document.getElementById('container');
    if (container) {
      container.addEventListener('scroll', handleScrollContainer);
    }
    return () => container?.removeEventListener('scroll', handleScrollContainer);
  }, []);

  useEffect(() => {
    if (productId) {
      void setActiveProduct(productId);
      if (
        (activeProduct?.productType.id === ProductTypeId.Recipe
          || activeProduct?.productType.id === ProductTypeId.Report)
          && product?.id
      ) {
        void setActiveRecipeAsync(product.id);
      }
    }
    if (product && 'biTemplate' in product) {
      void getDatasetList(product);
    }
  }, []);

  if (recipesRequestStatus.value === 'error') {
    return (
      <Typography variant="h5" sx={{ textAlign: 'center' }}>
        Error:
        {' '}
        {(recipesRequestStatus.error as ResponseError).localizedMessage}
      </Typography>
    );
  }

  if (!product) {
    return <div />;
  }

  if (recipeMarketLayersRequestPending) {
    return <Loader id="progress-recipe" />;
  }

  const calculatedCollapsedHeight = (ref: RefObject<HTMLDivElement>): number => {
    const collapseWrapperInner = ref
      .current
      ?.querySelector('.MuiCollapse-wrapperInner');
    return collapseWrapperInner?.children[0].getBoundingClientRect().height as number + 20;
  };

  const createProjectByRecipeClick = () => drawerContext.open(
    isGuestMode
      ? DrawerContentEnum.LOGIN
      : DrawerContentEnum.CHOOSE_RECIPE_LAYERS,
  );

  const path = () => (
    <PageBreadcrumbs
      links={recipeBreadcrumbs}
    />
  );

  const arrowBack = (product: ProductUnion) => (
    <Stack
      direction="column"
      gap="60px"
      sx={{
        maxWidth: '165px',
        width: '100%',
      }}
    >
      <Box sx={{
        display: 'flex',
        alignSelf: 'flex-start',
      }}
      >
        <Link to={{ pathname: '/marketplace' }} style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <ArrowLeftIcon />
          <Typography variant="subtitle2" color="primary.dark">
            {t('layer.back')}
          </Typography>
        </Link>
      </Box>
      <RecipeInfo
        product={product}
        count={layerList.length || datasetList?.length || 0}
      />
    </Stack>
  );

  const titleWithBuyButton = (product: ProductUnion) => (
    <Stack
      direction="row"
      gap="36px"
      alignItems="center"
      justifyContent="space-between"
      ref={refHeading}
    >
      <Box maxWidth="500px">
        <Typography variant="h2" color="primary.light" fontWeight="500">
          { product.title }
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexBasis: '240px' }}>
        <Button
          color="primary"
          className={styles.button}
          onClick={createProjectByRecipeClick}
          variant="contained"
          data-testid="ProceedButton"
        >
          {t('recipe.create_project_by_recipe')}
        </Button>
      </Box>
    </Stack>
  );

  const recipeDuration = (product: ProductUnion) => {
    const duration = 'duration' in product ? product.duration : 0;

    return (
      <Typography
        variant="subtitle1"
        color="primary.dark"
        marginTop="10px"
      >
        {`${t('recipe.result')} ${duration} ${getCaseOfCount(duration, t('recipe.duration', { returnObjects: true }))}`}
      </Typography>
    );
  };

  const productFeaturesAndInfo = (product: ProductUnion) => {
    const recipeGpRecipeValues = 'layers' in product ? product.recipeValues : null;
    const recipeBiValues = 'biTemplate' in product ? product.recipeValues : null;
    const recipeValues = recipeGpRecipeValues || recipeBiValues;

    if (recipeValues) {
      return (
        <Stack
          direction="row"
          className={styles.help}
        >
          <Box sx={{
            maxWidth: '165px',
            width: '100%',
            paddingTop: '30px',
            flexShrink: 0,
          }}
          />
          <Stack direction="column" flexGrow={1}>
            { recipeValues?.length > 0
                && (
                  <>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (recipeValues.length < 4) {
                          return;
                        }
                        setOpenAccordion((prev) => !prev);
                      }}
                    >
                      <Typography variant="h4" color="primary.light" flexGrow={1} fontWeight="400">
                        {t('recipe.features')}
                      </Typography>
                      <Stack direction="row" alignItems="center">
                        { (!(recipeValues.length < 4))
                          && (
                            <>
                              <Typography variant="body1" fontWeight={500} color="primary.dark">
                                { isOpenAccordion ? t('layer.hideAccordion') : t('layer.showAccordion')}
                              </Typography>
                              <ExpandMore sx={{
                                transform: isOpenAccordion ? 'rotate(180deg)' : 'none',
                                transition: 'transform .2s linear',
                              }}
                              />
                            </>
                          )}
                      </Stack>
                    </Stack>
                    <Collapse
                      className={styles.collapse}
                      in={isOpenAccordion}
                      collapsedSize={`${calculatedCollapsedHeight(refFeatureCardContainer)}px`}
                      ref={refFeatureCardContainer}
                    >
                      { recipeValues.map((item) => (
                        <FeatureCard
                          description={item.wording}
                          title={item.title}
                          key={item.num}
                          isRecipeValueCard
                        />
                      ))}
                    </Collapse>
                  </>
                )}
          </Stack>
        </Stack>
      );
    }
    return null;
  };

  const photos = (product: ProductUnion) => {
    const recipeImg = 'layers' in product && product.img;
    const recipeBiImg = 'biTemplate' in product && product.img;
    const img = recipeImg || recipeBiImg;

    if (img) {
      return (
        <Box
          width="100%"
          sx={{
            marginTop: '40px',
            height: '327px',
            overflow: 'hidden',
            borderRadius: '20px',
          }}
        >
          <img src={`${getImagesUrlPrefix()}${img}`} alt="map" className={styles.photo} />
        </Box>
      );
    }
    return null;
  };

  const recipeStages = (product: ProductUnion) => {
    const steps = 'steps' in product && product.steps;

    if (steps && steps.length > 0) {
      return (
        <Stack direction="row" marginTop="40px" gap="25px">
          <Box maxWidth="165px" width="100%" />
          <Box width="100%">
            <Typography variant="h4" maxWidth="670px" fontWeight="400">
              { `${t('recipe.stages_heading')} "${product.title}"` }
            </Typography>
            <Stack direction="column" marginTop="20px" display="block">
              <Box className={styles.box}>
                { steps.map((step, index) => (
                  <FeatureCard
                    isStageCard
                    title={step.title}
                    description={step.description}
                    stageNumber={index + 1}
                    key={step.id}
                  />
                ))}
              </Box>
            </Stack>
          </Box>
        </Stack>
      );
    }
    return null;
  };

  const includedProductsList = (product: ProductUnion) => {
    if (recipesRequestStatus.value === 'pending') {
      return (
        <Box ml="165px" pt="40px" pb="40px" position="relative">
          <Loader id="progress-recipe-included-products" />
        </Box>
      );
    }
    const recipeLayerList = layerList.length > 0 && layerList;
    const includedProducts = datasetList || recipeLayerList;

    if (includedProducts) {
      return (
        <Stack direction="row" gap="25px" marginTop="40px" paddingBottom="20px">
          <Box maxWidth="165px" width="100%" />
          <Box flexGrow={1}>
            <Box>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h4" color="primary.light" flexGrow={1} fontWeight="400">
                  {`${t('recipe.layers_included')} “${product.title}”`}
                </Typography>
                <Button
                  color="primary"
                  className={styles.button}
                  onClick={createProjectByRecipeClick}
                  variant="contained"
                  sx={{
                    flexBasis: '205px',
                    flexGrow: 0,
                    height: '40px',
                  }}
                >
                  {t('recipe.create_project_by_recipe')}
                </Button>
              </Stack>
              <Box marginTop="20px">
                <ProductsList products={includedProducts} />
              </Box>
            </Box>
          </Box>
        </Stack>
      );
    }
    return null;
  };

  const yourUsefulLayers = (product: ProductUnion) => {
    const usefulLayers = 'usefulLayers' in product ? product.usefulLayers : null;
    const usefulDatasets = 'usefulDatasets' in product ? product.usefulDatasets : null;
    const usefulProducts = usefulLayers || usefulDatasets;

    if (usefulProducts && usefulProducts.length > 0) {
      return (
        <Stack direction="row" gap="25px" marginBottom="55px">
          <Box maxWidth="165px" width="100%" />
          <Box flexGrow={1}>
            <Box marginTop="60px">
              <Typography variant="h4" color="primary.light">
                {t('layer.your_similar_layers')}
              </Typography>
              <Box marginTop="20px">
                <SimilarProducts products={usefulProducts} />
              </Box>
            </Box>
          </Box>
        </Stack>
      );
    }
    return null;
  };

  const footer = (product: ProductUnion) => {
    const duration = 'duration' in product ? product.duration : 0;

    return (
      <>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className={`${styles.footer} ${showFooter ? styles.footerShow : ''}`}
        >
          <Stack direction="column" gap="12px" padding="15px 40px">
            <Typography variant="h4" color="primary.light" fontWeight="400">
              { product.title }
            </Typography>
            <Stack direction="row" alignItems="center" gap="20px">
              <Stack direction="row" gap="7px" alignItems="center">
                <TimerIcon width="20px" height="20px" />
                <Typography
                  variant="body2"
                  color="primary.dark"
                  sx={{ opacity: '.5' }}
                >
                  {`${duration} ${getCaseOfCount(duration, t('recipe.duration', { returnObjects: true }))}`}
                </Typography>
              </Stack>
              <Stack direction="row" gap="7px" alignItems="center">
                <LayerIcon width="20px" height="20px" stroke={theme.palette.primary.main} fill="none" />
                <Typography
                  variant="body2"
                  color="primary.dark"
                  sx={{ opacity: '.5' }}
                >
                  {`${layerList.length || datasetList?.length || 0}
                    ${getCaseOfCount(layerList.length, t('recipe.layers', { returnObjects: true }))}`}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Button
            color="primary"
            className={styles.button}
            onClick={createProjectByRecipeClick}
            variant="contained"
            sx={{
              flexBasis: '240px',
              flexGrow: 0,
            }}
          >
            {t('recipe.create_project_by_recipe')}
          </Button>
        </Stack>
        <Box className={styles.footerPlaceholder} />
      </>
    );
  };

  return (
    <Box className={styles.container}>
      {path()}
      <Stack direction="row" marginTop="20px" gap="25px">
        {arrowBack(product)}
        <Box width="100%">
          {titleWithBuyButton(product)}
          {recipeDuration(product)}
          <div
            className={`${styles.desc} cms-styles cms-main`}
            dangerouslySetInnerHTML={createSideMarkup(product.description)}
          />
        </Box>
      </Stack>
      {productFeaturesAndInfo(product)}
      {photos(product)}
      {recipeStages(product)}
      {includedProductsList(product)}
      {yourUsefulLayers(product)}
      {footer(product)}
    </Box>
  );
};

export default Recipe;
