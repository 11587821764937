import {
  Box, Stack, Typography, useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TimerIcon } from '../../assets/images/timer.svg';
import { ReactComponent as LayerIcon } from '../../assets/images/layer.svg';
import { ProductUnion } from '../../api/types';
import { getCaseOfCount } from '../../utils/functions';

interface RecipeInfoProps {
  product: ProductUnion;
  count: number;
}

const RecipeInfo = ({ product, count }: RecipeInfoProps) => {
  const { t } = useTranslation('common');
  const theme = useTheme();

  const duration = 'duration' in product ? product.duration : 0;

  return (
    <Stack direction="column" gap="30px">
      <Stack direction="row" gap="15px" alignItems="center">
        <TimerIcon width="32px" height="32px" />
        <Box>
          <Typography variant="body2" color="text.disabled">
            {t('recipe.result')}
          </Typography>
          <Typography
            variant="title2"
            color="primary.dark"
          >
            {`${duration} ${getCaseOfCount(duration, t('recipe.duration', { returnObjects: true }))}`}
          </Typography>
        </Box>
      </Stack>
      <Stack direction="row" gap="15px" alignItems="center">
        <LayerIcon width="32px" height="32px" stroke={theme.palette.primary.main} fill="none" />
        <Box>
          <Typography variant="body2" color="text.disabled">
            {t('recipe.included')}
          </Typography>
          <Typography
            variant="title2"
            color="primary.dark"
          >
            {`${count} ${getCaseOfCount(count, t('recipe.layers', { returnObjects: true }))}`}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
};

export default RecipeInfo;
