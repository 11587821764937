import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import geoImg from '../../../../../../assets/images/geo.svg';
import biImg from '../../../../../../assets/images/bi.svg';
import crossImg from '../../../../../../assets/images/cross.svg';
import styles from './ProjectCreate.module.scss';
import { ProjectType } from '../../../../../../models/project';

interface ProjectCreateProps {
  projectType: ProjectType;
  onClick: () => void;
}

const ProjectCreate:FC<ProjectCreateProps> = ({ projectType, onClick }) => {
  const { t: translate } = useTranslation('common');

  return (
    <Stack direction="row" className={styles.projectCreate} onClick={onClick}>
      {projectType === ProjectType.GPProject && (
        <>
          <Box className={styles.projectCreateImg}>
            <img src={geoImg} alt={projectType} />
          </Box>
          <Typography variant="subtitle2">{ translate('account.add_project') }</Typography>
        </>
      )}
      {projectType === ProjectType.GPBIProject && (
        <>
          <Box className={styles.projectCreateImg}>
            <img src={biImg} alt={projectType} />
          </Box>
          <Typography variant="subtitle2">{ translate('account.create_bi_project') }</Typography>
        </>
      )}
      <Box className={styles.projectCreateCross}>
        <img src={crossImg} alt="cross" />
      </Box>
    </Stack>
  );
};

export default ProjectCreate;
