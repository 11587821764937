import { FC, useState } from 'react';
import { useObservable } from '@ngneat/react-rxjs';
import {
  Box,
  Collapse,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ResultingLayerSliders from '../../../../../blocks/ResultingLayerSliders';
import { GpLayerFormulaItem } from '../../../../../api/types';
import { uiLayers$ } from '../../../../../state/project/project.repository';
import styles from './LayerAtResultingCard.module.scss';

interface LayerAtResultingProps {
  formulaLayer: GpLayerFormulaItem;
  index: number;
  layerIndex: number;
}

const LayerAtResulting:FC<LayerAtResultingProps> = ({
  formulaLayer,
  index,
  layerIndex,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [uiLayers] = useObservable(uiLayers$);

  const uiLayer = uiLayers.find((layer) => layer.id === formulaLayer.id);

  return (
    <Stack direction="row" className={styles.layer}>
      <Box flexBasis="25px" flexShrink={0} />
      <Stack direction="row" padding="8px 20px 8px 8px" width="100%" maxWidth="362px">
        <Typography width="32px">{`${index}.${layerIndex + 1}`}</Typography>
        <Stack width="100%" gap="18px">
          <Stack direction="row" className={styles.title}>
            <Typography variant="body1" fontWeight={500}>
              {uiLayer?.n}
            </Typography>
            <IconButton
              className={`${styles.settings} ${open ? styles.settingsActive : styles.settingsDeactive}`}
              onClick={() => setOpen(!open)}
            >
              <SettingsIcon />
            </IconButton>
          </Stack>
          <Collapse in={open}>
            <ResultingLayerSliders id={formulaLayer.id} formula={formulaLayer} />
          </Collapse>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LayerAtResulting;
