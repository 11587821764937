import { createState, Store } from '@ngneat/elf';
import {
  getEntity,
  selectActiveEntity,
  selectAllEntities,
  upsertEntities,
  withActiveId,
  withEntities,
} from '@ngneat/elf-entities';
import {
  selectIsRequestPending, updateRequestStatus,
  withRequestsStatus, StatusState,
} from '@ngneat/elf-requests';
import { GpTariffPlan } from '../../api/types';

const tariffsState = createState(
  withEntities<GpTariffPlan>(),
  withActiveId(),
  withRequestsStatus<'tariffs'>(),
);

const tariffsStore = new Store({
  name: 'tariffs', state: tariffsState.state, config: tariffsState.config,
});

export const tariffs$ = tariffsStore.pipe(selectAllEntities());
export const activeTariff$ = tariffsStore.pipe(selectActiveEntity());
export const tariffsRequestPending$ = tariffsStore.pipe(selectIsRequestPending('tariffs'));

export const setAclRequestStatus = (status: Exclude<StatusState['value'], 'error'>) => {
  tariffsStore.update(
    updateRequestStatus('tariffs', status),
  );
};

export function setTariffs(tariffs: GpTariffPlan[]) {
  tariffsStore.update(
    upsertEntities(tariffs),
  );
}

export function getTariff(id: string) {
  return tariffsStore.query(getEntity(id));
}

export function resetTariffsStore() {
  tariffsStore.reset();
}
