import * as productsRepo from './products.repository';
import * as api from '../../services/geo-api.service';
import { tryGetResponseData } from '../common';
import { InputOrder } from '../filters/filters.repository';
import { GpLayerMap, GpRecipe, GpProduct } from '../../api/types';
import { getUniqueId, queriesWrapper } from '../../utils/functions';
import { MARKETPLACE_PAGE_SIZE_COUNT } from '../../utils/constants';

export async function setActiveProduct(id: string): Promise<void> {
  if (!productsRepo.getProduct(id)?.full) {
    productsRepo.setProductRequestStatus('pending');
    const res = await api.getProduct(id);
    try {
      // @ts-expect-error: ignore missing 'cmscreatedby' property
      const product: productsRepo.ExtendedGPProduct = {
        ...tryGetResponseData(res).GPProduct.get,
        full: true,
      };
      productsRepo.upsertProduct(product);
      productsRepo.setProductRequestStatus('success');
    } catch (e) {
      productsRepo.setProductRequestStatusError(e);
      throw e;
    }
  }

  productsRepo.setActiveProduct(id);
}

export async function fetchProducts(
  page: number,
  searchText: string,
  productTypes: string[] = [],
  territories: string[] = [],
  rubrics: string[] = [],
  updatePeriods: string[] = [],
  order?: InputOrder,
): Promise<void> {
  productsRepo.setProductRequestStatus('pending');
  productsRepo.setTotalCountProductRequestStatus('pending');

  const limit = MARKETPLACE_PAGE_SIZE_COUNT;
  const query = queriesWrapper<GpProduct>(api.getProducts, page, limit);
  api.getProductsCount(
    searchText,
    productTypes,
    territories,
    rubrics,
    updatePeriods,
  )
    .then((data) => {
      const { totalCount } = tryGetResponseData(data).GPProduct.list;
      productsRepo.setTotalCount(totalCount);
      productsRepo.setTotalCountProductRequestStatus('success');
    })
    .catch((e) => {
      if (e instanceof Error && e.message === 'aborted') {
        return;
      }
      productsRepo.setTotalCount(0);
      productsRepo.setTotalCountProductRequestStatusError(e);
      throw e;
    }); // TODO: For some reason, the query error is not being caught in the lower catch. Need research

  try {
    const res = await query(
      searchText,
      productTypes,
      territories,
      rubrics,
      updatePeriods,
      getUniqueId(),
      order,
    );

    const { data: products, hasMore } = res.GPProduct.list;

    productsRepo.setProducts(products, hasMore);
    productsRepo.setProductRequestStatus('success');
  } catch (e) {
    if (e instanceof Error && e.message === 'aborted') {
      return;
    }
    productsRepo.setProductRequestStatusError(e);
    throw e;
  }
}

export function getLayerListFromRecipe(recipe: GpRecipe) {
  return recipe.layers.map((l) => {
    const bgLayers = l.bg.map((bgl) => {
      const bg = {} as { id: string, n: string | undefined, uName: string };
      if (bgl.layer[0].__typename === 'GPLayerMap') {
        bg.uName = bgl.layer[0].uName;
        bg.id = bgl.layer[0].id;
      }
      bg.n = bgl.n;
      return bg;
    });

    const ovLayers = l.ov
      .filter((ovl) => ovl.layer.length && ovl.layer[0].__typename === 'GPLayerMap')
      .map((ovl) => {
        const ov = {} as { id: string, n: string | undefined, uName: string };
        ov.uName = (ovl.layer[0] as GpLayerMap).uName;
        ov.id = ovl.layer[0].id;
        ov.n = ovl.n;
        return ov;
      });

    return [...bgLayers, ...ovLayers];
  }).flat();
}

export async function getProductByLayerIdAsync(id: string): Promise<string> {
  const res = await api.getProductByLayerId(id);
  const products = tryGetResponseData(res).GPProduct.list.data;
  if (!products.length) {
    throw new Error('data is not present');
  }
  return products[0].id;
}
