import * as L from 'leaflet';

export default class PunctureControl extends L.Control {
  isEnabled = true;

  isActive = false;

  container: HTMLElement | undefined;

  title: string;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(title: string, options?: L.ControlOptions) {
    super(options);
    this.title = title;
  }

  enable() {
    L.DomUtil.removeClass(document.querySelector('.multipuncture-control')!, 'disabled');
    this.isEnabled = true;
  }

  disable() {
    L.DomUtil.addClass(document.querySelector('.multipuncture-control')!, 'disabled');
    this.isEnabled = false;
  }

  onAdd() {
    /* eslint-disable */
    // @ts-ignore
    const id = L.Util.Toggler.registerTool(this);
    const container = L.DomUtil.create('div', 'multipuncture-control');
    const link = L.DomUtil.create('a', 'multipuncture-icon', container);
    L.DomUtil.addClass(link, 'toolbar-button');
    container.setAttribute('id', id);
    link.setAttribute('title', this.title);

    L.DomEvent.on(link, 'click', L.DomEvent.stop, this).on(link, 'click', () => {

      if (!this.isActive) {
        // @ts-ignore
        L.Util.Toggler.disableAll();
        this.enable();
        this.activate();
      } else {
        // @ts-ignore
        L.Util.Toggler.enableAll();
        this.deActivate();
      }
    }, this);

    return container;
  }

  activate() {
    L.DomUtil.addClass(document.querySelector('.multipuncture-icon')!, 'icon-active');
    L.DomUtil.addClass(document.querySelector('.leaflet-top.leaflet-right')!, 'not-allowed');
    L.DomUtil.addClass(document.querySelector('.leaflet-top.leaflet-left')!, 'not-allowed');
    L.DomUtil.addClass(document.querySelector('.leaflet-control.leaflet-bar:last-child')!, 'not-allowed');
    L.DomUtil.addClass(document.querySelector('.leaflet-control.leaflet-bar.measurement')!, 'not-allowed');
    L.DomUtil.addClass(document.querySelector('.leaflet-control.leaflet-bar.isochrone')!, 'not-allowed');

    document.querySelectorAll('.leaflet-pm-toolbar').forEach((el) => {
      L.DomUtil.addClass(el as HTMLElement, 'not-allowed');
    });
    
    L.DomUtil.addClass(document.querySelector('.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom')!, 'icon-active-map');
    this.isActive = true;
  }

  deActivate() {
    L.DomUtil.removeClass(document.querySelector('.multipuncture-icon')!, 'icon-active');
    L.DomUtil.removeClass(document.querySelector('.leaflet-top.leaflet-right')!, 'not-allowed');
    L.DomUtil.removeClass(document.querySelector('.leaflet-top.leaflet-left')!, 'not-allowed');
    L.DomUtil.removeClass(document.querySelector('.leaflet-control.leaflet-bar:last-child')!, 'not-allowed');
    L.DomUtil.removeClass(document.querySelector('.leaflet-control.leaflet-bar.measurement')!, 'not-allowed');
    L.DomUtil.removeClass(document.querySelector('.leaflet-control.leaflet-bar.isochrone')!, 'not-allowed');

    document.querySelectorAll('.leaflet-pm-toolbar').forEach((el) => {
      L.DomUtil.removeClass(el as HTMLElement, 'not-allowed');
    });

    L.DomUtil.removeClass(document.querySelector(' .leaflet-container.leaflet-touch-drag.leaflet-touch-zoom')!, 'icon-active-map');
    this.isActive = false;
  }
}
