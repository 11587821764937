import {
  Box, Grid, Stack,
} from '@mui/material';
import { useObservable } from '@ngneat/react-rxjs';
import { useCallback, useContext, useEffect } from 'react';

import PageHeader from '../../blocks/PageHeader/PageHeader';
import TariffCard from '../../blocks/TariffCard/TariffCard';
import { resetTariffsStore, tariffs$, tariffsRequestPending$ } from '../../state/geoData/tariffs.repository';
import { pagesRequestPending$ } from '../../state/pages/pages.repository';
import { getTariffs } from '../../state/geoData/tariffs.service';
import { activeAccount$ } from '../../state/context/auth.repository';
import { DrawerContentEnum, DrawerContext } from '../../drawerContext';
import { GpCustomerProfileIn } from '../../api/types';
import { updateCustomerProfile } from '../../services/geo-api.service';
import Loader from '../../components/Loader/Loader';
import useComponentWillMount from '../../hooks/useComponentWillMount';

function Tariffs() {
  const [tariffs] = useObservable(tariffs$, { initialValue: [] });
  const [tariffsRequestPending] = useObservable(
    tariffsRequestPending$,
    { initialValue: true },
  );
  const [pagesRequestPending] = useObservable(pagesRequestPending$, { initialValue: true });
  const [account] = useObservable(activeAccount$);
  const drawerContext = useContext(DrawerContext);

  useComponentWillMount(() => resetTariffsStore());

  useEffect(() => {
    if (!tariffs.length && account) {
      void getTariffs();
    }
  }, [account]);

  const handleTariffClick = useCallback((id: string) => {
    if (account) {
      void updateCustomerProfile(
        account.id,
        { tariffPlan: id } as unknown as GpCustomerProfileIn,
      );
    } else {
      drawerContext.open(DrawerContentEnum.LOGIN);
    }
  }, [account]);

  if (tariffsRequestPending) {
    return <Loader id="progress-tariffs" />;
  }

  const renderTarriffCard = () => (
    <Box mt={2} pb={1.5}>
      <Grid container spacing={2.5}>
        {tariffs.map((tariff) => (
          <Grid item xs={3} key={tariff.id}>
            <TariffCard
              title={tariff.title}
              subtitle={tariff.subtitle}
              available={tariff.id !== account?.tariffPlan.id}
              advantages={tariff.valuesList}
              price={tariff.price}
              onButtonClick={() => handleTariffClick(tariff.id)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  return (
    <>
      <Stack justifyContent="center">
        <PageHeader
          placeholder={' '}
        />
      </Stack>

      {pagesRequestPending
        ? null
        : renderTarriffCard()}
    </>
  );
}

export default Tariffs;
