import { useState } from 'react';
import {
  Slider, Stack, Typography, Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { changeUILayer } from '../../state/project/project.actions';
import styles from './LayerSliders.module.scss';

interface LayerSlidersProps {
  opacity: number;
  grey: number;
  layerId: string;
  hue?: number;
}

const greyInvert = (v: number): number => {
  const res = 100 - v;
  return ((res === 99 ? 100 : res || 1) / 100);
};

interface LayerCardSliderProps {
  testid: string;
  value: number;
  min?: number;
  max?: number;
  title: string;
  ariaLabel: string;
  onChange(value: number | number[]): void;
  onChangeCommitted(value: number | number[]): void;
}

function LayerCardSlider({
  testid, value, min, max, title, ariaLabel, onChange, onChangeCommitted,
}: LayerCardSliderProps) {
  return (
    <Stack className={styles.layerSettings}>
      <Typography variant="title2" className={styles.label}>
        {title}
      </Typography>
      <Stack direction="row" alignItems="center">
        <Slider
          data-testid={`${testid}Slider`}
          size="small"
          min={min ?? 0}
          max={max ?? 100}
          sx={{ width: '120px' }}
          aria-label={ariaLabel}
          value={value}
          onChange={(_, value) => onChange(value)}
          onChangeCommitted={(_, value) => onChangeCommitted(value)}
        />
        <Typography data-testid={`${testid}Value`} variant="body2" color="secondary.light" paddingLeft="8px" width="30px">
          {value}
        </Typography>
      </Stack>
    </Stack>
  );
}

function LayerSliders({
  opacity = 0, grey = 0, layerId, hue,
}: LayerSlidersProps) {
  const [opacityValue, setOpacity] = useState(Math.floor(opacity * 100));
  const [greyValue, setGrey] = useState(Math.floor(greyInvert(Math.floor(grey * 100)) * 100));
  const [hueValue, setHue] = useState(hue || 0);

  const handleOpacityChange = (newValue: number | number[]) => {
    setOpacity(Math.floor(Number(newValue)));
  };

  const handleOpacitySave = (value: number | number[]) => {
    void changeUILayer({
      id: layerId,
      key: 'o',
      value: (value as number) / 100,
    });
  };

  const handleGreyChange = (newValue: number | number[]) => {
    setGrey(Math.floor(Number(newValue)));
  };
  const handleGreySave = (
    value: number | number[],
  ) => {
    void changeUILayer({
      id: layerId,
      key: 'g',
      value: greyInvert((value as number)),
    });
  };
  const { t } = useTranslation('common');

  const handleHueChange = (newValue: number | number[]) => {
    setHue(Math.floor(Number(newValue)));
  };
  const handleHueSave = (value: number | number[]) => {
    void changeUILayer({
      id: layerId,
      key: 'h',
      value: value as number,
    });
  };

  return (
    <Box>
      <LayerCardSlider
        testid="opacity"
        value={opacityValue}
        min={1}
        title={t('layer.opacity')}
        ariaLabel="opacity"
        onChange={handleOpacityChange}
        onChangeCommitted={handleOpacitySave}
      />
      <LayerCardSlider
        testid="grey"
        value={greyValue}
        min={1}
        title={t('layer.contrast')}
        ariaLabel="grey"
        onChange={handleGreyChange}
        onChangeCommitted={handleGreySave}
      />
      <LayerCardSlider
        testid="hue"
        value={hueValue}
        max={360}
        title={t('layer.color')}
        ariaLabel="hue"
        onChange={handleHueChange}
        onChangeCommitted={handleHueSave}
      />
    </Box>
  );
}

export default LayerSliders;
