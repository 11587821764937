import { useEffect } from 'react';
import { useObservable } from '@ngneat/react-rxjs';
import { activeAccountId$, getAuthProps, user$ } from '../../../state/context/auth.repository';
import { initUserAndSetUserMode } from '../../../state/context/auth.actions';
import Loader from '../../../components/Loader/Loader';
import { fetchOrders } from '../../../state/order';
import { fetchAclAsync } from '../../../state/project/project.actions';

const UnauthenticatedPage = () => {
  useEffect(() => {
    void initUserAndSetUserMode();
  }, []);

  return (
    <Loader id="progress-init-user-guard" />
  );
};

const InitUserGuard = ({ children }: { children: JSX.Element; }) => {
  const [user] = useObservable(user$);
  const [activeAccountId] = useObservable(activeAccountId$);

  useEffect(() => {
    if (user && user.name !== 'guest' && activeAccountId) {
      const fetchOrdersSafely = async () => {
        try {
          await fetchOrders();
          await fetchAclAsync();
        } catch (err) {
          const { activeId, token } = getAuthProps();
          if (activeId && token) {
            throw err;
          }
          // eslint-disable-next-line no-console
          console.log('fetchOrders failed due to logout in progress, ignoring:', err);
        }
      };

      void fetchOrdersSafely();
    }
  }, [activeAccountId]);

  return user
    ? children
    : <UnauthenticatedPage />;
};

export default InitUserGuard;
