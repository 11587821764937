/* eslint-disable react/jsx-indent */
import { FC } from 'react';
import { Box, Stack } from '@mui/material';
import styles from './ModalProjectLayout.module.scss';

interface ProjectInfoLayoutProps {
  body?: React.ReactNode,
  bodyRaw?: React.ReactNode,
  bodyHeader?: React.ReactNode,
  bodyHeaderRaw?: React.ReactNode,
  bodyFooter?: React.ReactNode,
  bodyFooterRaw?: React.ReactNode,
  side?: React.ReactNode,
  sideHeader?: React.ReactNode,
  sideFooter?: React.ReactNode,
}

const ProjectInfoLayout: FC<ProjectInfoLayoutProps> = ({
  body, side, bodyHeader, bodyFooter, sideHeader, sideFooter,
  bodyRaw, bodyHeaderRaw, bodyFooterRaw,
}) => (
  <Stack direction="row" height="100%">

    <Box className={styles.bodyColumn}>

      {bodyHeaderRaw && bodyHeaderRaw}
      {bodyHeader && <Box className={styles.bodyHeader}>{bodyHeader}</Box>}

      {bodyRaw && bodyRaw}
      {body && <Box className={styles.body}>{body}</Box>}

      {bodyFooterRaw && bodyFooterRaw}
      {bodyFooter && <Box className={styles.bodyFooter}>{bodyFooter}</Box>}

    </Box>

    {side && (
      <Box className={styles.side}>
          {sideHeader && sideHeader}
          {side && side}
          {sideFooter && <Box className={styles.sideFooter}>{sideFooter}</Box>}
      </Box>
    )}

  </Stack>
);

export default ProjectInfoLayout;
