import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  Typography, Box, Link as MuiLink,
} from '@mui/material';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { ReactComponent as ArrowLeftIcon } from '../../assets/images/arrowLeft.svg';
import { GpOrder, GpOrderItem } from '../../api/types';
import PageBreadcrumbs from '../../blocks/PageBreadcrumbs/PageBreadcrumbs';
import { DEFAULT_DATE_TIME } from '../../utils/constants';
import styles from './Order.module.scss';
import './statuses.scss';
import Loader from '../../components/Loader/Loader';
import { getOrder } from '../../services/geo-api.service';

function Order() {
  const { t: translate } = useTranslation('common');
  const { orderId } = useParams<string>();
  const [order, setOrder] = useState<GpOrder | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (orderId) {
      void (async () => {
        const res = await getOrder(orderId);
        setOrder(res.data.GPOrder.get as GpOrder);
        setLoading(false);
      })();
    }
  }, []);

  if (loading) {
    return <Loader id="progress-order" />;
  }

  let totalPrice = 0;
  order?.orderItems?.forEach((item) => {
    totalPrice += item.productPackaging[0].price;
  });

  const path = (order: GpOrder) => {
    const productBreadcrumbs = [
      { title: translate('page.account'), to: '/account' },
      { title: translate('account.orders'), to: '/account/orders' },
      {
        title: translate('order.self_short', {
          number: order.id,
        }),
        to: `/account/orders/${orderId!}`,
      },
    ];
    return (
      <PageBreadcrumbs
        links={productBreadcrumbs}
      />
    );
  };

  const arrowBack = () => (
    <Box className={styles.arrowWrapper}>
      <Link className={styles.arrowLink} to={{ pathname: '/account/orders' }}>
        <ArrowLeftIcon />
        <Typography variant="subtitle2">
          {translate('layer.back')}
        </Typography>
      </Link>
    </Box>
  );

  const renderOrderItems = (orderItems: GpOrderItem[]) => [...orderItems]
    .map((item, index) => (
      <Stack direction="row" className={styles.itemWrapper} key={item.id}>
        <Typography variant="body1">{ `${index + 1}.` }</Typography>
        <Stack className={styles.itemInfo} gap="4px">
          <Typography variant="body1">
            { item.productCategory[0].title }
          </Typography>

          <Typography className={styles.itemInfoText} variant="body1">
            { item.productPackaging[0].title }
          </Typography>

          { 'dataset' in item.productPackaging[0]
            && (
            <Typography className={styles.itemInfoText} variant="body1">
              { item.productPackaging[0].dataset.uName }
            </Typography>
            )}

          { 'layer' in item.productPackaging[0]
            && (
            <Typography className={styles.itemInfoText} variant="body1">
              { item.productPackaging[0].layer.uName }
            </Typography>
            )}

          { 'territory' in item.productCategory[0]
            && (
            <Typography className={styles.itemInfoText} variant="body1">
              { item.productCategory[0]?.territory.name }
            </Typography>
            )}

        </Stack>
        <Box className={styles.itemPriceWrapper}>
          <Typography variant="subtitle1" className={styles.itemPriceValue}>
            { `${item.productPackaging[0].price} ₽` }
          </Typography>
        </Box>
      </Stack>
    ));

  const renderOrderStatus = (order: GpOrder) => (
    <Box className={styles.statusWrapper}>
      <Stack direction="row">
        <Typography className={styles.statusTitle} variant="body1">
          { `${translate('order.status')}:` }
        </Typography>
        <Typography variant="body1" className={`ORDER_${order.status}`}>
          { translate(`order.status_${order.status.toLowerCase()}`) }
        </Typography>
      </Stack>
      <Stack direction="row">
        <Typography className={styles.statusTitle} variant="body1">
          { `${translate('order.date')}:` }
        </Typography>
        <Typography variant="body1">
          { order.dateTime === DEFAULT_DATE_TIME
            ? '-'
            : DateTime.fromMillis(order.dateTime || 0).setLocale('ru-Ru').toFormat('dd MMMM yyyy, HH:mm:ss') }
        </Typography>
      </Stack>
      <Stack direction="row">
        <Typography className={styles.statusTitle} variant="body1">
          { `${translate('order.check')}:` }
        </Typography>
        {
          order.billUri
            ? (
              <MuiLink
                className={styles.billLink}
                href={order.billUri}
                variant="body1"
                target="_blank"
              >
                <Typography variant="body1">
                  { order.billUri }
                </Typography>
              </MuiLink>
            )
            : (
              <Typography variant="body1">
                -
              </Typography>
            )
        }
      </Stack>
    </Box>
  );

  return (
    <Box paddingBottom="80px">
      { order ? path(order) : null }
      <Stack direction="row" marginTop="20px" gap="25px">
        { arrowBack() }
        <Stack className={styles.wrapper}>
          <Typography variant="h2">
            { translate('order.self', {
              number: order?.id,
              date: DateTime.fromMillis(order?.cmscreationdate || 0).setLocale('ru-Ru').toFormat('dd MMMM yyyy'),
            }) }
          </Typography>

          { order ? renderOrderStatus(order) : null }

          <Stack className={styles.detail}>
            <Typography variant="subtitle1">
              { `${translate('order.positions')} (${order?.orderItems?.length || 0}):` }
            </Typography>
            <Box className={styles.itemsWrapper}>
              {order?.orderItems && renderOrderItems(order?.orderItems) }
            </Box>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="subtitle1">{ translate('order.total') }</Typography>
              <Typography variant="subtitle1" color="primary.main">{ `${totalPrice} ₽` }</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}

export default Order;
