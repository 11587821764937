import {
  GpbiRecipe,
  GpbiRecipeIn,
  GpdsScheduleDb,
  GpLayerFormulaIn,
  GpLayerMap,
  GpPaletteIn,
  GpProject,
  GpProjectIn,
  GpRecipe,
  GpRecipeIn,
  GpSchedule,
  GpuiDataset,
  GpuiDatasetIn,
  GpuiLayer,
  GpuiLayerIn,
  TmpLayerTypenames,
} from '../../api/types';
import { RESULTING_LAYER_ID } from '../../utils/constants';
import { randomInt } from '../../utils/functions';
import { getScheduleWithLayer } from '../geoData/recipes.repository';

export const uiLayerTouiLayerIn = (
  sourceLayer: GpuiLayer,
  title?: string,
): GpuiLayerIn => {
  const layer = sourceLayer.layer.map((l) => ({
    id: l.id,
    typename: 'GPLayerMap',
  }));

  const uiLayerIn: GpuiLayerIn = {
    id: sourceLayer.id,
    on: sourceLayer.on,
    o: sourceLayer.o,
    g: sourceLayer.g,
    h: sourceLayer.h,
    p: sourceLayer.p as unknown as GpPaletteIn[],
    n: title ?? sourceLayer.n,
    layer,
    layerEm: sourceLayer.layerEm as unknown as GpLayerFormulaIn[],
    tmpLayerTypename: sourceLayer.tmpLayerTypename,
  };
  return uiLayerIn;
};

export const formulaLayerTouiLayerIn = (
  sourceLayer: GpuiLayer,
): GpuiLayerIn => {
  const layer = sourceLayer.layer.map((l) => ({
    id: l.id,
    typename: 'GPLayerMap',
  }));

  const layerEm = sourceLayer.layerEm
    .map((l) => ({ ...l, L: l.L.map((fi) => ({ ...fi, id: fi.title })) }));

  const uiLayerIn: GpuiLayerIn = {
    id: sourceLayer.id,
    on: sourceLayer.on,
    o: sourceLayer.o,
    g: sourceLayer.g,
    h: sourceLayer.h,
    p: sourceLayer.p as unknown as GpPaletteIn[],
    n: sourceLayer.n,
    layer,
    layerEm: layerEm as unknown as GpLayerFormulaIn[],
    tmpLayerTypename: sourceLayer.tmpLayerTypename,
  };
  return uiLayerIn;
};

export const GpuiDatasetToGpuiDatasetIn = (
  sourceUIDataset: GpuiDataset,
): GpuiDatasetIn => {
  const datasetListIn = {
    name: sourceUIDataset.name,
    ds: sourceUIDataset.ds.map((ds) => ({
      id: ds.id,
      typename: 'GPDSScheduleDB',
    })),
  };

  return datasetListIn;
};

export const gpDsScheduleDbToGpuiDatasetIn = (
  { id, title }: GpdsScheduleDb,
): GpuiDatasetIn => {
  const gpUiDatasetIn = {
    name: title,
    ds: [{
      id,
      typename: 'GPDSScheduleDB',
    }],
  };

  return gpUiDatasetIn;
};

export function mapLayerMapToUILayerLocal(
  layer: GpLayerMap,
  on: boolean = false,
): Partial<GpuiLayer> {
  return {
    id: layer.id,
    on,
    n: layer.title,
    g: 0,
    o: 1,
    h: randomInt(0, 360),
    layer: [{ ...layer }],
    tmpLayerTypename: TmpLayerTypenames.GpLayerMap,
  };
}

export const mapRecipeToRecipeIn = (
  recipe: GpRecipe,
): GpRecipeIn => {
  const newRecipe = structuredClone(recipe);
  delete newRecipe.__typename;

  const scheduleWithLayer = getScheduleWithLayer();

  const resultLayer = newRecipe.layers[0].ov
    .splice(newRecipe.layers[0].ov.findIndex((l) => l.id === RESULTING_LAYER_ID), 1)[0];

  const uiLayerTouiLayerInFunc = (uiLayerFromRecipe: GpuiLayer, index: number) => {
    if (scheduleWithLayer.length) {
      const layers = scheduleWithLayer
        .map((scheduleWithLayer) => ((scheduleWithLayer.schedule as GpSchedule).layer));

      const uiLayer = mapLayerMapToUILayerLocal(layers[index], true) as GpuiLayer;
      return uiLayerTouiLayerIn(uiLayer, uiLayerFromRecipe.n);
    }
    return uiLayerTouiLayerIn(uiLayerFromRecipe);
  };

  const inLayers = newRecipe.layers.map((layer) => ({
    id: layer.id,
    cid: layer.cid,
    bg: layer.bg.map((uiLayer) => uiLayerTouiLayerIn(uiLayer)),
    ov: [
      formulaLayerTouiLayerIn(resultLayer),
      layer.ov?.map((uiLayer, index) => uiLayerTouiLayerInFunc(uiLayer, index))].flat(),
  }));

  const semanticTerms = recipe.semanticTerms
    ? recipe.semanticTerms.map((term) => ({
      id: term.id,
      typename: 'GPSemanticCoreItem',
    }))
    : [];

  const usefulLayers = recipe.usefulLayers
    ? recipe.usefulLayers.map(({ id }) => ({
      id,
      typename: 'GPLayer',
    }))
    : [];

  const recipeIn = {
    ...newRecipe,
    usefulLayers,
    semanticTerms,
    layers: inLayers,
  } as unknown as GpRecipeIn;
  return recipeIn;
};

export const mapBiRecipeToRecipeIn = (
  recipe: GpbiRecipe,
): GpbiRecipeIn => {
  const datasetsList = recipe.datasetsList.map((datasets) => ({
    id: datasets.id,
    cid: datasets.cid,
    dslist: datasets.dslist.map((ds) => GpuiDatasetToGpuiDatasetIn(ds)),
  }));

  const usefulDatasets = recipe.usefulDatasets.map((usefulDataset) => ({
    id: usefulDataset.id,
    typename: 'GPStatDataset',
  }));

  const biRecipeIn = {
    ...recipe,
    datasetsList,
    usefulDatasets,
  } as unknown as GpbiRecipeIn;
  return biRecipeIn;
};

export const mapProjectToProjectIn = (
  project: GpProject,
): GpProjectIn => {
  const projectIn: GpProjectIn = {};
  projectIn.title = project.title;
  projectIn.markersCfg = project.markersCfg;
  projectIn.recipe = project.recipe.map((r) => mapRecipeToRecipeIn(r));
  return projectIn;
};

export function mapLayerMapToUILayerIn(
  {
    id,
    uName,
    title,
  }: GpLayerMap,
  on: boolean = false,
): GpuiLayerIn {
  return {
    id,
    on,
    uName,
    n: title,
    g: 0,
    o: 1,
    h: randomInt(0, 360),
    layer: [{
      // @ts-ignore
      title: uName,
      id,
      __typename: 'GPLayerMap',
    }],
    tmpLayerTypename: TmpLayerTypenames.GpLayerMap,
  };
}
