import { setTariffs, setAclRequestStatus } from './tariffs.repository';
import * as api from '../../services/geo-api.service';
import { GpTariffPlan } from '../../api/types';
import { tryGetResponseData } from '../common';

export async function getTariffs() {
  setAclRequestStatus('pending');
  const res = await api.fetchTariffs();
  const tariffs = tryGetResponseData(res).GPTariffPlan.list.data as GpTariffPlan[];
  setTariffs(tariffs);
  setAclRequestStatus('success');
}
