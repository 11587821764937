import {
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import ModalProjectLayout from '../../../blocks/ModalProject/Layout/ModalProjectLayout';
import ProjectInfoRow from './ProjectInfoRow';

import { ProjectTypeChip, ProjectTypeChipType } from './ProjectTypeChip';
import useProjectInfo from '../hooks/useProjectInfo';

function ProjectAboutTab() {
  const { t } = useTranslation('common');
  const {
    createDateString,
    updateDateString,
    authorName,
    projectType,
  } = useProjectInfo();

  const projectTypeComponent = (
    <ProjectTypeChip projectType={
        ProjectTypeChipType[projectType as keyof typeof ProjectTypeChipType]
      }
    />
  );

  const bodyHeader = (
    <Stack direction="column">
      <ProjectInfoRow title={t('project.author')} text={authorName} />
      <ProjectInfoRow title={t('project.creation_date')} text={createDateString} />
      <ProjectInfoRow title={t('project.update_date')} text={updateDateString} />
      <ProjectInfoRow title={t('project.type')} component={projectTypeComponent} />
    </Stack>
  );

  return (
    <ModalProjectLayout
      bodyHeader={bodyHeader}
    />
  );
}

export default ProjectAboutTab;
