import {
  GpLayer, GpStatDataset,
  GpLayerMap, GpdsScheduleDb,
  GpSchedule, GpdsSchedule,
} from '../../api/types';

export enum TabViews {
  First = 'first',
  Second = 'second',
  Third = 'third',
}

export type LayerMapOrScheduleDb = GpLayerMap | GpdsScheduleDb;
export type LayerType = 'GPLayer' | 'GPStatDataset';
export type LayerOrDataset = GpLayer | GpStatDataset;
export type GPSchedule = GpSchedule | GpdsSchedule;

export interface FlatLayersListItem {
  layerMapId: LayerMapOrScheduleDb['id'],
  layerTitle: string | null,
  scheduleTitle: string | null,
  uName: string | null,
  territoryName: string | null,
  layerMap: LayerMapOrScheduleDb;
}

export interface FlatSchedule {
  scheduleTitle: string,
  scheduleUname: string,
  layerMap: LayerMapOrScheduleDb,
}

export interface FlatLayersListItemSecond {
  layerTitle: string,
  layerId: string,
  layerAnnotation: string,
  layerType: LayerType | undefined,
  territoryName: string,
  schdules: FlatSchedule[],
}

export const getTitleOfSchedule = (
  schedules: GPSchedule[],
  layerOrDataset: GpLayerMap | GpdsScheduleDb,
) => schedules
  .find((schedule) => {
    if ('layer' in schedule) {
      return schedule.layer.id === layerOrDataset.id;
    }
    return schedule.dataset.id === layerOrDataset.id;
  })?.title;

export function composeLayersList(
  layersOrDatasets: LayerOrDataset[],
  layersMapOrSchedulesDb: LayerMapOrScheduleDb[],
  schedules: GPSchedule[],
) {
  const result: FlatLayersListItem[] = [];

  layersMapOrSchedulesDb.forEach((layerMapOrScheduleDb) => {
    const scheduleTitle = getTitleOfSchedule(schedules, layerMapOrScheduleDb);

    const gpLayer = layersOrDatasets.find(
      (layerOrDataset: LayerOrDataset) => layerOrDataset.uNameKey === layerMapOrScheduleDb.uName.split('_', 3).join('_'),
    );

    result.push({
      layerMapId: layerMapOrScheduleDb.id,
      layerMap: layerMapOrScheduleDb,
      layerTitle: gpLayer?.title ?? null,
      scheduleTitle: scheduleTitle ?? null,
      uName: layerMapOrScheduleDb.uName ?? null,
      territoryName: gpLayer?.territory?.name ?? null,
    });
  });

  return result;
}

export function composeLayersListSecond(
  layersOrDatasets: LayerOrDataset[],
  layersMapOrSchedulesDb: LayerMapOrScheduleDb[],
) {
  const result: FlatLayersListItemSecond[] = [];
  layersOrDatasets.forEach((layerOrDataset) => {
    const layerMapUnames = layersMapOrSchedulesDb
      .filter((el) => layerOrDataset.uNameKey === el.uName.split('_', 3).join('_'))
      .map((el) => el.uName);
    const filteredSchedules = (layerOrDataset.schedules as (GpSchedule | GpdsSchedule)[])
      .filter((el) => {
        if ('layer' in el) return layerMapUnames.includes(el.layer.uName);
        return layerMapUnames.includes(el.dataset.uName);
      });
    const flatSchedules: FlatSchedule[] = filteredSchedules.map((schedule) => ({
      scheduleTitle: schedule.title,
      scheduleUname: 'layer' in schedule ? schedule.layer.uName : schedule.dataset.uName,
      layerMap: 'layer' in schedule ? schedule.layer : schedule.dataset,
    }));

    result.push({
      layerTitle: layerOrDataset.title,
      layerId: layerOrDataset.id,
      layerAnnotation: layerOrDataset.annotation,
      layerType: layerOrDataset.__typename,
      territoryName: layerOrDataset.territory.name,
      schdules: flatSchedules ?? [],
    });
  });

  return result;
}
