import {
  Box,
  Stack,
} from '@mui/material';
import SideLayerList from '../SideLayerList';
import styles from './SideMap.module.scss';

function SideMap() {
  return (
    <Stack className={styles.projectSide}>
      <Box className={styles.projectSideContainer}>
        <Box height="100%">
          <SideLayerList />
        </Box>
      </Box>
    </Stack>
  );
}

export default SideMap;
