import { Typography } from '@mui/material';
import { FC } from 'react';
import styles from './TotalCount.module.scss';
import Loader from '../Loader/Loader';

interface TotalCountProps {
  totalCount?: number;
  pending: boolean;
}

const TotalCount: FC<TotalCountProps> = ({ totalCount, pending }) => {
  const renderLoader = (
    <div className={styles.loader}>
      <Loader className="loader-totalcount" id="progress-totalcount-produts" />
    </div>
  );

  return (
    pending
      ? renderLoader
      : <Typography variant="accentTitle1" className={styles.totalCount}>{ totalCount || 0 }</Typography>
  );
};

export default TotalCount;
