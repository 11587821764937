import {
  GpdsScheduleIn,
  GpLayer,
  GpLayerIn, GpOrder, GpOrderIn, GpOrderItemIn, GpScheduleIn, GpStatDatasetIn,
} from '../../api/types';
import * as api from '../../services/geo-api.service';
import { setOrdersRequestError, setOrdersRequestPending, upsertOrder } from './order.repository';

export const fetchOrders = () => api.getOrders();
export const fetchFullOrders = (page: number, limit: number) => api.getFullOrders(page, limit);
export const fetchPaymentStatus = (id: string) => api.getPaymentStatus(id);

const orderToOrderIn = (order: Partial<GpOrder>): Partial<GpOrderIn> => {
  const orderIn: Partial<GpOrderIn> = {
    id: order.id,
    num: order.num,
    status: order.status,
    dateTime: order.dateTime,
  };
  if (order.orderItems) {
    orderIn.orderItems = order.orderItems.map(
      (val) => JSON.parse(JSON.stringify(val)) as GpOrderItemIn,
    );
    orderIn.orderItems.forEach((i) => {
      const productCategory = i.productCategory?.[0] as GpLayerIn | GpStatDatasetIn;
      const productPackaging = i.productPackaging?.[0] as GpScheduleIn | GpdsScheduleIn;

      // TODO: need refactoring - productCategory and productPackaging is not '...In'
      if ('__typename' in productCategory) {
        delete (productCategory as unknown as GpLayer).__typename;
      }
      if ('rubrics' in productCategory && (!productCategory.rubrics || productCategory.rubrics.length === 0)) {
        delete productCategory.rubrics;
      }
      if ('similarLayers' in productCategory
        && (!productCategory.similarLayers || productCategory.similarLayers.length === 0)) {
        delete productCategory.similarLayers;
      }
      if ('semanticTerms' in productCategory
        && (!productCategory.semanticTerms || productCategory.semanticTerms.length === 0)) {
        delete productCategory.semanticTerms;
      }
      if ('productFeatures' in productCategory
        && (!productCategory.productFeatures || productCategory.productFeatures.length === 0)) {
        delete productCategory.productFeatures;
      }
      if ('recommendedRecipes' in productCategory
        && (!productCategory.recommendedRecipes || productCategory.recommendedRecipes.length === 0)) {
        delete productCategory.recommendedRecipes;
      }
      if ('territory' in productCategory && !productCategory.territory) {
        delete productCategory.territory;
      }
      if ('updatePeriod' in productCategory && !productCategory.updatePeriod) {
        delete productCategory.updatePeriod;
      }
      if ('geoType' in productCategory && productCategory.geoType) {
        productCategory.geoType = {
          id: productCategory.geoType?.id,
          typename: 'GPGeoType',
        };
      }
      if ('territory' in productCategory) {
        productCategory.territory = {
          id: productCategory.territory?.id,
          typename: 'GPTerritories',
        };
      }
      if ('updatePeriod' in productCategory) {
        productCategory.updatePeriod = {
          id: productCategory.updatePeriod?.id,
          typename: 'GPUpdatePeriod',
        };
      }
      // eslint-disable-next-line array-callback-return, consistent-return
      productCategory.schedules?.map((s) => {
        if ('layer' in s && 'layer' in productPackaging) {
          s.layer = {
            id: s.layer?.id,
            typename: 'GPLayerMap',
          };
          return s;
        }
        if ('dataset' in s && 'dataset' in productPackaging) {
          s.dataset = {
            id: s.dataset?.id,
            typename: 'GPDSScheduleDB',
          };
          return s;
        }
      });
      if ('rubrics' in productCategory) {
        productCategory.rubrics = productCategory.rubrics?.map((r) => ({
          id: r.id,
          typename: 'GPRubric',
        }));
      }
      if ('similarLayers' in productCategory) {
        productCategory.similarLayers = productCategory.similarLayers?.map((r) => ({
          id: r.id,
          typename: 'GPLayer',
        }));
      }
      if ('productFeatures' in productCategory) {
        productCategory.productFeatures = productCategory.productFeatures?.map((r) => ({
          id: r.id,
          typename: 'GPProductFeature',
        }));
      }
      if ('recommendedRecipes' in productCategory) {
        productCategory.recommendedRecipes = productCategory.recommendedRecipes?.map((r) => ({
          id: r.id,
          typename: 'GPRecipe',
        }));
      }
      if ('layer' in productPackaging) {
        productPackaging.layer = {
          id: productPackaging.layer?.id,
          typename: 'GPLayerMap',
        };
      }
      if ('dataset' in productPackaging) {
        productPackaging.dataset = {
          id: productPackaging.dataset?.id,
          typename: 'GPDSScheduleDB',
        };
      }
    });
    orderIn.orderItems = orderIn.orderItems.map((i) => {
      if ('layer' in i.productPackaging![0]) {
        return {
          ...i,
          productCategory: {
            on_GPLayerIn: i.productCategory![0],
          },
          productPackaging: {
            on_GPScheduleIn: i.productPackaging![0],
          },
        } as unknown as GpOrderItemIn;
      }
      return {
        ...i,
        productCategory: {
          on_GPStatDatasetIn: i.productCategory![0],
        },
        productPackaging: {
          on_GPDSScheduleIn: i.productPackaging![0],
        },
      } as unknown as GpOrderItemIn;
    });
  }

  return orderIn;
};

export const createOrder = (order: Partial<GpOrder>) => {
  const orderIn = orderToOrderIn(order);
  return api.newOrder(orderIn);
};

export const updateOrder = async (id: string, order: Partial<GpOrder>) => {
  const orderIn = orderToOrderIn(order);

  try {
    setOrdersRequestPending();
    const { data, errors } = await api.updateOrder(id, orderIn);

    if (errors?.length) {
      setOrdersRequestError(errors[0]);
      throw new Error(errors[0].message);
    }

    if (data?.GPOrder.update) {
      upsertOrder(data.GPOrder.update as GpOrder);
    }
  } catch (err) {
    // TODO: replace with snackbar
    setOrdersRequestError(err);
    // eslint-disable-next-line no-console
    console.log(err);
  }
};

export const deleteOrder = (id: string) => api.deleteOrder(id);
