import { Box, Modal, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';

import ModalProjectLayout from '../../blocks/ModalProject/Layout/ModalProjectLayout';
import { RubricsSideBody, RubricsSideFooter } from './RightBlock';
import RubricsBody from './RubricsBody/RubricsBody';
import HeaderWithCount from '../ModalProjectTabs/common/HeaderWithCount';

import useRubricsModal from './hooks/useRubricsModal';
import styles from './RubricsModal.module.scss';

interface AttachLayerDialogProps {
  open: boolean;
  handleClose: () => void;
}

function RubricsModal({ open, handleClose }: AttachLayerDialogProps) {
  const { t } = useTranslation('common');

  const {
    rubrics,
    activeRubrics,
    tempRubrics,
    selectedRubric,
    singleRubrics,
    leafRubrics,
    handleBodyRender,
    handleRubricGroupSelect,
    handleRubricFilterChange,
    clearSelectedRubric,
    clearTempRubrics,
  } = useRubricsModal();

  const handleCloseDialog = () => {
    clearTempRubrics();
    handleClose();
  };

  const body = (
    <RubricsBody
      rubrics={rubrics}
      tempRubrics={tempRubrics}
      selectedRubric={selectedRubric}
      leafRubrics={leafRubrics}
      handleRubricFilterChange={handleRubricFilterChange}
      handleRubricGroupSelect={handleRubricGroupSelect}
      onRender={handleBodyRender}
    />
  );

  const sideHeader = (
    <HeaderWithCount
      text={t('marketplace.selected_rubrics')}
      count={singleRubrics.length.toString()}
    />
  );

  const sideBody = (
    <RubricsSideBody
      singleRubrics={singleRubrics}
    />
  );

  const sideFooter = (
    <RubricsSideFooter
      activeRubrics={activeRubrics}
      singleRubrics={singleRubrics}
      clearSelectedRubric={clearSelectedRubric}
      handleCloseModal={handleCloseDialog}
    />
  );

  return (
    <Modal
      open={open}
      onClose={handleCloseDialog}
      className={styles.modal}
    >
      <Box className={styles.contentWrapper}>
        <Box className={styles.rubricsHeaderWrapper}>
          <Typography variant="modalTitle" alignSelf="center">
            {t('marketplace.catalog')}
          </Typography>
          <CloseIcon className={styles.rubricsHeaderClose} data-testid="closeCartIcon" onClick={handleCloseDialog} />
        </Box>
        <Box className={styles.rubricsMainWrapper}>
          <ModalProjectLayout
            bodyRaw={body}
            sideHeader={sideHeader}
            side={sideBody}
            sideFooter={sideFooter}
          />
        </Box>
      </Box>
    </Modal>
  );
}

export default RubricsModal;
