import {
  withProps,
  select,
  setProp,
  createStore,
} from '@ngneat/elf';
import {
  deleteEntities,
  getActiveEntity,
  getEntity,
  selectActiveEntity,
  selectAllEntities,
  setActiveId,
  setEntities, updateEntities,
  upsertEntities,
  withActiveId,
  withEntities,
} from '@ngneat/elf-entities';
import {
  selectIsRequestPending,
  selectRequestStatus,
  updateRequestStatus,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { map, withLatestFrom } from 'rxjs';
import { ResponseError } from '../../models/common';
import {
  GpOrder, GpOrderItem, OrderItem, Price,
} from '../../api/types';

interface OrderStateProps {
  price: number | null;
  discount: number | null;
  discountPercent: number | null;
  paymentStatus: string;
  promocode: string;
  selectedId: string;
  fullFlag: boolean;
  totalCount: number;
  orderItems: OrderItem[];
  hasMore: boolean;
}

const orderStore = createStore(
  { name: 'order' },
  withEntities<GpOrder>(),
  withActiveId(),
  withRequestsStatus<'orders'>(),
  withProps<OrderStateProps>({
    price: null,
    discount: null,
    discountPercent: null,
    orderItems: [],
    paymentStatus: '',
    promocode: '',
    selectedId: '',
    fullFlag: false,
    totalCount: 0,
    hasMore: false,
  }),
);

export const activeOrder$ = orderStore.pipe(selectActiveEntity());
export const ordersTotalCount$ = orderStore.pipe(select((state) => state.totalCount));
export const ordersHasMore$ = orderStore.pipe(select((state) => state.hasMore));
export const orders$ = orderStore.pipe(selectAllEntities());
export const orderLayerSchedules$ = activeOrder$.pipe(map((order) => order?.orderItems || []));
export const activeOrderLayerSchedules$ = activeOrder$.pipe(
  map((order) => order?.orderItems.filter((schedule) => schedule.selFlag === true) ?? []),
);
export const orderPrice$ = orderStore.pipe(map((state) => state.price));
export const orderDiscountPercent$ = orderStore.pipe(map((state) => state.discountPercent));
export const paymentStatus$ = orderStore.pipe(select((state) => state.paymentStatus));
export const promocode$ = orderStore.pipe(select((state) => state.promocode));
export const allLayerSchedulesActive$ = activeOrder$.pipe(
  map((order) => order?.orderItems.length !== undefined
    && order?.orderItems.length > 0
    && order?.orderItems.every((x) => x.selFlag)),
);

export const ordersRequestStatus$ = orderStore.pipe(selectRequestStatus('orders'));
export const ordersRequestPending$ = orderStore.pipe(selectIsRequestPending('orders'));

export function setOrdersRequestError(error: unknown) {
  orderStore.update(
    updateRequestStatus('orders', 'error', error as ResponseError),
  );
}

export function setOrdersRequestPending() {
  orderStore.update(
    updateRequestStatus('orders', 'pending'),
  );
}

export function setOrdersRequestSuccess() {
  orderStore.update(
    updateRequestStatus('orders', 'success'),
  );
}

export const getActiveOrder = () => orderStore.query(getActiveEntity());

export function getOrder(id: string): GpOrder | undefined {
  return orderStore.query(getEntity(id));
}

export const setOrders = (orders: GpOrder[], totalCount: number, hasMore: boolean) => orderStore.update(
  setEntities(orders),
  setProp('totalCount', totalCount),
  setProp('hasMore', hasMore),
);

export const upsertOrder = (order: GpOrder) => orderStore.update(
  upsertEntities(order),
);

export const setActiveOrder = (id: string) => orderStore.update(setActiveId(id));

export const deleteOrder = (id: string) => orderStore.update(deleteEntities(id));

function updateOrderItemsFn<T extends any[]>(
  fn: (order: GpOrder, ...args: T) => GpOrderItem[],
) {
  return (...args: T) => {
    const order = getActiveOrder();
    if (!order) {
      return;
    }
    const orderItems = fn(order, ...args);
    orderStore.update(updateEntities(order.id, { orderItems }));
  };
}

export const toggleOrderItemSelection = updateOrderItemsFn(
  (order: GpOrder, uName: string) => order.orderItems.map((item) => {
    const productPackaging = item.productPackaging[0];
    const { uName: itemUName } = 'layer' in productPackaging ? productPackaging.layer : productPackaging.dataset;
    return item.productPackaging.length > 0 && itemUName === uName
      ? { ...item, selFlag: !item.selFlag }
      : item;
  }),
);

export const toggleAllOrderItemsSelection = updateOrderItemsFn(
  (order: GpOrder) => {
    const allSelected = order.orderItems.every((item) => item.selFlag);
    return order.orderItems.map((item) => ({
      ...item,
      selFlag: !allSelected,
    }));
  },
);

export const deselectAllOrderItems = updateOrderItemsFn(
  (order: GpOrder) => order.orderItems.map((item) => ({ ...item, selFlag: false })),
);

export const removeSelectedOrderItems = updateOrderItemsFn(
  (order: GpOrder) => order.orderItems.filter((item) => !item.selFlag),
);

export function addOrderItems(orderId: string, ...orderItems: GpOrderItem[]) {
  const order = orderStore.query(getEntity(orderId));
  if (!order) {
    return;
  }

  const existingLayerIds = new Set<string>();
  const newItems: GpOrderItem[] = [];
  order.orderItems.forEach((item) => {
    const productPackaging = item.productPackaging[0];
    const { id } = 'layer' in productPackaging ? productPackaging.layer : productPackaging.dataset;
    existingLayerIds.add(id);
    newItems.push(item);
  });
  orderItems.forEach((item) => {
    const productPackaging = item.productPackaging[0];
    const { id } = 'layer' in productPackaging ? productPackaging.layer : productPackaging.dataset;
    if (!existingLayerIds.has(id)) {
      existingLayerIds.add(id);
      newItems.push(item);
    }
  });

  orderStore.update(updateEntities(orderId, { orderItems: newItems }));
}

export function removeOrderItem(orderId: string, orderItemId: string) {
  const order = orderStore.query(getEntity(orderId));
  if (!order) {
    return;
  }

  const orderItems = order.orderItems.filter((item) => {
    const productPackaging = item.productPackaging[0];
    const { id } = 'layer' in productPackaging ? productPackaging.layer : productPackaging.dataset;
    return id !== orderItemId;
  });
  orderStore.update(updateEntities(orderId, { orderItems }));
}

export const getPromocodeSnapshot = () => orderStore.getValue().promocode;

export const getOrderItemsProps = () => orderStore.getValue().orderItems;

export const setPromocode = (promocode: string) => orderStore.update(setProp('promocode', promocode));

export const setBasketPrice = (basket: Price) => {
  orderStore.update(
    setProp('price', basket.price),
    setProp('discount', basket.discount),
    setProp('discountPercent', basket.discountPercent),
    setProp('orderItems', basket.orderItems),
  );
};

export const setOrdersFullFlagTrue = () => orderStore.update(
  setProp('fullFlag', true),
);

export const getOrdersFullFlag = () => orderStore.getValue().fullFlag;

export const resetBasketPrice = () => orderStore.update(
  setProp('price', null),
  setProp('discount', null),
  setProp('discountPercent', null),
  setProp('fullFlag', false),
  setProp('orderItems', []),
);

export const setPaymentStatus = (status: string) => orderStore.update(
  setProp('paymentStatus', status),
);

export const resetOrderStore = () => { orderStore.reset(); };

export const selectedOrder$ = orderStore.pipe(withLatestFrom(
  orderStore.pipe(select((state) => state.selectedId)),
))
  .pipe(
    map((state) => state[0].entities[state[1]]),
  );

export function setSelectedOrder(orderId: string) {
  orderStore.update(setProp('selectedId', orderId));
}
