import {
  IconButton,
} from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Link } from 'react-router-dom';
import { FC } from 'react';
import styles from './MapBack.module.scss';

interface MapBackProps {
  onClick?: () => void;
}

const MapBack: FC<MapBackProps> = ({ onClick } = { onClick: () => null }) => (
  <Link to="/account" data-testid="backLink" className={styles.link}>
    <IconButton onClick={onClick} className={styles.color}>
      <ArrowBackRoundedIcon />
    </IconButton>
  </Link>
);

export default MapBack;
