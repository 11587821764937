import { Stack, Typography } from '@mui/material';
import styles from './ProjectInfoRow.module.scss';

interface ProjectInfoRowProps {
  title: string;
  text?: string;
  component?: React.ReactNode;
}

const ProjectInfoRow = (props: ProjectInfoRowProps) => {
  const { title, text, component } = props;
  return (
    <Stack direction="row" className={styles.infoRow}>
      <Typography className={styles.col1} variant="body1">{`${title}:`}</Typography>
      { text && <Typography className={styles.col2} variant="body1">{text}</Typography>}
      { component && component }
    </Stack>
  );
};

export default ProjectInfoRow;
