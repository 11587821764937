import { Box } from '@mui/material';
import { generatePath, useNavigate } from 'react-router-dom';
import styles from './MarketplaceCard.module.scss';
import { sendMetricReachGoal } from '../../utils/functions';
import { GpProductTypes, ProductUnion } from '../../api/types';
import Card from '../../blocks/Card/VerticalCard';
import CardHeader from '../../blocks/Card/components/CardHeader/CardHeader';
import CardTitle from '../../blocks/Card/components/CardTitle/CardTitle';
import CardBody from '../../blocks/Card/components/CardBody/CardBody';

interface MarketplaceCardProps {
  id: string;
  productType: GpProductTypes;
  product: ProductUnion;
}

const MarketplaceCardNew: React.FC<MarketplaceCardProps> = ({
  id, productType, product,
}: MarketplaceCardProps) => {
  const navigate = useNavigate();

  const handleCardClick = (id: string) => {
    void navigate(generatePath('/marketplace/:productId', { productId: id }));
    sendMetricReachGoal('marketPlaceCard');
  };

  const header = (
    <CardHeader
      typeId={productType.id}
      typeName={productType.name}
    />
  );

  const title = (
    <CardTitle
      title={product.title}
      product={product}
    />
  );

  const body = (
    <CardBody
      typeId={productType.id}
      product={product}
    />
  );

  return (
    <Box
      data-testid={`marketPlaceCard:${id}`}
      onClick={() => handleCardClick(id)}
      className={styles.productCard}
    >
      <Card header={header} title={title} body={body} />
    </Box>
  );
};

export default MarketplaceCardNew;
