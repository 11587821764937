import { Box, Stack } from '@mui/material';
import { GpLayer, GpStatDataset } from '../../api/types';
import ProductUpdateRow from './ProductUpdateRow';
import useProductUpdates from './useProductUpdates';
import Loader from '../Loader/Loader';

interface ProductUpdatesProps {
  product: GpLayer | GpStatDataset;
}

const ProductUpdates = ({ product }: ProductUpdatesProps) => {
  const { onLoad } = useProductUpdates();

  return (
    <Box data-testid="layerUpdates">
      <Stack direction="column" gap="12px">
        {onLoad
          ? (
            <Box mt="100px" position="relative">
              <Loader id="progress-product-updates" />
            </Box>
          )
          : product.schedules.map((schedule) => (
            <ProductUpdateRow
              schedule={schedule}
              product={product}
              key={'layer' in schedule ? schedule.layer.uName : schedule.dataset.uName}
            />
          ))}
      </Stack>
    </Box>
  );
};

export default ProductUpdates;
