import { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Typography, Stack, Button, Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';

import FormInput from '../../blocks/FormInput/FormInput';
import { sendMetricReachGoal } from '../../utils/functions';
import { authenticateUser, getUserData, logout } from '../../state/context/auth.actions';
import styles from './signin-form.module.scss';

interface LoginData {
  email: string;
  password: string;
}

const SignInForm = ({ onCompleted, toRegister }:
{ onCompleted: () => void; toRegister: () => void }) => {
  const { handleSubmit, control, formState: { errors } } = useForm<LoginData>();
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const { t } = useTranslation('common');

  const onFormSubmit = async ({ email, password }: LoginData) => {
    setFormLoading(true);
    const error = await authenticateUser(email, password, true);
    if (error) {
      setFormError(error);
    } else {
      const res = await getUserData();
      if (res) {
        await logout();
        setFormError(res);
      } else {
        setFormError('');
        onCompleted();
      }
    }
    setFormLoading(false);
  };

  return (
    <>
      <form data-testid="LoginForm" onSubmit={handleSubmit(onFormSubmit)}>
        <Stack spacing={3}>
          <Typography variant="h4" color="#fff" sx={{ textTransform: 'none' }}>{t('side.signInOrSignUp')}</Typography>

          <Controller
            name="email"
            control={control}
            rules={{ required: true, minLength: 3 }}
            render={({ field: { onChange, value } }) => (
              <FormInput
                placeholder="Email"
                onChange={onChange}
                value={value}
                helperText={errors?.email && t(`error.${errors.email.type}`)}
                id="email"
              />
            )}
          />

          <Controller
            name="password"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <FormInput
                placeholder="Пароль"
                onChange={onChange}
                value={value}
                helperText={errors?.password && t(`error.${errors.password.type}`)}
                id="password"
                inputType="password"
              />
            )}
          />

          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ visibility: 'hidden' }}>
            <Typography variant="body1" color="#fff">{t('side.not_registered_yet')}</Typography>
            <Button onClick={() => toRegister()}>
              <Typography variant="body1" color="primary" sx={{ textTransform: 'none' }}>{t('side.join')}</Typography>
            </Button>
          </Stack>
        </Stack>

        {formError && (
          <Typography component="p" variant="body2" color="error" align="center" data-testid={formError}>
            {t(`error.${formError}`)}
          </Typography>
        )}

        <Stack direction="row" spacing={2} mt={8}>
          <LoadingButton
            loading={formLoading}
            className={styles.button}
            type="submit"
            variant="contained"
            color="primary"
            data-testid="signInConfirm"
            onClick={() => sendMetricReachGoal('signInConfirm')}
          >
            {t('side.signIn')}
          </LoadingButton>
        </Stack>
      </form>

      <Box sx={{ textAlign: 'center', mt: '16px' }}>
        <Typography
          onClick={() => {
            toRegister();
            sendMetricReachGoal('signUp');
          }}
          variant="body1"
          display="inline-block"
          color="#fff"
          sx={{ borderBottom: '1px solid #fff', cursor: 'pointer' }}
        >
          {t('side.signup')}
        </Typography>
      </Box>
    </>
  );
};

export default SignInForm;
