import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import common_en from './translations/en/common.json';
import common_ru from './translations/ru/common.json';
import theme from './mui-theme-config';
import FallbackComponent from './fallback';
import packageJson from '../package.json';

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'ru', // language to use
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    ru: {
      common: common_ru,
    },
  },
// eslint-disable-next-line
}).catch((err) => console.error(err));

Sentry.init({
  dsn: 'https://5fa981c0f8394ada83252c13d6581395@bugs.basilevs.tech/5',
  integrations: [new BrowserTracing()],
  release: packageJson.version,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
  enabled: process.env.NODE_ENV !== 'development',
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <StyledEngineProvider injectFirst>
        <Sentry.ErrorBoundary fallback={FallbackComponent}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </Sentry.ErrorBoundary>
      </StyledEngineProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
