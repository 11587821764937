import { Button, styled } from '@mui/material';

export const ButtonOutlined = styled(Button)(({ theme }) => ({
  padding: '1rem 2.5rem',
  borderRadius: '3rem',
  maxWidth: '20rem',
  border: `0.1rem solid ${theme.palette.primary.main}`,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export const ButtonContained = styled(ButtonOutlined)(({ theme }) => ({
  border: 'none',
  background: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));
