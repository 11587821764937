import { useSearchParams } from 'react-router-dom';
import {
  Divider,
  Stack, Typography,
} from '@mui/material';
import { FC } from 'react';
import { ReactComponent as CheckboxIcon } from '../../../assets/images/checked-checkbox.svg';
import { ButtonContained, ButtonOutlined } from '../Button';
import styles from './PaymentCard.module.scss';

interface PaymentCardProps {
  title: string;
  items: string[];
  buttonTitle: string;
  img: string;
  onClick: () => void;
}

const PaymentCard:FC<PaymentCardProps> = ({
  title,
  items,
  buttonTitle,
  img,
  onClick,
}) => {
  const [searchParams] = useSearchParams();
  const result = searchParams.get('result');

  return (
    <Stack className={styles.card}>
      <Stack direction="row" className={styles.cardIco}>
        <img src={img} alt={title} />
      </Stack>
      <Typography variant="subtitle1" color="primary.dark" textAlign="center">
        {title}
      </Typography>
      <Divider className={styles.cardDivider} />
      <Stack gap="2rem" flex={1}>
        {items.map((item) => (
          <Stack
            key={item}
            direction="row"
            gap="1.2rem"
            alignItems="center"
          >
            <CheckboxIcon className={styles.cardCheckbox} />
            <Typography variant="body2" className={styles.cardListItem}>
              { item }
            </Typography>
          </Stack>
        ))}
      </Stack>
      <Divider className={styles.cardDivider} />
      <Stack direction="row" justifyContent="center">
        {result === 'success'
          ? (
            <ButtonContained onClick={onClick}>
              <Typography variant="button" color="secondary.main">
                { buttonTitle }
              </Typography>
            </ButtonContained>
          )
          : (
            <ButtonOutlined onClick={onClick}>
              <Typography variant="button" color="primary.main">
                { buttonTitle }
              </Typography>
            </ButtonOutlined>
          )}
      </Stack>
    </Stack>
  );
};
export default PaymentCard;
