import { ProjectMarkers } from '../../types/scalars';
import { LayerStylerItem } from '../../models/layer';
import { DEFAULT_PALETTE, RESULTING_LAYER_ID } from '../../utils/constants';
import { mapLayerMapToUILayerIn } from './project.adapter';
import { GpLayerMap, GpRecipe, GpuiLayer } from '../../api/types';
import { getAuthProps, getTokenWithoutBearer } from '../context/auth.repository';
// eslint-disable-next-line import/no-cycle
import { getBaseLayers, getOverlayLayersWithFormula } from './project.repository';

export function addUILayerMap(
  recipe: GpRecipe[],
  layerMap: GpLayerMap,
): GpRecipe[] {
  const mutableRecipe = structuredClone(recipe);
  const newUILayerOV = mapLayerMapToUILayerIn(layerMap);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  mutableRecipe[0].layers[0].ov.unshift(newUILayerOV as unknown as GpuiLayer);

  return mutableRecipe;
}

export function recipeForApplyChanges(recipe: GpRecipe[]): GpRecipe[] {
  const mutableRecipe = structuredClone(recipe);

  mutableRecipe[0].layers[0].ov = getOverlayLayersWithFormula();
  mutableRecipe[0].layers[0].bg = getBaseLayers();

  return mutableRecipe;
}

export function getUpdatedMarkersCfg(
  newValue: ProjectMarkers[keyof ProjectMarkers],
  key: keyof ProjectMarkers,
  oldMarkersCfg: ProjectMarkers | {} = {},
) {
  const saved = { ...oldMarkersCfg };
  saved[key] = newValue;
  return saved;
}

export const setUILayer = (data: GpuiLayer):
LayerStylerItem => {
  const { activeId } = getAuthProps();
  const layer = data.layer?.[0];
  if (layer && layer?.__typename === 'GPLayerMap') {
    const layerOptions = { ...layer.layerOptions, token: getTokenWithoutBearer, id: activeId };
    return ({
      id: layer.id,
      on: !!data.on || false,
      n: layer.title || '',
      u: [layer.uri, layerOptions],
      o: data.o ?? 1,
      g: data.g ?? 0.5,
      h: data.h ?? 0,
      p: DEFAULT_PALETTE,
    });
  }
  if (data.id === RESULTING_LAYER_ID) {
    return ({
      id: RESULTING_LAYER_ID,
      on: data.on && data.layerEm[0].L.length > 0,
      n: data.id,
      u: [],
      o: data.o ?? 1,
      g: data.g ?? 0.5,
      h: data.h ?? 0,
      type: 'layer',
      p: DEFAULT_PALETTE,
    });
  }
  throw Error('Invalid UI layer data');
};
