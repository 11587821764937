import {
  Box,
  Collapse, IconButton, Radio, Stack, Tooltip, Typography,
} from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useObservable } from '@ngneat/react-rxjs';
import { MouseEventHandler, useState } from 'react';
import styles from './LayerCard.module.scss';
import { ReactComponent as ClosedEye } from '../../../../assets/images/closed_eye.svg';
import { ReactComponent as Eye } from '../../../../assets/images/eye.svg';
import { ReactComponent as LayerIcon } from '../../../../assets/images/addLayer.svg';
import { ReactComponent as NoLayer } from '../../../../assets/images/noLayer.svg';
import { ReactComponent as TriangleIcon } from '../../../../assets/images/triangle.svg';
import LayerSliders from '../../../../components/LayerSliders';
import { LayerType } from '../../../../models/layer';
import { GpuiLayer } from '../../../../api/types';
import { toggleLayer, toggleLayerInResulting } from '../../../../state/project/project.actions';
import { activeFormulaLayer$ } from '../../../../state/project/project.repository';
import SortingButton from './SortingButton';
import FormulaSettings from './FormulaSettings/FormulaSettings';

interface LayerCardProps {
  layer: GpuiLayer;
  index: number;
  layerType: LayerType;
}

interface LayerCardButtonProps {
  hint: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  icon: JSX.Element;
  value?: string;
  testid?: string;
}

function LayerCardButton({
  hint, onClick, icon, value, testid,
}: LayerCardButtonProps) {
  return (
    <Tooltip title={hint} placement="top-start">
      <IconButton data-testid={testid} onClick={onClick} value={value}>
        {icon}
      </IconButton>
    </Tooltip>
  );
}

const LayerCard: React.FC<LayerCardProps> = ({
  layer,
  index,
  layerType,
}: LayerCardProps) => {
  const { t } = useTranslation('common');
  const [activeFormulaLayer] = useObservable(activeFormulaLayer$);
  const [openActiveFormula, setOpenActiveFormula] = useState<boolean>(false);

  const leftButton = () => (
    layer.tmpLayerTypename === 'GPLayerMap'
      && (
        <LayerCardButton
          hint={t('layer.add_to_resulting')}
          onClick={() => toggleLayerInResulting(layer.id)}
          icon={
        activeFormulaLayer?.layerEm[0].L.some((f) => f.id === layer.id)
          ? <LayerIcon />
          : <NoLayer />
      }
        />
      )
  );

  const rightButton = () => (
    layerType === LayerType.OV
      ? (
        <LayerCardButton
          testid={`eyeIcon${layer.on ? 'Open' : 'Close'}`}
          hint={t('layer.hide_layer')}
          onClick={(e) => toggleLayer({ id: e.currentTarget.value, layerType })}
          icon={layer.on ? <Eye /> : <ClosedEye style={{ width: '20px', height: '20px' }} />}
          value={layer.id}
        />
      )
      : (
        <Radio
          value={layer.id}
          className={styles.radio}
          onClick={(e) => toggleLayer({ id: (e.target as HTMLInputElement).value, layerType })}
          size="small"
          checked={layer.on}
        />
      )
  );

  return (
    <Draggable
      key={layer.id}
      draggableId={layer.id}
      index={index}
    >
      {(provided) => (
        <div
          key={layer.id}
          data-id={layer.id}
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={styles.card}
        >
          <Stack width="100%">
            <Stack direction="row" width="100%">
              <div
                className={styles.draggable_button}
                {...provided.dragHandleProps}
              >
                <SortingButton />
              </div>
              <Stack direction="row" padding="8px 20px 8px 8px" width="100%">
                <Stack width="32px">
                  <Typography>{index + 1}</Typography>
                </Stack>
                <Stack width="100%">
                  <Stack className={styles.layer}>
                    {layer.tmpLayerTypename === 'GPLayerFormula'
                      ? (
                        <Stack
                          className={styles.formulaTitle}
                          direction="row"
                          onClick={() => setOpenActiveFormula(!openActiveFormula)}
                        >
                          <Box position="relative">
                            <TriangleIcon
                              className={openActiveFormula
                                ? styles.triangle
                                : styles.triangleRotate}
                            />
                          </Box>
                          <Typography
                            fontWeight="500"
                            fontSize="14px"
                            color="#fff"
                            marginLeft="15px"
                          >
                            {layer.n ?? 'NULL'}
                          </Typography>
                        </Stack>
                      )
                      : <Typography fontWeight="500" fontSize="14px" color="#fff">{layer.n ?? 'NULL'}</Typography>}
                    <Stack className={styles.button}>
                      {leftButton()}
                      {rightButton()}
                    </Stack>
                  </Stack>
                  {layer.tmpLayerTypename === 'GPLayerMap'
                    && (
                      <Collapse in={layer.on}>
                        <LayerSliders
                          layerId={layer.id}
                          opacity={layer.o}
                          grey={layer.g}
                          hue={layer.h}
                        />
                      </Collapse>
                    )}
                </Stack>
              </Stack>
            </Stack>
            {layer.tmpLayerTypename === 'GPLayerFormula'
              && (
              <FormulaSettings
                open={openActiveFormula}
                index={index + 1}
                layer={layer}
              />
              )}
          </Stack>
        </div>
      )}
    </Draggable>
  );
};

export default LayerCard;
