import { FC } from 'react';
import { FlatLayersListItem, FlatLayersListItemSecond, TabViews } from './AclViewsHelpers';
import FirstViewAcl from './FirstViewAcl/FirstViewAcl';
import SecondViewAcl from './SecondViewAcl/SecondViewAcl';
import ThirdViewAcl from './ThirdViewAcl/ThirdViewAcl';

interface AclViewsProps {
  view: string,
  flatAclFirstView: FlatLayersListItem[],
  flatAclOtherView: FlatLayersListItemSecond[],
  addButton?: boolean,
}

const AclViews: FC<AclViewsProps> = ({
  view,
  flatAclFirstView,
  flatAclOtherView,
  addButton,
}) => (
  <>

    {view === TabViews.First && (
      <FirstViewAcl
        layers={flatAclFirstView}
        addButton={addButton}
      />
    )}
    {view === TabViews.Second && (
      <SecondViewAcl
        layers={flatAclOtherView}
        addButton={addButton}
      />
    )}
    {view === TabViews.Third && (
      <ThirdViewAcl
        layers={flatAclOtherView}
        addButton={addButton}
      />
    )}
  </>
);

export default AclViews;
