import { GpRubric } from '../../../../api/types';
import useRubricFilter from '../../hooks/useRubricFilter';
import NodParentRubric from './NodParentRubric';
import LeafParentRubric from './LeafParentRubric';

interface FilterProps {
  filter: GpRubric,
  active: boolean,
  onChange: (active: boolean, filter: GpRubric) => void,
  onChangeChild: (active: boolean, filter: GpRubric) => void,
  rubrics: GpRubric[],
  leafRubrics: GpRubric[],
  activeRubrics: GpRubric[],
}

function RubricFilter({
  filter, active, onChange, onChangeChild, rubrics, leafRubrics, activeRubrics,
}: FilterProps) {
  if (leafRubrics.includes(filter)) {
    return null;
  }

  const {
    nestedFilters,
    activeNestedFiltersIds,
    checked,
    indeterminate,
    open,
    onParentChange,
    handleLeafParentChange,
    handleListItemClick,
  } = useRubricFilter({
    filter,
    active,
    rubrics,
    activeRubrics,
    onChange,
    onChangeChild,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if ((event.target as Element).tagName !== 'INPUT') {
      onChange(checked, filter);
    }
  };

  const renderNestedFilters = (nestedFilters: GpRubric[]) => (
    <>
      { nestedFilters.map((filter) => (
        <RubricFilter
          filter={filter}
          key={filter.id}
          active={activeNestedFiltersIds.includes(filter.id)}
          rubrics={rubrics}
          leafRubrics={leafRubrics}
          activeRubrics={activeRubrics}
          onChange={onChange}
          onChangeChild={onChangeChild}
        />
      ))}
    </>
  );

  return nestedFilters && nestedFilters.filter((f) => !leafRubrics.includes(f)).length > 0
    ? (
      <NodParentRubric
        filter={filter}
        checked={checked}
        indeterminate={indeterminate}
        open={open}
        activeNestedFiltersIds={activeNestedFiltersIds}
        onChange={onParentChange}
        onClick={handleListItemClick}
      >
        {renderNestedFilters(nestedFilters)}
      </NodParentRubric>
    )
    : (
      <LeafParentRubric
        filter={filter}
        checked={checked}
        indeterminate={indeterminate}
        onClick={handleClick}
        onChange={handleLeafParentChange}
      />
    );
}

export default RubricFilter;
