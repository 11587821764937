import { useEffect } from 'react';
import { useObservable } from '@ngneat/react-rxjs';
import { fetchAclAsync } from '../../state/project/project.actions';
import { acl$, aclRequestStatus$ } from '../../state/acl/acl.repository';

function useProductUpdates() {
  const [acl] = useObservable(acl$);
  const [aclRequestStatus] = useObservable(aclRequestStatus$);

  useEffect(() => {
    if (!acl.length) {
      void fetchAclAsync();
    }
  }, []);

  return { onLoad: aclRequestStatus.value !== 'success' };
}

export default useProductUpdates;
