import {
  Grid,
} from '@mui/material';
import { GpLayer, GpStatDataset } from '../../../../api/types';
import UiCard from '../UiCard/UiCard';

interface SimilarProductsProps {
  products: GpLayer[] | GpStatDataset[];
}

const SimilarProducts = ({ products }: SimilarProductsProps) => (
  <Grid container columns={3} spacing="14px">
    {
      products.map((product) => (
        <UiCard
          type="layer"
          title={product.title}
          annotation={product.annotation}
          key={product.id}
          price={Number(product.price)}
          territory={product.territory.name}
          id={product.id}
        />
      ))
    }
  </Grid>
);

export default SimilarProducts;
