import { ApolloQueryResult, FetchResult } from '@apollo/client';

export function tryGetResponseData<T>(res: ApolloQueryResult<T> | FetchResult<T>): T {
  if (res.errors?.length) {
    throw new Error(res.errors[0].message);
  }
  if (!res.data) {
    throw new Error('data is not present');
  }

  return res.data;
}
