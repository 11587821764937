import { Box, Typography, Button } from '@mui/material';
import { useObservable } from '@ngneat/react-rxjs';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';

import { activeRubrics$, removeActiveRubric } from '../../state/filters/filters.repository';
import styles from './MarketplaceRubricChips.module.scss';

function MarketplaceRubricChips() {
  const { t } = useTranslation('common');
  const [activeRubrics] = useObservable(activeRubrics$);

  const removeRubrics = () => {
    activeRubrics.forEach((r) => { removeActiveRubric(r.id); });
  };

  return (
    <Box className={styles.wrapper}>
      {
        activeRubrics.map((r) => (
          <Box className={styles.chipsWrapper}>
            <Typography className={styles.chipsText} key={r.id}>
              {r.title}
            </Typography>
            <CloseIcon onClick={() => { removeActiveRubric(r.id); }} className={styles.iconClose} />
          </Box>
        ))
      }
      {
      activeRubrics.length
        ? (
          <Button className={styles.clearButton} onClick={() => { removeRubrics(); }}>
            <Typography className={styles.clearText} variant="body2" fontWeight={700}>
              {t('marketplace.clear_rubrics')}
            </Typography>
          </Button>
        )
        : null
      }
    </Box>
  );
}

export default MarketplaceRubricChips;
