import {
  Typography, Stack, Grid, Button, useTheme,
} from '@mui/material';
import { useObservable } from '@ngneat/react-rxjs';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';
import { ReactComponent as SuccessIcon } from '../../../../assets/images/success.svg';
import { ReactComponent as RecipeIcon } from '../../../../assets/images/recipe.svg';
import { ReactComponent as CartIcon } from '../../../../assets/images/cart.svg';
import { orderLayerSchedules$ } from '../../../../state/order';
import { GpRecipe } from '../../../../api/types';
import { availableObjectsUNames$ } from '../../../../state/acl/acl.repository';
import { getLayerListFromRecipe } from '../../../../state/geoData/products.actions';
import styles from './UiCard.module.scss';
import { createSideMarkup } from '../../../../utils/functions';

interface LayerInRecipeProps {
  isBuy: boolean;
  price?: string;
  title: string;
}

const LayerInRecipe = ({ isBuy, title, price }: LayerInRecipeProps) => {
  const { t } = useTranslation('common');

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography variant="title2" color="primary.dark">
        {title}
      </Typography>
      <Stack direction="row" alignItems="center" gap="6px">
        {isBuy
          && (
            <>
              <Typography variant="body1" color="#979DA8">
                {t('layer.alredyBuy')}
              </Typography>
              <SuccessIcon width="12px" height="12px" />
            </>
          )}
        {!isBuy && price
            && (
              <Typography
                variant="subtitle2"
                color="primary.main"
              >
                {`${price} ₽`}
              </Typography>
            )}
      </Stack>
    </Stack>
  );
};

const UiCardLarge = ({ recipe }: { recipe: GpRecipe }) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const theme = useTheme();
  const [availableObjectsUNames] = useObservable(availableObjectsUNames$);
  const [orderLayerSchedules] = useObservable(orderLayerSchedules$);
  const uNames = orderLayerSchedules.map((s) => (
    ('layer' in s.productPackaging[0])
      ? s.productPackaging[0].layer.uName
      : s.productPackaging[0].dataset.uName
  ));
  const clickBuyButton = async () => {
    void navigate(generatePath('/recipes/:recipeId', { recipeId: recipe.id }));
  };

  const layersInRecipe = getLayerListFromRecipe(recipe);

  return (
    <Grid item xs={3} width="100%">
      <Stack className={styles.stack}>
        <Stack direction="row">
          <Stack
            className={styles.card}
          >
            <Stack justifyContent="flex-end" width="100%">
              <Stack direction="row" gap="5px">
                <Typography
                  variant="body2"
                  color={`${theme.palette.primary.dark}40`}
                >
                  {t('recipe.self')}
                </Typography>
                <RecipeIcon />
              </Stack>
            </Stack>
            <Typography variant="subtitle1" color="primary.dark">
              {recipe.title}
            </Typography>
            <Typography
              variant="body2"
              color={`${theme.palette.primary.dark}50`}
              flexGrow={1}
              dangerouslySetInnerHTML={createSideMarkup(recipe.annotation)}
            />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack
                direction="row"
                alignItems="flex-end"
                gap="10px"
              >
                <Typography variant="subtitle1" color="primary.main">
                  цена
                </Typography>
                <Typography
                  variant="body2"
                  color="primary.main"
                  sx={{
                    textDecoration: 'line-through',
                  }}
                >
                  цена
                </Typography>
              </Stack>
              <Button
                onClick={clickBuyButton}
                className={styles.button}
              >
                <CartIcon stroke="#FA5026" />
              </Button>
            </Stack>
          </Stack>
          <Stack
            className={styles.included}

          >
            <Typography variant="body2" color={`${theme.palette.primary.dark}40`}>
              {t('recipe.included_layers')}
            </Typography>
            {layersInRecipe.length > 0
            && layersInRecipe.map((l) => (
              <LayerInRecipe
                key={l.id}
                isBuy={availableObjectsUNames.has(l.uName)
                  || uNames.includes(l.uName)}
                title={l.n as string}
                price="цена"
              />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Grid>
  );
};

export default UiCardLarge;
