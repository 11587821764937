import { useObservable } from '@ngneat/react-rxjs';

import { DateTime } from 'luxon';
import {
  activeProject$,
} from '../../../state/project/project.repository';

export interface ParsedProjectInfo {
  createDate: DateTime | null,
  updateDate: DateTime | null,
  createDateString: string,
  updateDateString: string,
  authorName: string,
  projectType: string,
}

export interface ProjectWithInfo {
  cmscreatedate: number,
  cmsupdatedate: number,
  cmscreatedby: string,
}

function isProjectWithInfo(obj: any): obj is ProjectWithInfo {
  return 'cmscreatedate' in obj && typeof obj.cmscreatedate === 'number'
    && 'cmsupdatedate' in obj && typeof obj.cmsupdatedate === 'number'
    && 'cmscreatedby' in obj && typeof obj.cmscreatedby === 'string';
}

const toDate = (i: number) => DateTime.fromMillis(i);
const toPretty = (d: DateTime) => d.setLocale('ru-Ru').toFormat('dd MMMM yyyy HH:mm');

function useProjectInfo(): ParsedProjectInfo {
  const [activeProject] = useObservable(activeProject$);

  let info: ParsedProjectInfo = {
    createDate: null,
    updateDate: null,
    createDateString: '',
    updateDateString: '',
    authorName: '',
    projectType: '',
  };

  const project = activeProject?.project?.[0];

  if (isProjectWithInfo(project)) {
    info = {
      createDate: toDate(project.cmscreatedate),
      updateDate: toDate(project.cmsupdatedate),
      createDateString: toPretty(toDate(project.cmscreatedate)),
      updateDateString: toPretty(toDate(project.cmsupdatedate)),
      authorName: project.cmscreatedby,
      projectType: activeProject?.projectType.model || '',
    };
  }

  return info;
}

export default useProjectInfo;
