import {
  getAllEntities, hasEntity, selectActiveEntity, setActiveId,
  setEntities, withActiveId, withEntities,
} from '@ngneat/elf-entities';
import { createState, Store } from '@ngneat/elf';
import {
  createRequestsStatusOperator,
  initializeAsPending,
  selectIsRequestPending,
  selectRequestStatus,
  updateRequestStatus,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { map } from 'rxjs/operators';
import { GpCmsPage } from '../../api/types';
import { setTitleAndMeta } from '../../utils/functions';

const pageState = createState(
  withEntities<GpCmsPage>(),
  withActiveId(),
  withRequestsStatus(
    initializeAsPending('pages'),
  ),
);

const pageStore = new Store({ name: 'pages', ...pageState });
export const trackPagesRequestsStatus = createRequestsStatusOperator(pageStore);

export const activePage$ = pageStore.pipe(selectActiveEntity());
export const activePageTitle$ = activePage$.pipe(map((e) => e?.title || ''));
export const activePageText$ = activePage$.pipe(map((e) => e?.text || ''));
export const activePageRoute$ = activePage$.pipe(map((e) => e?.route || ''));
export const activePageIsLandingToc$ = activePage$.pipe(map((e) => e?.isLandingToc));
export const activePageBlocks$ = activePage$.pipe(
  map((e) => e?.blocksEm?.filter((block) => block.block !== null) ?? []),
);
export const activePageExtension$ = activePage$.pipe(map((e) => e?.extension));
export const pagesRequestPending$ = pageStore.pipe(selectIsRequestPending('pages'));
export const pagesRequestStatus$ = pageStore.pipe(selectRequestStatus('pages'));

export function setPages(pages: GpCmsPage[]) {
  pageStore.update(
    setEntities(pages),
    updateRequestStatus('pages', 'success'),
  );
}

export function setActivePage(page: GpCmsPage | null) {
  if (page && pageStore.query(hasEntity(page.id))) {
    pageStore.update(setActiveId(page.id));
  } else {
    pageStore.update(setActiveId(null));
  }
  setTitleAndMeta(page);
}

export function getPage(pathname: string) {
  const pathArr = pathname.split('/').splice(1);
  const pages = pageStore.query(getAllEntities());
  let resultPage: GpCmsPage | null = null;
  let occurrenceCount = 0;

  pages.forEach((page) => {
    const pageArr = page.route.split('/').splice(1);
    pathArr.forEach((p, i) => {
      if (pageArr.length > 0 && p === pageArr[i] && (i + 1) >= occurrenceCount) {
        occurrenceCount = i + 1;
        if (occurrenceCount === pathArr.length && pathArr.length >= pageArr.length) {
          resultPage = page;
        }
      }
    });
  });

  return resultPage;
}

export function resetPageStore(): void {
  pageStore.reset();
}
