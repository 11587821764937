import { IconButton, SvgIcon } from '@mui/material';
import { IconButtonProps } from '@mui/material/IconButton/IconButton';

import { ReactComponent as ProceedButtonIcon } from '../../assets/images/proceed-button.svg';

export default function ProceedButton(props: IconButtonProps) {
  const { color } = props;
  return (
    <IconButton {...props}>
      <SvgIcon
        component={ProceedButtonIcon}
        inheritViewBox
        sx={{ fontSize: '30px', color: color ? undefined : '#061136' }}
      />
    </IconButton>
  );
}
