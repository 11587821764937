import {
  useState, useRef, RefObject, useEffect,
} from 'react';
import {
  Typography, Button, Box, Stack, Collapse,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useObservable } from '@ngneat/react-rxjs';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { productsRequestStatus$, activeProduct$, resetActiveProduct } from '../../state/geoData/products.repository';
import { aclRequestStatus$ } from '../../state/acl/acl.repository';
import { ReactComponent as ArrowLeftIcon } from '../../assets/images/arrowLeft.svg';
import { sendMetricReachGoal, createSideMarkup } from '../../utils/functions';
import FeatureCard from '../../feature/Generals/components/FeatureCard';
import RecommendedRecipes from '../../feature/Generals/components/RecommendedRecipes';
import SimilarProducts from '../../feature/Generals/components/SimilarProducts';
import LayerInfo from './ProductInfo';
import { ResponseError } from '../../models/common';
import { ProductType, ProductTypeId } from '../../models/product';
import { ProductUnion } from '../../api/types';
import { setActiveProduct } from '../../state/geoData/products.actions';
import PageBreadcrumbs from '../../blocks/PageBreadcrumbs/PageBreadcrumbs';
import styles from './Product.module.scss';
import ProductUpdates from '../../components/ProductUpdates/ProductUpdates';
import Recipe from '../Recipe/Recipe';
import Loader from '../../components/Loader/Loader';
import { getImagesUrlPrefix } from '../../state/context/auth.repository';

function Product() {
  const refBuyLayers = useRef<HTMLElement>();
  const refFeatureCardContainer = useRef<HTMLDivElement>(null);
  const [isOpenAccordion, setOpenAccordion] = useState<boolean>(true);
  const [productsRequestStatus] = useObservable(productsRequestStatus$);
  const [aclRequestStatus] = useObservable(aclRequestStatus$);
  const { t } = useTranslation('common');
  const { productId } = useParams<string>();
  const [activeProduct] = useObservable(activeProduct$);
  const product = activeProduct?.product[0];

  useEffect(() => {
    void setActiveProduct(productId!);
    return () => {
      resetActiveProduct();
    };
  }, []);

  if (activeProduct?.productType?.id === ProductTypeId.Recipe
    || activeProduct?.productType.id === ProductTypeId.Report) {
    return <Recipe />;
  }

  const handleBuyLayer = () => {
    if (refBuyLayers.current) {
      const container = refBuyLayers.current.closest('#container');
      const elementTop = refBuyLayers.current.getBoundingClientRect().top;
      container?.scrollBy({ top: elementTop, behavior: 'smooth' });
    }
    sendMetricReachGoal('layerBuyButton');
  };

  if (productsRequestStatus.value === 'pending') {
    return (<Loader id="progress-product" />);
  }

  if (productsRequestStatus.value === 'error') {
    return (
      <Typography variant="h5" sx={{ textAlign: 'center' }}>
        Error:
        {' '}
        {(productsRequestStatus
          .error as ResponseError).localizedMessage}
      </Typography>
    );
  }

  const calculatedCollapsedHeight = (ref: RefObject<HTMLDivElement>): number => {
    const collapseWrapperInner = ref
      .current
      ?.querySelector('.MuiCollapse-wrapperInner');
    return collapseWrapperInner?.children[0].getBoundingClientRect().height as number + 20;
  };

  const path = (product: ProductUnion) => {
    const productBreadcrumbs = [
      { title: product?.title || '', to: `/marketplace/${productId!}` },
    ];
    return (
      <PageBreadcrumbs
        links={productBreadcrumbs}
      />
    );
  };

  const buyButton = (product: ProductUnion) => (
    'price' in product && 'schedules' in product
      ? (
        <Box sx={{ display: 'flex', flexBasis: '240px' }}>
          <Button
            color="primary"
            className={styles.button}
            data-testid="layerBuyButton"
            disabled={ !product.schedules?.length }
            onClick={handleBuyLayer}
            variant="contained"
          >
            {`${t('layer.buy')} от ${product.price || ''} ₽`}
          </Button>
        </Box>
      )
      : null
  );

  const rangeScedule = (product: ProductUnion) => {
    if (product.__typename === ProductType.Layer) {
      const sortUpdates = product
        .schedules?.filter((s) => s && s.releaseDate)
        .sort((a, b) => a.releaseDate - b.releaseDate);

      const rangeSchedule = sortUpdates.length > 1
        ? `${sortUpdates[sortUpdates.length - 1]?.title} - ${sortUpdates[0]?.title}`
        : sortUpdates[0]?.title;

      return (
        <Typography
          variant="subtitle1"
          color="primary.dark"
          marginTop="10px"
        >
          {rangeSchedule}
        </Typography>
      );
    }
    return null;
  };

  const layerUpdates = (product: ProductUnion) => (
    'schedules' in product
      ? (
        <Stack className={styles.updateWrapper} direction="row" marginTop="40px" gap="25px" ref={refBuyLayers}>
          <Box width="100%">
            <Typography variant="h4" maxWidth="670px" fontWeight="400">
              { `${'layer' in product.schedules[0]
                ? t('layer.self')
                : t('dataset.self')} “${product.title}” ${t('layer.represent')}` }
            </Typography>
            <Stack direction="column" gap="12px" marginTop="20px">
              <ProductUpdates product={ product } />
            </Stack>
          </Box>
        </Stack>
      ) : null
  );

  const productFeatures = (product: ProductUnion) => (
    'productFeatures' in product && product.productFeatures?.length > 0
      ? (
        <Stack direction="column" flexGrow={1}>
          <>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                setOpenAccordion((prev) => !prev);
              }}
            >
              <Typography variant="h4" color="primary.light" flexGrow={1}>
                {t(
                  activeProduct?.productType.id === ProductTypeId.StatDataset
                    ? 'layer.analize'
                    : 'layer.features',
                )}
              </Typography>
              <Stack direction="row" alignItems="center">
                { product.productFeatures?.length > 3
                  ? (
                    <>
                      <Typography variant="body1" fontWeight={500} color="primary.dark">
                        { isOpenAccordion ? t('layer.hideAccordion') : t('layer.showAccordion')}
                      </Typography>
                      <ExpandMore sx={{
                        transform: isOpenAccordion ? 'rotate(180deg)' : 'none',
                        transition: 'transform .2s linear',
                      }}
                      />
                    </>
                  )
                  : null}
              </Stack>
            </Stack>
            <Collapse
              in={isOpenAccordion}
              className={styles.collapseMui}
              collapsedSize={`${calculatedCollapsedHeight(refFeatureCardContainer)}px`}
              ref={refFeatureCardContainer}
            >
              { product.productFeatures.map((item) => (
                <FeatureCard
                  title={item.title}
                  description={item.description}
                  key={item.id}
                />
              ))}
            </Collapse>
          </>
        </Stack>
      ) : null
  );

  const reccomendRecipes = (product: ProductUnion) => (
    product.__typename === ProductType.Layer
    && aclRequestStatus.value === 'success'
      ? (
        product.recommendedRecipes?.length > 0 && (
          <Stack direction="row" gap="25px">
            <Box maxWidth="165px" width="100%" />
            <Box flexGrow={1}>
              <Box marginTop="60px">
                <Typography variant="h4" color="primary.light">
                  {t('layer.recommended_recipes')}
                </Typography>
                <Box marginTop="20px">
                  <RecommendedRecipes recipes={product.recommendedRecipes} />
                </Box>
              </Box>
            </Box>
          </Stack>
        )
      ) : null
  );

  const similarLayers = (product: ProductUnion) => (
    product.__typename === ProductType.Layer
      ? (
        product.similarLayers?.length > 0 && (
          <Stack direction="row" gap="25px">
            <Box maxWidth="165px" width="100%" />
            <Box flexGrow={1}>
              <Box marginTop="60px">
                <Typography variant="h4" color="primary.light">
                  {t('layer.your_similar_layers')}
                </Typography>
                <Box marginTop="20px">
                  <SimilarProducts products={product.similarLayers} />
                </Box>
              </Box>
            </Box>
          </Stack>
        )
      ) : null
  );

  const productFeaturesWithInfo = (
    product: ProductUnion,
  ) => (
    <Stack
      direction="row"
      className={styles.product}
    >
      <Box sx={{
        maxWidth: '165px',
        width: '100%',
        paddingTop: '30px',
      }}
      />
      { productFeatures(product) }
    </Stack>
  );

  const titleWithBuyButton = (
    product: ProductUnion,
  ) => (
    <Stack className={styles.title}>
      <Box maxWidth="500px">
        <Typography variant="h2" color="primary.light">
          { product.title }
        </Typography>
      </Box>
      { 'price' in product
        ? buyButton(product)
        : null }
    </Stack>
  );

  const arrowBack = (product: ProductUnion) => (
    <Stack
      direction="column"
      gap="60px"
      sx={{
        maxWidth: '165px',
        width: '100%',
      }}
    >
      <Box sx={{
        display: 'flex',
        alignSelf: 'flex-start',
      }}
      >
        <Link to={{ pathname: '/marketplace' }} style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          <ArrowLeftIcon />
          <Typography variant="subtitle2" color="primary.dark">
            {t('layer.back')}
          </Typography>
        </Link>
      </Box>
      <LayerInfo product={product} />
    </Stack>
  );

  const photos = (product: ProductUnion) => (
    'photos' in product && product.photos?.length
      ? (
        <Box className={styles.image}>
          <img src={`${getImagesUrlPrefix()}${product.photos[0]}`} alt="map" className={styles.photo} />
        </Box>
      ) : null
  );

  if (activeProduct && product) {
    return (
      <Box paddingBottom="80px" className={styles.container}>
        { path(product) }
        <Stack direction="row" marginTop="20px" gap="25px">
          { arrowBack(product) }
          <Box width="100%">
            { titleWithBuyButton(product) }
            { rangeScedule(product) }
            <div
              className={`${styles.desc} cms-styles cms-main`}
              dangerouslySetInnerHTML={createSideMarkup(product.description)}
            />
          </Box>
        </Stack>
        { productFeaturesWithInfo(product) }
        { photos(product) }
        { layerUpdates(product) }
        { reccomendRecipes(product) }
        { similarLayers(product) }

      </Box>
    );
  }
  return (<div />);
}

export default Product;
