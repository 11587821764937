import { ProductUnion } from '../../../../../api/types';
import { ProductTypeId } from '../../../../../models/product';
import { ProjectTypeIds } from '../../../../../models/project';

export const getValuesName = (id: string) => {
  switch (id) {
    case ProductTypeId.Report:
      return 'marketplace.instruments';
    case ProductTypeId.Recipe:
      return 'marketplace.will_help';
    case ProjectTypeIds.GPBIProject:
      return 'account.dataset_used';
    case ProjectTypeIds.GPProject:
      return 'account.layers_used';
    default:
      return 'marketplace.actual_periods';
  }
};

export const getSchedules = (product?: ProductUnion) => {
  if (product && 'schedules' in product) {
    return product.schedules;
  }
  return null;
};

export const getRecipeValues = (product?: ProductUnion) => {
  if (product && 'recipeValues' in product) {
    return product.recipeValues;
  }
  return null;
};

export const getServiceValues = (product?: ProductUnion) => {
  if (product && 'serviceValues' in product) {
    return product.serviceValues;
  }
  return null;
};
