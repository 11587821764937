import { generatePath, useNavigate } from 'react-router-dom';
import { GpbiRecipe, GpRecipe } from '../api/types';
import { ProjectType } from '../models/project';
import { setCreatedProjectId } from '../state/project/project.repository';
import { createGeoProject, createBIProject, setActiveProject } from '../state/project/project.actions';

const useCreateNewProject = () => {
  const navigate = useNavigate();

  const createNewProject = async (projectType: ProjectType, recipe?: GpRecipe | GpbiRecipe) => {
    const projectId = projectType === ProjectType.GPProject
      ? await createGeoProject(recipe as GpRecipe)
      : await createBIProject(recipe);
    const type = projectType === ProjectType.GPProject ? 'map' : 'bi';

    await setActiveProject(projectId);
    setCreatedProjectId(projectId);

    navigate(generatePath(`/${type}/:projectId`, { projectId }));
  };
  return createNewProject;
};

export default useCreateNewProject;
