/* eslint-disable max-len */
export enum ProductTypeId {
  Layer = '1f5c8582-52ae-4028-83c2-7646fc743c40',
  GeoModel = '1968de6c-e1e5-4bed-9c47-f8fc3fc02960',
  CommercialService = '16e0d873-90de-4fda-a62f-e97a20695708',
  Recipe = '0f69f4d1-0495-47a3-a4c5-fd0c70bb2c10',
  StatDataset = 'ddc9fbfd-b55b-4c5c-89f7-f565ac7db568',
  Report = '909b158e-70a7-46ca-8e5b-573ee0862159',
}

export enum ProductType {
  Layer = 'GPLayer',
  GeoModel = 'GPGeoModel',
  CommercialService = 'GPCommercialService',
  StatDataset = 'GPStatDataset',
  Recipe = 'GPRecipe',
  BIRecipe = 'GPBIRecipe',
}

export type ProductSearchableProp = 'territory' | 'title' | 'rubrics' | 'updatePeriod' | 'search';

type PropTypeMap = {
  [key in ProductSearchableProp]: Array<ProductType>;
};

// once ...WhereIn for corresponding types change - add here
export const productTypesHaveProperty: PropTypeMap = {
  title: [ProductType.StatDataset, ProductType.CommercialService, ProductType.GeoModel, ProductType.Layer, ProductType.Recipe, ProductType.BIRecipe],
  rubrics: [ProductType.StatDataset, ProductType.CommercialService, ProductType.GeoModel, ProductType.Layer, ProductType.Recipe, ProductType.BIRecipe],
  updatePeriod: [ProductType.Layer],
  territory: [ProductType.CommercialService, ProductType.GeoModel, ProductType.Layer, ProductType.StatDataset],
  search: [ProductType.StatDataset, ProductType.CommercialService, ProductType.GeoModel, ProductType.Layer, ProductType.Recipe, ProductType.BIRecipe],
};
