import {
  Box,
  Collapse,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { useObservable } from '@ngneat/react-rxjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { updateUILayerFormula } from '../../../../../state/project/project.actions';
import {
  activeFormula$,
  activeFormulaLayer$,
} from '../../../../../state/project/project.repository';
import { FormulaMethodsWasm, FormulaMethodsWebGl } from '../../../../../utils/constants';
import { isWebGLSupported } from '../../../../../utils/functions';
import Switch from './Switch';
import styles from './CalculationSettings.module.scss';

const CalculationSettings = () => {
  const { t: translate } = useTranslation('common');
  const [activeFormulaLayer] = useObservable(activeFormulaLayer$);
  const [activeFormula] = useObservable(activeFormula$);
  const [open, setOpen] = useState<boolean>(false);
  const [formulaMethods, setFormulaMethods] = useState(FormulaMethodsWebGl);

  useEffect(() => {
    const formulaMethods = isWebGLSupported() ? FormulaMethodsWebGl : FormulaMethodsWasm;
    setFormulaMethods(formulaMethods);
    const formulaMethodValues = formulaMethods.map((m) => m[1]);
    if (
      activeFormula
      && activeFormulaLayer
      && formulaMethodValues.length > 0
      && !formulaMethodValues.includes(activeFormula.M)) {
      void updateUILayerFormula(
        { id: activeFormulaLayer.id, update: { M: formulaMethodValues[0] as number } },
      );
    }
  }, [activeFormula]);

  const onMethodChange = (value: string | number) => {
    if (activeFormulaLayer) {
      void updateUILayerFormula(
        { id: activeFormulaLayer.id, update: { M: value as number } },
      );
      setOpen(false);
    }
  };

  const handleChangeHighlight = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (activeFormulaLayer) {
      void updateUILayerFormula({ id: activeFormulaLayer.id, update: { I: event.target.checked } });
    }
  };

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="title2" className={styles.label}>{translate('side.calc')}</Typography>
        <Stack direction="row" className={styles.dropdown} onClick={() => setOpen(!open)}>
          <Typography variant="title2">
            {formulaMethods.map(([name, value]) => value === activeFormula?.M && (
              <span key={value}>
                {translate(`formulaMethods.${name}`)}
              </span>
            ))}
          </Typography>
          {open
            ? <KeyboardArrowUpIcon className={styles.dropdownIcon} />
            : <KeyboardArrowDownIcon className={styles.dropdownIcon} />}
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="space-between" gap="17px">
        <Box width="42px" />
        <Collapse in={open}>
          <Stack className={styles.dropdownList}>
            <Divider className={styles.dropdownDivider} />
            {formulaMethods.map(([name, value]) => (
              <Stack key={value} direction="row" className={styles.dropdownItem} onClick={() => onMethodChange(value)}>
                <Typography variant="title2">
                  {translate(`formulaMethods.${name}`)}
                </Typography>
                <Box width="24px" />
              </Stack>
            ))}
          </Stack>
        </Collapse>
      </Stack>
      <Stack direction="row" className={styles.highlight}>
        <Typography variant="title2" className={styles.label}>
          {translate('map.highlight_result')}
        </Typography>
        <Stack direction="row" gap="5px" alignItems="center">
          <Switch
            size="small"
            checked={!!activeFormula?.I}
            data-testid="switch"
            inputProps={{ 'aria-label': 'ant design' }}
            onChange={handleChangeHighlight}
          />
          <Typography variant="title2" marginLeft="auto">
            {activeFormula?.I
              ? translate('map.yes')
              : translate('map.no')}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CalculationSettings;
