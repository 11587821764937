import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import HeaderForProjects from '../../components/HeaderForProjects/HeaderForProjects';
import Main from '../Main/Main';
import Side from '../Side/Side';

const Base = () => {
  const location = useLocation();

  return (
    <Box display="flex" flexDirection="column" className="App App-media">
      {location.pathname.includes('bi') && <HeaderForProjects biProject />}
      {location.pathname.includes('map') && <HeaderForProjects />}

      <Box display="flex">
        <Box flexGrow={1} className="layout-block">
          <Main />
        </Box>

        {location.pathname.includes('bi') ? '' : (
          <Box sx={{ width: '400px' }} className="layout-block side-block">
            <Side />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Base;
