import {
  Box, Tab, Tabs,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Route, Routes, Navigate, useLocation, useNavigate,
} from 'react-router-dom';
import { useEffect, useState } from 'react';

import Projects from '../../feature/Account/components/Projects';
import Layers from '../../feature/Account/components/Layers';
import Orders from '../../feature/Account/components/Orders';
import PageBreadcrumbs from '../../blocks/PageBreadcrumbs/PageBreadcrumbs';
import styles from './Account.module.scss';

function Account() {
  const location = useLocation();
  const { t: translate } = useTranslation('common');
  const [tabValue, setTabValue] = useState(location.pathname);
  const navigate = useNavigate();
  const [breadcrumbsLinks, setBreadcrumbsLinks] = useState<{ title: string, to: string }[]>(
    [{ title: translate('page.account'), to: '/account/projects' }],
  );
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
    navigate(newValue);
  };

  useEffect(() => {
    if (tabValue !== location.pathname) setTabValue(location.pathname);

    const pathTitle = translate(`account.${location.pathname.split('/')[2]}`);
    setBreadcrumbsLinks((prev) => [...prev, { title: pathTitle, to: location.pathname }]);

    return () => {
      setBreadcrumbsLinks((prev) => {
        prev.splice(1);
        return prev;
      });
    };
  }, [location.pathname]);

  return (
    <>
      <PageBreadcrumbs
        links={breadcrumbsLinks}
      />
      <div className={styles.account}>
        <Box className={styles.accountTabs} data-testid="accountTabs">
          <Tabs value={tabValue} aria-label={translate('account.tabs_area_label')} onChange={handleChange}>
            <Tab label={translate('account.projects')} value="/account/projects" />
            <Tab label={translate('account.layers')} value="/account/layers" />
            <Tab label={translate('account.orders')} value="/account/orders" />
          </Tabs>
        </Box>

        <Routes>
          <Route path="/" element={<Navigate replace to="projects" />} />
          <Route path="projects" element={<Projects />} />
          <Route path="layers" element={<Layers />} />
          <Route path="orders" element={<Orders />} />
          <Route path="*" element={<Navigate replace to="projects" />} />
        </Routes>
      </div>
    </>
  );
}

export default Account;
