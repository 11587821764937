import { Box, RadioGroup, Radio } from '@mui/material';
import { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useObservable } from '@ngneat/react-rxjs';

import { ReactComponent as FirstView } from '../../../assets/images/firstView.svg';
import { ReactComponent as SecondView } from '../../../assets/images/secondView.svg';
import { ReactComponent as ThirdView } from '../../../assets/images/thirdView.svg';
import { ReactComponent as FirstViewSelected } from '../../../assets/images/firstViewSelected.svg';
import { ReactComponent as SecondViewSelected } from '../../../assets/images/secondViewSelected.svg';
import { ReactComponent as ThirdViewSelected } from '../../../assets/images/thirdViewSelected.svg';

import Search from '../../../blocks/Search/Search';
import Loader from '../../Loader/Loader';
import ModalProjectLayout from '../../../blocks/ModalProject/Layout/ModalProjectLayout';
import LayersList from './LayersList';
import ButtonsApplyCancel from '../common/ButtonsApplyCancel';
import HeaderWithCount from '../common/HeaderWithCount';
import useProjectLayers from '../hooks/useProjectLayers';
import { declineChanges, step$ } from '../../../state/project/project.repository';
import { ProjectType } from '../../../models/project';
import { applyUiDatasetsChanges, applyUiLayerChanges } from '../../../state/project/project.actions';
import AclViews from '../../AclViews/AclViews';

import styles from './ProjectDataTab.module.scss';
import LocalPagination from '../../LocalPagination/LocalPagination';

enum TabValue {
  First = 'first',
  Second = 'second',
  Third = 'third',
}

interface ProjectDataTabProps {
  onCloseModal: () => void;
}

const ProjectDataTab:FC<ProjectDataTabProps> = ({ onCloseModal }) => {
  const { t } = useTranslation('common');
  const [step] = useObservable(step$);
  const [view, setView] = useState(TabValue.First);

  const {
    flatLayersList,
    flatAclFirstView,
    flatAclOtherView,
    activeProject,
    onBodyLoad,
    onSideLoad,
    page,
    isLastPage,
    totalCountLoaded,
    handlePageChange,
    fetchLastPage,
    setInputSearch,
  } = useProjectLayers();

  const handleApplyChanges = () => {
    onCloseModal();
    if (activeProject.projectType.model === ProjectType.GPBIProject) {
      void applyUiDatasetsChanges();
    } else {
      void applyUiLayerChanges();
    }
  };

  const body = (
    <>
      <Box className={styles.searchContainer}>
        <Search
          placeholder={t('search.data')}
          handleInputChange={(value) => setInputSearch(value)}
          name="search_project_query"
        />
        <RadioGroup
          className={styles.radioGroup}
          value={view}
          defaultValue={TabValue.First}
          onChange={(e) => { setView(e.target.value as TabValue); }}
        >
          <Radio
            className={styles.radioButton}
            value={TabValue.First}
            icon={<FirstView />}
            checkedIcon={<FirstViewSelected />}
          />
          <Radio
            className={styles.radioButton}
            value={TabValue.Second}
            icon={<SecondView />}
            checkedIcon={<SecondViewSelected />}
          />
          <Radio
            className={styles.radioButton}
            value={TabValue.Third}
            icon={<ThirdView />}
            checkedIcon={<ThirdViewSelected />}
          />
        </RadioGroup>
      </Box>
      { onBodyLoad
        ? <Loader id="progress-acl-view" />
        : (
          <>
            <AclViews
              view={view}
              flatAclFirstView={flatAclFirstView}
              flatAclOtherView={flatAclOtherView}
              addButton
            />
            <LocalPagination
              page={page}
              isLastPage={isLastPage}
              disableToLastPage={!totalCountLoaded}
              handlePageChange={handlePageChange}
              handleLastClick={fetchLastPage}
            />
          </>
        ) }
    </>
  );

  const sideHeader = (
    <HeaderWithCount
      text={t(
        activeProject.projectType.model === ProjectType.GPBIProject
          ? 'project.data'
          : 'project.layers',
      )}
      count={flatLayersList.length.toString()}
    />
  );

  const side = onSideLoad
    ? (
      <Box className={styles.sideLoadWrapper}>
        <Loader id="progress-project-data-tab" />
      </Box>
    )
    : <LayersList layers={flatLayersList} />;

  const sideFooter = (
    <ButtonsApplyCancel
      disabledApply={step === 0 || onBodyLoad || onSideLoad}
      disabledCancel={!step || onBodyLoad || onSideLoad}
      onApply={handleApplyChanges}
      onCancel={declineChanges}
    />
  );

  return (
    <ModalProjectLayout
      body={body}
      sideHeader={ sideHeader }
      side={side}
      sideFooter={sideFooter}
    />
  );
};

export default ProjectDataTab;
