import {
  GpLayer, GpStatDataset,
  GpLayerMap, GpdsScheduleDb,
  GpProductTypes, GpSchedule, GpdsSchedule,
} from '../../../../api/types';
import { ProductTypeId } from '../../../../models/product';

export function getAvailableSchedules(
  availableSchedules: (GpLayerMap | GpdsScheduleDb)[],
  layerOrDataset: GpLayer | GpStatDataset,
) {
  return layerOrDataset.__typename === 'GPLayer'
    ? layerOrDataset.schedules
      .filter((s) => availableSchedules
        .some((l) => l.id === s.layer.id))
    : (layerOrDataset as GpStatDataset).schedules
      .filter((s) => availableSchedules
        .some((l) => l.id === s.dataset.id));
}

export function getProductType(product: GpLayer | GpStatDataset, productTypes: GpProductTypes[]) {
  return ('layer' in product.schedules[0]
    ? productTypes.find((type) => type.id === ProductTypeId.Layer)
    : productTypes.find((type) => type.id === ProductTypeId.StatDataset)) as GpProductTypes;
}

export type LayerMapOrScheduleDb = GpLayerMap | GpdsScheduleDb;
export type LayerType = 'GPLayer' | 'GPStatDataset';
export type LayerOrDataset = GpLayer | GpStatDataset;
export type GPSchedule = GpSchedule | GpdsSchedule;

export interface FlatLayersListItem {
  layerMapId: LayerMapOrScheduleDb['id'],
  layerTitle: string | null,
  scheduleTitle: string | null,
  uName: string | null,
  territoryName: string | null,
  layerMap: LayerMapOrScheduleDb;
}

export interface FlatSchedule {
  scheduleTitle: string,
  scheduleUname: string,
  layerMap: LayerMapOrScheduleDb,
}

export interface FlatLayersListItemSecond {
  layerTitle: string,
  layerId: string,
  layerAnnotation: string,
  layerType: LayerType | undefined,
  territoryName: string,
  schdules: FlatSchedule[],
}

export const getTitleOfSchedule = (
  schedules: GPSchedule[],
  layerOrDataset: GpLayerMap | GpdsScheduleDb,
) => schedules
  .find((schedule) => {
    if ('layer' in schedule) {
      return schedule.layer.id === layerOrDataset.id;
    }
    return schedule.dataset.id === layerOrDataset.id;
  })?.title;

export function composeLayersList(
  layersOrDatasets: LayerOrDataset[],
  layersMapOrSchedulesDb: LayerMapOrScheduleDb[],
  schedules: GPSchedule[],
) {
  const result: FlatLayersListItem[] = [];

  if (layersOrDatasets.length > 0
    || schedules.length > 0) {
    layersMapOrSchedulesDb.forEach((layerMapOrScheduleDb) => {
      const scheduleTitle = getTitleOfSchedule(schedules, layerMapOrScheduleDb);

      const gpLayer = layersOrDatasets.find(
        (layerOrDataset: LayerOrDataset) => layerOrDataset.uNameKey === layerMapOrScheduleDb.uName.split('_', 3).join('_'),
      );

      if (gpLayer) {
        result.push({
          layerMapId: layerMapOrScheduleDb.id,
          layerMap: layerMapOrScheduleDb,
          layerTitle: gpLayer?.title ?? null,
          scheduleTitle: scheduleTitle ?? null,
          uName: layerMapOrScheduleDb.uName ?? null,
          territoryName: gpLayer?.territory?.name ?? null,
        });
      }
    });
  }

  return result;
}

export function composeLayersListSecond(
  layersOrDatasets: LayerOrDataset[],
  layersMapOrSchedulesDb: LayerMapOrScheduleDb[],
) {
  const result: FlatLayersListItemSecond[] = [];

  if (layersMapOrSchedulesDb.length > 0) {
    layersOrDatasets.forEach((layerOrDataset) => {
      const layerMapUnames = layersMapOrSchedulesDb
        .filter((el) => layerOrDataset.uNameKey === el.uName.split('_', 3).join('_'))
        .map((el) => el.uName);
      const filteredSchedules = (layerOrDataset.schedules as (GpSchedule | GpdsSchedule)[])
        .filter((el) => {
          if ('layer' in el) return layerMapUnames.includes(el.layer.uName);
          return layerMapUnames.includes(el.dataset.uName);
        });
      const flatSchedules: FlatSchedule[] = filteredSchedules.map((schedule) => ({
        scheduleTitle: schedule.title,
        scheduleUname: 'layer' in schedule ? schedule.layer.uName : schedule.dataset.uName,
        layerMap: 'layer' in schedule ? schedule.layer : schedule.dataset,
      }));

      result.push({
        layerTitle: layerOrDataset.title,
        layerId: layerOrDataset.id,
        layerAnnotation: layerOrDataset.annotation,
        layerType: layerOrDataset.__typename,
        territoryName: layerOrDataset.territory.name,
        schdules: flatSchedules ?? [],
      });
    });
  }

  return result;
}
