import { useState } from 'react';

const usePageAndSearch = () => {
  const [pageCount, setPageCount] = useState(0);
  const [inputSearch, setInputSearch] = useState('');
  const [page, setPage] = useState(1);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return {
    page,
    pageCount,
    inputSearch,
    handlePageChange,
    setPage,
    setPageCount,
    setInputSearch,
  };
};

export default usePageAndSearch;
