import { useState } from 'react';
import { LeafletMap, L, LayerListStyler } from '../types/leaflet';
import loadScript from './script.service';

interface UseStylerReturnValue {
  styler: LayerListStyler | null;
  loadStyler: (e: LeafletMap) => void;
}

const STYLER_PLUGIN_PATH = '/static/leaflet/leaflet-layers-list-styler-min.js';

const useStyler = (): UseStylerReturnValue => {
  const [styler, setStyler] = useState<LayerListStyler | null>(null);

  const loadStyler = (e: LeafletMap) => {
    loadScript(STYLER_PLUGIN_PATH, () => {
      const control = new L.LayerListStyler({ hideParrent: true });
      control.addTo(e);
      setStyler(control);
    });
  };

  return { styler, loadStyler };
};

export default useStyler;
