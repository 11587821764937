import {
  withProps, select, setProps, createStore,
} from '@ngneat/elf';
import {
  getActiveId,
  selectActiveEntity, selectActiveId, selectAllEntities,
  setActiveId, setEntities, updateEntities, withActiveId, withEntities,
} from '@ngneat/elf-entities';
import { excludeKeys, localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { map } from 'rxjs';
import { GpCustomerProfile, GpTariffPlan, GpUser } from '../../api/types';
// eslint-disable-next-line import/no-cycle
import { getProfileIdFromToken, getTokenWithRefreshToken, getUserData } from './auth.actions';

interface AuthProps {
  token: string | null;
  refreshToken: string | null;
  user: GpUser | null;
  demoProjectId: string | null;
  usernameFromToken: string | null;
  emptyProjectId: string | null;
  emptyBiProjectId: string | null;
  paymentGatewayUrl: string | null;
  paymentRedirectUrl: string | null;
  biUri: string | null;
  imagesUrlPrefix: string | null;
  documentsUrlPrefix: string | null;
}

const nullAuth = {
  token: null,
  refreshToken: null,
  user: null,
  usernameFromToken: null,
  demoProjectId: null,
  emptyProjectId: null,
  emptyBiProjectId: null,
  paymentGatewayUrl: null,
  paymentRedirectUrl: null,
  biUri: null,
  imagesUrlPrefix: null,
  documentsUrlPrefix: null,
} as AuthProps;

const authStore = createStore(
  { name: 'auth' },
  withEntities<GpCustomerProfile>(),
  withActiveId(),
  withProps<AuthProps>(nullAuth),
);

export const userExists$ = authStore.pipe(select((s) => s.user !== null));
export const user$ = authStore.pipe(select((s) => s.user));
export const accounts$ = authStore.pipe(selectAllEntities());
export const activeAccount$ = authStore.pipe(selectActiveEntity());
export const activeAccountId$ = authStore.pipe(selectActiveId());
export const token$ = authStore.pipe(select((s) => s.token));
export const bIUri$ = authStore.pipe(select((s) => s.biUri));
export const isGuestMode$ = authStore.pipe(selectAllEntities()).pipe(
  map((profiles) => profiles.every((p) => p.gpUser.name === 'guest')),
);

export const authStoreSnapshot = authStore.getValue();

export const getDemoProjectId = () => authStore.query((state) => state.demoProjectId);
export const getUsernameFromToken = () => authStore.query((state) => state.usernameFromToken);
export const getEmptyProjectId = () => authStore.query((state) => state.emptyProjectId);
export const getEmptyBiProjectId = () => authStore.query((state) => state.emptyBiProjectId);
export const getPaymentGatewayUrl = () => authStore.query((state) => state.paymentGatewayUrl ?? '');
export const getPaymentRedirectUrl = () => authStore.query((state) => state.paymentRedirectUrl ?? '');
export const getImagesUrlPrefix = () => authStore.query((state) => state.imagesUrlPrefix ?? '');
export const getDocumentsUrlPrefix = () => authStore.query((state) => state.documentsUrlPrefix ?? '');

export const persist = persistState(authStore, {
  key: 'auth',
  storage: localStorageStrategy,
  source: () => authStore.pipe(excludeKeys(['activeId'])),
});

export function reallyResetAuthWithPersist() {
  authStore.update((state) => ({
    ...state,
    ...nullAuth,
  }));
}

export function setActiveAccountId(id: string): void {
  if (authStore.query(getActiveId) !== id) {
    authStore.update(setActiveId(id));
  }
}

export function updateAuth(user: GpUser) {
  authStore.update((state) => ({
    ...state, user,
  }));
}

export function setAuthProps(props: Partial<AuthProps>) {
  authStore.update(setProps(props));
}

export function getAuthProps() {
  return authStore.getValue();
}
export function getTokenWithoutBearer() {
  return (authStore.getValue().token as string).replace('Bearer ', '');
}

export function setProfiles(profiles: GpCustomerProfile[]) {
  authStore.update(setEntities(profiles));
}

export function updateTariff(id: string, tariff: GpTariffPlan) {
  authStore.update(updateEntities(id, {
    tariffPlan: {
      id: tariff.id,
      title: tariff.title,
      num: tariff.num,
      price: tariff.price,
      subtitle: tariff.subtitle,
      valuesList: tariff.valuesList,
    } as GpTariffPlan,
  }));
}

// eslint-disable-next-line @typescript-eslint/no-misused-promises
persist.initialized$.subscribe(async (initialized) => {
  if (initialized) {
    const { token, refreshToken } = authStore.getValue();
    if (token && refreshToken) {
      const profileId = getProfileIdFromToken();
      await getTokenWithRefreshToken('', profileId);
      setTimeout(() => void getUserData(profileId), 1000);
    }
  }
});
