import { FormGroup, Typography } from '@mui/material';
import SingleRubricFilter from './RubricFilter/SingleRubricFilter';
import { GpRubric } from '../../../api/types';

interface LeftBlockProps {
  selectedRubric: GpRubric | null;
  leafRubrics: GpRubric[];
  tempRubrics: GpRubric[];
  handleRubricFilterChange: (active: boolean, rubric: GpRubric) => void;
}

function CentralBlock({
  selectedRubric,
  leafRubrics,
  tempRubrics,
  handleRubricFilterChange,
}: LeftBlockProps) {
  return (
    <FormGroup>
      {
        selectedRubric
          ? (
            <>
              <Typography variant="subtitle1" mb="3px">
                {selectedRubric.title}
              </Typography>
              {
                leafRubrics
                  .filter((r) => selectedRubric.id === r.parent.id)
                  .map((filter) => (
                    <SingleRubricFilter
                      filter={filter}
                      key={filter.id}
                      active={tempRubrics.includes(filter)}
                      onChange={handleRubricFilterChange}
                    />
                  ))
              }
            </>
          )
          : null
      }
    </FormGroup>
  );
}

export default CentralBlock;
