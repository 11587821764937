import { useMemo } from 'react';
import {
  Stack, Box,
} from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import { Droppable } from 'react-beautiful-dnd';
import { GpuiLayer } from '../../../api/types';
import { LayerType } from '../../../models/layer';

import { BASE_LAYERS_DROPPABLE, OVERLAY_LAYERS_DROPPABLE } from '../constants';
import LayerCard from './LayerCard';
import layerListContext from './LayerListContext';

interface LayerListProps {
  layers: GpuiLayer[];
  layerType: LayerType;
}

function LayerList({ layers, layerType }: LayerListProps) {
  const value = useMemo(
    () => ({ layers }),
    [layers],
  );

  return (
    <layerListContext.Provider value={ value }>
      <form>
        <Stack spacing={3}>
          <FormGroup>
            <div>
              <Droppable
                droppableId={
                  layerType === LayerType.OV
                    ? OVERLAY_LAYERS_DROPPABLE
                    : BASE_LAYERS_DROPPABLE
                }
              >
                {(provided) => (
                  <Stack
                    ref={provided.innerRef}
                    component="div"
                  >
                    {layers.map((layer, index) => (
                      <LayerCard key={layer.id} layer={layer} index={index} layerType={layerType} />
                    ))}
                    {provided.placeholder}
                    {
                      !layers.length && (
                        <Box height={30} />
                      )
                    }
                  </Stack>
                )}
              </Droppable>
            </div>
          </FormGroup>
        </Stack>
      </form>
    </layerListContext.Provider>
  );
}

export default LayerList;
