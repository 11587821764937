import { useEffect, useState } from 'react';
import { GpRubric } from '../../../api/types';
import { getLeafRubrics, getNestedFilters, getActiveNestedFiltersIds } from '../functions/RubricsModalHelper';

interface UseRubricFilterProps {
  filter: GpRubric,
  active: boolean,
  rubrics: GpRubric[],
  activeRubrics: GpRubric[],
  onChange: (active: boolean, filter: GpRubric) => void,
  onChangeChild: (active: boolean, filter: GpRubric) => void,
}

function useRubricFilter({
  filter,
  active,
  rubrics,
  activeRubrics,
  onChange,
  onChangeChild,
}: UseRubricFilterProps) {
  const [checked, setChecked] = useState<boolean>(active);
  const [indeterminate, setIndeterminate] = useState<boolean>();
  const [open, setOpen] = useState<boolean>(false);

  const leafRubrics = getLeafRubrics(rubrics);
  const nestedFilters = getNestedFilters(filter, rubrics);
  const activeNestedFiltersIds = getActiveNestedFiltersIds(filter, rubrics, activeRubrics);

  useEffect(() => {
    if (nestedFilters && activeNestedFiltersIds) {
      setChecked(nestedFilters.length === activeNestedFiltersIds.length);
      setIndeterminate(
        activeNestedFiltersIds.length > 0
        && nestedFilters.length !== activeNestedFiltersIds.length,
      );
    } else {
      setChecked(active);
      setIndeterminate(undefined);
    }
  }, [active, nestedFilters, activeNestedFiltersIds]);

  function handleParentChange(active: boolean, filter: GpRubric) {
    const calcActive = indeterminate ? false : active;
    const nestedFilters = getNestedFilters(filter, rubrics);
    nestedFilters!.forEach((nestedFilter) => (leafRubrics.includes(nestedFilter)
      ? onChangeChild(active, nestedFilter)
      : handleParentChange(calcActive, nestedFilter)));
  }

  function onParentChange(event: React.ChangeEvent<HTMLInputElement>) {
    return handleParentChange(event.target.checked, filter);
  }

  function handleLeafParentChange(event: React.ChangeEvent<HTMLInputElement>) {
    const active = indeterminate ? false : event.target.checked;
    nestedFilters!.forEach((nestedFilter) => onChangeChild(active, nestedFilter));
  }

  function handleListItemClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if ((event.target as Element).tagName !== 'INPUT') {
      if (nestedFilters && activeNestedFiltersIds) {
        setOpen(!open);
      } else {
        onChange(checked, filter);
      }
    }
  }

  return {
    nestedFilters,
    activeNestedFiltersIds,
    checked,
    indeterminate,
    open,
    onParentChange,
    handleLeafParentChange,
    handleListItemClick,
  };
}

export default useRubricFilter;
