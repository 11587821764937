import { Box } from '@mui/material';
import { FC } from 'react';

import LocalPagination from '../../../../../components/LocalPagination/LocalPagination';

import AclViews from '../../../../../components/AclViews/AclViews';
import styles from './LayersBody.module.scss';
import { FlatLayersListItem, FlatLayersListItemSecond } from '../LayersHelpers';

interface LayersBodyProps {
  view: string,
  page: number,
  isLastPage: boolean,
  totalCountLoaded: boolean,
  flatAclFirstView: FlatLayersListItem[],
  flatAclOtherView: FlatLayersListItemSecond[],
  handlePageClick: (e: React.ChangeEvent<unknown>, value: number) => void,
  handleLastClick: (e: React.ChangeEvent<unknown>) => void,
}

const LayersBody: FC<LayersBodyProps> = ({
  view,
  page,
  isLastPage,
  totalCountLoaded,
  flatAclFirstView,
  flatAclOtherView,
  handlePageClick,
  handleLastClick,
}) => (
  <>
    <Box className={styles.layersBodyWrapper}>
      <AclViews
        view={view}
        flatAclFirstView={flatAclFirstView}
        flatAclOtherView={flatAclOtherView}
      />
    </Box>
    <LocalPagination
      page={page}
      isLastPage={isLastPage}
      disableToLastPage={!totalCountLoaded}
      handlePageChange={handlePageClick}
      handleLastClick={handleLastClick}
    />
  </>
);

export default LayersBody;
