import './types/mui.d';

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FA5026', // primary-orange
      dark: '#061136', // primary-blue
      light: '#071236', // text-dark-blue
    },
    secondary: {
      main: '#FFFFFF', // primary-white
      dark: '#EBEBEB', // secondary-grey
      light: '#F1F2F5', // background-color
    },
    text: {
      primary: '#061136', // primary-blue
      secondary: '#071236', // dark-blue
      disabled: '#818692', // text-gray-disabled
    },
    side: {
      main: '#fff',
      light: '#979DA8',
      dark: '#2D3A5C',
      contrastText: '#131d40',
    },
  },
  typography: {
    htmlFontSize: 10,
  },
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'h1' },
          style: {
            fontFamily: 'Blender Pro',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '6.4rem',
            lineHeight: '6.4rem',
            letterSpacing: '-0.42rem',
          },
        },
        {
          props: { variant: 'h2' },
          style: {
            fontFamily: 'Blender Pro',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '4rem',
            lineHeight: '4rem',
            letterSpacing: '-0.03rem',
          },
        },
        {
          props: { variant: 'h3' },
          style: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '2.6rem',
            lineHeight: '3.2rem',
            letterSpacing: '-0.05rem',
            textTransform: 'uppercase',
          },
        },
        {
          props: { variant: 'h4' },
          style: {
            fontFamily: 'Blender Pro',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '2.2rem',
            lineHeight: '2rem',
            letterSpacing: '-0.02rem',
            textTransform: 'uppercase',
          },
        },
        {
          props: { variant: 'title1' },
          style: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '2rem',
            lineHeight: '3.2rem',
            letterSpacing: '-0.05rem',
          },
        },
        {
          props: { variant: 'title2' },
          style: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '1.2rem',
            lineHeight: '1.6rem',
          },
        },
        {
          props: { variant: 'body1' },
          style: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '1.4rem',
            letterSpacing: '-0.02rem',
          },
        },
        {
          props: { variant: 'body2' },
          style: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '1.2rem',
            lineHeight: '1.6rem',
            letterSpacing: '-0.02rem',
          },
        },
        {
          props: { variant: 'description' },
          style: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '1rem',
            lineHeight: '1.2rem',
          },
        },
        {
          props: { variant: 'subtitle1' },
          style: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '1.6rem',
            lineHeight: '2rem',
            color: '#000000',
          },
        },
        {
          props: { variant: 'subtitle2' },
          style: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '1.2rem',
            lineHeight: '1.5rem',
            color: '#000000',
          },
        },
        {
          props: { variant: 'subtitle3' },
          style: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '1rem',
            lineHeight: '1.6rem',
            color: '#000000',
          },
        },
        {
          props: { variant: 'accentTitle1' },
          style: {
            fontFamily: 'Blender Pro',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '1.6rem',
            lineHeight: '1.4rem',
            letterSpacing: '-0.02rem',
            color: '#000000',
          },
        },
        {
          props: { variant: 'accentTitle2' },
          style: {
            fontFamily: 'Blender Pro',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '1.2rem',
            lineHeight: '1.2rem',
            letterSpacing: '-0.02rem',
            color: '#000000',
          },
        },
        {
          props: { variant: 'button' },
          style: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '1.4rem',
            lineHeight: '2.4rem',
            letterSpacing: '0.02rem',
            color: '#000000',
            textTransform: 'none',
          },
        },
        {
          props: { variant: 'headmenu' },
          style: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '1.2rem',
            lineHeight: '1.6rem',
            letterSpacing: '0.02rem',
            color: '#000000',
          },
        },
        {
          props: { variant: 'H3card' },
          style: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
          },
        },
        {
          props: { variant: 'H2card' },
          style: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '20px',
          },
        },
        {
          props: { variant: 'H1card' },
          style: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '20px',
            color: '#000000',
          },
        },
        {
          props: { variant: 'textCard' },
          style: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '13px',
            lineHeight: '20px',
          },
        },
        {
          props: { variant: 'priceCard' },
          style: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '14px',
            lineHeight: '20px',
          },
        },
        {
          props: { variant: 'descriptionCard' },
          style: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '11px',
            lineHeight: '12px',
          },
        },
        {
          props: { variant: 'statusCard' },
          style: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '13px',
            lineHeight: '20px',
          },
        },
        {
          props: { variant: 'cartCard' },
          style: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '16px',
          },
        },
        {
          props: { variant: 'modalTitle' },
          style: {
            fontFamily: 'Blender Pro',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '30px',
            lineHeight: '30px',
          },
        },
        {
          props: { variant: 'featureCardTitle' },
          style: {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '1.8rem',
            lineHeight: '2.4rem',
          },
        },
      ],
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter',
          fontSize: '1rem',
          fontWeight: 500,
          lineHeight: '1.2rem',
          color: '#061136',
        },
      },
    },
  },
});

export default theme;
