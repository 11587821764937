import {
  withProps, select, setProps, createStore,
} from '@ngneat/elf';
import {
  getActiveId,
  getEntity,
  resetActiveId,
  selectActiveEntity,
  selectAllEntities,
  setActiveId,
  setEntities,
  upsertEntities,
  withActiveId,
  withActiveIds,
  withEntities,
} from '@ngneat/elf-entities';
import {
  createRequestsStatusOperator, selectIsRequestPending,
  selectRequestStatus,
  StatusState,
  updateRequestStatus,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { map } from 'rxjs';
import { GpProduct } from '../../api/types';
import { TotalCountProps } from '../../types/shared';

export interface ExtendedGPProduct extends GpProduct {
  full?: boolean;
}

export interface ProductsStoreProps extends TotalCountProps {
  hasMore: boolean;
}

const productsStore = createStore(
  { name: 'products' },
  withEntities<ExtendedGPProduct>(),
  withActiveId(),
  withActiveIds(),
  withProps<ProductsStoreProps>({ totalCount: 0, hasMore: false }),
  withRequestsStatus<'products'>(),
  withRequestsStatus<'totalCount'>(),
);

export const trackLayerProductsRequestsStatus = createRequestsStatusOperator(productsStore);

export const products$ = productsStore.pipe(
  selectAllEntities(),
  map((products) => products.filter((p) => p.product.length)),
);
export const activeProduct$ = productsStore.pipe(selectActiveEntity());
export const productsTotalCount$ = productsStore.pipe(select((state) => state.totalCount));
export const productsHasMore$ = productsStore.pipe(select((state) => state.hasMore));

export const productsRequestPending$ = productsStore
  .pipe(selectIsRequestPending('products'));
export const productsRequestStatus$ = productsStore
  .pipe(selectRequestStatus('products'));

export const totalCountProductRequestPending$ = productsStore.pipe(selectIsRequestPending('totalCount'));
export const totalCountProductRequestStatus$ = productsStore.pipe(selectRequestStatus('totalCount'));

export function setActiveProduct(id: string): void {
  productsStore.update(setActiveId(id));
}

export function resetActiveProduct(): void {
  productsStore.update(resetActiveId());
}

export function resetProductsStore(): void {
  productsStore.reset();
}

export function getProduct(id: string): ExtendedGPProduct | undefined {
  return productsStore.query(getEntity(id));
}

export function getActiveProductId(): string | undefined {
  return productsStore.query(getActiveId) as string | undefined;
}

export function upsertProduct(product: ExtendedGPProduct): void {
  productsStore.update(upsertEntities(product));
}

export function setProductRequestStatusError(e: any): void {
  productsStore.update(
    updateRequestStatus('products', 'error', e),
  );
}

export function setProductRequestStatus(status: Exclude<StatusState['value'], 'error'>): void {
  productsStore.update(
    updateRequestStatus('products', status),
  );
}

export function setTotalCountProductRequestStatusError(e: any): void {
  productsStore.update(
    updateRequestStatus('totalCount', 'error', e),
  );
}

export function setTotalCountProductRequestStatus(status: Exclude<StatusState['value'], 'error'>): void {
  productsStore.update(
    updateRequestStatus('totalCount', status),
  );
}

export function setProducts(products: GpProduct[], hasMore: boolean): void {
  productsStore.update(
    setEntities(products),
    setProps({ hasMore }),
  );
}

export function setTotalCount(totalCount: number): void {
  productsStore.update(
    setProps({ totalCount }),
  );
}
