import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import { LeafletMap } from '../types/leaflet';

const useGeosearch = (e: LeafletMap): void => {
  const search = GeoSearchControl({
    provider: new OpenStreetMapProvider({
      searchUrl: 'https://nominatim.openstreetmap.org/search',
      reverseUrl: 'https://nominatim.openstreetmap.org/reverse',
    }),
    style: 'bar',
    showMarker: false,
    autoComplete: true,
    autoCompleteDelay: 250,
    searchLabel: 'Введите город',
  });
  e.addControl(search);
};

export default useGeosearch;
