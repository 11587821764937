import { Button, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import packageJson from '../../../package.json';
import error_img from '../../assets/images/error-screen.png';
import styles from './ErrorScreen.module.scss';

interface ErrorScreenProps {
  onClick?: () => void;
}

const ErrorScreen = ({ onClick }: ErrorScreenProps) => {
  const { t } = useTranslation('common');

  return (
    <Box sx={{
      backgroundColor: '#061136',
      height: '100vh',
      width: '100%',
    }}
    >
      <Stack direction="row" alignItems="center" justifyContent="center" height="100%">
        <img src={error_img} alt="error-screen" className={styles.image} />
        <Box>
          <Typography className={styles.face}>
            { t('errors.face') }
          </Typography>
          <Typography className={styles.somethingWentWrong}>
            { t('errors.something_went_wrong') }
          </Typography>
          <Button variant="contained" onClick={onClick} className={styles.button}>
            <Typography className={styles.back}>
              { t('errors.back') }
            </Typography>
          </Button>
        </Box>
      </Stack>
      <Typography className={styles.version}>
        { `${t('errors.version')}: ${packageJson.version}` }
      </Typography>
    </Box>
  );
};

export default ErrorScreen;
