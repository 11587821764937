import {
  Box,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { createSideMarkup } from '../../../../utils/functions';
import { GpLayer, GpStatDataset } from '../../../../api/types';
import styles from './ProductsList.module.scss';

interface ProductsListItemProps {
  title: string;
  subtitle: string;
  price?: string;
}

const ProductsListItem = ({ title, subtitle, price }: ProductsListItemProps) => {
  const { t } = useTranslation('common');
  return (
    <Stack className={styles.stack} direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="column" gap="10px" flexBasis="600px">
        <Typography variant="subtitle1">{title}</Typography>
        <Typography
          variant="body2"
          color="primary.dark"
          sx={{
            opacity: '0.5',
          }}
          dangerouslySetInnerHTML={createSideMarkup(subtitle)}
        />
      </Stack>
      <Box flexShrink={0}>
        <Typography variant="subtitle1" fontWeight="700" color="primary.main">
          { price && `${t('recipe.from')} ${price} ₽`}
        </Typography>
      </Box>
    </Stack>
  );
};

interface ProductsListProps {
  products: (GpLayer | GpStatDataset)[];
}

const ProductsList = ({ products }: ProductsListProps) => (
  <Stack direction="column" gap="20px">
    {products.length > 0
      && products.map((p) => (
        <ProductsListItem
          key={p.id}
          title={p.title || 'Без названия'}
          subtitle={p.annotation}
          price={p.price}
        />
      ))}
  </Stack>
);

export default ProductsList;
