import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, Stack, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as ArrowRightIcon } from '../../assets/images/arrowRight.svg';
import { ReactComponent as HomeIcon } from '../../assets/images/home.svg';
import styles from './PageBreadcrumbs.module.scss';

export interface PageBreadcrumbsProps {
  links?: { title: string, to: string }[];
}

const PageBreadcrumbs:FC<PageBreadcrumbsProps> = ({ links }) => {
  const { t: translate } = useTranslation('common');
  const location = useLocation();
  const pathWithoutMarketplace = location.pathname.includes('/account') || location.pathname.includes('/help');

  return (
    <Stack direction="row" mt="2.5rem" mb="3rem" className={styles.container}>
      { pathWithoutMarketplace && (
        <Link to={{ pathname: '/marketplace' }} className={styles.breadcrumbsHome}>
          <HomeIcon />
        </Link>
      ) }
      <Breadcrumbs aria-label="breadcrumb" separator={<ArrowRightIcon />} className={styles.breadcrumbs}>
        { !pathWithoutMarketplace && (
          <Link to={{ pathname: '/marketplace' }} className={styles.breadcrumbsHome}>
            <HomeIcon />
            <Typography variant="body2" color="text.disabled">
              { translate('page.marketplace') }
            </Typography>
          </Link>
        )}
        {links?.map((link, index) => (
        // eslint-disable-next-line react/no-array-index-key
          <Link to={{ pathname: link.to }} key={index}>
            <Typography variant="body2" color="text.disabled">
              { link.title }
            </Typography>
          </Link>
        ))}
      </Breadcrumbs>
    </Stack>
  );
};

export default PageBreadcrumbs;
