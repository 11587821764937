import {
  styled, Menu as MuiMenu, MenuItem as MuiMenuItem,
} from '@mui/material';

export const Menu = styled(MuiMenu)(({ theme }) => ({
  '& .MuiMenu-paper': {
    minWidth: '145px',
    borderRadius: '10px',
    padding: '10px 20px',
    background: theme.palette.secondary.main,
  },
  '& .MuiMenu-list': {
    padding: '0px',
  },
}));

export const MenuItem = styled(MuiMenuItem)(() => ({
  padding: '0px',
  display: 'flex',
  justifyContent: 'center',
}));
