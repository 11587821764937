import { SvgIcon } from '@mui/material';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import styles from './Logo.module.scss';
import { ReactComponent as LogoIcon } from '../../assets/images/logo.svg';
import { sendMetricReachGoal } from '../../utils/functions';

function Logo() {
  return (
    <Link to="/" data-testid="logo" onClick={() => sendMetricReachGoal('logo')}>
      <Box className={styles.logoIcon}>
        <SvgIcon
          sx={{ width: 77, height: 31 }}
          component={LogoIcon}
          inheritViewBox
        />
      </Box>

      <Box className={styles.logoPlatform}>Platforma</Box>
    </Link>
  );
}

export default Logo;
