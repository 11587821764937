import {
  memo,
  useEffect, useRef,
} from 'react';
import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { initMarkersSrc } from '../../utils/leaflet-icon-init';

interface MapProps {
  setMap: (map: L.Map) => void;
  setMapEl?: (mapEl: HTMLElement) => void;
  styles: string;
  options: L.MapOptions;
  mapHeight: string;
}

const initMapResizeObserver = (map: L.Map, mapDiv: HTMLDivElement) => {
  const resizeObserver = new ResizeObserver(() => {
    map.invalidateSize();
  });

  map.on('remove', () => resizeObserver.disconnect());
  resizeObserver.observe(mapDiv);
};

function Map({
  setMap, styles, options, mapHeight, setMapEl,
}: MapProps) {
  const mapRef = useRef(null);

  useEffect(initMarkersSrc, []);

  useEffect(() => {
    if (mapRef.current) {
      const Lmap = L.map(mapRef.current, options);
      setMap(Lmap);

      if (setMapEl) {
        setMapEl(mapRef.current);
      }
      initMapResizeObserver(Lmap, mapRef.current);
    }
  }, [mapRef]);

  return (
    <div ref={mapRef} className={styles} style={{ height: mapHeight }} />
  );
}

export default memo(Map);
