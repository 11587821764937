import { LeafletMouseEvent, Map as MapOriginal } from 'leaflet';
import { LayerExtended, LeafletMap, Multipuncture } from '../../../types/leaflet';
import { getSortedOverlayLayers } from '../../../state/project/project.repository';
import { resultingValueMapper } from '../../../utils/functions';
import { Feature, GpLayerMap } from '../../../api/types';
import { geoServerGetFeature } from '../../../services/geo-api.service';

export interface PopoverContent {
  title: string;
  res: string;
  id: string;
  uName: string;
  punctureValue?: number;
}

interface SetMultipunctureData {
  map: MapOriginal | null;
  layersObj: { [key: string]: any };
  multipuncture: Multipuncture | null;
  setPopoverContent: (value: React.SetStateAction<PopoverContent[]>) => void;
  setGeoFeatures: (value: React.SetStateAction<Feature[]>) => void;
  setLoadingFeatures: (value: React.SetStateAction<boolean>) => void;
  evt: LeafletMouseEvent;
}

export const setMultipunctureAsync = async (data: SetMultipunctureData) => {
  const overlayLayers = getSortedOverlayLayers().map((l) => l.layer[0] as GpLayerMap);

  data.map?.eachLayer(((layer) => {
    const layerMap = overlayLayers
      .find((item) => item.id === (layer as unknown as LayerExtended)._platforma_id);

    if (layerMap) {
      data.layersObj[layerMap.uName] = layer;
    }
  }));

  if ((data.map as LeafletMap).state.resultingEnabled) {
    data.layersObj.RES = (data.map as LeafletMap).state.formulaLayer;
  }

  const mpcRes = data.multipuncture?.getMultipuncture(data.layersObj, data.evt.latlng);

  if (mpcRes) {
    try {
      const orderedUNames = overlayLayers.map((ovL) => ovL.uName);
      const UNames = Object.keys(mpcRes.L || {}).sort(
        (a, b) => orderedUNames.indexOf(a) - orderedUNames.indexOf(b),
      );

      const content: PopoverContent[] = UNames.map((key) => {
        const layerMap = overlayLayers
          .find((el) => el.uName === key);
        return {
          title: layerMap!.title,
          res: mpcRes.L[key][3] > 0
            ? resultingValueMapper(mpcRes.L[key][0])
            : 'Нет',
          id: layerMap!.id,
          uName: key,
        };
      });

      data.setPopoverContent(content);
      data.setLoadingFeatures(true);

      const resGeoFeatures = await geoServerGetFeature(UNames, {
        latmax: data.evt.latlng.lat,
        latmin: data.evt.latlng.lat,
        longmin: data.evt.latlng.lng,
        longmmax: data.evt.latlng.lng,
      });
      data.setGeoFeatures(resGeoFeatures.data.GPGeoserverGetFeature.get.features as Feature[]);
      data.setLoadingFeatures(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
};
