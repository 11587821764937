import {
  Box, Typography, Stack, Link,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useNavigate, useSearchParams,
} from 'react-router-dom';
import { DateTime } from 'luxon';
import PageBreadcrumbs from '../../blocks/PageBreadcrumbs/PageBreadcrumbs';
import success from '../../assets/images/success_payment.svg';
import fail from '../../assets/images/fail_payment.svg';
import { sleep } from '../../utils/functions';
import { ButtonOutlined } from './Button';
import PaymentList from './PaymentList/PaymentList';
import { getOrder } from '../../services/geo-api.service';
import { GpOrder } from '../../api/types';
import Loader from '../Loader/Loader';
import styles from './PaymentStatus.module.scss';
import { tryGetResponseData } from '../../state/common';

const refetchOrder = async (orderId: string) => {
  await sleep(2000);
  const res = await getOrder(orderId);
  return tryGetResponseData(res).GPOrder.get as GpOrder;
};

function PaymentStatus() {
  const { t: translate } = useTranslation('common');
  const [order, setOrder] = useState<GpOrder | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const result = searchParams.get('result');
  const orderId = searchParams.get('orderId');

  const productBreadcrumbs = [
    { title: translate('page.account'), to: '/account' },
    { title: translate('account.orders'), to: '/account/orders' },
  ];

  useEffect(() => {
    if (orderId) {
      void (async () => {
        let order: GpOrder;
        const res = await getOrder(orderId);
        order = tryGetResponseData(res).GPOrder.get as GpOrder;
        if (result === 'success') {
          while (!order.paymentId) {
            // eslint-disable-next-line no-await-in-loop
            order = await refetchOrder(orderId);
          }
        } else {
          order = await refetchOrder(orderId);
        }
        setOrder(order);
        setLoading(false);
      })();
    }
  }, []);

  if (loading) {
    return <Loader id="progress-payment-status" />;
  }

  const totalPrice = order?.orderItems.reduce((total, item) => {
    total.sum += Number(item.productPackaging[0].price);
    return total;
  }, { sum: 0 } as { sum: number });

  const SuccessfulGrid = (
    <>
      <Typography variant="body1">{translate('payment.date')}</Typography>
      <Typography variant="body1">
        { DateTime.fromMillis(order?.dateTime || Date.now()).setLocale('ru-Ru').toFormat('dd MMMM yyyy, HH:mm:ss') }
      </Typography>

      <Typography variant="body1">{translate('payment.bill')}</Typography>
      <Link target="_blank" href={order?.billUri}>
        <Typography variant="body1">
          { translate('payment.download') }
        </Typography>
      </Link>
    </>
  );

  return (
    <Stack pb="13.5rem">
      <PageBreadcrumbs links={productBreadcrumbs} />
      <Stack>
        <Stack direction="row" className={styles.payment}>
          <Stack justifyContent="center" alignItems="center">
            <img src={result === 'success' ? success : fail} alt={result || ''} />
          </Stack>
          <Stack>
            <Typography variant="h2" fontSize="3rem">
              { result === 'success'
                ? translate('payment.thanks')
                : translate('payment.fail')}
            </Typography>
            {result === 'success' && (
            <Typography variant="subtitle1" mt="2rem" color="primary.dark">
              { translate(
                'payment.success',
                {
                  number: order?.id || 0,
                  date: DateTime
                    .fromMillis(order?.cmscreationdate || Date.now()).setLocale('ru-Ru').toFormat('dd MMMM yyyy'),
                },
              ) }
            </Typography>
            )}
            { result === 'fail'
                && (
                  <Typography variant="subtitle1" mt="2rem" color="primary.dark">
                    { translate('payment.not_paid') }
                  </Typography>
                )}
            <Box className={styles.paymentGrid}>
              { result === 'success' && SuccessfulGrid }
              <Typography variant="body1">{translate('payment.positions_amount')}</Typography>
              <Typography variant="body1">
                { order?.orderItems.length || 0}
              </Typography>

              <Typography variant="body1">
                { translate('payment.sum') }
              </Typography>
              <Typography variant="subtitle1" className={styles.paymentSum}>
                { `${totalPrice?.sum || 0} ₽` }
              </Typography>
            </Box>
            {result === 'success'
              ?? (
                <ButtonOutlined
                  onClick={() => navigate(`/account/orders/${order?.id || ''}`)}
                  className={styles.paymentButton}
                >
                  <Typography variant="button" color="primary.main">
                    { translate('payment.show_order') }
                  </Typography>
                </ButtonOutlined>
              )}
          </Stack>
        </Stack>
      </Stack>

      <Box mt="7rem">
        <PaymentList />
      </Box>
    </Stack>
  );
}

export default PaymentStatus;
