import { Autocomplete, Box } from '@mui/material';
import { useCallback, useState } from 'react';
import ProjectNameTextField from '../../ui/SideTextField';

interface SelectWithAutocompleteProps<T> {
  id: string;
  options: T[];
  labelKey?: keyof T;
  noOptionsText?: string;
  value: T;
  setValue(v: T | null): void;
  isOptionEqualToValue?(o: T, v: T): boolean;
}

function SelectWithAutocomplete<T>(
  {
    id, options, noOptionsText, labelKey, value, setValue, isOptionEqualToValue,
  }: SelectWithAutocompleteProps<T>,
) {
  const [inputValue, setInputValue] = useState('');

  const handleChange = useCallback(
    (_: any, value: T | null) => setValue(value),
    [setValue],
  );
  const renderOptions = useCallback((props, option: T) => (
    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
      {(labelKey && option[labelKey]) || option}
    </Box>
  ), []);
  const handleInputChange = useCallback((_: any, value: string) => setInputValue(value), []);
  const renderInput = useCallback((params) => (
    <ProjectNameTextField
      {...params}
      inputProps={{
        ...params.inputProps,
      }}
    />
  ), []);

  return (
    <Autocomplete
      id={id}
      sx={{ width: '300px' }}
      options={options}
      value={value}
      autoHighlight
      isOptionEqualToValue={isOptionEqualToValue}
      noOptionsText={noOptionsText}
      onChange={handleChange}
      renderOption={renderOptions}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      renderInput={renderInput}
    />
  );
}

export default SelectWithAutocomplete;
