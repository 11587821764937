import { memo } from 'react';
import {
  Button, Grid, Paper, Typography, Box,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { GpTariffPlanItem } from '../../api/types';
import styles from './TariffCard.module.scss';

interface TariffCardProps {
  title: string;
  subtitle: string;
  available: boolean;
  advantages?: GpTariffPlanItem[];
  price?: number;
  onButtonClick(): void;
}

function TariffCard({
  title, subtitle, available, advantages, price, onButtonClick,
}: TariffCardProps) {
  return (
    <Paper
      data-testid={title}
      elevation={0}
      className={styles.card}
    >
      <Typography variant="h4" color="text.primary" align="center" sx={{ textTransform: 'none' }}>{title}</Typography>
      <Typography variant="title2" component="p" align="center" color="text.primary" className={styles.destiny}>
        { subtitle }
      </Typography>

      <Grid container className={styles.options}>
        {advantages ? advantages.map((item) => (
          <Grid item className={styles.itemOptions} key={item.num} sx={item.active ? {} : { opacity: '0.2' }}>
            <CheckIcon color="primary" />
            <Typography variant="title2" component="p" ml={1}>{item.title}</Typography>
          </Grid>
        )) : ''}
      </Grid>

      <Box mt="auto">
        <Button
          disabled={!available}
          variant="outlined"
          className={styles.price}
          onClick={onButtonClick}
        >
          {price ? `${price} ₽` : 'Бесплатно'}
        </Button>
      </Box>
    </Paper>
  );
}

export default memo(TariffCard);
