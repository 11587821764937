import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, Stack, Typography, useTheme,
} from '@mui/material';
import { DateTime } from 'luxon';
import { createSideMarkup, getCaseOfCount, removeSpecialChar } from '../../../../utils/functions';
import { ReactComponent as Point } from '../../../../assets/images/point.svg';
import { ReactComponent as Timer } from '../../../../assets/images/timerSmall.svg';
import { ProductUnion } from '../../../../api/types';
import styles from './CardTitle.module.scss';

interface CardTitleProps {
  updateDate?: number | null;
  title: string;
  product?: ProductUnion;
  territoryAcl?: string;
  annotationAcl?: string;
}

const CardTitle:FC<CardTitleProps> = ({
  updateDate, title, product, territoryAcl, annotationAcl,
}) => {
  const { t: translate } = useTranslation('common');
  const theme = useTheme();

  return (
    <Stack className={styles.title}>
      <Typography height="4rem" variant="H1card">{ removeSpecialChar(title) }</Typography>
      <Box minHeight="1.6rem">
        { updateDate
        && (
          <Typography color={`${theme.palette.text.primary}50`} variant="textCard">
            { DateTime.fromMillis(updateDate).setLocale('ru-Ru').toFormat('dd MMMM yyyy, HH:mm:ss') }
          </Typography>
        )}
        { (product || territoryAcl || annotationAcl) && (
          <>
            <Box className={styles.info}>
              { ((product && 'territory' in product) || territoryAcl) && (
                <>
                  <Point />
                  <Typography variant="H3card">
                    {product && 'territory' in product && product.territory?.name}
                    {territoryAcl}
                  </Typography>
                </>
              )}
              {product && 'duration' in product && (
              <>
                <Timer />
                <Typography variant="H3card">
                  {`${translate('recipe.result')} ${product.duration} ${getCaseOfCount(
                    product.duration,
                    translate('recipe.duration', { returnObjects: true }),
                  )}`}
                </Typography>
              </>
              )}
            </Box>
            <Box>
              <Box
                className={styles.annotation}
                dangerouslySetInnerHTML={createSideMarkup(product?.annotation || annotationAcl)}
              />
            </Box>
          </>
        ) }
      </Box>
    </Stack>
  );
};

export default CardTitle;
