import { useState } from 'react';
import { useObservable } from '@ngneat/react-rxjs';

import {
  rubrics$, activeRubrics$, tempRubrics$,
  addTempRubric, removeTempRubric,
} from '../../../state/filters/filters.repository';

import { getLeafRubrics } from '../functions/RubricsModalHelper';
import { GpRubric } from '../../../api/types';

function useRubricsModal() {
  const [rubrics] = useObservable(rubrics$);
  const [tempRubrics] = useObservable(tempRubrics$);
  const [activeRubrics] = useObservable(activeRubrics$);
  const [selectedRubric, setSelectedRubric] = useState<GpRubric | null>(null);
  const handleBodyRender = () => {
    setSelectedRubric(null);
    tempRubrics.forEach((r) => removeTempRubric(r.id));
    activeRubrics.forEach((r) => addTempRubric(r));
  };

  const clearTempRubrics = () => {
    tempRubrics.forEach((r) => removeTempRubric(r.id));
  };

  const handleRubricGroupSelect = (active: boolean, rubric: GpRubric) => {
    if (selectedRubric !== rubric) {
      if (selectedRubric) {
        removeTempRubric(selectedRubric.id);
      }
      addTempRubric(rubric);
      setSelectedRubric(rubric);
    } else {
      removeTempRubric(rubric.id);
      setSelectedRubric(null);
    }
  };

  const handleRubricFilterChange = (active: boolean, rubric: GpRubric) => {
    if (active) {
      addTempRubric(rubric);
    } else {
      removeTempRubric(rubric.id);
    }
  };

  const clearSelectedRubric = () => {
    setSelectedRubric(null);
  };

  const singleRubrics = tempRubrics.filter((r) => selectedRubric !== r);
  const leafRubrics = getLeafRubrics(rubrics);

  return {
    rubrics,
    activeRubrics,
    tempRubrics,
    selectedRubric,
    singleRubrics,
    leafRubrics,
    handleBodyRender,
    clearTempRubrics,
    handleRubricGroupSelect,
    handleRubricFilterChange,
    clearSelectedRubric,
  };
}

export default useRubricsModal;
