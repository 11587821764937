export default function loadScript(url: string, cb: () => void): void {
  let script = document.querySelector(`script[src="${url}"]`);

  if (!script) {
    script = document.createElement('script');
    script.setAttribute('src', url);
    document.body.appendChild(script);

    if (cb) {
      script.addEventListener('load', cb);
    }
  } else {
    cb();
  }
}
