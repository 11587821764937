import { FC, useEffect, useRef } from 'react';
import {
  Stack,
  Box,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageBreadcrumbs from '../../../../blocks/PageBreadcrumbs/PageBreadcrumbs';
import { createSideMarkup } from '../../../../utils/functions';
import { ReactComponent as ArrowLeftIcon } from '../../../../assets/images/arrowLeft.svg';
import styles from './HelpNotLandingToc.module.scss';

interface HelpNotLandingTocProps {
  title: string;
  text: string;
  route: string;
}

const HelpNotLandingToc: FC<HelpNotLandingTocProps> = ({ title, text, route }) => {
  const { t: translate } = useTranslation('common');
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    queueMicrotask(() => {
      divRef.current?.querySelectorAll('div > p + div > img')
        .forEach((block) => {
          const parentElement = block.closest('div')?.parentElement;
          if (parentElement) {
            parentElement.classList.add(styles.notLandingTocImageWithP);
          }
        });

      divRef.current?.querySelectorAll('div > img + p')
        .forEach((block) => {
          if (block.parentElement) {
            block.parentElement.classList.add(styles.notLandingTocImageBlock);
            block.parentElement.classList.remove(styles.notLandingTocImageWithP);
          }
        });

      divRef.current?.querySelectorAll(':is(li > ul, li > ol)')
        .forEach((element) => {
          const parentElement = element.closest('li');
          if (parentElement) {
            parentElement.classList.add(styles.notLandingTocInnerList);
          }
        });

      divRef.current?.querySelectorAll('li > p')
        .forEach((element) => {
          const parentElement = element.closest('li');
          if (parentElement) {
            const countLine = parentElement.getBoundingClientRect().height
              / parseInt(window.getComputedStyle(parentElement).lineHeight, 10);
            if (countLine > 1) {
              parentElement.classList.add(styles.notLandingTocInnerList);
            }
          }
        });
    });
  }, [divRef.current]);

  const breadcrumbsLinks = [
    { title: translate('page.help'), to: '/help' },
    { title, to: route },
  ];

  return (
    <Stack>
      <PageBreadcrumbs
        links={breadcrumbsLinks}
      />
      <Stack direction="row">
        <Box className={styles.arrow}>
          <Link to={{ pathname: '/help' }} className={styles.arrowItem}>
            <ArrowLeftIcon />
            <Typography variant="subtitle2" color="primary.dark">
              {translate('layer.back')}
            </Typography>
          </Link>
        </Box>
        <div
          ref={divRef}
          className={styles.notLandingToc}
          dangerouslySetInnerHTML={createSideMarkup(text)}
        />
      </Stack>
    </Stack>
  );
};

export default HelpNotLandingToc;
