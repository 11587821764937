import { Box, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import MapBack from '../MapBack/MapBack';
import ProjectTitle from '../ProjectTitle/ProjectTitle';
import UserSection from '../UserSection';
import styles from './HeaderForProjects.module.scss';
import { HeaderForProjectProps } from './HeaderForProjectProps';
import { modalContext, ModalContextEnum } from '../../blocks/ModalProject/ModalProject';

function HeaderForProjects({ biProject }: HeaderForProjectProps) {
  const navigate = useNavigate();
  const openContext = useContext(modalContext);
  const { t: translate } = useTranslation('common');

  return (
    <Box className={styles.main}>
      <Box className={styles.firstContainer}>
        <Box display="flex" alignItems="center">
          <MapBack onClick={() => navigate('/account')} />
          <ProjectTitle />
        </Box>

        <Button
          className={styles.aboutProject}
          onClick={() => { openContext.setOpen(ModalContextEnum.ABOUT_PROJECT); }}
        >
          <Typography variant="subtitle2" color="secondary.main">
            { translate('project.about') }
          </Typography>
        </Button>

        {/* <Button variant="contained" className={styles.button}>
          {translate('account.download_report')}
        </Button> */}

      </Box>

      <Box className={styles.userInfo}>
        <UserSection projectOpen />
      </Box>

      {biProject
        && (
          <Box className={styles.addData}>
            <Button
              variant="text"
              endIcon={<AddIcon color="secondary" />}
              onClick={() => { openContext.setOpen(ModalContextEnum.PROJECT_DATA); }}
            >
              <Typography variant="subtitle1" color="secondary.main">
                { translate('dataset.attach_data') }
              </Typography>
            </Button>
          </Box>
        ) }
    </Box>
  );
}

export default HeaderForProjects;
