import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useObservable } from '@ngneat/react-rxjs';

import styles from './PageHeader.module.scss';
import Search from '../Search/Search';
import { createSideMarkup } from '../../utils/functions';
import {
  activePageText$, activePageTitle$, pagesRequestPending$, resetPageStore,
} from '../../state/pages/pages.repository';
import Loader from '../../components/Loader/Loader';
import useComponentWillMount from '../../hooks/useComponentWillMount';

interface SearchFormProps {
  handleInputChange?: ((value: string) => void) | undefined,
  placeholder?: string,
  name?: string,
}

function PageHeader({
  handleInputChange, placeholder, name,
}: SearchFormProps) {
  const [activeClass, setActiveClass] = useState('');
  const location = useLocation();
  const [pageTitle] = useObservable(activePageTitle$);
  const [pageText] = useObservable(activePageText$);
  const [pagesRequestPending] = useObservable(pagesRequestPending$, { initialValue: true });

  useComponentWillMount(() => resetPageStore());

  const renderPageHeader = () => location.pathname !== '/marketplace' && (
    <>
      <Box className={styles.box}>
        <Typography
          textAlign="center"
          className={styles.title}
          data-testid="pageTitle"
          variant="h3"
          sx={{ textTransform: 'none' }}
          dangerouslySetInnerHTML={createSideMarkup(pageTitle)}
        />
      </Box>
      <Box
        className={styles.searchSubtitle}
        textAlign="center"
        dangerouslySetInnerHTML={createSideMarkup(pageText)}
      />
    </>
  );

  useEffect(() => {
    if (location.pathname === '/account') {
      setActiveClass('account-search');
    } if (location.pathname === '/tariffs') {
      setActiveClass('tariffs-search');
    }
  }, []);

  return (
    <div className={styles[activeClass]}>
      {
        pagesRequestPending
          ? <Loader id="progress-page-header" />
          : renderPageHeader()
      }

      {handleInputChange !== undefined
        ? (
          <Search name={name} placeholder={placeholder} handleInputChange={handleInputChange} />
        )
        : null}

    </div>
  );
}

export default PageHeader;
