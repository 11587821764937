import {
  Box, Typography, Stack,
} from '@mui/material';
import { useObservable } from '@ngneat/react-rxjs';
import { activePageExtension$ } from '../../../../state/pages/pages.repository';
import { createSideMarkup } from '../../../../utils/functions';

const SideHome = () => {
  const [pageExtension] = useObservable(activePageExtension$);

  return (
    <Stack sx={{ paddingLeft: '30px' }} spacing={1}>
      <Box color="secondary">
        <Typography variant="subtitle2" color="secondary" sx={{ fontSize: '14px' }}>
          {pageExtension?.title}
        </Typography>
        <Typography variant="body2" color="secondary" mt={0.5} sx={{ fontSize: '14px' }}>
          {pageExtension?.subtitle}
        </Typography>
      </Box>
      <div
        className="cms-styles cms-side"
        dangerouslySetInnerHTML={createSideMarkup(pageExtension?.body)}
      />
    </Stack>
  );
};

export default SideHome;
