import { FC } from 'react';
import { Box } from '@mui/material';
import styles from './HorizontalCardLarge.module.scss';

interface CardProps {
  header?: React.ReactNode;
  title?: React.ReactNode;
  body?: React.ReactNode;
}

const HorizontalCardLarge: FC<CardProps> = ({ header, title, body }) => (
  <Box className={styles.cardWrapper}>
    <Box className={styles.cardHeader}>
      {header}
    </Box>

    <Box className={styles.cardTitle}>
      {title}
    </Box>

    <Box className={styles.cardBody}>
      {body}
    </Box>
  </Box>
);

export default HorizontalCardLarge;
