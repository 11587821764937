import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC, useCallback } from 'react';

import { useObservable } from '@ngneat/react-rxjs';
import styles from './AclViewsCommon.module.scss';
import { addUiItem, layersMap$, uiDataset$ } from '../../../state/project/project.repository';
import { FlatSchedule, LayerMapOrScheduleDb } from '../../ModalProjectTabs/hooks/models';

export interface ScheduleProps {
  title: string;
  uName: string;
  layerMap: LayerMapOrScheduleDb;
  addButton?: boolean;
}

interface ProductScheduleProps {
  flatLayersMap: FlatSchedule[];
  addButton?: boolean;
}

const Schedule:FC<ScheduleProps> = ({
  title, uName, layerMap, addButton,
}) => {
  const { t } = useTranslation('common');
  const [uiDataset] = useObservable(uiDataset$);
  const [layersMap] = useObservable(layersMap$);

  const handlerDisabledButton = (
    layersMap.some((l) => l.uName === uName)
    || (uiDataset && uiDataset.flat().some((d) => d.uName === uName))
  );

  const handleAddUiItem = useCallback(() => addUiItem(layerMap), []);

  const button = addButton
    ? (
      <Button
        variant="contained"
        onClick={handleAddUiItem}
        className={styles.button}
        disabled={handlerDisabledButton}
      >
        {t('acl.add')}
      </Button>
    )
    : null;

  return (
    <Box className={styles.scheduleContainer}>
      <Box className={styles.scheduleTextWrapper}>
        <Box>
          <Typography variant="body1">
            { title }
          </Typography>
          <Typography variant="body2" sx={{ opacity: '0.5' }}>
            { uName }
          </Typography>
        </Box>
      </Box>
      { button }
    </Box>
  );
};

const SchedulesList:FC<ProductScheduleProps> = ({ flatLayersMap, addButton }) => (
  <Box className={styles.bodyWrapper}>
    {flatLayersMap.map((flatLayersMap) => (
      <Schedule
        title={flatLayersMap.scheduleTitle}
        uName={flatLayersMap.scheduleUname}
        layerMap={flatLayersMap.layerMap}
        addButton={addButton}
      />
    ))}
  </Box>
);

export default SchedulesList;
