import { Button, Typography } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogProps {
  open: boolean;
  version: string;
  onClose: () => void;
  updateReady: boolean;
  updateCallback: () => void;
}

const VersionDialog = ({
  onClose,
  version,
  open,
  updateReady,
  updateCallback,
}: DialogProps) => (
  <BootstrapDialog onClose={onClose} open={open || updateReady}>
    <DialogTitle sx={{ m: 0, p: 2 }}>
      <Box sx={{ mr: '50px' }}>{version}</Box>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      {updateReady
        ? (
          <>
            <Typography sx={{ padding: '10px' }}>Доступно обновление</Typography>
            <Button onClick={updateCallback}>Обновить</Button>
          </>
        )
        : null}
    </DialogTitle>
  </BootstrapDialog>

);

export default VersionDialog;
