import { FormEvent, useContext, useState } from 'react';
import {
  Typography, Stack, Button, Select, MenuItem, SelectChangeEvent, Link, Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';

import FormInput from '../../blocks/FormInput/FormInput';
import styles from './signup-form.module.scss';
import { DrawerContentEnum, DrawerContext } from '../../drawerContext';

function SignUpForm() {
  const { t } = useTranslation('common');
  const drawerContext = useContext(DrawerContext);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    drawerContext.close();
  };

  const [tariff, setTariff] = useState('0');

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setTariff(event.target.value as string);
  };

  // eslint-disable-next-line
  const form =
  (
    <form onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <Typography variant="h4" color="#fff" sx={{ textTransform: 'none' }}>Регистрация</Typography>

        <FormInput placeholder={t('side.name')} />
        <FormInput placeholder={t('side.email')} />
        <FormInput placeholder={t('side.signUp')} />

        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={tariff}
          className={styles.select}
          onChange={handleChange}
          fullWidth
          displayEmpty
        >
          <MenuItem value="0">Пробный</MenuItem>
          <MenuItem value="1">Минимальный</MenuItem>
          <MenuItem value="2">Максимальный</MenuItem>
        </Select>

        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="body1" color="#fff">{t('side.not_registered_yet')}</Typography>
          <Button onClick={() => drawerContext.setContent(DrawerContentEnum.LOGIN)}>
            <Typography variant="body1" color="primary" sx={{ textTransform: 'none' }}>Войти в аккаунт</Typography>
          </Button>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={2} mt={8}>
        <LoadingButton
          type="submit"
          className="button"
          variant="contained"
          color="primary"
          datat-testid="signInConfirm"
        >
          {t('side.signUp')}
        </LoadingButton>
      </Stack>
    </form>
  );

  return (
    <Stack spacing={3}>
      <Box display="flex" sx={{ justifyContent: 'flex-end' }}>
        <Box
          display="flex"
          className="circle"
        >
          <CloseIcon
            onClick={() => drawerContext.setContent(DrawerContentEnum.LOGIN)}
            sx={{ color: '#fff' }}
          />
        </Box>
      </Box>
      <Typography variant="body1" color="#fff">
        {t('side.form_signup_not_yet')}
      </Typography>

      <Typography variant="body1" color="#fff">
        {t('side.signup_info')}
        <Link href="mailto:geo@platforma.id" variant="body1" color="#fff">
          geo@platforma.id
        </Link>
        .
      </Typography>
    </Stack>
  );
}

export default SignUpForm;
