import {
  Typography, Stack, Link,
} from '@mui/material';

import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import { useTranslation } from 'react-i18next';
import logoSkolkovo from '../../assets/images/skolkovo.png';
import styles from './Contacts.module.scss';

function Contacts() {
  const { t } = useTranslation('common');

  return (
    <Stack mt={4} p="30px" sx={{ opacity: 0.8 }}>
      <Stack direction="row" spacing={1}>
        <RoomOutlinedIcon fontSize="small" />
        <Typography color="secondary.dark" variant="subtitle2">
          {t('contacts.adress')}
        </Typography>
      </Stack>

      <Stack direction="row" spacing={1} mt={2}>
        <LanguageIcon fontSize="small" />
        <Link
          target="_blank"
          underline="none"
          href="https://platforma.id"
          color="secondary.dark"
          variant="subtitle2"
        >
          platforma.id
        </Link>
      </Stack>

      <Stack direction="row" spacing={1} mt={2}>
        <EmailOutlinedIcon fontSize="small" />
        <Link
          href="mailto:geo@platforma.id"
          underline="none"
          color="secondary.dark"
          variant="subtitle2"
          target="_blank"
        >
          geo@platforma.id
        </Link>
      </Stack>

      <Stack spacing={1} className={styles.imageWrap}>
        <img src={logoSkolkovo} alt="logo_skolkovo" className={styles.image} />
      </Stack>
    </Stack>
  );
}

export default Contacts;
