import {
  Slider, Stack, Box, Typography,
} from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GpLayerFormulaItem } from '../../api/types';
import { updateFormulaLayerItemArea, updateFormulaLayerItemWeight } from '../../state/project/project.actions';
import styles from './ResultingLayerSliders.module.scss';
import Histogram from '../../components/Histogram/Histogram';
import { getLayerDistribution } from '../../state/project/project.repository';

interface ResultingLayerSlidersProps {
  id: string;
  formula: GpLayerFormulaItem;
}

export default function ResultingLayerSliders({ id, formula }: ResultingLayerSlidersProps) {
  const [area, setArea] = React.useState<[number, number]>(
    [(200 - (formula.max ?? 160)) / 2, (200 - (formula.min ?? 40)) / 2],
  );
  const distribution = getLayerDistribution(id);
  const [weight, setWeight] = React.useState<number>(formula.weight ?? 50);
  const { t } = useTranslation('common');

  const handleAreaChangeCommited = useCallback(
    (value: [number, number]) => updateFormulaLayerItemArea(
      {
        id,
        area: [200 - value[1] * 2, 200 - value[0] * 2],
      },
    ),
    [],
  );
  const handleWeightChangeCommited = useCallback(
    (value: number) => updateFormulaLayerItemWeight({ id, weight: value }),
    [],
  );

  return (
    <Stack gap="14px">
      <Stack direction="row" className={styles.weight}>
        <Typography variant="title2" className={styles.label}>
          {t('layer.calc_weight')}
        </Typography>
        <Stack direction="row" alignItems="center" className={styles.slider}>
          <Box width="120px">
            <Slider
              data-testid="sliderWeight"
              size="small"
              value={weight}
              min={1}
              max={100}
              onChange={(_, value) => setWeight(value as number)}
              onChangeCommitted={(_, value) => handleWeightChangeCommited(value as number)}
            />
          </Box>
          <Typography variant="title2" data-testid="weightValue" className={styles.sliderCount}>
            {weight}
          </Typography>
        </Stack>
      </Stack>
      <Stack gap="8px">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="title2" className={styles.label}>
            {t('layer.area_values')}
          </Typography>
          <Typography variant="title2" data-testid="areaValue">
            {area[0]}
            {' '}
            -
            {' '}
            {area[1]}
          </Typography>
        </Stack>
        <Box className={styles.container}>
          <Box>
            { distribution && (
            <Histogram
              dataset={distribution}
              chartHeight={27}
              areaMax={area[1] * 2}
              areaMin={area[0] * 2}
              isInFormula
            />
            ) }
          </Box>
          <Slider
            data-testid="sliderArea"
            size="small"
            min={0}
            max={100}
            step={0.5}
            getAriaLabel={() => 'range'}
            value={area}
            onChange={(_, value) => setArea(value as [number, number])}
            onChangeCommitted={(_, value) => handleAreaChangeCommited(value as [number, number])}
            className={styles.sliderRange}
          />
        </Box>
      </Stack>
    </Stack>
  );
}
