/* eslint-disable import/prefer-default-export */
import * as api from '../../services/geo-api.service';
import { setRecipesRequestError } from './recipes.repository';
import { GpLayer } from '../../api/types';
import { RECIPE_MARKET_LAYERS_PAGE_SIZE } from '../../utils/constants';

const calculatePagesCount = (
  totalCount: number,
  pageSize: number,
): number => (((totalCount >= pageSize) && ((totalCount % pageSize) === 0))
  ? Math.floor(totalCount / pageSize)
  : (Math.floor(totalCount / pageSize) + 1));

export const createNumbersArray = (
  length: number,
  startValue: number,
) => Array.from({ length }, (_, i) => i + startValue);

export async function fetchLayersForRecipe(
  uNameKeys: string[] = [],
): Promise<GpLayer[]> {
  if (uNameKeys.length > 0) {
    const res = await api.fetchLayers(uNameKeys);

    if (res.errors?.length) {
      setRecipesRequestError(res.errors[0]);
    }

    const { totalCount, data } = res.data.GPLayer.list;

    const totalPages = calculatePagesCount(totalCount, RECIPE_MARKET_LAYERS_PAGE_SIZE);

    if (totalPages > 1) {
      const promiseCount = createNumbersArray(totalPages - 1, 1);
      const res = await Promise.all(
        [...promiseCount.map(
          () => api.fetchLayers(uNameKeys),
        )],
      );

      const flattenDocuments = (res.map((data) => data.data.GPLayer.list.data)).flat();

      return [...data, ...flattenDocuments] as GpLayer[];
    }
    return data as GpLayer[];
  }
  return [];
}
