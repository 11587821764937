import { Box } from '@mui/material';

import { FC, useEffect } from 'react';

import LeftBlock from './LeftBlock';
import CentralBlock from './CentralBlock';

import { GpRubric } from '../../../api/types';
import styles from '../RubricsModal.module.scss';
import useRubricsBody from '../hooks/useRubricsBody';

interface RubricsBodyProps {
  rubrics: GpRubric[],
  tempRubrics: GpRubric[],
  selectedRubric: GpRubric | null,
  leafRubrics: GpRubric[],
  handleRubricGroupSelect: (active: boolean, rubric: GpRubric) => void,
  handleRubricFilterChange: (active: boolean, rubric: GpRubric) => void,
  onRender: () => void,
}

const RubricsBody: FC<RubricsBodyProps> = ({
  rubrics,
  tempRubrics,
  selectedRubric,
  leafRubrics,
  handleRubricFilterChange,
  handleRubricGroupSelect,
  onRender,
}) => {
  useRubricsBody();
  useEffect(() => {
    onRender();
  }, []);

  return (
    <Box className={styles.rubricsBodyWrapper}>
      <Box className={styles.scrollWrapperLeft}>
        <Box className={styles.rubricsBodyLeftBlock}>
          <LeftBlock
            rubrics={rubrics}
            tempRubrics={tempRubrics}
            selectedRubric={selectedRubric}
            handleRubricGroupSelect={handleRubricGroupSelect}
            handleRubricFilterChange={handleRubricFilterChange}
          />
        </Box>
      </Box>
      <Box className={styles.scrollWrapper}>
        <Box className={styles.rubricsBodyCentralBlock}>
          <CentralBlock
            selectedRubric={selectedRubric}
            leafRubrics={leafRubrics}
            tempRubrics={tempRubrics}
            handleRubricFilterChange={handleRubricFilterChange}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default RubricsBody;
