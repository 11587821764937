import {
  Box, Divider, Popover, Stack, Typography,
} from '@mui/material';
import { useObservable } from '@ngneat/react-rxjs';
import { LeafletMouseEvent, Map as MapOriginal } from 'leaflet';
import {
  memo, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Multipuncture,
} from '../../types/leaflet';
import pin from '../../assets/images/pin.svg';

import './MapPopup.scss';
import styles from './MapPopup.module.scss';
import PunctureControl from '../../utils/puncture-control';
import { PopoverContent, setMultipunctureAsync } from '../../feature/MapPage/actions/MapPage.actions';
import Histogram from '../Histogram/Histogram';
import { activeFormula$, getLayerDistribution, isLayerInFormula } from '../../state/project/project.repository';
import { Feature } from '../../api/types';

interface MapPopupProps {
  mpunctureControl: PunctureControl | null;
  multipuncture: Multipuncture | null;
  map: MapOriginal | null;
}

const LEAFLET_CONTROL_CLASSES = ['leaflet-bar', 'leaflet-control', 'measurement',
  'leaflet-right', 'leaflet-pm-toolbar', 'button-container'];

function MapPopup({ multipuncture, mpunctureControl, map }: MapPopupProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [popoverPosition, setPopoverPosition] = useState<[number, number]>([0, 0]);
  const [popoverContent, setPopoverContent] = useState<PopoverContent[]>([]);
  const [geoFeatures, setGeoFeatures] = useState<Feature[]>([]);
  const [loadingFeatures, setLoadingFeatures] = useState<boolean>(false);
  const [activeFormula] = useObservable(activeFormula$);
  const { t } = useTranslation('common');
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onMapClick = (evt: LeafletMouseEvent) => {
    setLoadingFeatures(true);
    if (
      Array.from((evt.originalEvent.target as HTMLElement).classList)
        .some((className) => LEAFLET_CONTROL_CLASSES.includes(className))
    ) {
      return;
    }

    const layersObj: { [key: string]: any } = {};

    void setMultipunctureAsync({
      layersObj,
      map,
      setPopoverContent,
      multipuncture,
      evt,
      setGeoFeatures,
      setLoadingFeatures,
    });

    setPopoverPosition([evt.originalEvent.clientY, evt.originalEvent.clientX]);
    setAnchorEl(evt.target as HTMLButtonElement);
  };

  useEffect(() => {
    if (mpunctureControl) {
      document.querySelector('.multipuncture-icon')?.addEventListener('click', () => {
        if (mpunctureControl?.isActive) {
          map?.on('click', onMapClick);
        } else {
          map?.removeEventListener('click', onMapClick);
        }
      });
    }
  }, [mpunctureControl]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const renderContentPopover = (
    popoverContent: { title: string, res: string, id: string, uName: string }[],
  ) => popoverContent.map((content) => {
    const distribution = getLayerDistribution(content.id);
    const isInFormula = isLayerInFormula(content.id);
    const geoFeatureValue = geoFeatures?.find((gf) => gf.layerId === content.uName)?.properties.val;
    const layer = activeFormula?.L.find((layer) => layer.id === content.id);
    const area = [
      (200 - (layer?.max ?? 160)) / 2,
      (200 - (layer?.min ?? 40)) / 2,
    ];
    return (
      <Stack key={content.id} gap="10px" className={styles.popupContent}>
        <Stack spacing={1} direction="row" justifyContent="space-between">
          <Typography variant="body2" color="secondary">{content.title}</Typography>
          <Stack alignItems="flex-end">
            <Typography variant="body2" color="primary.main" fontWeight={700}>
              { loadingFeatures || !geoFeatureValue ? <span>&mdash;</span> : geoFeatureValue }
            </Typography>
            <Typography variant="body2" color="secondary">{content.res}</Typography>
          </Stack>
        </Stack>
        {distribution && (
        <Histogram
          chartHeight={30}
          dataset={distribution}
          areaMin={area[0] * 2}
          areaMax={area[1] * 2}
          resultValue={Math.round(Number(content.res) * 2)}
          isUnderline
          isInFormula={isInFormula}
        />
        ) }
      </Stack>
    );
  });

  return (
    <div className="inner">
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: popoverPosition[0] - 35, left: popoverPosition[1] + 22 }}
        className={styles.popup}
      >
        <Box sx={{ position: 'relative' }}>
          <Box className={styles.popupContainer}>
            <Typography variant="h6" color="secondary">{t('map.calc_impact')}</Typography>
            <Stack
              mt={1}
              spacing={1}
              divider={<Divider sx={{ backgroundColor: 'secondary' }} flexItem />}
              gap="10px"
            >
              { renderContentPopover(popoverContent) }
            </Stack>
          </Box>
        </Box>
      </Popover>

      <Popover
        id="poper"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: popoverPosition[0] - 32, left: popoverPosition[1] - 14 }}
        sx={{ backgroundColor: 'transparent' }}
      >
        <Box sx={{ position: 'relative' }}>
          <img src={pin} alt="" style={{ width: '28px' }} />
        </Box>
      </Popover>
    </div>
  );
}

export default memo(MapPopup);
