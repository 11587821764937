import { FC, useContext } from 'react';
import {
  Box, Collapse, Divider, Stack,
} from '@mui/material';
import { useObservable } from '@ngneat/react-rxjs';
import { activeFormula$ } from '../../../../../state/project/project.repository';
import LayerAtResulting from '../LayerAtResultingCard/LayerAtResultingCard';
import CalculationSettings from '../CalculationSettings/CalculationSettings';
import styles from './FormulaSettings.module.scss';
import { GpuiLayer } from '../../../../../api/types';
import LayerSliders from '../../../../../components/LayerSliders';
import layerListContext from '../../LayerListContext';

interface FormulaSettingsProps {
  index: number;
  open: boolean;
  layer: GpuiLayer;
}

const FormulaSettings:FC<FormulaSettingsProps> = ({ index, open, layer }) => {
  const [activeFormula] = useObservable(activeFormula$);
  const layersContext = useContext(layerListContext);

  return (
    <Collapse in={open}>
      <Stack direction="row">
        <Box width="63px" />
        <Stack flex={1} pr="20px" pb="20px">
          <LayerSliders
            layerId={layer.id}
            opacity={layer.o}
            grey={layer.g}
            hue={layer.h}
          />
          <Divider className={styles.divider} />
          <Box marginTop="10px">
            <CalculationSettings />
          </Box>
        </Stack>
      </Stack>
      <Stack>
        {activeFormula?.L.map((layer) => (
          <LayerAtResulting
            key={layer.id}
            index={index}
            formulaLayer={layer}
            layerIndex={
              layersContext.layers.findIndex((listedLayer) => listedLayer.id === layer.id)
            }
          />
        ))}
      </Stack>
    </Collapse>
  );
};

export default FormulaSettings;
