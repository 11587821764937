import { useObservable } from '@ngneat/react-rxjs';
import { memo, useEffect, useState } from 'react';
import { GpTerritories } from '../../../api/types';
import { activeRecipeTerritory$, recipeTerritory$ } from '../../../state/geoData/recipes.repository';
import TerritoriesAutoComplete from './TerritoriesAutoComplete';
import { setActiveRecipeTerritoryAsync } from '../../../state/geoData/recipes.effects';

const TerritoriesAutocompleteWrapper = () => {
  const [territories] = useObservable(recipeTerritory$);
  const [autocompleteValue, setAutocompleteValue] = useState<GpTerritories | null>(null);
  const [activeTerritory] = useObservable(activeRecipeTerritory$);
  useEffect(() => {
    if (autocompleteValue && !activeTerritory) {
      void setActiveRecipeTerritoryAsync(autocompleteValue.id);
    }
  }, [autocompleteValue?.id !== activeTerritory?.id]);

  useEffect(() => {
    if (activeTerritory) {
      setAutocompleteValue(activeTerritory);
      void setActiveRecipeTerritoryAsync(activeTerritory.id);
    }
  }, [activeTerritory]);

  return (
    <TerritoriesAutoComplete
      value={autocompleteValue}
      setValue={setAutocompleteValue}
      cities={territories || []}
    />
  );
};

export default memo(TerritoriesAutocompleteWrapper);
