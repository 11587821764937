import { Box, Stack, Typography } from '@mui/material';
import { useObservable } from '@ngneat/react-rxjs';
import { DateTime } from 'luxon';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LayerAvailabilityEnum,
  scheduleWithLayer$,
} from '../../../state/geoData/recipes.repository';
import styles from './RecipeLayerList.module.scss';

const RecipeLayerList = () => {
  const [schedulesWithLayers] = useObservable(scheduleWithLayer$);
  const { t } = useTranslation('common');

  return (
    <Box className={styles.wrapper}>
      <Typography variant="h4" className={styles.titleWrapper}>
        {t('layer.layers')}
        :
        {' '}
      </Typography>

      <Stack mt={2} spacing={1.5}>
        {schedulesWithLayers.map((schedulesWithLayer, i) => (
          <Stack key={schedulesWithLayer.id}>
            <Typography
              variant="h6"
              className={schedulesWithLayer.availability === LayerAvailabilityEnum.Unavailable
                ? styles.layerTitleUnavailable
                : styles.layerTitle}
            >
              {String(i + 1)}
              {'. '}
              {schedulesWithLayer.layerOrDataset.title}
            </Typography>

            <Stack
              className={
                schedulesWithLayer.availability === LayerAvailabilityEnum.Unavailable
                  ? styles.layerDescriptionWrapperUnavailable
                  : styles.layerDescriptionWrapper
              }
            >
              <Typography variant="body1" color="side.main" align="left">
                {
                  schedulesWithLayer.schedule
                    && 'layer' in schedulesWithLayer.schedule
                    && schedulesWithLayer.schedule?.layer.uName
                }
                {
                  schedulesWithLayer.schedule
                    && 'dataset' in schedulesWithLayer.schedule
                    && schedulesWithLayer.schedule?.dataset.uName
                }
              </Typography>
              <Typography variant="body2" color="side.main" align="left">
                {schedulesWithLayer.schedule?.releaseDate
                  ? DateTime.fromMillis(schedulesWithLayer.schedule.releaseDate)
                    .toLocaleString({
                      day: '2-digit',
                      month: 'long',
                      year: 'numeric',
                    })
                  : t('layer.update_date_absent')}
              </Typography>
              <Typography variant="subtitle1" color="primary.main" align="left">
                {
                  schedulesWithLayer.availability === LayerAvailabilityEnum.Available
                  && (schedulesWithLayer.schedule?.price
                    ? `${schedulesWithLayer.schedule.price} ₽`
                    : t('layer.price_absent'))
                }
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

export default memo(RecipeLayerList);
