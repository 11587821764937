import { useState } from 'react';
import { useObservable } from '@ngneat/react-rxjs';
import LayersBody from './LayersBody/LayersBody';
import LayersHeader from './LayersHeader/LayersHeader';
import useLayers from './hooks/useLayers';
import Loader from '../../../../components/Loader/Loader';
import { aclRequestStatusPending$, layerDatasetSchedulesPending$ } from '../../../../state/acl/acl.repository';

const Layers = () => {
  const [view, setView] = useState('first');
  const {
    page,
    isLastPage,
    totalCountLoaded,
    flatAclFirstView,
    flatAclOtherView,
    setInputSearch,
    handlePageChange,
    fetchLastPage,
  } = useLayers();

  const [layerDatasetSchedulesPending] = useObservable(
    layerDatasetSchedulesPending$,
    { initialValue: false },
  );
  const [aclRequestStatusPending] = useObservable(
    aclRequestStatusPending$,
    { initialValue: true },
  );

  const onLoad = layerDatasetSchedulesPending || aclRequestStatusPending;

  const handleViewChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setView(e.target.value);
  };

  return (
    <>
      <LayersHeader
        view={view}
        handleViewChange={handleViewChange}
        handleSearchInput={setInputSearch}
      />
      { onLoad
        ? <Loader id="progress-acl-view" />
        : (
          <LayersBody
            view={view}
            page={page}
            isLastPage={isLastPage}
            totalCountLoaded={totalCountLoaded}
            flatAclFirstView={flatAclFirstView}
            flatAclOtherView={flatAclOtherView}
            handlePageClick={handlePageChange}
            handleLastClick={fetchLastPage}
          />
        ) }
    </>
  );
};

export default Layers;
