import { useTranslation } from 'react-i18next';
import {
  Box, Stack, Typography,
} from '@mui/material';
import { ReactComponent as LocationIcon } from '../../assets/images/location.svg';
import { ReactComponent as CalendarIcon } from '../../assets/images/calendar.svg';
import { ReactComponent as BriefCaseIcon } from '../../assets/images/briefcase.svg';
import { ReactComponent as ToolIcon } from '../../assets/images/tool.svg';
import { ProductUnion } from '../../api/types';
import { ProductType } from '../../models/product';
import styles from './Product.module.scss';

import { getDocumentsUrlPrefix } from '../../state/context/auth.repository';

const ProductInfo = (
  { product }: { product: ProductUnion },
) => {
  const { t } = useTranslation('common');
  return (
    <Stack direction="column" gap="30px">
      <Stack direction="row" gap="15px" alignItems="center">
        <LocationIcon width="32px" height="32px" />
        <Box>
          <Typography variant="body2" color="text.disabled">
            {t('layer.territory')}
          </Typography>
          <Typography
            variant="title2"
            color="primary.dark"
            textTransform="capitalize"
          >
            { ('territory' in product) ? product.territory?.name : '' }
          </Typography>
        </Box>
      </Stack>
      { product.__typename !== ProductType.CommercialService && (
        <Stack direction="row" gap="15px" alignItems="center">
          <CalendarIcon width="32px" height="32px" />
          <Box>
            <Typography variant="body2" color="text.disabled">
              {t('layer.update_period')}
            </Typography>
            <Typography
              variant="title2"
              color="primary.dark"
              textTransform="capitalize"
              data-testid="updatingType"
            >
              { 'updatePeriod' in product && product?.updatePeriod?.title }
            </Typography>
          </Box>
        </Stack>
      )}
      <Stack direction="row" gap="15px" alignItems="center">
        <BriefCaseIcon style={{ maxWidth: '32px', width: '100%' }} />
        <Box>
          <Typography variant="body2" color="text.disabled">
            {t('layer.supplier')}
          </Typography>
          <Typography
            variant="title2"
            color="primary.dark"
            textTransform="capitalize"
          >
            { ('supplier' in product && product.supplier) || '' }
          </Typography>
        </Box>
      </Stack>
      { ('passport' in product) && product.passport && (
      <Stack direction="row" gap="15px" alignItems="center">
        <ToolIcon width="32px" height="32px" />
        <Box>
          <Typography variant="body2" color="text.disabled">
            {t('layer.passport')}
          </Typography>
          <a
            target="_blank"
            rel="noreferrer"
            className={styles.link}
            href={`${getDocumentsUrlPrefix()}${product.passport}`}
          >
            <Typography
              variant="title2"
              color="primary.dark"
              textTransform="uppercase"
            >
              {t('layer.download')}
            </Typography>
          </a>
        </Box>
      </Stack>
      )}
    </Stack>
  );
};

export default ProductInfo;
