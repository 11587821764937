// eslint-disable-next-line import/prefer-default-export
export const customTranslation = {
  tooltips: {
    placeMarker: 'Нажмите, чтобы нанести маркер',
    firstVertex: 'Нажмите, чтобы нанести первый объект',
    continueLine: 'Нажмите, чтобы продолжить рисование',
    finishLine: 'Нажмите любой существующий маркер для завершения',
    finishPoly: 'Выберите первую точку, чтобы закончить',
    finishRect: 'Нажмите, чтобы закончить',
    startCircle: 'Нажмите, чтобы добавить центр круга',
    finishCircle: 'Нажмите, чтобы задать радиус',
    placeCircleMarker: 'Нажмите, чтобы нанести круговой маркер',
  },
  actions: {
    finish: 'Завершить',
    cancel: 'Отменить',
    removeLastVertex: 'Отменить последнее действие',
  },
  buttonTitles: {
    drawMarkerButton: 'Маркер',
    drawPolyButton: 'Полигон',
    drawLineButton: 'Кривая',
    drawCircleButton: 'Эллипс',
    drawRectButton: 'Прямоугольник',
    editButton: 'Редактировать',
    dragButton: 'Передвинуть',
    cutButton: 'Вырезать',
    deleteButton: 'Удалить',
    drawCircleMarkerButton: 'Круговой маркер',
    snappingButton: 'Привязать перетаскиваемый маркер к другим слоям и вершинам',
    pinningButton: 'Связать общие точки вместе',
  },
};
