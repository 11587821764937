import { FC } from 'react';
import { Box } from '@mui/material';
import styles from './VerticalCard.module.scss';

interface CardProps {
  header?: React.ReactNode,
  title?: React.ReactNode,
  body?: React.ReactNode,
  footer?: React.ReactNode,
}

const Card: FC<CardProps> = ({
  header, title, body, footer,
}) => (
  <Box className={styles.cardWrapper}>
    { header && <Box className={styles.header}>{ header }</Box>}
    { title && <Box className={styles.title}>{ title }</Box>}
    { body && <Box className={styles.body}>{ body }</Box>}
    { footer && <Box className={styles.footer}>{ footer }</Box>}
  </Box>
);

export default Card;
