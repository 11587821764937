import {
  Box, FormGroup,
} from '@mui/material';
import RubricFilter from './RubricFilter/RubricFilter';
import SingleRubricFilter from './RubricFilter/SingleRubricFilter';

import { GpRubric } from '../../../api/types';
import { getLeafRubrics, getNonChildRubrics, getSingleRubrics } from '../functions/RubricsModalHelper';
import styles from '../RubricsModal.module.scss';

interface LeftBlockProps {
  rubrics: GpRubric[];
  tempRubrics: GpRubric[];
  selectedRubric: GpRubric | null;
  handleRubricGroupSelect: (active: boolean, rubric: GpRubric) => void;
  handleRubricFilterChange: (active: boolean, rubric: GpRubric) => void;
}

function LeftBlock({
  rubrics,
  tempRubrics,
  selectedRubric,
  handleRubricGroupSelect,
  handleRubricFilterChange,
}: LeftBlockProps) {
  return (
    <FormGroup className={styles.rubricsBodyFormGroup}>
      <Box>
        {
          getNonChildRubrics(rubrics)
            .map((filter) => (
              getSingleRubrics(rubrics).includes(filter)
                ? (
                  <SingleRubricFilter
                    filter={filter}
                    active={tempRubrics.includes(filter)}
                    onChange={handleRubricFilterChange}
                  />
                )
                : (
                  <RubricFilter
                    filter={filter}
                    key={filter.id}
                    active={selectedRubric === filter}
                    onChange={handleRubricGroupSelect}
                    onChangeChild={handleRubricFilterChange}
                    rubrics={rubrics}
                    leafRubrics={getLeafRubrics(rubrics)}
                    activeRubrics={tempRubrics}
                  />
                )
            ))
        }
      </Box>
    </FormGroup>
  );
}

export default LeftBlock;
