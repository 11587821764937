import {
  Box, TableContainer, Typography, Table, TableBody, TableCell, TableRow, Checkbox, Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useObservable } from '@ngneat/react-rxjs';
import { useTranslation } from 'react-i18next';
import {
  deactivateActiveItemFromOrder,
  removeActiveItemFromOrder,
  removeItemFromActiveOrder,
  toggleAllOrderSchedules,
  toggleOrderItem,
  allLayerSchedulesActive$,
} from '../../state/order';
import { GpOrderItem } from '../../api/types';
import styles from './ScheduleTable.module.scss';

function ScheduleTable({ items, discount }: {
  items: GpOrderItem[],
  discount: number | null,
}) {
  const { t } = useTranslation('common');

  const [allLayerSchedulesActive] = useObservable(allLayerSchedulesActive$);

  const handleToggle = (orderItemId: string) => toggleOrderItem(orderItemId);
  const handleToggleAll = () => toggleAllOrderSchedules();
  const handleRemoveActive = () => removeActiveItemFromOrder();
  const handleDeactivateActive = () => deactivateActiveItemFromOrder();

  const renderRow = (row: GpOrderItem) => {
    const productPackaging = row.productPackaging[0];
    const productCategory = row.productCategory[0];
    const { uName, id } = 'layer' in productPackaging ? productPackaging.layer : productPackaging.dataset;

    return (
      <TableRow key={ uName } sx={{ verticalAlign: 'top' }} data-testid={`orderItem:${id}`}>
        <TableCell align="left" sx={{ padding: '15px 0' }}>
          <Checkbox
            onClick={() => handleToggle(uName)}
            sx={{ color: '#fff', padding: '0' }}
            checked={row.selFlag}
          />
        </TableCell>

        <TableCell align="left" sx={{ padding: '15px 0' }}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="subtitle1" sx={{ color: '#fff', mb: '10px' }}>{ productCategory.title }</Typography>
            <CloseIcon
              sx={{ color: '#fff', cursor: 'pointer' }}
              onClick={() => removeItemFromActiveOrder(id)}
            />
          </Box>

          <Typography variant="body2" sx={{ color: '#fff', mb: '10px' }}>
            { 'territory' in productCategory ? productCategory.territory.name : ''}
          </Typography>
          <Typography variant="body2" sx={{ color: '#fff', mb: '5px' }}>{uName}</Typography>
          <Typography variant="subtitle1" sx={{ color: 'primary.main' }}>
            { discount
              ? (row.productPackaging[0].price * (100 - discount)) / 100
              : row.productPackaging[0].price }
            ₽
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <TableContainer sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Table data-testid="orderItemsTable" sx={{ maxWidth: '400px' }}>
        <TableBody>
          <TableRow>
            <TableCell align="left" sx={{ padding: '0' }}>
              <Checkbox
                data-testid="generalCheckBox"
                onClick={handleToggleAll}
                className={styles.checkbox}
                checked={allLayerSchedulesActive}
                sx={{ color: '#fff', p: '0' }}
              />
            </TableCell>

            <TableCell align="left" sx={{ padding: '0' }}>
              <Box display="flex" justifyContent="space-between">
                <Button
                  data-testid="deactivateButton"
                  sx={{ padding: '0' }}
                  onClick={handleDeactivateActive}
                >
                  <Typography variant="body2" sx={{ '&:hover': { color: 'primary.main' }, color: '#fff' }}>
                    {t('cart.cansel')}
                  </Typography>
                </Button>
                <Button
                  data-testid="removeActiveButton"
                  sx={{ padding: '0' }}
                  onClick={handleRemoveActive}
                >
                  <Typography variant="body2" sx={{ '&:hover': { color: 'primary.main' }, color: '#fff' }}>
                    {t('cart.delete_choosen')}
                  </Typography>
                </Button>
              </Box>
            </TableCell>

          </TableRow>

        </TableBody>
      </Table>
      <Box sx={{ flexGrow: 1, overflowY: 'auto', flexBasis: '50px' }}>
        <Table>
          <TableBody>
            {items
              .filter((row) => row.productCategory.length > 0 && row.productPackaging.length > 0)
              .map((row) => renderRow(row))}
          </TableBody>
        </Table>
      </Box>
    </TableContainer>
  );
}

export default ScheduleTable;
