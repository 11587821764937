import { getRegistry } from '@ngneat/elf';
import jwt_decode from 'jwt-decode';
import { GpCustomerProfile, GpCustomerProfileIn, GpUser } from '../../api/types';
import { EnvironmentConfig } from '../../models/common';
// eslint-disable-next-line import/no-cycle
import {
  getBasicIdConfig, getCustomerProfiles, getUser, updateCustomerProfile,
} from '../../services/geo-api.service';
import { getGuestTokenRefreshTiming } from '../../superset-sdk/guestTokenRefresh';
import { GUEST_PASSWORD, GUEST_USERNAME } from '../../utils/constants';
import { tryGetResponseData } from '../common';
import { getTariff } from '../geoData/tariffs.repository';
// eslint-disable-next-line import/no-cycle
import {
  getAuthProps, reallyResetAuthWithPersist, setActiveAccountId, setAuthProps,
  setProfiles, updateAuth, updateTariff,
} from './auth.repository';

interface TokenResponse {
  token: string;
  refreshToken: string;
  error?: string;
}

export async function fetchGlobalCfg() {
  let basicConfigId = null;
  let paymentRedirectURL = null;
  let paykeeperFormUrl = null;
  let demoProjectId = null;
  let emptyProjectId = null;
  let emptyBiProjectId = null;
  let supersetUri = null;
  let imagesUrlPrefix = null;
  let documentsUrlPrefix = null;

  try {
    const res = await fetch('/static/config/global.json')
      .then((res) => res.json() as Promise<EnvironmentConfig>);
    basicConfigId = res.cmsConfigId;
    paymentRedirectURL = res.paymentRedirectURL;
    paykeeperFormUrl = res.paykeeperFormUrl;
    supersetUri = res.biUrl;
    imagesUrlPrefix = res.imagesUrlPrefix;
    documentsUrlPrefix = res.documentsUrlPrefix;
    if (basicConfigId) {
      const res = await getBasicIdConfig(basicConfigId);
      const basicConfigData = tryGetResponseData(res).Config.get.config;
      demoProjectId = basicConfigData.demo_project_id;
      emptyProjectId = basicConfigData.empty_project_id;
      emptyBiProjectId = basicConfigData.bi_empty_project_id;
    }
    setAuthProps({
      imagesUrlPrefix,
      documentsUrlPrefix,
      demoProjectId,
      emptyProjectId,
      emptyBiProjectId,
      biUri: supersetUri,
      paymentGatewayUrl: paykeeperFormUrl,
      paymentRedirectUrl: paymentRedirectURL,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('failed fetching global config', error);
  }
}

export async function auth(username: string, password: string): Promise<TokenResponse> {
  const authRequest = { username, password, rememberMe: true };

  try {
    const response = await fetch('/api/cms/authentication', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(authRequest),
    });

    if (response.ok) {
      const tokenResponse = await response.json();
      return {
        token: tokenResponse.token as string,
        refreshToken: tokenResponse.refreshToken as string,
        error: tokenResponse.error as string,
      };
    }

    throw new Error(response.statusText);
  } catch (error: any) {
    return {
      token: '',
      refreshToken: '',
      error,
    };
  }
}

export const logout = async () => {
  getRegistry().forEach((store) => {
    store.reset();
  });
  reallyResetAuthWithPersist();
  // eslint-disable-next-line no-console
  // await apolloClientResetStore().catch((e) => console.warn('[ unfinished queries stopped ]', e));
};

export async function updateCustomerProfileAsync(id: string, data: GpCustomerProfileIn) {
  const res = await updateCustomerProfile(id, data);
  const tariffId = tryGetResponseData(res).GPCustomerProfile.update.tariffPlan.id;

  const tariff = getTariff(tariffId);
  if (!tariff) return;

  updateTariff(id, tariff);
}

export async function getTokenWithRefreshToken(refreshToken?: string, profileId?: string) {
  try {
    const { refreshToken: actualRefreshToken, activeId: actualProfileId } = getAuthProps();
    if (refreshToken && actualRefreshToken !== refreshToken) return;

    const response = await fetch('/api/cms/auth/refreshToken', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refreshToken: actualRefreshToken, 'profile-id': profileId || actualProfileId }),
    });

    const tokenResponse: TokenResponse = await response.json();
    const { token, refreshToken: newRefreshToken } = tokenResponse;
    const parsedJwt = jwt_decode<Record<string, any>>(token);
    const profileIdFromToken = parsedJwt['profile-id'];
    const usernameFromToken = parsedJwt.preferred_username;

    setAuthProps({
      token: `Bearer ${token}`,
      refreshToken: newRefreshToken,
      usernameFromToken,
    });
    setActiveAccountId(profileIdFromToken as string);
    setTimeout(() => {
      void getTokenWithRefreshToken(newRefreshToken);
    }, getGuestTokenRefreshTiming(token) * 0.9);
  } catch {
    void logout();
  }
}

export function getProfileIdFromToken() {
  const { token } = getAuthProps();
  const parsedJwt = jwt_decode<Record<string, any>>(token!);
  const profileIdFromToken = parsedJwt['profile-id'] as string;
  return profileIdFromToken;
}

export async function setActiveProfileId(activeId?: string) {
  const res = await getCustomerProfiles();
  const profiles = tryGetResponseData(res).GPCustomerProfile.list.data as GpCustomerProfile[];
  setProfiles(profiles);
  await fetchGlobalCfg();
  if (profiles.length) {
    const profileId = activeId ?? profiles[0].id;
    await getTokenWithRefreshToken('', profileId);
  }
}

export async function getUserData(activeId?: string): Promise<string> {
  const { token } = getAuthProps();
  if (!token) return 'user_not_found';
  try {
    const res = await getUser();
    const userList = tryGetResponseData(res).GPUser.list.data;

    if (userList.length >= 1) {
      let user = userList[0] as GpUser;
      const response = await fetch('/api/cms/private/me', {
        headers: {
          auth: token,
        },
      }).then((res) => res.json());
      user = { ...user, user: response };
      updateAuth(user);

      await fetchGlobalCfg();
      if (!activeId) await setActiveProfileId(activeId);
      return '';
    }

    return 'user_not_found';
  } catch {
    return 'user_not_found';
  }
}

export async function authenticateUser(email: string, password: string, fromSignInForm?: boolean):
Promise<string> {
  const isGuest = email === GUEST_USERNAME && password === GUEST_PASSWORD;

  let tokenResponse = await auth(email, password);

  if (tokenResponse.error) {
    if (!isGuest || fromSignInForm) {
      return 'credentials';
    }

    const { activeId } = getAuthProps();
    await getTokenWithRefreshToken('', activeId as string);

    tokenResponse = await auth(email, password);

    if (tokenResponse.error) {
      // eslint-disable-next-line no-console
      console.error('Could not auth as guest');
      return '';
    }
  }

  setAuthProps({
    token: `Bearer ${tokenResponse.token}`,
    refreshToken: tokenResponse.refreshToken,
  });
  if (!fromSignInForm) await getUserData();
  return '';
}

export async function initUserAndSetUserMode() {
  const { token, activeId } = getAuthProps();
  await (token
    ? getUserData(activeId as string)
    : authenticateUser(GUEST_USERNAME, GUEST_PASSWORD));
}
