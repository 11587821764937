import {
  Button, styled, Typography,
  Stack,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const ButtonStyled = styled(Button)(({ theme }) => ({
  '&': {
    width: 'fit-content',
    height: '40px',
    textTransform: 'none',
    borderRadius: '20px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
}));

interface ProjectPriceProps {
  to: string;
  buttonLabel: string;
}

function ProjectPrice({ to, buttonLabel }: ProjectPriceProps) {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack>
        <Typography variant="body2" color="secondary" sx={{ opacity: 0.8 }}>Стоимость</Typography>
        <Typography variant="h5" color="secondary">32 650 ₽</Typography>
        <Typography variant="description" color="secondary" sx={{ opacity: 0.5 }}>3 265 м²  ·  2 слоя</Typography>
      </Stack>

      <Link to={to}>
        <ButtonStyled>
          {buttonLabel}
        </ButtonStyled>
      </Link>
    </Stack>
  );
}

export default ProjectPrice;
