import { FC } from 'react';
import {
  Box,
  Button,
  List,
  ListItem,
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import styles from './LocalPagination.module.scss';

interface LocalPaginationProps {
  page: number;
  isLastPage: boolean;
  disableToLastPage: boolean;
  handlePageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  handleLastClick: (event: React.ChangeEvent<unknown>) => void;
}

const LocalPagination:FC<LocalPaginationProps> = ({
  page,
  isLastPage,
  disableToLastPage,
  handlePageChange,
  handleLastClick,
}) => {
  const renderFirstButton = (
    <ListItem className={styles.paginationListItem}>
      <Button
        type="button"
        disabled={page === 1}
        className={styles.paginationButton}
        onClick={(event) => handlePageChange(event, 1)}
      >
        <FirstPageIcon className={styles.paginationButtonIcon} fontSize="small" />
      </Button>
    </ListItem>
  );

  const renderPreviousButton = (
    <ListItem className={styles.paginationListItem}>
      <Button
        type="button"
        disabled={page === 1}
        className={styles.paginationButton}
        onClick={(event) => handlePageChange(event, page - 1)}
      >
        <KeyboardArrowLeftIcon className={styles.paginationButtonIcon} fontSize="small" />
      </Button>
    </ListItem>
  );

  const renderNextButton = (
    <ListItem className={styles.paginationListItem}>
      <Button
        type="button"
        disabled={isLastPage}
        className={styles.paginationButton}
        onClick={(event) => handlePageChange(event, page + 1)}
      >
        <KeyboardArrowRightIcon className={styles.paginationButtonIcon} fontSize="small" />
      </Button>
    </ListItem>
  );

  const renderLastButton = (
    <ListItem className={styles.paginationListItem}>
      <Button
        type="button"
        disabled={disableToLastPage || isLastPage}
        className={styles.paginationButton}
        onClick={handleLastClick}
      >
        <LastPageIcon className={styles.paginationButtonIcon} fontSize="small" />
      </Button>
    </ListItem>
  );

  const renderDotsElement = (isPrevious: boolean) => {
    if ((isPrevious && page > 1) || (!isLastPage && !isPrevious)) {
      return (
        <ListItem className={styles.paginationListItem}>
          <Box className={styles.paginationThereMore}>...</Box>
        </ListItem>
      );
    }
    return null;
  };

  const renderPageButton = (pageNumber: number) => {
    const className = (pageNumber === page)
      ? styles.paginationButtonSelected
      : styles.paginationButton;

    if (pageNumber > 0 && (!isLastPage || pageNumber <= page)) {
      return (
        <ListItem className={styles.paginationListItem}>
          <Button
            type="button"
            className={className}
            onClick={(event) => handlePageChange(event, pageNumber)}
          >
            {pageNumber}
          </Button>
        </ListItem>
      );
    }
    return null;
  };

  return (
    <Box>
      <List className={styles.paginationListWrapper}>
        {renderFirstButton}
        {renderPreviousButton}
        {renderDotsElement(true)}
        {renderPageButton(page - 1)}
        {renderPageButton(page)}
        {renderPageButton(page + 1)}
        {renderDotsElement(false)}
        {renderNextButton}
        {renderLastButton}
      </List>
    </Box>
  );
};

export default LocalPagination;
