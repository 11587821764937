import {
  Box,
  Typography,
} from '@mui/material';

import styles from '../CardBody.module.scss';
import 'react-image-gallery/styles/css/image-gallery.css';
import { GpdsSchedule, GpSchedule } from '../../../../../api/types';

export interface ScheduleProps {
  schedule: GpSchedule | GpdsSchedule;
  index: number;
}

const Schedule: React.FC<ScheduleProps> = ({ schedule, index }: ScheduleProps) => (
  <Box className={styles.scheduleContainer}>
    <Box display="flex" gap="5px">
      <Typography variant="H3card">
        { index + 1 }
        .
      </Typography>
      <Box>
        <Typography variant="H3card">
          { schedule.title }
        </Typography>
        <Box>
          <Typography variant="descriptionCard" color="text.disabled">
            { 'layer' in schedule ? schedule.layer?.uName : schedule.dataset?.uName }
          </Typography>
        </Box>
      </Box>
    </Box>
    <Box>
      <Typography variant="priceCard" color="primary.main" sx={{ whiteSpace: 'nowrap' }}>
        { schedule.price
          ? `${schedule.price} ₽`
          : null}
      </Typography>
    </Box>
  </Box>
);

export default Schedule;
