import { useState, createContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useObservable } from '@ngneat/react-rxjs';

import {
  Box, Typography, Tab, Modal,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { TabContext, TabList } from '@mui/lab';
import { ProjectType } from '../../models/project';
import ProjectAboutTab from '../../components/ModalProjectTabs/ProjectAboutTab/ProjectAboutTab';
// eslint-disable-next-line import/no-cycle
import ProjectDataTab from '../../components/ModalProjectTabs/ProjectDataTab/ProjectDataTab';

import { activeProject$, declineChanges } from '../../state/project/project.repository';
import { removeSpecialChar } from '../../utils/functions';

import styles from './ModalProject.module.scss';
import { TabPanel } from '../../components/TabPanel/TabPanel';

export enum ModalContextEnum {
  PROJECT_DATA = 'ProjectData',
  ABOUT_PROJECT = 'ProjectAbout',
  FALSE = '',
}

export const modalContext = createContext<{
  setOpen(s: ModalContextEnum): void,
}>({ setOpen: () => {} });

function ModalProjectWrapper({ children }: { children: JSX.Element }) {
  const { t } = useTranslation('common');
  const [activeProject] = useObservable(activeProject$);
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(ModalContextEnum.PROJECT_DATA);

  const handleClose = () => {
    declineChanges();
    setOpen(false);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleChange = (event: React.SyntheticEvent, tab: ModalContextEnum) => {
    setTab(tab);
  };

  const value = useMemo(
    () => ({
      setOpen: (s: ModalContextEnum) => {
        setOpen(!!s.length);
        setTab(s);
      },
    }),
    [],
  );

  return (
    <modalContext.Provider value={value}>
      { children }
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box className={ styles.container }>
          <TabContext value={tab}>
            <Box className={ styles.containerHeader }>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h6" alignSelf="center">
                  {removeSpecialChar(activeProject?.project[0].title ?? t('project.first'))}
                </Typography>
                <CloseIcon className={styles.closeIcon} onClick={() => { handleClose(); }} />
              </Box>
              <TabList className={styles.tabsContainer} value={tab} aria-label="Tabs" onChange={handleChange}>
                <Tab
                  className={styles.tab}
                  label={(
                    <Typography variant="h4" fontSize="1.6rem">
                      {activeProject && t(
                        activeProject.projectType.model === ProjectType.GPBIProject
                          ? 'project.bi_data'
                          : 'project.geo_data',
                      )}
                    </Typography>
                  )}
                  value={ModalContextEnum.PROJECT_DATA}
                />
                <Tab
                  className={styles.tab}
                  label={<Typography variant="h4" fontSize="1.6rem">{t('project.about')}</Typography>}
                  value={ModalContextEnum.ABOUT_PROJECT}
                />
              </TabList>
            </Box>
            <TabPanel value={ModalContextEnum.PROJECT_DATA} className={styles.tabPanel}>
              <ProjectDataTab onCloseModal={handleCloseModal} />
            </TabPanel>
            <TabPanel value={ModalContextEnum.ABOUT_PROJECT} className={styles.tabPanel}>
              <ProjectAboutTab />
            </TabPanel>
          </TabContext>
        </Box>
      </Modal>
    </modalContext.Provider>
  );
}

export default ModalProjectWrapper;
