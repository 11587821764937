import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { removeUiItem } from '../../../state/project/project.repository';
import styles from './LayersList.module.scss';
import { FlatLayersListItem } from '../hooks/models';

interface LayersListProps {
  layers: FlatLayersListItem[];
}

const layer = ({
  layerMapId,
  layerTitle,
  scheduleTitle,
  uName,
  territoryName,
}: FlatLayersListItem, index: number) => (
  <Box className={styles.listItemWrapper} key={layerMapId}>
    <Box display="flex">
      <Typography fontWeight="500" variant="body1" key={index}>
        {`${index + 1}.`}
      </Typography>

      <Box className={styles.listItemText}>
        <Typography fontWeight="500" variant="body1">
          {layerTitle}
        </Typography>
        <Typography className={styles.listItemTextUname}>
          {scheduleTitle}
        </Typography>
        <Typography className={styles.listItemTextUname}>
          {uName}
        </Typography>
        <Typography className={styles.listItemTextUname}>
          {territoryName}
        </Typography>
      </Box>

    </Box>
    <CloseIcon onClick={() => removeUiItem(layerMapId)} className={styles.iconClose} />
  </Box>
);

function LayersList({ layers }: LayersListProps) {
  return layers.length ? (
    <Box className={styles.listWrapper}>
      {layers.map((item, index) => layer(item, index))}
    </Box>
  ) : null;
}

export default LayersList;
