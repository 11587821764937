import { FC, useContext } from 'react';

import {
  Box,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { GpOrderItem } from '../../../../../../api/types';

import { DrawerContentEnum, DrawerContext } from '../../../../../../drawerContext';
import { sendMetricReachGoal } from '../../../../../../utils/functions';

import styles from './MiniCartCard.module.scss';
import ActionButton from './ActionButton';

interface MiniCartCardProps {
  items: GpOrderItem[],
}

const MiniCartCard: FC<MiniCartCardProps> = ({ items }) => {
  const { t } = useTranslation('common');
  const drawerContext = useContext(DrawerContext);
  const openCart = () => {
    drawerContext.open(DrawerContentEnum.CART);
    sendMetricReachGoal('cart');
  };

  const renderOrderItems = (orderItems: GpOrderItem[]) => [...orderItems].splice(0, 3)
    .map((item, index) => (
      <Stack className={styles.cardRow} key={item.id} direction="row">
        <Typography variant="body2">{ `${index + 1}.` }</Typography>
        <Stack gap="4px">

          <Typography maxWidth="150px" variant="H3card" color="secondary.main">
            { item.productCategory[0].title }
          </Typography>

          <Typography className={styles.cardSecondaryText} variant="textCard">
            { item.productPackaging[0].title }
          </Typography>

          { 'dataset' in item.productPackaging[0]
            && (
            <Typography className={styles.cardSecondaryText} variant="textCard">
              { item.productPackaging[0].dataset.uName }
            </Typography>
            )}

          { 'layer' in item.productPackaging[0]
            && (
            <Typography className={styles.cardSecondaryText} variant="textCard">
              { item.productPackaging[0].layer.uName }
            </Typography>
            )}

          { 'territory' in item.productCategory[0]
            && (
            <Typography className={styles.cardSecondaryText} variant="textCard">
              { item.productCategory[0]?.territory.name }
            </Typography>
            )}

        </Stack>
        <Typography variant="subtitle1">
          { `${item.productPackaging[0].price} ₽` }
        </Typography>
      </Stack>
    ));

  const buttonDisabled = !(items?.length > 0);

  return (
    <Box className={styles.card}>

      <Box className={styles.cardHeader}>
        <Typography variant="subtitle1">{ t('order.in_cart') }</Typography>
      </Box>

      <Box className={styles.cardContent}>
        <Typography variant="H2card" color="secondary.main">
          { `${t('order.cart_positions')} (${items?.length || 0}):` }
        </Typography>
        {items && renderOrderItems(items) }
      </Box>

      <Box className={styles.cardActions}>
        <ActionButton
          className={styles.button}
          data-testid="order-cart-open"
          onClick={openCart}
          dark
          disabled={buttonDisabled}
        >
          <Typography variant="cartCard" color="secondary.main" sx={{ opacity: '0.5' }}>
            { t('order.open_cart') }
          </Typography>
        </ActionButton>
      </Box>

    </Box>
  );
};

export default MiniCartCard;
