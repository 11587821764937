import { getRegistry, getStore } from '@ngneat/elf';
import { getActiveIds, setActiveIds } from '@ngneat/elf-entities';
import {
  GpPageConfig, GpProductTypes, GpProjectTypes, GpRubric, GpTerritories, GpUpdatePeriod,
} from '../../api/types';
import * as api from '../../services/geo-api.service';
import { tryGetResponseData } from '../common';
import * as filtersRepo from './filters.repository';
import { MARKETPLACE_FILTERS_SIZE_COUNT } from '../../utils/constants';

async function fetchTerritories(search?: string): Promise<void> {
  const changeSearchIn = filtersRepo.getTerritoriesSearchInput() !== (search || '');
  if (!filtersRepo.getTerritoriesHasMore() && !changeSearchIn) {
    return;
  }

  const offset = changeSearchIn
    ? 0
    : filtersRepo.getTerritoriesCount();
  const limit = !search && filtersRepo.getTerritoriesFetchedCount()
    ? filtersRepo.getTerritoriesFetchedCount()
    : MARKETPLACE_FILTERS_SIZE_COUNT;

  const res = await api.getTerritoryList(limit, offset, search);
  const territories = tryGetResponseData(res).GPTerritories.list.data as GpTerritories[];
  // eslint-disable-next-line prefer-destructuring
  const hasMore = tryGetResponseData(res).GPTerritories.list.hasMore;
  filtersRepo.setTerritoriesHasMore(hasMore);
  if (changeSearchIn) {
    filtersRepo.setTerritoriesSearchInput(search || '');
    filtersRepo.setTerritories(territories);
  } else {
    if (filtersRepo.getTerritoriesSearchInput() === '') {
      filtersRepo.addTerritoriesFetchedCount(territories.length);
    }
    filtersRepo.addTerritories(territories);
  }
}

async function fetchRubrics(): Promise<void> {
  if (filtersRepo.hasRubrics()) {
    return;
  }

  const res = await api.getRubricList();
  const rubrics = tryGetResponseData(res).GPRubric.list.data as GpRubric[];
  filtersRepo.setRubrics(rubrics);
}

async function fetchUpdatePeriods(search?: string): Promise<void> {
  const changeSearchIn = filtersRepo.getUpdatePeriodSearchInput() !== (search || '');
  if (!filtersRepo.getUpdatePeriodHasMore() && !changeSearchIn) {
    return;
  }

  const offset = changeSearchIn
    ? 0
    : filtersRepo.getUpdatePeriodCount();
  const limit = !search && filtersRepo.getUpdatePeriodFetchedCount()
    ? filtersRepo.getUpdatePeriodFetchedCount()
    : MARKETPLACE_FILTERS_SIZE_COUNT;

  const res = await api.getUpdatePeriodList(limit, offset, search);
  const updatePeriod = tryGetResponseData(res).GPUpdatePeriod.list.data as GpUpdatePeriod[];
  // eslint-disable-next-line prefer-destructuring
  const hasMore = tryGetResponseData(res).GPUpdatePeriod.list.hasMore;
  filtersRepo.setUpdatePeriodHasMore(hasMore);
  if (changeSearchIn) {
    filtersRepo.setUpdatePeriodSearchInput(search || '');
    filtersRepo.setUpdatePeriods(updatePeriod);
  } else {
    if (filtersRepo.getUpdatePeriodSearchInput() === '') {
      filtersRepo.addUpdatePeriodFetchedCount(updatePeriod.length);
    }
    filtersRepo.addUpdatePeriod(updatePeriod);
  }
}

export async function fetchProductTypes(search?: string): Promise<void> {
  const changeSearchIn = filtersRepo.getProductTypesSearchInput() !== (search || '');
  if (!filtersRepo.getProductTypesHasMore() && !changeSearchIn) {
    return;
  }

  const offset = changeSearchIn
    ? 0
    : filtersRepo.getProductTypesCount();
  const limit = !search && filtersRepo.getProductTypesFetchedCount()
    ? filtersRepo.getProductTypesFetchedCount()
    : MARKETPLACE_FILTERS_SIZE_COUNT;

  const res = await api.getProductTypesList(limit, offset, search);
  const productTypes = tryGetResponseData(res).GPProductTypes.list.data as GpProductTypes[];
  // eslint-disable-next-line prefer-destructuring
  const hasMore = tryGetResponseData(res).GPProductTypes.list.hasMore;
  filtersRepo.setProductTypesHasMore(hasMore);
  if (changeSearchIn) {
    filtersRepo.setProductTypesSearchInput(search || '');
    filtersRepo.setProductTypes(productTypes);
  } else {
    if (filtersRepo.getProductTypesSearchInput() === '') {
      filtersRepo.addProductTypesFetchedCount(productTypes.length);
    }
    filtersRepo.addProductTypes(productTypes);
  }
}

export async function fetchProjectTypes(): Promise<void> {
  if (filtersRepo.hasProjectTypes()) {
    return;
  }

  const res = await api.getProjectTypesList();
  const projectTypes = tryGetResponseData(res).GPProjectTypes.list.data as GpProjectTypes[];
  filtersRepo.setProjectTypes(projectTypes);
}

export async function fetchFilters(target?: string, search?: string): Promise<void> {
  switch (target) {
    case 'territories':
      await fetchTerritories(search);
      break;
    case 'productType':
      await fetchProductTypes(search);
      break;
    case 'updatePeriod':
      await fetchUpdatePeriods(search);
      break;
    default:
      await fetchTerritories();
      await fetchRubrics();
      await fetchUpdatePeriods();
      await fetchProductTypes();
  }
}

export async function updatePageConfig(configId?: string | undefined) {
  const configIn: Partial<GpPageConfig> = {
    id: configId,
    uri: 'marketplace',
    config: {},
  };
  let hasFilters = false;

  Array.from(getRegistry().entries()).forEach(([name, store]) => {
    if (!name.startsWith(filtersRepo.CONFIG_STORE_PREFIX)) {
      return;
    }
    const activeIds = store.query(getActiveIds) as string[];
    if (!activeIds) {
      return;
    }
    const key = name.slice(filtersRepo.CONFIG_STORE_PREFIX.length);
    configIn.config![key] = activeIds;
    hasFilters ||= !!activeIds.length;
  });

  return hasFilters || configIn.id
    ? api.updatePageConfig(configIn)
    : null;
}

export async function loadPageConfig(configId: string): Promise<void> {
  const res = await api.getPageConfig(configId);
  const pageConfig = tryGetResponseData(res).GPPageConfig.get;
  if (!pageConfig?.config) {
    throw Error('Page config is undefined');
  }

  const config = new Map<string, string[]>(Object.entries(pageConfig.config));
  config.forEach((value, key) => {
    const store = getStore(`${filtersRepo.CONFIG_STORE_PREFIX}${key}`);
    if (store) {
      store.update(setActiveIds(value));
    }
  });
}
