import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useObservable } from '@ngneat/react-rxjs';
import {
  Stack,
  Typography,
} from '@mui/material';
import {
  activeProjectTypesIds$, addActiveProjectType, projectTypes$, removeActiveProjectType,
} from '../../state/filters/filters.repository';
import { projectsTotalCount$, totalCoutProjectsRequestPending$ } from '../../state/project/project.repository';
import FilterGroup from '../FilterGroup/FilterGroup';
import { IFilter } from '../../models/filters';
import { sendMetricReachGoal } from '../../utils/functions';
import styles from './ProjectFilters.module.scss';
import TotalCount from '../../components/TotalCount/TotalCount';

const ProjectFilters = () => {
  const { t: translate } = useTranslation('common');
  const [projectTypes] = useObservable(projectTypes$);
  const [projectTotalCount] = useObservable(projectsTotalCount$);
  const [totalCoutProjectsRequestPending] = useObservable(totalCoutProjectsRequestPending$, { initialValue: true });
  const [activeProjectTypesIds] = useObservable(activeProjectTypesIds$);
  const [open, setOpen] = useState(false);

  const handleProjectTypesClick = () => {
    setOpen(!open);
    sendMetricReachGoal('projectTypeFilter');
  };

  const handleProjectTypesChange = (active: boolean, id: string) => {
    if (active) {
      addActiveProjectType(id);
    } else {
      removeActiveProjectType(id);
    }
  };

  return (
    <Stack className={styles.filters}>
      <Stack direction="row" gap="10px">
        <Typography variant="h4">{ translate('account.filters') }</Typography>
        <TotalCount pending={totalCoutProjectsRequestPending} totalCount={projectTotalCount} />
      </Stack>

      <FilterGroup
        title={translate('account.project_type')}
        open={open}
        onClick={handleProjectTypesClick}
        onFilterChange={handleProjectTypesChange}
        dataTestId="projectTypesFilter"
        filters={
          projectTypes.map<IFilter>((type) => ({
            id: type.id,
            title: type.name,
          }))
        }
        filterType="territories"
        activeFiltersIds={activeProjectTypesIds}
      />
    </Stack>
  );
};

export default ProjectFilters;
