import { Stack, Typography } from '@mui/material';
import { useObservable } from '@ngneat/react-rxjs';

import { useTranslation } from 'react-i18next';
import { activeAccount$, getImagesUrlPrefix, user$ } from '../../state/context/auth.repository';
import styles from './UserInfo.module.scss';

function UserInfo({ onUserClick }: { onUserClick: () => void }) {
  const { t } = useTranslation('common');
  const [user] = useObservable(user$);
  const [account] = useObservable(activeAccount$);

  return (user && account
    ? (
      <Stack
        data-testid="userInfo"
        spacing={1.5}
        direction="row"
        justifyContent="flex-end"
        onClick={() => onUserClick()}
        sx={{ cursor: 'pointer' }}
      >

        <Stack>
          <Typography variant="body2" color="#fff" align="right">{ user?.name }</Typography>
          <Typography variant="subtitle3" color="#fff" align="right" sx={{ opacity: '0.8' }}>
            {account.gpOrganization?.name}
          </Typography>
          <Typography variant="subtitle3" color="primary">{`${t('side.tariff')} ${account?.tariffPlan.title}`}</Typography>
        </Stack>

        {user && (
          <div data-testid="userAvatar" className={styles['image-wrap']}>
            <img
              className={styles.image}
              src={user.avatar ? `${getImagesUrlPrefix()}${user.avatar}` : '/profile-image.png'}
              alt="user"
            />
          </div>
        )}
      </Stack>
    ) : (null)
  );
}

export default UserInfo;
