import { FC } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { OrderCardProps } from './OrderCardProps';
import { GpOrderItem } from '../../../../../../api/types';
import styles from './OrderCard.module.scss';
import './statuses.scss';
import { DEFAULT_DATE_TIME } from '../../../../../../utils/constants';

const OrderCard: FC<OrderCardProps> = ({
  orderId,
  createDate,
  paymentDate,
  orderItems,
  status,
}) => {
  const { t: translate } = useTranslation('common');
  const navigate = useNavigate();

  const handleCardClick = (id: string) => {
    void navigate(generatePath('/account/orders/:orderId', { orderId: id }));
  };
  const totalPrice = orderItems?.reduce((total, item) => {
    // eslint-disable-next-line no-param-reassign
    total += Number(item.productPackaging[0].price);
    return total;
  }, 0);

  const renderOrderItems = (orderItems: GpOrderItem[]) => [...orderItems].splice(0, 3)
    .map((item, index) => (
      <Stack direction="row" className={styles.orderCardItem} key={item.id}>
        <Box display="flex">
          <Typography variant="H3card">{ `${index + 1}.` }</Typography>
          <Stack gap="4px">
            <Typography maxWidth="150px" variant="H3card">
              { item.productCategory[0].title }
            </Typography>

            <Typography className={styles.orderItem} variant="textCard">
              { item.productPackaging[0].title }
            </Typography>

            { 'dataset' in item.productPackaging[0]
              && (
              <Typography className={styles.orderItem} variant="textCard">
                { item.productPackaging[0].dataset.uName }
              </Typography>
              )}

            { 'layer' in item.productPackaging[0]
              && (
              <Typography className={styles.orderItem} variant="textCard">
                { item.productPackaging[0].layer.uName }
              </Typography>
              )}

            { 'territory' in item.productCategory[0]
              && (
              <Typography className={styles.orderItem} variant="textCard">
                { item.productCategory[0]?.territory.name }
              </Typography>
              )}
          </Stack>
        </Box>
        <Typography variant="H2card" color="primary.main" className={styles.orderCardPrice}>
          { `${item.productPackaging[0].price} ₽` }
        </Typography>
      </Stack>
    ));

  return (
    <Stack className={styles.orderCard} onClick={() => { handleCardClick(orderId); }}>
      <Typography variant="H1card">
        { translate('order.self', {
          number: orderId,
          date: DateTime.fromMillis(createDate).setLocale('ru-Ru').toFormat('dd MMMM yyyy'),
        }) }
      </Typography>

      <Stack direction="row" justifyContent="space-between" className={styles.orderCardInfo}>
        <Stack gap="5px">
          <Typography className={styles.orderCardStatus} variant="textCard">
            { `${translate('order.status')}:` }
          </Typography>
          <Typography className={styles.orderCardStatus} variant="textCard">
            { `${translate('order.date')}:` }
          </Typography>
        </Stack>
        <Stack gap="5px">
          <Typography variant="statusCard" className={`ORDER_${status}`}>
            { translate(`order.status_${status.toLowerCase()}`) }
          </Typography>
          <Typography className={styles.orderCardStatus} variant="textCard">
            { paymentDate === DEFAULT_DATE_TIME
              ? '-'
              : DateTime.fromMillis(paymentDate).setLocale('ru-Ru').toFormat('dd MMMM yyyy, HH:mm:ss')}
          </Typography>
        </Stack>
      </Stack>

      <Stack className={styles.orderCardOrders}>
        <Typography variant="H2card">
          { `${translate('order.positions')} (${orderItems?.length || 0}):` }
        </Typography>
        {orderItems && renderOrderItems(orderItems) }
      </Stack>

      <Stack direction="row" justifyContent="space-between">
        <Typography variant="H2card">{ translate('order.total') }</Typography>
        <Typography variant="H2card" color="primary.main">{ `${totalPrice || 0}₽` }</Typography>
      </Stack>
    </Stack>
  );
};

export default OrderCard;
