import {
  createStore, select, withProps, setProp,
} from '@ngneat/elf';
import {
  getAllEntities, selectAllEntities, setEntities, withEntities,
} from '@ngneat/elf-entities';
import {
  withRequestsStatus,
  selectRequestStatus,
  updateRequestStatus,
  selectIsRequestPending,
  StatusState,
} from '@ngneat/elf-requests';
import { map } from 'rxjs';
import { AclObjectUnion, GpdsScheduleDb, GpLayerMap } from '../../api/types';
import { TotalCountProps } from '../../types/shared';

const aclStore = createStore(
  { name: 'acl' },
  withEntities<AclObjectUnion>(),
  withRequestsStatus<'acl'>(),
  withRequestsStatus<'layerDatasetSchedules'>(),
  withProps<TotalCountProps>({ totalCount: 0 }),
);

export const acl$ = aclStore.pipe(selectAllEntities());
export const layersOrDatasetsCount$ = aclStore.pipe(select((state) => state.totalCount));

export const aclRequestStatus$ = aclStore.pipe(selectRequestStatus('acl'));
export const aclRequestStatusPending$ = aclStore.pipe(selectIsRequestPending('acl'));
export const layerDatasetSchedulesPending$ = aclStore.pipe(selectIsRequestPending('layerDatasetSchedules'));
export const layerDatasetSchedulesStatus$ = aclStore.pipe(selectRequestStatus('layerDatasetSchedules'));

export const layerDatasetSchedulesSuccess$ = aclStore.pipe(
  selectRequestStatus('layerDatasetSchedules'),
  select((state) => state.value === 'success'),
);

export const setAclRequestStatus = (status: Exclude<StatusState['value'], 'error'>) => {
  aclStore.update(
    updateRequestStatus('acl', status),
  );
};

export const setLayerDatasetSchedulesStatus = (status: Exclude<StatusState['value'], 'error'>) => {
  aclStore.update(
    updateRequestStatus('layerDatasetSchedules', status),
  );
};

export const availableLayers$ = acl$.pipe(
  map((acl) => (acl.filter((x) => x.__typename === 'GPLayerMap')) as GpLayerMap[]),
);

export const availableDataset$ = acl$.pipe(
  map((acl) => (acl.filter((x) => x.__typename === 'GPDSScheduleDB')) as GpdsScheduleDb[]),
);

export const availableLayersOrDataSet$ = acl$.pipe(
  map((acl) => (
    acl.filter((x) => x.__typename === 'GPLayerMap' || x.__typename === 'GPDSScheduleDB')
  ) as [GpLayerMap | GpdsScheduleDb]),
);
export const availableObjectsUNames$ = availableLayersOrDataSet$.pipe(
  map((objects) => new Set(objects.map((x) => x.uName))),
);

export const setAcl = (acl: AclObjectUnion[]) => aclStore.update(
  setEntities(acl),
);
export const getAvailableLayers = () => aclStore
  .query(getAllEntities()).filter((x) => x.__typename === 'GPLayerMap') as GpLayerMap[];

export const getAllAcl = () => aclStore.query(getAllEntities()) as GpdsScheduleDb[] | GpLayerMap[];

export const getAllAclLayersOrDatasets = () => aclStore.query(getAllEntities())
  .filter(
    (acl) => acl.__typename === 'GPLayerMap' || acl.__typename === 'GPDSScheduleDB',
  ) as GpdsScheduleDb[] | GpLayerMap[];

export const resetAclStore = () => aclStore.reset();
export const setlayersOrDatasetsCount = (totalCount: number) => aclStore.update(
  setProp('totalCount', totalCount),
);
