import { FC, useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import styles from './Histogram.module.scss';

interface HistogramProps {
  dataset: number[];
  chartHeight: number;
  areaMin: number;
  areaMax: number;
  resultValue?: number;
  isUnderline?: boolean;
  axisXLabel?: number[];
  isInFormula?: boolean;
}

const Histogram: FC<HistogramProps> = ({
  dataset,
  chartHeight,
  areaMin,
  areaMax,
  resultValue,
  isUnderline,
  axisXLabel,
  isInFormula,
}) => {
  const scale = useMemo<number>(() => {
    const maxValue = Math.max(...dataset);
    return chartHeight / maxValue;
  }, []);

  const generateClasses = (index: number):string => {
    const classes: string[] = [styles.histogramItem];
    if (index <= areaMin - 1 || index >= areaMax || !isInFormula) {
      classes.push(styles.histogramItemGray);
      if (index === resultValue) {
        classes.push(styles.histogramItemResult);
      }
      return classes.join(' ');
    }
    if (index === resultValue) {
      if (isUnderline) {
        classes.push(styles.histogramItemUnderline);
      }
      classes.push(styles.histogramItemResult);
      return classes.join(' ');
    }
    if (isUnderline) {
      classes.push(styles.histogramItemUnderline);
    }
    classes.push(styles.histogramItemOrange);
    return classes.join(' ');
  };

  return (
    <Stack gap="5px">
      <Stack direction="row" height={`${chartHeight}px`} className={styles.histogram}>
        {dataset.map((value, index) => (
          <Box
            height={`${value * scale}px`}
            className={generateClasses(index)}
          />
        ))}
      </Stack>
      {axisXLabel
      && (
        <Stack direction="row" className={styles.axis}>
          {axisXLabel.map((number) => (
            <Box flex={1} className={styles.axisItem}>
              <Typography variant="description" className={styles.axisText}>
                {number}
              </Typography>
            </Box>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default Histogram;
