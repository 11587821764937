import { useObservable } from '@ngneat/react-rxjs';
import { useContext, useState } from 'react';
import { Box } from '@mui/material';
import { activeAccount$ } from '../../state/context/auth.repository';
import UserInfo from '../UserInfo/UserInfo';
import UserMenu from '../UserMenu/UserMenu';
import { orderLayerSchedules$, resetOrderStore, ordersRequestStatus$ } from '../../state/order';
import { DrawerContentEnum, DrawerContext } from '../../drawerContext';
import { sendMetricReachGoal } from '../../utils/functions';
import { getTokenWithRefreshToken } from '../../state/context/auth.actions';
import { resetAclStore } from '../../state/acl/acl.repository';
import CartIcon from './CartIcon';

interface UserSectionProps {
  onLogout?: () => void;
  projectOpen: boolean;
}

const UserSection = ({ onLogout, projectOpen }: UserSectionProps) => {
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const [account] = useObservable(activeAccount$);
  const drawerContext = useContext(DrawerContext);
  const [orderSchedules] = useObservable(orderLayerSchedules$);
  const [ordersRequestStatus] = useObservable(ordersRequestStatus$);
  const [orderItems] = useObservable(orderLayerSchedules$);

  const handleAccountChange = async (event: React.MouseEvent<HTMLElement>, orgValue: string) => {
    resetOrderStore();
    resetAclStore();
    event.stopPropagation();
    await getTokenWithRefreshToken('', orgValue);
  };

  const openCart = () => {
    drawerContext.open(DrawerContentEnum.CART);
    sendMetricReachGoal('cart');
  };

  return (account
    ? (
      <div>
        {
          userMenuOpened ? (
            <>
              <UserInfo onUserClick={() => setUserMenuOpened(!userMenuOpened)} />
              <UserMenu
                onMenuClick={() => setUserMenuOpened(!userMenuOpened)}
                activeOrganization={account.id}
                handleAccountChange={handleAccountChange}
                onLogout={onLogout}
                projectOpen={projectOpen}
              />
            </>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'end', gap: '10px' }}>
              <UserInfo onUserClick={() => setUserMenuOpened(!userMenuOpened)} />
              <Box position="relative">
                {!projectOpen
                  ? (
                    <CartIcon
                      onLoad={ordersRequestStatus.value === 'idle'}
                      disabled={!orderSchedules.length}
                      count={orderItems.length}
                      onClick={openCart}
                    />
                  )
                  : ''}
              </Box>
            </Box>
          )
        }
      </div>
    ) : (null)
  );
};

export default UserSection;
