import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { useObservable } from '@ngneat/react-rxjs';
import { setActivePage } from '../../state/pages/pages.actions';

import styles from './Main.module.scss';
import MapPageWrapper from '../../pages/MapPageWrapper/MapPageWrapper';
import HeaderLayout from '../Header/HeaderLayout';
import GuestGuard from '../../feature/Guards/GuestGuard/GuestGuard';
import { activeAccountId$ } from '../../state/context/auth.repository';

function Main() {
  const location = useLocation();
  const [activeAccountId] = useObservable(activeAccountId$);
  const inProject = location.pathname.includes('/map') || location.pathname.includes('/bi');

  useEffect(() => {
    if (activeAccountId) {
      void setActivePage(location.pathname);
    }
  }, [location.pathname, activeAccountId]);

  return (
    <Box sx={{ backgroundColor: 'primary.dark' }} className={!inProject ? styles.main : styles.mainInProject }>
      <Box
        sx={{ backgroundColor: 'secondary.light' }}
        className={ !inProject ? styles.mainInner : styles.mainInProjectInner }
      >
        <div className={styles.container} id="container">
          <Routes>
            <Route
              path="/map/:projectId"
              element={
                (
                  <GuestGuard>
                    <MapPageWrapper type="map" />
                  </GuestGuard>
                )
              }
            />

            <Route
              path="/bi/:projectId"
              element={
                (
                  <GuestGuard>
                    <MapPageWrapper type="bi" />
                  </GuestGuard>
                )
              }
            />

            <Route
              path="*"
              element={(
                <Box
                  p="12px 40px"
                  sx={{ position: 'relative', height: '100%', backgroundColor: 'secondary.light' }}
                >
                  <HeaderLayout />
                </Box>
              )}
            />
          </Routes>
        </div>
      </Box>

    </Box>
  );
}

export default Main;
