import {
  Typography,
  Button,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import styles from './ButtonsApplyCancel.module.scss';

interface ButtonsApplyCancelProps {
  disabledApply: boolean;
  disabledCancel: boolean;
  applyText?: string;
  cancelText?: string;
  onApply: () => void;
  onCancel: () => void;
}

function ButtonsApplyCancel(props: ButtonsApplyCancelProps) {
  const { t } = useTranslation('common');
  const {
    disabledApply, disabledCancel, onApply, onCancel,
  } = props;
  let { applyText, cancelText } = props;

  applyText = applyText || t('project.apply');
  cancelText = cancelText || t('project.cancel');

  return (
    <Stack>
      <Button
        className={styles.ApplyButton}
        variant="contained"
        onClick={() => onApply()}
        disabled={disabledApply}
      >
        <Typography className={styles.ApplyText} variant="body1">
          {applyText}
        </Typography>
      </Button>
      <Button
        className={styles.CancelButton}
        onClick={() => onCancel()}
        disabled={disabledCancel}
      >
        <Typography className={styles.CancelText} variant="body1">
          {cancelText}
        </Typography>
      </Button>
    </Stack>
  );
}

export default ButtonsApplyCancel;
