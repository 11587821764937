import { Grid } from '@mui/material';
import { GpRecipe } from '../../../../api/types';
import { UiCard, UiCardLarge } from '../UiCard';

const RecommendedRecipes = ({ recipes }: { recipes: GpRecipe[] }) => {
  const [firstElement, ...restRecipies] = recipes;
  return (
    <Grid container columns={3} spacing="14px">
      <UiCardLarge recipe={firstElement} />
      {
        restRecipies.length > 0 && restRecipies.map((layer) => (
          <UiCard
            type="recipe"
            key={layer.id}
            title={layer.title}
            annotation={layer.annotation}
            id={layer.id}
          />
        ))
      }
    </Grid>
  );
};

export default RecommendedRecipes;
