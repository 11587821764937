import {
  Box,
  Typography,
} from '@mui/material';

import 'react-image-gallery/styles/css/image-gallery.css';
import { GpCommercialServiceValue, GpRecipeValue } from '../../../../../api/types';
import styles from '../CardBody.module.scss';

export interface ValueProps {
  value: GpRecipeValue | GpCommercialServiceValue;
  index: number;
}

const Value: React.FC<ValueProps> = ({ value, index }: ValueProps) => (
  <Box display="flex" gap="5px">
    <Typography variant="body1">
      { index + 1 }
      .
    </Typography>
    <Box>
      <Typography variant="body1" className={styles.value}>
        {value.title}
      </Typography>
    </Box>
  </Box>
);

export default Value;
