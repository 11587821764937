import {
  Box, Button, Typography,
} from '@mui/material';
import { useContext } from 'react';
import { useObservable } from '@ngneat/react-rxjs';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CartIcon } from '../../assets/images/cart.svg';
import { addItemsToActiveOrder } from '../../state/order/order.actions';
import { orderLayerSchedules$ } from '../../state/order/order.repository';
import { getDocumentsUrlPrefix, isGuestMode$ } from '../../state/context/auth.repository';
import { DrawerContentEnum, DrawerContext } from '../../drawerContext';
import { sendMetricReachGoal } from '../../utils/functions';
import {
  GpdsSchedule, GpLayer, GpSchedule, GpStatDataset,
} from '../../api/types';
import { availableObjectsUNames$ } from '../../state/acl/acl.repository';
import styles from './ProductUpdates.module.scss';

interface ProductUpdateRowProps {
  schedule: GpSchedule | GpdsSchedule;
  product: GpLayer | GpStatDataset;
}

const ProductUpdateRow = ({ schedule, product }: ProductUpdateRowProps) => {
  const { t } = useTranslation('common');
  const [isGuestMode] = useObservable(isGuestMode$);
  const drawerContext = useContext(DrawerContext);
  const [orderLayerSchedules] = useObservable(orderLayerSchedules$);
  const [availableObjectsUNames] = useObservable(availableObjectsUNames$);
  const { uName, id } = 'layer' in schedule ? schedule.layer : schedule.dataset;
  const uNames = orderLayerSchedules.map((s) => (
    'layer' in s.productPackaging[0] ? s.productPackaging[0].layer.uName : s.productPackaging[0].dataset.uName));
  const checkDisable = !uName || uNames.includes(uName) || availableObjectsUNames.has(uName);

  const handleBuyLayerUpdate = async () => {
    if (checkDisable) {
      return;
    }
    if (isGuestMode) {
      drawerContext.open(DrawerContentEnum.LOGIN);
    } else {
      await addItemsToActiveOrder({ schedule, product });
    }
    sendMetricReachGoal('currentLayerBuyButton');
  };

  const passport = ('layer' in schedule ? schedule.layer.passport : schedule.dataset.passport);

  return (
    <Box
      className={`${styles.container} ${!checkDisable ? styles.containerHover : ''}`}
    >
      <Box>
        <Typography variant="subtitle1" color="primary.dark">
          { !schedule.title ? t('layer.no_title') : schedule.title }
        </Typography>
        <Typography variant="body2" color="text.disabled">
          {uName}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" gap="2px">
        <Typography variant="body2" color="text.disabled">
          {`${t('layer.passport')}: `}
        </Typography>
        {
          passport
            ? (
              <a
                target="_blank"
                rel="noreferrer"
                className={styles.link}
                href={`${getDocumentsUrlPrefix()}${passport}`}
              >
                {t('layer.download')}
              </a>
            )
            : (
              <Typography variant="body2" color="text.disabled">
                {t('layer.unavailable')}
              </Typography>
            )
        }
      </Box>
      <Box>
        <Button
          className={styles.button}
          disabled={checkDisable}
          data-testid={`currentLayerBuyButton:${id}`}
          onClick={handleBuyLayerUpdate}
        >
          <Typography variant="subtitle1" fontWeight="700" color="primary.main">
            {`${schedule.price} ₽`}
          </Typography>
          <CartIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default ProductUpdateRow;
