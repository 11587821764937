import React from 'react';
import Box from '@mui/material/Box';
import SignInForm from '../signin-form/signin-form';

function AuthForm({ onCompleted, toRegister }:
{ onCompleted: () => void; toRegister: () => void }) {
  return (
    <Box sx={{ width: '100%' }}>
      <SignInForm onCompleted={() => onCompleted()} toRegister={() => toRegister()} />
    </Box>
  );
}

export default AuthForm;
