import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useObservable } from '@ngneat/react-rxjs';
import {
  Map as MapOriginal, Layer, LatLngExpression, LatLng,
} from 'leaflet';
import '@geoman-io/leaflet-geoman-free';
import { TFunction, useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { setUILayer } from '../../state/project/project-recipe.service';

import {
  L,
  LeafletMap,
} from '../../types/leaflet';
import { RESULTING_LAYER_ID } from '../../utils/constants';
import { createTooltip } from '../../utils/functions';
import MapPage from './MapPage';
import { customTranslation } from '../../utils/leaflet-pm.translations';
import { initLayerCanvas } from '../../utils/leaflet-layer-canvas';

import {
  activeFormulaLayer$,
  activeProject$, getBaseLayers, getOverlayLayers,
  popActiveProjectBounds,
  projectLayerStyles$,
  resetMap,
  resultingEnabled$,
} from '../../state/project/project.repository';
import {
  setActiveProject, toggleLayer, updateProjectMarkersCfg, updateProjectPositionAndZoom,
} from '../../state/project/project.actions';
import {
  useFormulaLayer,
  gpFormulaToPluginFormula,
} from '../../services/useFormulaLayer';
import usePositionsave from '../../services/usePositionSave';
import useGeosearch from '../../services/useGeoserch';
import useStyler from '../../services/useStyler';
import { initMarkersSrc } from '../../utils/leaflet-icon-init';
import MapPopup from '../../components/MapPopup';
import useRulerWithPuncture from '../../services/useRuler';
import useMultipuncture from '../../services/useMultipuncture';
import { GpProject } from '../../api/types';
import { LayerType } from '../../models/layer';
import BI from '../BI/BI';
import { activeAccountId$ } from '../../state/context/auth.repository';

const LayerToExtendJSON = (t: unknown) => {
  const i = (t as { toGeoJSON: () => { properties: unknown } }).toGeoJSON();
  if (t instanceof L.Circle) {
    i.properties = { radius: t.getRadius(), subType: 'Circle' };
  }
  if (t instanceof L.CircleMarker && !(t instanceof L.Circle)) {
    i.properties = { radius: t.getRadius(), subType: 'CircleMarker' };
  }
  return i as unknown;
};

const addToolbarToMap = (e: MapOriginal) => {
  e.pm.addControls({
    position: 'topleft',
  });
  e.pm.setLang('ru', customTranslation, 'ru');
  e.pm.setLang('ru');
};

const addScaleToMap = (e: MapOriginal) => {
  const scaleOptions = {
    metric: true,
    imperial: false,
    maxWidth: 200,
    updateWhenIdle: true,
  };
  L.control
    .scale(scaleOptions)
    .addTo(e);
};

const addToolbarTooltips = (e: MapOriginal) => {
  const mapE = e.getContainer();
  Array.from(mapE.querySelectorAll<HTMLElement>('.toolbar-button'))
    .concat(Array.from(mapE.querySelectorAll<HTMLElement>('.control-icon')))
    .forEach((item) => {
      const title = item.title === '' ? 'Вращать' : item.title;
      const dir: string = item.getBoundingClientRect().x < mapE.getBoundingClientRect().width / 2
        ? 'left'
        : 'right';

      createTooltip(title, dir, item);
    });
};

const initZoomControl = (e: MapOriginal, t: TFunction) => {
  e.zoomControl.remove();
  L.control.zoom({ zoomInTitle: t('map.zoom_in'), zoomOutTitle: t('map.zoom_out') }).addTo(e);
  e.getContainer().querySelectorAll<HTMLElement>('.leaflet-control-zoom a')
    .forEach((item) => {
      item.setAttribute('style', 'position: relative;');
      item.classList.add('toolbar-button');
    });
};

const addToolbarTogglerListeners = (e: MapOriginal) => {
  e.on('pm:buttonclick', () => {
    L.Util.Toggler.disableAll();
  });

  e.on('pm:drawend', () => {
    L.Util.Toggler.enableAll();
  });

  e.on('pm:globaldragmodetoggled', (e) => {
    if (!e.enabled) {
      L.Util.Toggler.enableAll();
    }
  });
  e.on('pm:globalremovalmodetoggled', (e) => {
    if (!e.enabled) {
      L.Util.Toggler.enableAll();
    }
  });
  e.on('pm:globaleditmodetoggled', (e) => {
    if (!e.enabled) {
      L.Util.Toggler.enableAll();
    }
  });
  e.on('pm:globalrotatemodetoggled', (e) => {
    if (!e.enabled) {
      L.Util.Toggler.enableAll();
    }
  });
};
const initMapControls = (e: MapOriginal) => {
  addScaleToMap(e);
  addToolbarToMap(e);
  addToolbarTogglerListeners(e);
};
const drawMarkers = (project: GpProject, map: MapOriginal) => {
  if (project?.markersCfg?.extendGeoJSON) {
    const layers = L.PM.Toolbar.createLayersFromJSON(
      project.markersCfg.extendGeoJSON,
    ) as Layer[];
    layers.forEach((layer) => {
      layer.on('pm:edit', () => {
        const layers = map.pm.getGeomanLayers(false);
        const extendGeoJSON = (layers as Layer[]).map(LayerToExtendJSON);

        void updateProjectMarkersCfg({
          newData: extendGeoJSON,
          key: 'extendGeoJSON',
        });
      });
    });
    L.layerGroup(layers).addTo(map);
  }
};

interface Props {
  type: 'map' | 'bi';
}

const MapPageWrapper = ({ type }: Props) => {
  const [lastCid, setLastCid] = useState(0);
  const [isFormulaLayerAddedToMap, setIsFormulaLayerAddedToMap] = useState(false);
  const [map, setMap] = useState<L.Map | null>(null);
  const [isProjectReady, setIsProjectReady] = useState<boolean>(false);
  const [formulaLayerGroup] = useState<{ [key: string]: L.TileLayer; }>({});
  const { t } = useTranslation('common');

  const [activeProject] = useObservable(activeProject$);
  const [resultingLayer] = useObservable(activeFormulaLayer$);
  const [resultingEnabled] = useObservable(resultingEnabled$);
  const [projectLayerStyles] = useObservable(projectLayerStyles$);
  const [activeAccountId] = useObservable(activeAccountId$);

  const geoProject = (activeProject?.project?.[0] as GpProject);
  const [position] = useState<LatLngExpression>(
    geoProject?.markersCfg?.position
      ? new LatLng(
        geoProject.markersCfg.position.lat,
        geoProject.markersCfg.position.lng,
      )
      : [55.725507, 37.628173],
  );

  const { projectId } = useParams();
  const { styler, loadStyler } = useStyler();
  const { formulaLayer, loadFormula } = useFormulaLayer();
  const { mpunctureControl, loadRuler } = useRulerWithPuncture();
  const { multipuncture, loadMultipuncture } = useMultipuncture();

  const updateProjectOnMarkersChange = (e: MapOriginal) => {
    e.on('pm:create', ({ layer }) => {
      layer.on('pm:edit', () => {
        const layers = e.pm.getGeomanLayers(false);
        const extendGeoJSON = (layers as Layer[]).map(LayerToExtendJSON);

        void updateProjectMarkersCfg({
          newData: extendGeoJSON,
          key: 'extendGeoJSON',
        });
      });
      const layers = e.pm.getGeomanLayers(false);
      const extendGeoJSON = (layers as Layer[]).map(LayerToExtendJSON);

      void updateProjectMarkersCfg({
        newData: extendGeoJSON,
        key: 'extendGeoJSON',
      });
    });

    e.on('pm:remove', () => {
      const layers = e.pm.getGeomanLayers(false);
      const extendGeoJSON = (layers as Layer[]).map(LayerToExtendJSON);

      if (activeProject) {
        void updateProjectMarkersCfg({
          newData: extendGeoJSON,
          key: 'extendGeoJSON',
        });
      }
    });
  };

  useEffect(() => {
    if (resultingLayer && styler && map) {
      Object.keys(formulaLayerGroup).forEach((id) => {
        if (!resultingLayer?.layerEm[0].L.some((el) => el.id === id)) {
          formulaLayerGroup[id].removeFrom(map);
          delete formulaLayerGroup[id];
        }
      });
      const baseLayer = getBaseLayers();
      const overlayLayers = getOverlayLayers();
      resultingLayer?.layerEm[0].L.forEach((el) => {
        if (!Object.keys(formulaLayerGroup).some((id) => el.id === id)) {
          const layer = [...baseLayer, ...overlayLayers].find((item) => item.id === el.id);
          if (layer) {
            const layerMap = setUILayer(layer);
            const tileLayer = styler.initLayer(layerMap, true);
            if (tileLayer) {
              formulaLayerGroup[layer.id] = tileLayer;
              tileLayer.setOpacity(0).addTo(map);
            }
          }
        }
      });
    }
  }, [resultingLayer, map, styler]);

  useEffect(() => {
    if (map) {
      (map as LeafletMap).state = {};
      initZoomControl(map, t);
      initMapControls(map);
      updateProjectOnMarkersChange(map);
      initMarkersSrc();

      useGeosearch(map as LeafletMap);
      loadStyler(map as LeafletMap);
      loadMultipuncture(map as LeafletMap);

      loadRuler(map as LeafletMap, () => {
        drawMarkers(geoProject, map);
        addToolbarTooltips(map);
      });
      const bounds = popActiveProjectBounds();
      if (bounds) {
        map.fitBounds(bounds);
        const center = map.getCenter();
        const zoom = map.getZoom();
        void updateProjectPositionAndZoom(center, zoom);
      }
    }

    return () => {
      if (map) {
        setTimeout(() => { (map as LeafletMap).remove(); });
      }
    };
  }, [map]);

  useEffect(() => {
    initLayerCanvas();
  }, []);

  useEffect(() => {
    if (map) {
      map.eachLayer((l) => {
        l.on('pm:edit', () => {
          const layers = map.pm.getGeomanLayers(false);
          // eslint-disable-next-line @typescript-eslint/unbound-method
          const extendGeoJSON = (layers as Layer[]).map(L.PM.Toolbar.LayerToExtendJSON);

          void updateProjectMarkersCfg({
            newData: extendGeoJSON,
            key: 'extendGeoJSON',
          });
        });
      });
    }
  }, [lastCid]);

  useEffect(() => {
    setIsProjectReady(false);
    if (projectId && activeAccountId) {
      void setActiveProject(projectId).then(() => setIsProjectReady(true));
    }
  }, [projectId, activeAccountId]);

  useEffect(() => {
    if (map) {
      (map as LeafletMap).state.formulaLayer = formulaLayer;
    }
  }, [formulaLayer]);

  useEffect(() => {
    if (map && isProjectReady && resultingLayer) {
      loadFormula(formulaLayerGroup, resultingLayer.layerEm[0]);
      usePositionsave(map as LeafletMap, geoProject);

      if (mpunctureControl) {
        drawMarkers(geoProject, map);
      }
    }
  }, [map, isProjectReady]);

  useEffect(() => {
    if (projectId && projectLayerStyles.cid > 0 && styler && map) {
      styler.updateLayers({ layers: projectLayerStyles });
      if (formulaLayer && projectLayerStyles.cid > lastCid) {
        styler.addOverlay(formulaLayer, RESULTING_LAYER_ID);
        styler.updateLayers({ layers: projectLayerStyles });
        setLastCid(projectLayerStyles.cid);
      }
    }
  }, [styler, projectLayerStyles, projectId, formulaLayer]);

  useEffect(() => {
    if (map && formulaLayer) {
      if (resultingEnabled && !isFormulaLayerAddedToMap) {
        formulaLayer.addTo(map);
        setIsFormulaLayerAddedToMap(true);
      }
      if (!resultingEnabled && isFormulaLayerAddedToMap) {
        formulaLayer.removeFrom(map);
        setIsFormulaLayerAddedToMap(false);
      }
    }
  }, [resultingEnabled, formulaLayer]);

  useEffect(() => {
    if (resultingLayer) {
      if (!Object.keys(resultingLayer.layerEm[0].L)?.length && resultingEnabled) {
        void toggleLayer({
          id: RESULTING_LAYER_ID,
          layerType: LayerType.OV,
        });
      }
      const newFormula = gpFormulaToPluginFormula(resultingLayer.layerEm[0]);
      formulaLayer?.updateFormula({ formula: newFormula });
    }
  }, [resultingLayer]);

  useEffect(() => {
    if (map) {
      map.panTo(position);
    }
  }, [position]);

  const resetMapOnExit = () => {
    Object.keys(formulaLayerGroup).forEach((key) => {
      delete formulaLayerGroup[key];
    });
    resetMap();
  };

  useEffect(() => resetMapOnExit, []);

  const mapPage = (
    <>
      <MapPage
        setMap={setMap}
      />
      <MapPopup
        map={map}
        mpunctureControl={mpunctureControl}
        multipuncture={multipuncture}
      />
    </>
  );

  return (
    <Box overflow="hidden">
      { type === 'map' ? mapPage : <BI /> }
    </Box>
  );
};

export default MapPageWrapper;
