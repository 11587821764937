import { useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { LeafletMap, L, MapControl } from '../types/leaflet';
import { createTooltip } from '../utils/functions';
import PunctureControl from '../utils/puncture-control';
import loadScript from './script.service';

interface UseRulerReturnValue {
  mpunctureControl: PunctureControl | null,
  loadRuler: (e: LeafletMap, cb: () => void) => void;
}

const ISOCHRONE_URL = '/static/leaflet/leaflet-isochrone-min.js';
const ICH_URL = '/ghopper/isochrone';

function addIsochroneMenu(ichControl: MapControl, t: TFunction) {
  const minutesTranslation = t('map.minutes');

  const menu = document.createElement('div');
  menu.classList.add('leaflet-control');
  menu.style.alignItems = 'center';
  menu.style.display = 'none';
  menu.style.position = 'absolute';
  menu.style.top = '100%';
  menu.style.right = '64px';
  menu.style.flexDirection = 'column';
  menu.style.gap = '4px';

  function createRow() {
    const row = document.createElement('div');
    row.style.display = 'flex';
    row.style.flexWrap = 'nowrap';
    row.style.gap = '4px';
    return row;
  }

  function createButton(minutes: number) {
    const button = document.createElement('button');
    button.innerText = `${minutes.toString()} ${minutesTranslation}`;
    return button;
  }

  const topRow = createRow();
  const bottomRow = createRow();

  const button5min = createButton(5);
  button5min.disabled = true;
  const button10min = createButton(10);
  const button15min = createButton(15);

  button5min.onclick = (ev) => {
    ichControl.setIchProp({ timelimit: '300' });
    button5min.disabled = true;
    button10min.disabled = false;
    button15min.disabled = false;
    ev.stopPropagation();
  };
  button10min.onclick = (ev) => {
    ichControl.setIchProp({ timelimit: '600' });
    button5min.disabled = false;
    button10min.disabled = true;
    button15min.disabled = false;
    ev.stopPropagation();
  };
  button15min.onclick = (ev) => {
    ichControl.setIchProp({ timelimit: '900' });
    button5min.disabled = false;
    button10min.disabled = false;
    button15min.disabled = true;
    ev.stopPropagation();
  };

  const buttonCar = document.createElement('button');
  buttonCar.className = 'car-icon';
  buttonCar.style.width = '40px';
  buttonCar.style.height = '40px';
  buttonCar.disabled = true;
  buttonCar.onclick = (ev) => {
    ichControl.setIchProp({ profile: 'car' });
    ev.stopPropagation();
  };

  const buttonByFoot = document.createElement('button');
  buttonByFoot.style.width = '40px';
  buttonByFoot.className = 'walk-icon';
  buttonByFoot.style.height = '40px';
  buttonByFoot.onclick = (ev) => {
    // Not implemented on the server
    // ichControl.setIchProp({ profile: 'byFoot' });
    ev.stopPropagation();
  };

  topRow.append(button5min, button10min, button15min);
  bottomRow.append(buttonCar, buttonByFoot);

  menu.append(topRow, bottomRow);

  return menu;
}

const initIsochronePlugin = (e: LeafletMap, t: TFunction) => {
  loadScript(ISOCHRONE_URL, () => {
    const Ich = L.Control.Isochrone.extend({});

    const ichControl = new Ich({
      ichUrl: ICH_URL,
      color: '#FF0000',
      btnTitle: t('map.isochrone'),
      toDraw: true,
      timelimit: 300, // seconds
      profile: 'car',
    });

    e.addControl(ichControl);
    ichControl.setDrawing(true);
    // TODO: replace with required callback
    const eventFunc = (layer: unknown) => {
      // eslint-disable-next-line no-console
      console.log('layer', layer);
    };

    ichControl.on('drawed', eventFunc);

    L.DomUtil.addClass(ichControl.container, 'isochrone');
    createTooltip(t('map.isochrone'), 'right', ichControl.container.firstChild as HTMLElement);
    const menu = addIsochroneMenu(ichControl, t);

    ichControl.container.parentElement?.append(menu);
    ichControl.link.onclick = (e) => {
      const link = e.target as HTMLElement;
      menu.style.display = link.className === 'icon-active' ? 'flex' : 'none';
    };
  });
};

const MEASUREMENT_PLUGIN_PATH = '/static/leaflet/leaflet-measurement-min.js';

const useRulerWithPuncture = (): UseRulerReturnValue => {
  const [mpunctureControl, setMpControl] = useState<PunctureControl | null>(null);
  const { t } = useTranslation('common');

  const loadRuler = (e: LeafletMap, cb: () => void) => {
    loadScript('../leaflet.draw.js', () => {
      loadScript(MEASUREMENT_PLUGIN_PATH, () => {
        L.Util.Toggler.registerTool(L.PM.Toolbar);
        const Ruler = L.Control.Measurement.extend({
        });

        setTimeout(() => {
          const RulerContorol = new Ruler({
            unitSystem: 'metric',
            color: '#FF0080',
            type: 'path',
            position: 'topleft',
            title: t('map.measure_distance'),
          });
          e.addControl(RulerContorol);
          L.DomUtil.addClass(RulerContorol.container.firstChild as HTMLElement, 'toolbar-button');

          const Ruler2 = L.Control.Measurement.extend({});
          const Ruler2Contorol = new Ruler2({
            unitSystem: 'metric',
            color: '#FF0000',
            position: 'topright',
            type: 'polygon',
            show_azimut: true,
            title: t('map.measure_square'),
          });
          e.addControl(Ruler2Contorol);
          L.DomUtil.addClass(Ruler2Contorol.container, 'measurement');
          L.DomUtil.addClass(Ruler2Contorol.container.firstChild as HTMLElement, 'toolbar-button');

          const mpControl = new PunctureControl(t('map.show_values'), {
            position: 'topright',
          });
          e.addControl(mpControl);

          setMpControl(mpControl);

          initIsochronePlugin(e, t);

          cb();
        });
      });
    });
  };

  return { mpunctureControl, loadRuler };
};

export default useRulerWithPuncture;
