import { useObservable } from '@ngneat/react-rxjs';
import { Navigate } from 'react-router-dom';
import { isGuestMode$, user$ } from '../../../state/context/auth.repository';

const GuestGuard = ({ children }: { children: JSX.Element }) => {
  const [user] = useObservable(user$);
  const [isGuestMode] = useObservable(isGuestMode$);

  return !user || isGuestMode
    ? <Navigate to="/" />
    : children;
};

export default GuestGuard;
