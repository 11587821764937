import {
  Box, Stack, RadioGroup, Radio,
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Search from '../../../../../blocks/Search/Search';
import { ReactComponent as FirstViewIcon } from '../../../../../assets/images/firstView.svg';
import { ReactComponent as SecondViewIcon } from '../../../../../assets/images/secondView.svg';
import { ReactComponent as ThirdViewIcon } from '../../../../../assets/images/thirdView.svg';
import { ReactComponent as FirstViewSelectedIcon } from '../../../../../assets/images/firstViewSelected.svg';
import { ReactComponent as SecondViewSelectedIcon } from '../../../../../assets/images/secondViewSelected.svg';
import { ReactComponent as ThirdViewSelectedIcon } from '../../../../../assets/images/thirdViewSelected.svg';

import styles from './LayersHeader.module.scss';

interface LayersHeaderProps {
  view: string,
  handleViewChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  handleSearchInput: (value: string) => void,
}

const LayersHeader: FC<LayersHeaderProps> = ({
  view,
  handleViewChange,
  handleSearchInput,
}) => {
  const { t } = useTranslation('common');

  return (
    <Stack direction="row" mt="20px" gap="14px">
      <Box className={styles.layersHeaderSearch}>
        <Search
          placeholder={t('search.goods')}
          handleInputChange={handleSearchInput}
          name="search_project_query"
        />
      </Box>
      <RadioGroup className={styles.layersHeaderRadioGroup} value={view} defaultValue="first" onChange={handleViewChange}>
        <Radio
          className={styles.layersHeaderRadioButton}
          value="first"
          icon={<FirstViewIcon />}
          checkedIcon={<FirstViewSelectedIcon />}
        />
        <Radio
          className={styles.layersHeaderRadioButton}
          value="second"
          icon={<SecondViewIcon />}
          checkedIcon={<SecondViewSelectedIcon />}
        />
        <Radio
          className={styles.layersHeaderRadioButton}
          value="third"
          icon={<ThirdViewIcon />}
          checkedIcon={<ThirdViewSelectedIcon />}
        />
      </RadioGroup>
    </Stack>
  );
};

export default LayersHeader;
