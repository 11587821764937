// TODO: remove
// @ts-ignore
import Pdf from 'pdf-template';
import {
  GpLayerFormula, GpLayerMap, GpLayersList, GpProject, GpuiLayer,
} from '../api/types';
// @ts-ignore-disable

function isGPLayerFormula(layer: GpuiLayer): layer is GpuiLayer {
  return layer.layerEm?.length > 0;
}

const usePDF = (
  project: GpProject,
  formula: GpLayerFormula,
  layers: GpLayerMap[],
  mapElement: HTMLElement | null,
) => {
  const urlTemplate = 'http://www.dev.pbd.ai/report/templates/template.txt';
  const urlFont = 'http://www.dev.pbd.ai/report/templates/ptSans.ttf';

  const map = {
    'project.title': 'title',
    'project.cmscreatedate': 'createdate',
    'project.recipe.layers[0]': {
      key: 'layers',
      transform: (
        value: GpLayersList,
        allData: { project: GpProject, formula: GpLayerFormula, layers: GpLayerMap[] },
      ) => {
        const layersIds = [...value.bg, ...value.ov]
          .filter((x) => (isGPLayerFormula(x) && x.on))
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          .map((x) => x.layerEm?.[0].id);

        return allData.layers.filter((x) => layersIds.includes(x.id));
      },
    },
  };

  const data = {
    project,
    formula,
    layers,
  };

  const pdf = new Pdf({
    urlTemplate,
    data,
    map,
    fontConfigs: [
      { url: urlFont, name: 'PTSans' },
    ],
    screenshots: [
      {
        key: 'map',
        element: mapElement as HTMLElement,
      },
    ],
  });

  return { save: pdf.save.bind(pdf) };
};

export default usePDF;
