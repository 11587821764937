import { createContext } from 'react';
import { GpuiLayer } from '../../../api/types';

export interface ILayerListContext {
  layers: GpuiLayer[],
}

const layerListContext = createContext<ILayerListContext>({
  layers: [],
});

export default layerListContext;
