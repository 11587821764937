import React, { useState } from 'react';
import { TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { sendMetricReachGoal } from '../../utils/functions';
import styles from './Search.module.scss';

interface SearchProps {
  placeholder?: string;
  defaultValue?: string;
  handleInputChange: (value: string) => void;
  name?: string;
}

function Search({
  placeholder = '',
  defaultValue,
  handleInputChange,
  name,
}: SearchProps) {
  const [interval, setInterval] = useState<NodeJS.Timeout | undefined>();

  const handleInput = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (interval) {
      clearTimeout(interval);
    }
    const newInterval = setTimeout(() => {
      clearTimeout(interval);
      handleInputChange(evt.target.value.toLowerCase());
    }, 500);
    setInterval(newInterval);
  };

  const handleKeyPress = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt) {
      if (interval) {
        clearTimeout(interval);
      }
      if (evt.key === 'Enter') {
        evt.preventDefault();
        const target = evt.target as HTMLInputElement;
        handleInputChange(target.value.toLowerCase());
      }
    }
  };

  return (
    <TextField
      className={styles.searchFilterField}
      data-testid="searchField"
      name={name}
      defaultValue={defaultValue}
      size="small"
      fullWidth
      autoComplete="off"
      variant="outlined"
      placeholder={placeholder ?? ''}
      onChange={handleInput}
      onClick={() => sendMetricReachGoal('search')}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon className={styles.searchIcon} />
          </InputAdornment>
        ),
      }}
      onKeyPress={handleKeyPress}
    />
  );
}

export default Search;
