import { GpbiProject } from '../../api/types';
import { getProjectGn } from '../../services/geo-api.service';
import { getAuthProps, getUsernameFromToken } from '../../state/context/auth.repository';
import { sleep } from '../../utils/functions';

interface GuestToken {
  token: string;
}

export enum ActionsEnum {
  Create = 'CREATE',
  Delete = 'DELETE',
  Update = 'UPDATE',
}

export async function biStartProcess(id: string, action: ActionsEnum) {
  const { token } = getAuthProps();
  const requestBody = {
    processDefinitionKey: 'geo.crud.bi.dashboard',
    variables: [
      {
        key: 'biId',
        value: id,
      },
      {
        key: 'action',
        value: action,
      },
    ],
  };
  await fetch(
    '/api/bpm/startProcess',
    {
      method: 'POST',
      headers: {
        auth: token ?? '',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    },
  );
}

export async function fetchGuestTokenFromBackend(id: string): Promise<string> {
  const { token } = getAuthProps();
  const username = getUsernameFromToken();
  const requestBody = {
    user: {
      username,
      first_name: '',
      last_name: '',
    },
    rls: [],
    resources: [{
      type: 'dashboard',
      id,
    }],
  };
  const res = await fetch(
    '/api/aaa/getGuestToken',
    {
      method: 'POST',
      headers: {
        auth: token ?? '',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    },
  );
  const guestTokenResponse = await res.json();
  const guestToken = guestTokenResponse as GuestToken;
  return guestToken.token;
}

export const refetchProjectGn = async (id: string) => {
  await sleep(2000);
  const { data } = await getProjectGn(id);
  return data.GPProjectGn.get.project[0] as GpbiProject;
};
