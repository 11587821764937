import {
  Box, Checkbox, FormControlLabel, FormGroup, Grid, Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useObservable } from '@ngneat/react-rxjs';
import { fetchRecipes } from '../../state/geoData/recipes.effects';

import styles from './RecipeCatalog.module.scss';
import RecipeCard from '../../blocks/RecipeCard/RecipeCard';
import {
  recipes$, recipesRequestPending$, recipesRequestStatus$, recipesTotalCount$,
} from '../../state/geoData/recipes.repository';
import PageHeader from '../../blocks/PageHeader/PageHeader';
import checkboxImg from '../../assets/images/checkbox.svg';
import checkedImg from '../../assets/images/checked-checkbox.svg';
import RecipeFilter from './RecipeFilter';
import { ResponseError } from '../../models/common';
import { activeAccountId$ } from '../../state/context/auth.repository';
import Loader from '../../components/Loader/Loader';

const DURATION_FILTERS: [number, number][] = [[1, 10], [11, 20], [21, 30]];

function RecipeCatalog() {
  const { t } = useTranslation('common');
  const [inputSearch, setInputSearch] = useState('');
  const [recipes] = useObservable(recipes$);
  const [activeFilters, setActiveFilters] = useState(new Set<[number, number]>());
  const [recipesRequestPending] = useObservable(recipesRequestPending$);
  const [recipesRequestStatus] = useObservable(recipesRequestStatus$);
  const [recipesTotalCount] = useObservable(recipesTotalCount$);
  const [activeAccountId] = useObservable(activeAccountId$);

  useEffect(() => {
    if (activeAccountId) {
      void fetchRecipes(Array.from(activeFilters));
    }
  }, [activeFilters, activeAccountId]);

  const handleInputChange = (value: string) => {
    setInputSearch(value);
  };

  const checkBoxChange = (checked: boolean, index: number) => {
    const newActiveFilters = new Set(activeFilters);
    if (checked) {
      newActiveFilters.add(DURATION_FILTERS[index]);
    } else {
      newActiveFilters.delete(DURATION_FILTERS[index]);
    }
    setActiveFilters(newActiveFilters);
  };

  const renderRecipeList = () => (
    <Grid item container xs spacing={2.5}>
      {recipes
        .filter((item) => item.title.toLowerCase().includes(inputSearch))
        .map((recipe) => (
          <Grid data-testid={`recipeCard:${recipe.id}`} item xs={4} key={recipe.id}>
            <Link to={recipe.id}>
              <RecipeCard
                title={recipe.title}
                body={recipe.description}
                timeSpend={`${recipe.duration} минут`}
              />
            </Link>
          </Grid>
        ))}
    </Grid>
  );

  if (recipesRequestStatus.value === 'error') {
    return (
      <Typography variant="h5" sx={{ textAlign: 'center' }}>
        Error:
        {' '}
        {(recipesRequestStatus.error as ResponseError).localizedMessage}
      </Typography>
    );
  }

  return (
    <div className={styles.catalog}>
      <Grid container gap={2.5} flexWrap="nowrap">
        <Grid item xs={3}>
          <Typography variant="body2" component="span">
            { t('recipeCatalog.count') }
            {' '}
          </Typography>
          <Box className={styles.recipe}>
            <Typography variant="accentTitle1" color="#fff">{recipesTotalCount || 0}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} alignSelf="baseline">
          <PageHeader
            handleInputChange={handleInputChange}
            placeholder={t('recipeCatalog.search')}
            name="search_recipe_query"
          />
        </Grid>
      </Grid>

      <Grid container mt={0} spacing={2.5}>
        <Grid item xs={3}>
          <Box mb={1}>
            <Typography variant="title2" color="text.primary" data-testid="filtersTitle">
              {t('layer.filters')}
            </Typography>
          </Box>

          <FormGroup>
            <RecipeFilter title={t('recipeCatalog.byDurationFilter')}>
              <>
                {DURATION_FILTERS.map(
                  ([from, to], index) => (
                    <FormControlLabel
                      key={from}
                      sx={{ pl: 0.5 }}
                      control={
                        (
                          <Checkbox
                            data-testid={`recipesFilter-${from}-${to}`}
                            icon={<img src={checkboxImg} alt="" />}
                            checkedIcon={<img src={checkedImg} alt="" />}
                            onChange={(e) => checkBoxChange(e.target.checked, index)}
                          />
                        )
                      }
                      label={`${from} - ${to}`}
                    />
                  ),
                )}
              </>
            </RecipeFilter>

          </FormGroup>
        </Grid>
        {recipesRequestPending
          ? <Loader id="progress-recipe-catalog" />
          : renderRecipeList()}
      </Grid>
    </div>
  );
}

export default RecipeCatalog;
