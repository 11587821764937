import { FormulaPalette } from './formula-layer';
import { FormulaLayer } from '../types/leaflet';

export interface LayerStylerItem {
  id: string;
  on: boolean;
  n: string;
  u: Array<any> | FormulaLayer;
  p: FormulaPalette,
  o: number;
  g: number;
  h: number;
  type?: string;
}

export interface ProjectLayerStyles {
  cid: number;
  bg: Array<LayerStylerItem>;
  ov: Array<LayerStylerItem>;
}

export interface TableElement {
  id: string;
  name: string;
  data: string;
  wkt: string;
}

export interface GPGeoItem {
  id: string;
  geometryJSON: string;
}

export enum LayerType {
  BG = 'bg',
  OV = 'ov',
}
