import { Routes, Route, Navigate } from 'react-router-dom';
import DynamicPage from '../../pages/DynamicPage/DynamicPage';
import Account from '../../pages/Account/Account';
import RecipeCatalog from '../../pages/RecipeCatalog/RecipeCatalog';
import Recipe from '../../pages/Recipe/Recipe';
import Marketplace from '../../pages/Marketplace/Marketplace';
import Tariffs from '../../pages/Tariffs/Tariffs';
import Product from '../../pages/Product/Product';
import Order from '../../pages/Order/Order';
import Header from '../../components/Header/Header';
import GuestGuard from '../../feature/Guards/GuestGuard/GuestGuard';
import { APP_ENV, ENV, PROD_ENV } from '../../utils/constants';
import PaymentStatus from '../../components/PaymentStatus/PaymentStatus';
import Help from '../../pages/Help/Help';

const appEnv: ENV = APP_ENV;

const HeaderLayout = () => (
  <>
    <Header />

    <Routes>
      <Route
        path="/"
        element={<Navigate to="/marketplace" />}
      />
      <Route path="/home" element={<DynamicPage />} />
      <Route path="/help" element={<Help />} />
      <Route path="/help/*" element={<Help />} />
      <Route
        path="account/*"
        element={
          (
            <GuestGuard>
              <Account />
            </GuestGuard>
          )
        }
      />
      <Route path="/recipes" element={<RecipeCatalog />} />

      <Route path="/recipes/:recipeId" element={<Recipe />} />
      <Route path="/marketplace" element={<Marketplace />} />
      <Route path="/account/orders/status" element={<PaymentStatus />} />

      { appEnv !== PROD_ENV ? <Route path="/tariffs" element={<Tariffs />} /> : null }

      <Route path="/marketplace/:productId" element={<Product />} />
      <Route path="/account/orders/:orderId" element={<Order />} />

      <Route path="*" element={<Marketplace />} />
    </Routes>
  </>
);

export default HeaderLayout;
