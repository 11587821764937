import { GpRubric } from '../../../api/types';

export function getParentsRubrics(rubrics: GpRubric[]) {
  const parents = new Set<GpRubric>();
  rubrics.forEach((r) => {
    if (r.parent) {
      parents.add(
        (rubrics.find((rubric) => rubric.id === r.parent.id) as GpRubric),
      );
    }
  });
  return Array.from(parents);
}

export function getLeafRubrics(rubrics: GpRubric[]) {
  const parentRubrics = getParentsRubrics(rubrics);
  return rubrics
    .filter((el) => el.parent)
    .filter((el) => !parentRubrics.includes(el));
}

export const getChildRubrics = (rubrics: GpRubric[]) => rubrics
  .filter((el) => el.parent);

export const getNonChildRubrics = (rubrics: GpRubric[]) => rubrics
  .filter((el) => !el.parent);

export function getSingleRubrics(rubrics: GpRubric[]) {
  const parentRubrics = getParentsRubrics(rubrics);
  return rubrics
    .filter((el) => !el.parent)
    .filter((el) => !parentRubrics.includes(el));
}

export function getNestedFilters(filter: GpRubric, rubrics: GpRubric[]) {
  const children = rubrics
    .filter((f) => f.parent && f.parent.id === filter.id);
  return children.length
    ? children
    : undefined;
}

export function getActiveNestedFiltersIds(
  filter: GpRubric,
  rubrics: GpRubric[],
  activeRubrics: GpRubric[],
) {
  const nestedFilters = getNestedFilters(filter, rubrics);
  const leafRubrics = getLeafRubrics(rubrics);
  const res: string[] = [];
  if (nestedFilters) {
    nestedFilters.forEach((filter) => (
      leafRubrics.includes(filter)
        ? (activeRubrics.includes(filter) && res.push(filter.id))
        : getActiveNestedFiltersIds(filter, rubrics, activeRubrics)
          .forEach((filter) => res.push(filter))
    ));
  }
  return res;
}
