import { DragIndicatorOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { SORTABLE_HANDLE_CLASS } from '../../../constants';

import styles from './SortingButton.module.scss';

const SortingButton = () => (
  <IconButton
    disableRipple
    sx={{ width: '25px', height: '100%' }}
    color="secondary"
    aria-label="sort layer button"
    className={`${SORTABLE_HANDLE_CLASS} ${styles.handle}`}
  >
    <DragIndicatorOutlined fontSize="medium" />
  </IconButton>
);

export default SortingButton;
