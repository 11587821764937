import { Box, Grid } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useObservable } from '@ngneat/react-rxjs';

import Logo from '../../blocks/Logo/Logo';
import styles from './Header.module.scss';
import { isGuestMode$, user$ } from '../../state/context/auth.repository';
import { APP_ENV, ENV, PROD_ENV } from '../../utils/constants';
import { sendMetricReachGoal } from '../../utils/functions';

function Header() {
  const { t } = useTranslation('common');
  const location = useLocation();
  const [user] = useObservable(user$);
  const [isGuestMode] = useObservable(isGuestMode$);
  const appEnv: ENV = APP_ENV;

  return (
    <Box className={styles.header}>
      <Logo />

      <Box data-testid="headerMenu" className={styles.headerMenu}>
        <Grid container columnSpacing={4} className={styles.navContainer}>
          <Grid item>
            <NavLink
              to="/marketplace"
              data-testid="marketplaceLink"
              onClick={() => sendMetricReachGoal('marketplaceLink')}
              className={({ isActive }) => (isActive ? `${styles.link} ${styles['link-active']}` : styles.link)}
            >
              { t('page.marketplace') }

            </NavLink>
          </Grid>
          { appEnv !== PROD_ENV
            ? (
              <Grid item>
                <NavLink
                  to="/tariffs"
                  data-testid="tariffsLink"
                  onClick={() => sendMetricReachGoal('tariffsLink')}
                  className={({ isActive }) => (isActive ? `${styles.link} ${styles['link-active']}` : styles.link)}
                >
                  { t('page.tariffs') }
                </NavLink>
              </Grid>
            )
            : null }

          <Grid item>
            <NavLink
              to="/help"
              data-testid="helpLink"
              onClick={() => sendMetricReachGoal('helpLink')}
              className={({ isActive }) => (isActive ? `${styles.link} ${styles['link-active']}` : styles.link)}
            >
              { t('page.help') }

            </NavLink>
          </Grid>
          { (user && !isGuestMode)
            && (
              <Grid item>
                <NavLink
                  to="/account/projects"
                  data-testid="accountLink"
                  onClick={() => sendMetricReachGoal('accountLink')}
                  className={() => (location.pathname.includes('account')
                    ? `${styles.link} ${styles['link-active']}`
                    : styles.link)}
                >
                  { t('page.account') }
                </NavLink>
              </Grid>
            )}
        </Grid>
      </Box>
    </Box>
  );
}

export default Header;
