import { ListItemButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { GpRubric } from '../../../../api/types';
import RubricFormContolLabel from './RubricFormControlLabel';
import styles from './RubricFilter.module.scss';

interface FilterProps {
  filter: GpRubric,
  active: boolean,
  onChange: (active: boolean, filter: GpRubric) => void,
}

function SingleRubricFilter({
  filter, active, onChange,
}: FilterProps) {
  const [checked, setChecked] = useState<boolean>(active);
  const [isHover, setIsHover] = useState<boolean>(false);

  useEffect(() => {
    setChecked(active);
  }, [active]);

  return (
    <ListItemButton
      onMouseEnter={() => { setIsHover(true); }}
      onMouseLeave={() => { setIsHover(false); }}
      className={styles.listItemButton}
    >
      <RubricFormContolLabel
        filter={filter}
        checked={checked}
        onChange={(e) => onChange(e.target.checked, filter)}
        isHover={isHover}
      />
    </ListItemButton>
  );
}

export default SingleRubricFilter;
