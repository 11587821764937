import {
  Box, Collapse, FormGroup, ListItemButton,
} from '@mui/material';
import { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import styles from './RubricFilter.module.scss';
import { GpRubric } from '../../../../api/types';
import RubricFormContolLabel from './RubricFormControlLabel';

interface NodParentRubricProps {
  children?: JSX.Element,
  filter: GpRubric,
  checked: boolean,
  indeterminate: boolean | undefined,
  open: boolean,
  activeNestedFiltersIds: string[],
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void,
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
}

function NodParentRubric({
  children,
  filter,
  checked,
  indeterminate,
  open,
  activeNestedFiltersIds,
  onChange,
  onClick,
}: NodParentRubricProps) {
  const [isHover, setIsHover] = useState<boolean>(false);

  return (
    <FormGroup
      className={styles.formGroup}
    >
      <ListItemButton
        onClick={onClick}
        onMouseEnter={() => { setIsHover(true); }}
        onMouseLeave={() => { setIsHover(false); }}
        className={styles.listItemButton}
      >
        <RubricFormContolLabel
          filter={filter}
          checked={checked}
          indeterminate={indeterminate}
          onChange={onChange}
          isHover={isHover}
        />
        { open
          ? <ExpandLess className={styles.expandIcon} />
          : <ExpandMore className={styles.expandIcon} />}
      </ListItemButton>
      <Collapse className={styles.collapse} in={open}>
        {
          activeNestedFiltersIds
          && (
            <Box className={styles.filtersWrap}>
              {children}
            </Box>
          )
        }
      </Collapse>
    </FormGroup>
  );
}

export default NodParentRubric;
