import { Observable, map } from 'rxjs';
import {
  createStore, select, withProps, setProp,
} from '@ngneat/elf';
import {
  addActiveIds, getActiveIds, getEntitiesCount, removeActiveIds, resetActiveIds,
  selectActiveIds, selectAllEntities, setEntities, withActiveIds, withEntities,
  upsertEntities, getActiveEntities, setActiveIds, selectActiveEntities, getAllEntities,
} from '@ngneat/elf-entities';
import {
  GpTerritories, GpRubric, GpUpdatePeriod, GpProductTypes, GpProjectTypes,
} from '../../api/types';

export const CONFIG_STORE_PREFIX = 'pageConfig.';

const territoryStore = createStore(
  { name: `${CONFIG_STORE_PREFIX}territory` },
  withEntities<GpTerritories>(),
  withActiveIds(),
  withProps<{ hasMore: boolean, search: string, fetchedCount: number }>({
    hasMore: true,
    search: '',
    fetchedCount: 0,
  }),
);

export enum OrderDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface InputOrder {
  direction: OrderDirectionEnum;
  field: string;
  tableName?: string;
}

export const territory$ = territoryStore.pipe(selectAllEntities());
export const activeTerritoriesIds$: Observable<string[]> = territoryStore.pipe(selectActiveIds());

export function hasTerritories(): boolean {
  return !!territoryStore.query(getEntitiesCount());
}

export const setTerritoriesHasMore = (hasMore: boolean) => territoryStore
  .update(setProp('hasMore', hasMore));

export const getTerritoriesHasMore = () => territoryStore.state.hasMore;

export const getTerritoriesCount = () => territoryStore.query(getEntitiesCount());

export const addTerritories = (territories: GpTerritories[]) => {
  territoryStore.update(upsertEntities(territories));
};

export function setTerritories(territories: GpTerritories[]): void {
  territoryStore.update(setEntities(territories));
}

export function addActiveTerritory(id: string): void {
  if (!territoryStore.query(getActiveIds).includes(id)) {
    territoryStore.update(addActiveIds(id));
  }
}

export function removeActiveTerritory(id: string): void {
  territoryStore.update(removeActiveIds(id));
}

export const getTerritoriesSearchInput = () => territoryStore.state.search;

export const setTerritoriesSearchInput = (search: string) => territoryStore
  .update(setProp('search', search));

export const getTerritoriesFetchedCount = () => territoryStore.state.fetchedCount;

export const setTerritoriesFetchedCount = (fetchedCount: number) => territoryStore
  .update(setProp('fetchedCount', fetchedCount));

export const addTerritoriesFetchedCount = (fetchedCount: number) => territoryStore
  .update(setProp(
    'fetchedCount',
    getTerritoriesFetchedCount() + fetchedCount,
  ));

const rubricsStore = createStore(
  { name: `${CONFIG_STORE_PREFIX}rubric` },
  withEntities<GpRubric>(),
  withActiveIds(),
  withProps<{ tempIds: string[] }>(<{ tempIds: string[] }>{
    tempIds: [],
  }),
);

export const rubrics$ = rubricsStore.pipe(selectAllEntities());
export const activeRubrics$ = rubricsStore.pipe(selectActiveEntities());
export const activeRubricsIds$: Observable<string[]> = rubricsStore.pipe(selectActiveIds());

export function hasRubrics(): boolean {
  return !!rubricsStore.query(getEntitiesCount());
}

export function setRubrics(rubrics: GpRubric[]) {
  rubricsStore.update(setEntities(rubrics));
}

export function getRubrics() {
  return rubricsStore.query(getAllEntities());
}

export function addActiveRubric(id: string): void {
  if (!rubricsStore.query(getActiveIds).includes(id)) {
    rubricsStore.update(addActiveIds(id));
  }
}

export function removeActiveRubric(id: string): void {
  rubricsStore.update(removeActiveIds(id));
}

export const setActiveRubrics = (id: string[]) => {
  rubricsStore.update(setActiveIds(id));
};

export const getActiveRubrics = () => rubricsStore.query(getActiveEntities());

export const tempRubricsIds$ = rubricsStore.pipe(select((state) => state.tempIds));
export const tempRubrics$ = tempRubricsIds$.pipe(
  map((state) => getRubrics().filter((r) => state.some((id) => id === r.id))),
);

export function setTempRubrics(rubrics: GpRubric[]) {
  rubricsStore.update(setProp('tempIds', rubrics.map((r) => r.id)));
}

export const addTempRubric = (rubric: GpRubric) => {
  if (!rubricsStore.state.tempIds.includes(rubric.id)) {
    rubricsStore.update(setProp('tempIds', [...rubricsStore.state.tempIds, rubric.id]));
  }
};

export function removeTempRubric(id: string): void {
  rubricsStore.update(setProp('tempIds', rubricsStore.state.tempIds.filter((tempId) => tempId !== id)));
}

const updatePeriodStore = createStore(
  { name: `${CONFIG_STORE_PREFIX}updatePeriod` },
  withEntities<GpUpdatePeriod>(),
  withActiveIds(),
  withProps<{ hasMore: boolean, search: string, fetchedCount: number }>({
    hasMore: true,
    search: '',
    fetchedCount: 0,
  }),
);

export const updatedPeriod$ = updatePeriodStore.pipe(selectAllEntities());
// eslint-disable-next-line max-len
export const activeUpdatePeriodIds$: Observable<string[]> = updatePeriodStore.pipe(selectActiveIds());

export function hasUpdatePeriods(): boolean {
  return !!updatePeriodStore.query(getEntitiesCount());
}

export function setUpdatePeriods(updatePeriods: GpUpdatePeriod[]) {
  updatePeriodStore.update(setEntities(updatePeriods));
}

export function addActiveUpdatePeriod(id: string): void {
  if (!updatePeriodStore.query(getActiveIds).includes(id)) {
    updatePeriodStore.update(addActiveIds(id));
  }
}

export function removeActiveUpdatePeriod(id: string): void {
  updatePeriodStore.update(removeActiveIds(id));
}

export const setUpdatePeriodHasMore = (hasMore: boolean) => updatePeriodStore
  .update(setProp('hasMore', hasMore));

export const getUpdatePeriodHasMore = () => updatePeriodStore.state.hasMore;

export const getUpdatePeriodCount = () => updatePeriodStore.query(getEntitiesCount());

export const addUpdatePeriod = (updatePeriods: GpUpdatePeriod[]) => {
  updatePeriodStore.update(upsertEntities(updatePeriods));
};

export const getUpdatePeriodSearchInput = () => updatePeriodStore.state.search;

export const setUpdatePeriodSearchInput = (search: string) => updatePeriodStore
  .update(setProp('search', search));

export const getUpdatePeriodFetchedCount = () => updatePeriodStore.state.fetchedCount;

export const setUpdatePeriodFetchedCount = (fetchedCount: number) => updatePeriodStore
  .update(setProp('fetchedCount', fetchedCount));

export const addUpdatePeriodFetchedCount = (fetchedCount: number) => updatePeriodStore
  .update(setProp(
    'fetchedCount',
    getTerritoriesFetchedCount() + fetchedCount,
  ));

const productTypesStore = createStore(
  { name: `${CONFIG_STORE_PREFIX}productType` },
  withEntities<GpProductTypes>(),
  withActiveIds(),
  withProps<{ hasMore: boolean, search: string, fetchedCount: number }>({
    hasMore: true,
    search: '',
    fetchedCount: 0,
  }),
);

export const productTypes$ = productTypesStore.pipe(selectAllEntities());
// eslint-disable-next-line max-len
export const activeProductTypesIds$: Observable<string[]> = productTypesStore.pipe(selectActiveIds());

export function hasProductTypes(): boolean {
  return !!productTypesStore.query(getEntitiesCount());
}

export function setProductTypes(productTypes: GpProductTypes[]) {
  productTypesStore.update(setEntities(productTypes));
}

export function addActiveProductTypes(id: string): void {
  if (!productTypesStore.query(getActiveIds).includes(id)) {
    productTypesStore.update(addActiveIds(id));
  }
}

export function removeActiveProductTypes(id: string): void {
  productTypesStore.update(removeActiveIds(id));
}

export const setProductTypesHasMore = (hasMore: boolean) => productTypesStore
  .update(setProp('hasMore', hasMore));

export const getProductTypesHasMore = () => productTypesStore.state.hasMore;

export const getProductTypesCount = () => productTypesStore.query(getEntitiesCount());

export const addProductTypes = (productTypes: GpProductTypes[]) => {
  productTypesStore.update(upsertEntities(productTypes));
};

export const getProductTypesSearchInput = () => productTypesStore.state.search;

export const setProductTypesSearchInput = (search: string) => productTypesStore
  .update(setProp('search', search));

export const getProductTypesFetchedCount = () => productTypesStore.state.fetchedCount;

export const setProductTypesFetchedCount = (fetchedCount: number) => productTypesStore
  .update(setProp('fetchedCount', fetchedCount));

export const addProductTypesFetchedCount = (fetchedCount: number) => productTypesStore
  .update(setProp(
    'fetchedCount',
    getTerritoriesFetchedCount() + fetchedCount,
  ));

const orderStore = createStore(
  { name: 'order' },
  withProps<{ order?: InputOrder }>({ }),
);

export const order$ = orderStore.pipe(select((state) => state.order));

export function setOrder(order: InputOrder | undefined): void {
  orderStore.update(() => ({ order }));
}

export function resetActiveFilters(): void {
  territoryStore.update(resetActiveIds());
  rubricsStore.update(resetActiveIds());
  updatePeriodStore.update(resetActiveIds());
  productTypesStore.update(resetActiveIds());
}

const projectTypesStore = createStore(
  { name: `${CONFIG_STORE_PREFIX}projectType` },
  withActiveIds(),
  withEntities<GpProjectTypes>(),
);

export function setProjectTypes(projectTypes: GpProjectTypes[]): void {
  projectTypesStore.update(setEntities(projectTypes));
}

export function hasProjectTypes(): boolean {
  return !!projectTypesStore.query(getEntitiesCount());
}

export function addActiveProjectType(id: string): void {
  if (!projectTypesStore.query(getActiveIds).includes(id)) {
    projectTypesStore.update(addActiveIds(id));
  }
}

export function removeActiveProjectType(id: string): void {
  projectTypesStore.update(removeActiveIds(id));
}

export const projectTypes$ = projectTypesStore.pipe(selectAllEntities());

export const activeProjectTypesIds$: Observable<string[]> = projectTypesStore.pipe(selectActiveIds());

const aclTypesStore = createStore(
  { name: `${CONFIG_STORE_PREFIX}projectType` },
  withActiveIds(),
  withEntities<GpProjectTypes>(),
);

export function setAclTypes(projectTypes: GpProjectTypes[]): void {
  aclTypesStore.update(setEntities(projectTypes));
}

export function hasAclTypes(): boolean {
  return !!aclTypesStore.query(getEntitiesCount());
}

export function addActiveAclType(id: string): void {
  if (!aclTypesStore.query(getActiveIds).includes(id)) {
    aclTypesStore.update(addActiveIds(id));
  }
}

export function removeActiveAclType(id: string): void {
  aclTypesStore.update(removeActiveIds(id));
}

export const aclTypes$ = aclTypesStore.pipe(selectAllEntities());

export const activeAclTypesIds$: Observable<string[]> = aclTypesStore.pipe(selectActiveIds());
