import React, { FC, useState } from 'react';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import { ReactComponent as SearchIcon } from '../../assets/images/search.svg';
import { sendMetricReachGoal } from '../../utils/functions';

interface SearchProps {
  className?: string;
  placeholder?: string;
  handleInputChange: (value: string) => void;
  name?: string;
  disabled?: boolean;
}

const SearchTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '&': {
      height: '40px',
      borderRadius: '20px',
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: '1.2rem',
      lineHeight: '1.5rem',
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
    },
    '& input': {
      padding: '12px 14px',
    },
    '& fieldset': {
      borderColor: 'rgba(6, 17, 54, 0.2)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(6, 17, 54, 0.2)',
      borderWidth: '1px',
    },
    '& .MuiInputAdornment-root': {
      order: 1,
      paddingRight: '10px',
    },
  },
});

const Search:FC<SearchProps> = ({
  className = '',
  placeholder = '',
  handleInputChange,
  name,
  disabled,
}) => {
  const [interval, setInterval] = useState<NodeJS.Timeout | undefined>();

  const handleInput = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (interval) {
      clearTimeout(interval);
    }
    const newInterval = setTimeout(() => {
      clearTimeout(interval);
      handleInputChange(evt.target.value.toLowerCase());
    }, 500);
    setInterval(newInterval);
  };

  const handleKeyPress = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt) {
      if (interval) {
        clearTimeout(interval);
      }
      if (evt.key === 'Enter') {
        evt.preventDefault();
        const target = evt.target as HTMLInputElement;
        handleInputChange(target.value.toLowerCase());
      }
    }
  };

  return (
    <SearchTextField
      data-testid="searchField"
      name={name}
      className={className ?? ''}
      size="small"
      fullWidth
      variant="outlined"
      placeholder={placeholder ?? ''}
      onChange={handleInput}
      disabled={disabled}
      onClick={() => sendMetricReachGoal('search')}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      onKeyPress={handleKeyPress}
    />
  );
};

export default Search;
