import {
  Box, Grid, Typography,
} from '@mui/material';
import { useObservable } from '@ngneat/react-rxjs';
import React, { useContext } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { first } from 'rxjs';
import PageTitle from '../../blocks/PageTitle/PageTitle';
import ProceedButton from '../../blocks/ProceedButton/ProceedButton';
import { DrawerContentEnum, DrawerContext } from '../../drawerContext';
import useCreateNewProject from '../../hooks/useCreateNewProject';
import { GpCmsPageBlock } from '../../api/types';
import { ResponseError } from '../../models/common';
import { ProjectType } from '../../models/project';
import {
  getDemoProjectId, getEmptyProjectId, getImagesUrlPrefix, user$,
} from '../../state/context/auth.repository';
import { setActiveRecipeAsync, setActiveRecipeTerritoryAsync } from '../../state/geoData/recipes.effects';
import { getActiveTerritoryId, getMissingScheduleWithLayer } from '../../state/geoData/recipes.repository';
import {
  activePageBlocks$,
  activePageText$,
  activePageTitle$,
  pagesRequestPending$,
  pagesRequestStatus$,
} from '../../state/pages/pages.repository';
import { fetchAclAsync } from '../../state/project/project.actions';
import { createSideMarkup } from '../../utils/functions';

import styles from './DynamicPage.module.scss';
import Loader from '../../components/Loader/Loader';

function DynamicPage() {
  const navigate = useNavigate();
  const [pageTitle] = useObservable(activePageTitle$);
  const [pageText] = useObservable(activePageText$);
  const [pageBlocks] = useObservable(activePageBlocks$);
  const [pageRequestPending] = useObservable(pagesRequestPending$);
  const [pageRequestStatus] = useObservable(pagesRequestStatus$);
  const [user] = useObservable(user$);
  const drawerContext = useContext(DrawerContext);
  const createNewProject = useCreateNewProject();

  const navigateToNewProject = async () => {
    await fetchAclAsync();
    await setActiveRecipeAsync(getEmptyProjectId() as string);

    const activeTerritory: string = getActiveTerritoryId();
    if (activeTerritory) {
      await setActiveRecipeTerritoryAsync(activeTerritory);
    }

    const missingLayers = getMissingScheduleWithLayer();
    if (!missingLayers.length) {
      await createNewProject(ProjectType.GPProject);
    } else {
      drawerContext.open(DrawerContentEnum.CHOOSE_RECIPE_LAYERS);
    }
  };

  const handleNewProjectClick = () => {
    if (user) {
      void navigateToNewProject();
    } else {
      const newProjectCallback = () => {
        user$.pipe(first()).subscribe((user) => {
          if (user) {
            void navigateToNewProject();
          }
        });
      };
      drawerContext.open(DrawerContentEnum.LOGIN, () => newProjectCallback);
    }
  };

  const handleDemoProjectClick = () => {
    const projectId = getDemoProjectId();

    if (projectId) {
      if (user) {
        navigate(generatePath('/map/:projectId', { projectId }));
      } else {
        const demoCallback = () => {
          user$.pipe(first()).subscribe((user) => {
            if (user) {
              navigate(generatePath('/map/:projectId', { projectId }));
            }
          });
        };
        drawerContext.open(DrawerContentEnum.LOGIN, () => demoCallback);
      }
    }
  };

  const handleDetailClick = (block: GpCmsPageBlock) => (event: React.MouseEvent) => {
    event.stopPropagation();
    if (block.detailCmd) {
      navigate(block.detailCmd);
    }
  };

  const renderPageHeader = () => (
    <>
      <PageTitle pageTitle={pageTitle} />

      <Box
        className={`${styles.dynamicSubtitle} cms-styles cms-main`}
        dangerouslySetInnerHTML={createSideMarkup(pageText)}
      />
    </>
  );

  const renderPageBlocks = (block: GpCmsPageBlock) => (
    <Grid
      data-testid={block.id}
      item
      xs={block.widthInGrid}
      key={block.id}
      onClick={(e) => {
        if (block.actionCmd) {
          e.stopPropagation();
          switch (block.actionCmd) {
            case '/project/new':
              handleNewProjectClick();
              break;
            case '/project/demo':
              handleDemoProjectClick();
              break;
            default:
              navigate(block.actionCmd);
              break;
          }
        }
      }}
    >
      <div
        className={`${styles.dynamicBlock} ${styles.blockProject}`}
        style={
          block.bgImg
            ? { backgroundImage: `url(${getImagesUrlPrefix()}${block.bgImg}` }
            : { backgroundColor: '#061136' }
        }
      >
        <div
          className={`${styles.blockHeader} cms-styles cms-main`}
          dangerouslySetInnerHTML={createSideMarkup(block.title)}
        />
        <div
          className={`${styles.blockTitle} cms-styles cms-main`}
          dangerouslySetInnerHTML={createSideMarkup(block.subtitle)}
        />
        <div className={styles.blockBottom}>
          <span className={styles.blockStart}>{ block.actionText }</span>
          <ProceedButton color="secondary" onClick={handleDetailClick(block)} />
        </div>
      </div>
    </Grid>
  );

  if (pageRequestStatus.value === 'error') {
    return (
      <Typography variant="h5" sx={{ textAlign: 'center' }}>
        Error:
        {' '}
        {(pageRequestStatus.error as ResponseError).localizedMessage}
      </Typography>
    );
  }

  return (
    <>
      {
        pageRequestPending
          ? <Loader id="progress-dynamic-page" />
          : renderPageHeader()
      }

      <Box sx={{ mt: '12px', pb: '12px' }}>
        <Grid container spacing={2}>
          {
            pageBlocks
              .sort((item1, item2) => item1.num - item2.num)
              .map((block) => block.block)
              .map((block) => renderPageBlocks(block))
          }
        </Grid>
      </Box>
    </>
  );
}

export default DynamicPage;
