import {
  FC, useCallback, useState, MouseEvent,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  IconButton, Stack, Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem } from './Menu';
import ConfirmModal from '../../../../feature/Account/components/Projects/components/ConfirmModal/ConfirmModal';
import { getProductIcon, getProductName, getAclProductIcon } from './helpers/headerHelpers';
import { LayerType } from '../../../../components/ModalProjectTabs/hooks/models';
import styles from './CardHeader.module.scss';

interface CardHeaderProps {
  typeName?: string;
  typeId?: string;
  isProject?: boolean;
  projectId?: string;
  projectName?: string;
  productType?: LayerType;
}

const CardHeader:FC<CardHeaderProps> = ({
  typeId, typeName, isProject, projectId, projectName, productType,
}) => {
  const { t: translate } = useTranslation('common');
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handeDeleteProject = useCallback(() => {
    setOpenModal(true);
    setMenuAnchorEl(null);
  }, []);

  const handleOpenMenu = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setMenuAnchorEl(e.currentTarget);
  }, []);
  return (
    <Stack className={styles.header} direction="row">
      <Typography variant="textCard" sx={{ opacity: '0.5' }} align="right">
        {typeName}
        { productType && getProductName(productType) }
      </Typography>
      {typeId && getProductIcon(typeId)}
      { productType && getAclProductIcon(productType) }
      { isProject && (
      <>
        <IconButton onClick={handleOpenMenu}>
          <MoreVertIcon fontSize="small" />
        </IconButton>
        <Menu
          open={!!menuAnchorEl}
          anchorEl={menuAnchorEl}
          onClose={() => setMenuAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={handeDeleteProject}>
            <Typography variant="button" fontWeight={700} color="primary.main">
              {translate('projectCardMenu.delete_project')}
            </Typography>
          </MenuItem>
        </Menu>
        <ConfirmModal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          projectTitle={projectName || ''}
          projectId={projectId || ''}
        />
      </>
      ) }
    </Stack>
  );
};

export default CardHeader;
