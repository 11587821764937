import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PaymentCard from '../PaymentCard/PaymentCard';
import ShopIcon from '../../../assets/images/shop.svg';
import CopyIcon from '../../../assets/images/copy-success.svg';
import TaskIcon from '../../../assets/images/task-square.svg';

export interface IPaymentCard {
  title: string;
  items: string[];
  buttonTitle: string;
  img: string;
  onClick: () => void;
}

const PaymentList = () => {
  const { t: translate } = useTranslation('common');
  const navigate = useNavigate();
  const paymentsCards = useMemo<IPaymentCard[]>(() => {
    const translates = translate('payment.cards', { returnObjects: true }) as unknown as IPaymentCard[];
    const commonArr = [
      { img: ShopIcon, onClick: () => { navigate('/marketplace'); } },
      { img: CopyIcon, onClick: () => { navigate('/account'); } },
      { img: TaskIcon, onClick: () => { navigate('/help'); } },
    ];

    return translates.map((card, index) => ({
      ...card,
      img: commonArr[index].img,
      onClick: commonArr[index].onClick,
    }));
  }, []);

  const [searchParams] = useSearchParams();
  const result = searchParams.get('result');

  return (
    <Stack gap="20px">
      <Typography variant="h2" fontSize="3rem">
        { result === 'success' ? translate('payment.continue') : translate('payment.you_can_сontinue') }
      </Typography>
      <Stack direction="row" justifyContent="space-between" gap="1.5rem">
        { paymentsCards.map((item) => <PaymentCard key={item.title} {...item} />) }
      </Stack>
    </Stack>
  );
};

export default PaymentList;
