import * as api from '../../services/geo-api.service';
import { setOrdersRequestSuccess } from './order.repository';
import * as orderRepository from './order.repository';
import * as orderService from './order.service';
import { tryGetResponseData } from '../common';
import { DEFAULT_DATE_TIME } from '../../utils/constants';
import {
  GpdsSchedule,
  GpLayer,
  GpOrder,
  GpOrderItem,
  GpSchedule,
  GpStatDataset,
  OrderStatuses,
  Price,
} from '../../api/types';

export interface GPScheduleProduct {
  schedule: GpSchedule | GpdsSchedule;
  product: GpLayer | GpStatDataset;
}

function getActiveOrder() {
  const activeOrder = orderRepository.getActiveOrder();
  if (!activeOrder) {
    throw new Error('No active order');
  }

  return activeOrder;
}

async function setBasketPrice(promocode: string) {
  const { id } = getActiveOrder();
  const res = await api.getBasketPrice(id, promocode);
  const basket = tryGetResponseData(res).GPOrderPrice.get as Price;
  if (basket && basket.price !== 0) {
    orderRepository.setBasketPrice(basket);
  } else {
    orderRepository.resetBasketPrice();
  }
}

async function updateOrderPrice(orderId: string) {
  const promocode = orderRepository.getPromocodeSnapshot();
  try {
    const res = await api.getBasketPrice(orderId, promocode);
    const orderPrice = tryGetResponseData(res).GPOrderPrice.get;
    if (orderPrice && orderPrice.price !== 0) {
      orderRepository.setBasketPrice(orderPrice as Price);
    } else {
      orderRepository.resetBasketPrice();
    }
    orderRepository.setOrdersRequestSuccess();
  } catch (e) {
    orderRepository.setOrdersRequestError(e);
  }
}

async function createNewOrder(orderIn: Partial<GpOrder>) {
  const res = await orderService.createOrder(orderIn);
  const order = tryGetResponseData(res).GPOrder.create;
  orderRepository.upsertOrder(order as GpOrder);
  orderRepository.setActiveOrder(order.id);
  await updateOrderPrice(order.id);
}

export const fetchOrders = async () => {
  const res = await orderService.fetchOrders();
  const orders = tryGetResponseData(res).GPOrder.list.data as GpOrder[];

  const activeOrder = orders.find((order) => order.status === OrderStatuses.Created);
  if (activeOrder) {
    const resOrder = await api.getOrder(activeOrder.id);
    const order = tryGetResponseData(resOrder).GPOrder.get as GpOrder;
    orderRepository.upsertOrder(order);
    orderRepository.setActiveOrder(order.id);

    if (order.orderItems.length) {
      await updateOrderPrice(order.id);
    } else {
      orderRepository.setOrdersRequestSuccess();
    }
  } else {
    await createNewOrder({
      status: OrderStatuses.Created,
      dateTime: DEFAULT_DATE_TIME,
      orderItems: [],
    });
  }
};

export const fetchFullOrders = async (page: number, limit: number) => {
  const res = await orderService.fetchFullOrders(page, limit);
  const orders = tryGetResponseData(res).GPOrder.list.data as GpOrder[];
  const { totalCount, hasMore } = tryGetResponseData(res).GPOrder.list;
  orderRepository.setOrdersFullFlagTrue();
  orderRepository.setOrders(orders, totalCount, hasMore);
};

async function updateOrderAsync(orderId: string) {
  const order = orderRepository.getOrder(orderId);
  if (!order) {
    return;
  }

  await orderService.updateOrder(order.id, order);
  await updateOrderPrice(order.id);
}

const productToProductIn = (product: GpLayer | GpStatDataset) => {
  const cloneProduct = { ...product };
  delete cloneProduct.__typename;
  return cloneProduct;
};

export async function addItemsToActiveOrder(...schedulesWithProduct: GPScheduleProduct[]) {
  const orderItems: GpOrderItem[] = schedulesWithProduct.map((x) => ({
    id: x.schedule.id,
    productPackaging: [x.schedule],
    productCategory: [productToProductIn(x.product)],
    selFlag: true,
  } as GpOrderItem));
  const order = orderRepository.getActiveOrder();
  if (!order || order.status !== OrderStatuses.Created) {
    await createNewOrder({
      orderItems,
      status: OrderStatuses.Created,
      dateTime: DEFAULT_DATE_TIME,
    });
  } else {
    orderRepository.addOrderItems(order.id, ...orderItems);
    await updateOrderAsync(order.id);
  }
}

export const toggleAllOrderSchedules = async () => {
  orderRepository.toggleAllOrderItemsSelection();
  const { id } = getActiveOrder();
  await updateOrderAsync(id);
};

export async function removeItemFromActiveOrder(layerId: string) {
  const activeOrder = orderRepository.getActiveOrder();
  if (!activeOrder) {
    return;
  }
  orderRepository.removeOrderItem(activeOrder.id, layerId);
  await updateOrderAsync(activeOrder.id);
}

export const setPromocode = async (promocode: string) => {
  orderRepository.setPromocode(promocode);
  await setBasketPrice(promocode);
};

export const toggleOrderItem = async (orderItemId: string) => {
  orderRepository.toggleOrderItemSelection(orderItemId);
  const { id } = getActiveOrder();
  await updateOrderAsync(id);
};

export const removeActiveItemFromOrder = async () => {
  orderRepository.removeSelectedOrderItems();
  const activeOrder = getActiveOrder();
  const orderItems = activeOrder.orderItems.filter((x) => !x.selFlag);
  if (orderItems.length === 0) {
    orderRepository.setPromocode('');
  }
  await updateOrderAsync(activeOrder.id);
};

export const deactivateActiveItemFromOrder = async () => {
  orderRepository.deselectAllOrderItems();
  const { id, orderItems } = getActiveOrder();
  await orderService.updateOrder(id, { orderItems });
  await updateOrderAsync(id);
};

export const deleteOrder = async (id: string) => {
  const res = await orderService.deleteOrder(id);
  tryGetResponseData(res);
  orderRepository.deleteOrder(id);
};

export const checkPaymentStatus = async (id: string) => {
  const res = await orderService.fetchPaymentStatus(id);
  const { status } = tryGetResponseData(res).PKPaymentState.get[0];
  if (status === 'success') {
    orderRepository.setPaymentStatus('success');
  } else {
    orderRepository.setPaymentStatus('fail');
  }
};

export const updateOrderStatus = async (status: OrderStatuses) => {
  const activeOrder = getActiveOrder();
  await orderService.updateOrder(activeOrder.id, { status });
  setOrdersRequestSuccess();
};

export const updateProductPackagingPrice = async () => {
  const { id, orderItems } = getActiveOrder();
  const orderItemsProps = orderRepository.getOrderItemsProps();
  const newOrderItems = orderItems.map((item) => {
    const productPackaging = structuredClone(item.productPackaging[0]);
    if ('layer' in productPackaging) {
      const price = orderItemsProps
        .find((itemProps) => itemProps.layer.uName === productPackaging.layer.uName)?.price;
      productPackaging.price = price!;
      return {
        ...item,
        productPackaging: [productPackaging],
      };
    }
    const price = orderItemsProps
      .find((itemProps) => itemProps.layer.uName === productPackaging.dataset.uName)?.price;
    productPackaging.price = price!;
    return {
      ...item,
      productPackaging: [productPackaging],
    };
  });
  await orderService.updateOrder(id, { orderItems: newOrderItems });
  setOrdersRequestSuccess();
};

export const resetOrderStore = () => { orderRepository.resetOrderStore(); };
