import { GpCmsPage } from '../../api/types';
import * as api from '../../services/geo-api.service';
import { tryGetResponseData } from '../common';
import { setPages, getPage, setActivePage as setActPage } from './pages.repository';

export async function fetchPages() {
  const res = await api.getPages();
  const pages = tryGetResponseData(res).GPCmsPage.list?.data as GpCmsPage[];
  setPages(pages);
}

export async function setActivePage(pathname: string) {
  await fetchPages();
  const page = getPage(pathname);
  setActPage(page);
}
