import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useObservable } from '@ngneat/react-rxjs';
import {
  Stack,
  Typography,
} from '@mui/material';
import {
  productTypes$,
  activeAclTypesIds$,
  addActiveAclType,
  removeActiveAclType,
} from '../../state/filters/filters.repository';
import { availableLayersOrDataSet$ } from '../../state/acl/acl.repository';
import { fetchFilters } from '../../state/filters/filters.effects';
import FilterGroup from '../FilterGroup/FilterGroup';
import { IFilter } from '../../models/filters';
import { GpProductTypes } from '../../api/types';
import { sendMetricReachGoal } from '../../utils/functions';
import styles from './LayersFilters.module.scss';
import { ProductTypeId } from '../../models/product';

function filterLayersProductTypes(filter: GpProductTypes) {
  return filter.id === ProductTypeId.Layer || filter.id === ProductTypeId.StatDataset;
}

const LayersFilters = () => {
  const { t: translate } = useTranslation('common');
  const [productTypes] = useObservable(productTypes$);
  const [acl] = useObservable(availableLayersOrDataSet$);
  const [activeAclTypesIds] = useObservable(activeAclTypesIds$);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    void fetchFilters('productType');
  }, []);

  const handleProductTypesClick = () => {
    setOpen(!open);
    sendMetricReachGoal('productTypesFilter');
  };

  const handleProductTypesFilterChange = (active: boolean, id: string) => {
    if (active) {
      addActiveAclType(id);
    } else {
      removeActiveAclType(id);
    }
  };

  return (
    <Stack className={styles.filters}>
      <Stack direction="row" gap="10px">
        <Typography variant="h4">{ translate('account.filters') }</Typography>
        <Typography variant="accentTitle1" className={styles.filtersCount}>{ acl.length }</Typography>
      </Stack>

      <FilterGroup
        title={translate('layer.filter_product_type')}
        open={open}
        onClick={handleProductTypesClick}
        dataTestId="productTypesFilter"
        filters={
          productTypes
            .filter((p) => filterLayersProductTypes(p))
            .map<IFilter>((p) => ({
            id: p.id,
            title: p.name,
          }))
        }
        activeFiltersIds={activeAclTypesIds}
        onFilterChange={handleProductTypesFilterChange}
        filterType="productType"
      />
    </Stack>
  );
};

export default LayersFilters;
