import { styled, Switch as MuiSwitch } from '@mui/material';

const Switch = styled(MuiSwitch)(({ theme }) => ({
  '.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    backgroundColor: theme.palette.primary.main,
  },
  '& .MuiSwitch-thumb': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:after': {
      content: '""',
      height: '12px',
      width: '12px',
      backgroundColor: theme.palette.primary.dark,
      borderRadius: '50%',
    },
  },
}));

export default Switch;
