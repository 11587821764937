import L, { icon, Marker } from 'leaflet';

export const initMarkersSrc = () => {
  // @ts-ignore
  // eslint-disable-next-line no-underscore-dangle
  delete L.Icon.Default.prototype._getIconUrl;

  const iconRetinaUrl = '/marker-icon-2x.png';
  const iconUrl = '/marker-icon.png';
  const shadowUrl = '/marker-shadow.png';
  const iconDefault = icon({
    iconRetinaUrl,
    iconUrl,
    shadowUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
  });
  Marker.prototype.options.icon = iconDefault;
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: '/marker-icon-2x.png',
    iconUrl: '/marker-icon.png',
    shadowUrl: '/marker-shadow.png',
  });
};

export default { initMarkersSrc };
