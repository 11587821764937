import { useContext } from 'react';
import {
  Box, Button,
} from '@mui/material';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import { useObservable } from '@ngneat/react-rxjs';
import { useTranslation } from 'react-i18next';
import { Routes, Route, useLocation } from 'react-router-dom';
import { DrawerContentEnum, DrawerContext } from '../../drawerContext';
import Contacts from '../../blocks/Contacts/Contacts';
import MarketplaceFiltes from '../../blocks/MarketplaceFilters/MarketplaceFilters';

import {
  activeAccountId$, isGuestMode$, user$, token$,
} from '../../state/context/auth.repository';
import styles from './Side.module.scss';
import SideMap from '../../pages/SideMap';
import SideCalculation from '../../pages/SideCalculation';
import UserSection from '../../components/UserSection';
import GuestGuard from '../../feature/Guards/GuestGuard/GuestGuard';
import SideHome from '../../feature/Side/pages/SideHome/SideHome';
import { sendMetricReachGoal } from '../../utils/functions';
import ProjectFilters from '../../blocks/ProjectFilters/ProjectFilters';
import LayersFilters from '../../blocks/LayersFilters/LayersFilters';

function useAuthReady() {
  const [accountId] = useObservable(activeAccountId$);
  const [token] = useObservable(token$);
  const [user] = useObservable(user$);
  return [(accountId && token && user) as boolean];
}

function LoginButton() {
  const drawerContext = useContext(DrawerContext);
  const [isGuestMode] = useObservable(isGuestMode$);
  const [isAuthReady] = useAuthReady();

  const { t } = useTranslation('common');

  return isAuthReady && isGuestMode
    ? (
      <Button
        data-testid="drawerButton"
        onClick={() => {
          drawerContext.open(DrawerContentEnum.LOGIN);
          sendMetricReachGoal('drawerButton');
        }}
        variant="contained"
        className={styles['login-button']}
      >
        <LoginOutlinedIcon />
        {t('side.signIn')}
      </Button>
    ) : null;
}

function UserSectionOrLogin({ projectOpen } : { projectOpen: boolean }) {
  const [isGuestMode] = useObservable(isGuestMode$);
  const [isAuthReady] = useAuthReady();

  return (
    <div className={styles.sideUser}>
      {isAuthReady && !isGuestMode ? (
        <Box ml="auto">
          <UserSection projectOpen={projectOpen} />
        </Box>
      ) : <LoginButton />}
    </div>
  );
}

function Side() {
  const location = useLocation();
  return (
    <Box className={styles.side} display="flex" flexDirection="column" sx={{ backgroundColor: 'text.primary' }}>
      <Routes>
        <Route
          path="/map/*"
          element={(
            <GuestGuard>
              <SideMap />
            </GuestGuard>
        )}
        />
        <Route path="/map/calculate" element={<SideCalculation />} />
        <Route
          path="*"
          element={(
            <>
              <UserSectionOrLogin projectOpen={false} />
              <SideHome />
            </>
          )}
        />
        <Route
          path="/marketplace"
          element={(
            <>
              <UserSectionOrLogin projectOpen={false} />
              <MarketplaceFiltes />
            </>
          )}
        />
        <Route
          path="/account/projects"
          element={(
            <>
              <UserSectionOrLogin projectOpen={false} />
              <SideHome />
              <ProjectFilters />
            </>
          )}
        />
        <Route
          path="/account/layers"
          element={(
            <>
              <UserSectionOrLogin projectOpen={false} />
              <SideHome />
              <LayersFilters />
            </>
          )}
        />
      </Routes>
      {location.pathname.includes('map') ? '' : (
        <Box mt="auto">
          <Contacts />
        </Box>
      )}
    </Box>
  );
}

export default Side;
