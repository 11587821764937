import { Typography, Stack } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GpuiLayer, GpLayer, GpuiDataset, GpStatDataset, ProductUnion,
} from '../../../../api/types';
import {
  getRecipeValues, getSchedules, getServiceValues, getValuesName,
} from './helpers/typesHelpers';
import {
  renderLayers, renderRecipeValues, renderSchedules, renderServiceValues,
} from './helpers/renderHelpers';
import styles from './CardBody.module.scss';
import { FlatSchedule } from '../../../../components/AclViews/AclViewsHelpers';
import SchedulesList from '../../../../components/AclViews/common/ProductSchedule';
import { ProductTypeId } from '../../../../models/product';

interface CardBodyProps {
  typeId?: string;
  layersOrDatasets?: GpuiLayer[] | GpuiDataset[] | null;
  products?: (GpLayer | GpStatDataset)[];
  product?: ProductUnion;
  aclSchedules?: FlatSchedule[];
  addButton?: boolean;
}

const CardBody:FC<CardBodyProps> = ({
  layersOrDatasets, typeId, products, product, aclSchedules, addButton,
}) => {
  const { t: translate } = useTranslation('common');

  const schedules = getSchedules(product);
  const recipeValues = getRecipeValues(product);
  const serviceValues = getServiceValues(product);

  return (
    <Stack gap="10px">
      <Typography variant="H2card" color="text.primary">
        { typeId && translate(getValuesName(typeId)) }
        { aclSchedules && translate('project.periods') }
        { typeId !== ProductTypeId.Recipe
          ? ` (${layersOrDatasets?.length
            || schedules?.length || recipeValues?.length || serviceValues?.length || aclSchedules?.length || 0}):`
          : null }
      </Typography>
      <Stack gap="10px" className={styles.listContainer}>
        {layersOrDatasets && products && renderLayers(layersOrDatasets, products) }
        { renderSchedules(schedules) }
        { renderRecipeValues(recipeValues) }
        { renderServiceValues(serviceValues) }
        { aclSchedules && <SchedulesList flatLayersMap={aclSchedules} addButton={addButton} />}
      </Stack>
    </Stack>
  );
};

export default CardBody;
