import React from 'react';
import { DrawerProps } from '@mui/material';

export enum DrawerContentEnum {
  EMPTY,
  LOGIN,
  REGISTER,
  CHOOSE_PROJECT,
  BUY_LAYER,
  BUY_LAYER_UPDATE,
  CHOOSE_RECIPE_LAYERS,
  CART,
}

export type CallbackFn = () => void;
export type Variant = DrawerProps['variant'];

export interface IDrawerContext {
  isOpen: boolean;
  content: DrawerContentEnum;
  variant: Variant;
  open(content: DrawerContentEnum, callbackFn?: CallbackFn, variant?: Variant): void;
  close(): void;
  setContent(content: DrawerContentEnum): void;
}

export const DrawerContext = React.createContext<IDrawerContext>({
  isOpen: false,
  content: DrawerContentEnum.EMPTY,
  variant: 'temporary',
  open: () => { },
  close: () => { },
  setContent: () => { },
});
