/* eslint-disable  */
import * as L from 'leaflet';

export function initLayerCanvas() {
  L.TileLayer.Canvas = L.TileLayer.extend({
    _delays: {},
    _delaysForZoom: null,
    createCanvas: function(e, t, a) {
      var s, o = e.getContext("2d"),
        i = this.getTileSize(),
        r = i.x,
        n = i.y;
      e.width = r, e.height = n;
      var l = new Image;
      l.onload = function() {
        try {
          o.drawImage(l, 0, 0), e.complete = !0
        } catch (e) {
          s = e
        } finally {
          a(s, e)
        }
      };
      var c = this._getZoomForUrl();
      l.src = isNaN(c) ? "" : this.getTileUrl(t), l.crossOrigin = "anonymous"
    },
    createTile: function(e, t) {
      var a = this,
        s = this.options.timeout,
        o = e.z,
        i = document.createElement("canvas");
      return s ? (o !== this._delaysForZoom && (this._clearDelaysForZoom(), this._delaysForZoom = o), this._delays[o] || (this._delays[o] = []), this._delays[o].push(setTimeout((function() {
        a.createCanvas(i, e, t)
      }), s))) : this.createCanvas(i, e, t), i
    },
    _clearDelaysForZoom: function() {
      var e = this._delaysForZoom,
        t = this._delays[e];
      t && (t.forEach((function(e, a) {
        clearTimeout(e), delete t[a]
      })), delete this._delays[e])
    }
  }), L.tileLayer.canvas = function(e, t) {
    return new L.TileLayer.Canvas(e, t)
  };
}
