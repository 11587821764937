import {
  Box, Checkbox, FormControlLabel, Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { IFilter } from '../../models/filters';
import checkboxImg from '../../assets/images/checkbox.svg';
import checkedImg from '../../assets/images/checked-checkbox.svg';
import indeterminateImg from '../../assets/images/indeterminate.svg';
import styles from './Filter.module.scss';

interface FilterProps {
  id: string,
  title: string,
  active: boolean,
  onChange: (active: boolean, id: string) => void,
  nestedFilters?: IFilter[],
  activeNestedFiltersIds?: string[],
  scrollable?: boolean,
  disabled: boolean,
}

function Filter({
  id, title, active, onChange, nestedFilters, activeNestedFiltersIds, scrollable, disabled,
}: FilterProps) {
  const [checked, setChecked] = useState<boolean>(active);
  const [indeterminate, setIndeterminate] = useState<boolean>();

  useEffect(() => {
    if (nestedFilters && activeNestedFiltersIds) {
      setChecked(nestedFilters.length === activeNestedFiltersIds.length);
      setIndeterminate(
        activeNestedFiltersIds.length > 0
        && nestedFilters.length !== activeNestedFiltersIds.length,
      );
    } else {
      setChecked(active);
      setIndeterminate(undefined);
    }
  }, [active, nestedFilters, activeNestedFiltersIds]);

  function handleParentChange(active: boolean) {
    nestedFilters!.forEach((nestedFilter) => onChange(active, nestedFilter.id));
  }

  return (
    <>
      <FormControlLabel
        key={id}
        disabled={disabled}
        className={scrollable
          ? styles.formControlLabelScroll
          : styles.formControlLabel}
        control={(
          <Checkbox
            className={styles.checkbox}
            icon={<img src={checkboxImg} alt="" />}
            checkedIcon={<img src={checkedImg} alt="" />}
            indeterminateIcon={<img src={indeterminateImg} alt="" />}
            value={checked}
            checked={checked}
            indeterminate={indeterminate}
            onChange={
              nestedFilters && activeNestedFiltersIds
                ? (e) => handleParentChange(e.target.checked)
                : (e) => onChange(e.target.checked, id)
            }
          />
        )}
        label={<Typography variant="body2" color="#b4b8c3">{title}</Typography>}
        labelPlacement="start"
      />
      {
        nestedFilters && activeNestedFiltersIds
        && (
        <Box className={styles.filtersWrap}>
          { nestedFilters.map((filter) => (
            <Filter
              id={filter.id}
              key={filter.id}
              title={filter.title}
              active={activeNestedFiltersIds.includes(filter.id)}
              onChange={onChange}
              disabled={disabled}
            />
          ))}
        </Box>
        )
      }
    </>
  );
}

export default Filter;
