import { Button, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useObservable } from '@ngneat/react-rxjs';
import { useTranslation } from 'react-i18next';
import styles from './FirstViewAcl.module.scss';
import { addUiItem, layersMap$, uiDataset$ } from '../../../state/project/project.repository';
import HorizontalCard from '../../../blocks/Card/HorizontalCard';
import { FlatLayersListItem } from '../../ModalProjectTabs/hooks/models';

interface AclSchedulesProps {
  layers: FlatLayersListItem[];
  addButton?: boolean;
}

interface FirstViewAclRowProps {
  layers: FlatLayersListItem;
  addButton?: boolean;
}

const Card:FC<FirstViewAclRowProps> = ({ layers, addButton }) => {
  const { t } = useTranslation('common');
  const [uiDataset] = useObservable(uiDataset$);
  const [layersMap] = useObservable(layersMap$);

  const handlerDisabledButton = (
    layersMap.some((l) => l.uName === layers.uName)
    || (uiDataset && uiDataset.flat().some((d) => d.uName === layers.uName))
  );

  const handleAddUiItem = () => addUiItem(layers.layerMap);

  const sideBodyLeft = (
    <>
      <Typography variant="subtitle1" color="primary.dark">
        { layers.layerTitle }
      </Typography>
      <Typography variant="body2" color="text.disabled">
        { layers.uName }
      </Typography>
    </>
  );

  const body = (
    <>
      <Typography variant="body2" fontWeight={700} color="primary.dark">
        {layers.scheduleTitle}
      </Typography>
      <Typography variant="body2" color="text.disabled">
        { layers.territoryName }
      </Typography>
    </>
  );

  const sideBodyRight = addButton
    ? (
      <Button
        variant="contained"
        onClick={handleAddUiItem}
        className={styles.button}
        disabled={handlerDisabledButton}
      >
        {t('acl.add')}
      </Button>
    )
    : null;

  return (
    <HorizontalCard
      sideBodyLeft={sideBodyLeft}
      body={body}
      sideBodyRight={sideBodyRight}
    />
  );
};

const FirstViewAcl:FC<AclSchedulesProps> = ({ layers, addButton }) => {
  const layerList = layers.map((el) => (
    <Card layers={el} addButton={addButton} key={el.uName} />
  ));

  return (
    <Stack className={styles.wrapper}>
      { layers.length > 0
        ? layerList
        : null}
    </Stack>
  );
};

export default FirstViewAcl;
