import { FC, useCallback } from 'react';
import {
  Button,
  IconButton, Modal, Stack, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseConfirmModalIcon } from '../../../../../../assets/images/closeConfirmModal.svg';
import { deleteProject } from '../../../../../../state/project/project.actions';
import { removeSpecialChar } from '../../../../../../utils/functions';
import styles from './ConfirmModal.module.scss';

interface ConfirmModalProps {
  projectId: string;
  isOpen: boolean;
  projectTitle: string;
  onClose: () => void;
}

const ConfirmModal:FC<ConfirmModalProps> = ({
  isOpen,
  onClose,
  projectTitle,
  projectId,
}) => {
  const { t: translate } = useTranslation('common');
  const removeProjectHandler = useCallback(async () => {
    void deleteProject(projectId);
  }, [projectId]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Stack className={styles.modal}>
        <Stack direction="row" justifyContent="flex-end">
          <Typography variant="h2" className={styles.modalTitle}>
            { translate('projectCardMenu.delete_modal') }
          </Typography>
          <IconButton onClick={onClose} className={styles.modalClose}>
            <CloseConfirmModalIcon />
          </IconButton>
        </Stack>
        <Typography variant="body1" className={styles.modalQuestion}>
          { translate('projectCardMenu.question', { projectTitle: removeSpecialChar(projectTitle) }) }
        </Typography>
        <Stack direction="row" justifyContent="center" gap="20px">
          <Button className={styles.modalButtonDelete} onClick={removeProjectHandler}>
            <Typography variant="subtitle2" color="secondary.main">
              {translate('projectCardMenu.delete')}
            </Typography>
          </Button>
          <Button onClick={onClose} className={styles.modalButtonCancel}>
            <Typography variant="subtitle2" color="primary.main">
              {translate('projectCardMenu.cancel')}
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default ConfirmModal;
