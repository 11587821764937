import { Autocomplete, Box } from '@mui/material';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GpTerritories } from '../../../api/types';
import { setActiveRecipeTerritoryAsync } from '../../../state/geoData/recipes.effects';
import ProjectNameTextField from '../ui/SideTextField';

interface TerritoriesAutoCompleteProps {
  cities: GpTerritories[];
  value: GpTerritories | null;
  setValue: React.Dispatch<React.SetStateAction<GpTerritories | null>>;
}

const TerritoriesAutoComplete = ({ cities, value, setValue }: TerritoriesAutoCompleteProps) => {
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation('common');

  return (
    <Autocomplete
      id="recipe-territories-select"
      data-testid="RecipeTerritoriesSelect"
      sx={{ width: 300 }}
      options={cities}
      value={value}
      autoHighlight
      isOptionEqualToValue={(option, value) => option.id === value.id}
      noOptionsText={t('search.nothing_is_here')}
      getOptionLabel={(option) => option.name}
      onChange={(event: any, newValue: GpTerritories | null) => {
        if (newValue) {
          setValue(newValue);
          void setActiveRecipeTerritoryAsync(newValue.id);
        }
      }}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          {option.name}
        </Box>
      )}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <ProjectNameTextField
          {...params}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  );
};

export default memo(TerritoriesAutoComplete);
