import {
  lazy, memo, Suspense, useState,
} from 'react';
import { useObservable } from '@ngneat/react-rxjs';
import { Box, IconButton } from '@mui/material';
import { NavLink } from 'react-router-dom';
import Map from '../../blocks/Map/Map';
import { ReactComponent as Question } from '../../assets/images/question.svg';
import 'leaflet/dist/leaflet.css';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import '../../assets/styles/measurement.css';
import '../../assets/styles/geosearch-override.css';
import './geoman-controls.css';

import styles from './MapPage.module.scss';
import { resultingEnabled$ } from '../../state/project/project.repository';
import { APP_ENV, ENV, PROD_ENV } from '../../utils/constants';
import { sendMetricReachGoal } from '../../utils/functions';

const DownloadReport = lazy(() => import('../../components/DownloadReport'));

const mapOptions = {
  center: [59.930728, 30.384580] as L.LatLngTuple,
  zoom: 11,
  zoomControl: true,
  attributionControl: false,
};

interface MapPage2Props {
  setMap: (map: L.Map) => void;
}

function MapPage({ setMap }: MapPage2Props) {
  const [resultingEnabled] = useObservable(resultingEnabled$);
  const [mapEl, setMapEl] = useState<null | HTMLElement>(null);
  const appEnv: ENV = APP_ENV;

  return (
    <div
      className={styles.map}
    >
      <Map
        setMap={setMap}
        setMapEl={setMapEl}
        options={mapOptions}
        styles={styles.map}
        mapHeight="100%"
      />

      {resultingEnabled && appEnv !== PROD_ENV && (
        <div className={styles.download}>
          <Suspense fallback={<div>...</div>}>
            <DownloadReport mapElement={mapEl} />
          </Suspense>
        </div>
      )}

      <Box>
        <NavLink
          to="/help"
          data-testid="helpLink"
          target="_blank"
          onClick={() => sendMetricReachGoal('helpLink')}
        >
          <IconButton
            className={styles.icon}
          >
            <Question />
          </IconButton>

        </NavLink>
      </Box>

    </div>
  );
}

export default memo(MapPage);
