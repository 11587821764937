import { useEffect, useState } from 'react';
import { useObservable } from '@ngneat/react-rxjs';

import { activeProject$, layersMap$, uiDataset$ } from '../../../state/project/project.repository';
import {
  availableDataset$,
  availableLayers$,
  aclRequestStatusPending$,
  layerDatasetSchedulesPending$,
} from '../../../state/acl/acl.repository';
import {
  fetchAclAsync, getScheduleAndTerritories,
} from '../../../state/project/project.actions';

import {
  GpLayerMap, GpLayer, GpStatDataset, GpdsScheduleDb, GpdsSchedule, GpSchedule,
} from '../../../api/types';
import { ProjectType } from '../../../models/project';
import {
  FlatLayersListItem, FlatLayersListItemSecond, FlatSchedule,
  GPSchedule, LayerMapOrScheduleDb, LayerOrDataset,
} from './models';
import { MARKETPLACE_PAGE_SIZE_COUNT, PROJECTS_PAGE_SIZE_COUNT } from '../../../utils/constants';
import usePageAndSearch from '../../../hooks/usePageAndSearch';

export const getTitleOfSchedule = (
  schedules: GPSchedule[],
  layerOrDataset: GpLayerMap | GpdsScheduleDb,
) => schedules
  .find((schedule) => {
    if ('layer' in schedule) {
      return schedule.layer.id === layerOrDataset.id;
    }
    return schedule.dataset.id === layerOrDataset.id;
  })?.title;

function composeLayersList(
  layersOrDatasets: LayerOrDataset[],
  layersMapOrSchedulesDb: LayerMapOrScheduleDb[],
  schedules: GPSchedule[],
) {
  const result: FlatLayersListItem[] = [];

  layersMapOrSchedulesDb.forEach((layerMapOrScheduleDb) => {
    const scheduleTitle = getTitleOfSchedule(schedules, layerMapOrScheduleDb);

    const gpLayer = layersOrDatasets.find(
      (layerOrDataset: LayerOrDataset) => layerOrDataset.uNameKey === layerMapOrScheduleDb.uName
        .split('_', 3).join('_'),
    );

    result.push({
      layerMapId: layerMapOrScheduleDb.id,
      layerMap: layerMapOrScheduleDb,
      layerTitle: gpLayer?.title ?? null,
      scheduleTitle: scheduleTitle ?? null,
      uName: layerMapOrScheduleDb.uName ?? null,
      territoryName: gpLayer?.territory?.name ?? null,
    });
  });

  return result;
}

function composeLayersListSecond(
  layersOrDatasets: LayerOrDataset[],
  layersMapOrSchedulesDb: LayerMapOrScheduleDb[],
) {
  const result: FlatLayersListItemSecond[] = [];
  layersOrDatasets.forEach((layerOrDataset) => {
    const layerMapUnames = layersMapOrSchedulesDb
      .filter((el) => layerOrDataset.uNameKey === el.uName.split('_', 3).join('_'))
      .map((el) => el.uName);
    const filteredSchedules = (layerOrDataset.schedules as (GpSchedule | GpdsSchedule)[])
      .filter((el) => {
        if ('layer' in el) return layerMapUnames.includes(el.layer.uName);
        return layerMapUnames.includes(el.dataset.uName);
      });
    const flatSchedules: FlatSchedule[] = filteredSchedules.map((schedule) => ({
      scheduleTitle: schedule.title,
      scheduleUname: 'layer' in schedule ? schedule.layer.uName : schedule.dataset.uName,
      layerMap: 'layer' in schedule ? schedule.layer : schedule.dataset,
    }));

    result.push({
      layerTitle: layerOrDataset.title,
      layerId: layerOrDataset.id,
      layerAnnotation: layerOrDataset.annotation,
      layerType: layerOrDataset.__typename,
      territoryName: layerOrDataset.territory.name,
      schdules: flatSchedules ?? [],
    });
  });

  return result;
}

function useProjectLayers() {
  const [aclLayers] = useObservable(availableLayers$);
  const [aclDataset] = useObservable(availableDataset$);

  const limit = MARKETPLACE_PAGE_SIZE_COUNT;

  const [activeProject] = useObservable(activeProject$);
  const [datasetInProject] = useObservable(uiDataset$);
  const [layersMapInProject] = useObservable(layersMap$);
  const [onSideLoad, setOnSideLoad] = useState(true);

  const [knownPages, setKnownPages] = useState<number[]>([]);

  const [layerDatasetSchedulesPending] = useObservable(
    layerDatasetSchedulesPending$,
    { initialValue: true },
  );
  const [aclRequestStatusPending] = useObservable(
    aclRequestStatusPending$,
    { initialValue: true },
  );

  const [layersOrDatasets, setlayersOrDatasets] = useState<GpLayer[] | GpStatDataset[]>([]);
  const [allLayersOrDatasets, setallLayersOrDatasets] = useState<GpLayer[] | GpStatDataset[]>([]);

  const [uNames, setUnames] = useState<string[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [isLastPage, setIsLastPage] = useState(true);
  const [totalCountLoaded, setTotalCountLoaded] = useState(false);

  const {
    page,
    inputSearch,
    handlePageChange,
    setPage,
    setInputSearch,
  } = usePageAndSearch();

  useEffect(() => {
    if (totalCount > 0) {
      setTotalCountLoaded(true);
    }
  }, [totalCount]);

  useEffect(() => {
    void fetchAclAsync();
  }, []);

  useEffect(() => {
    void (async () => {
      if (layersMapInProject.length) {
        const { gpLayers, hasMore } = await getScheduleAndTerritories(
          layersMapInProject,
          activeProject.projectType.model,
          inputSearch,
        ) as { gpLayers: GpLayer[], hasMore: boolean };
        setallLayersOrDatasets(gpLayers);
        setOnSideLoad(false);
        setIsLastPage(!hasMore);
      }
    })();
  }, []);

  useEffect(() => {
    void (async () => {
      if (datasetInProject) {
        const { gpDatasets, hasMore } = await getScheduleAndTerritories(
          datasetInProject.flat(),
          activeProject.projectType.model,
          inputSearch,
        ) as { gpDatasets: GpStatDataset[], hasMore: boolean };
        setallLayersOrDatasets(gpDatasets);
        setOnSideLoad(false);
        setIsLastPage(!hasMore);
      }
    })();
  }, []);

  const fetchWrapper = async () => {
    if (activeProject.projectType.model === ProjectType.GPProject && aclLayers.length > 0) {
      const { gpLayers, totalCount, hasMore } = await getScheduleAndTerritories(
        aclLayers,
        activeProject.projectType.model,
        inputSearch,
        page - 1,
        limit,
      ) as { gpLayers: GpLayer[], totalCount: number, hasMore: boolean };
      if (!inputSearch) {
        if (!knownPages.includes(page)) {
          setKnownPages([...knownPages, page]);
          const newAllLayersOrDatasets = (allLayersOrDatasets as GpLayer[]).concat(gpLayers);
          setallLayersOrDatasets(newAllLayersOrDatasets);
        }
      } else {
        const newAllLayersOrDatasets = (allLayersOrDatasets as GpLayer[]).concat(gpLayers);
        setallLayersOrDatasets(newAllLayersOrDatasets);
      }
      const uNameKeys = gpLayers.map((el) => el.uNameKey);
      setUnames(uNameKeys);
      setlayersOrDatasets(gpLayers);
      setTotalCount(totalCount);
      setIsLastPage(!hasMore);
    }
    if (activeProject.projectType.model === ProjectType.GPBIProject
      && aclDataset.length > 0
      && datasetInProject) {
      const { gpDatasets, totalCount, hasMore } = await getScheduleAndTerritories(
        aclDataset,
        activeProject.projectType.model,
        inputSearch,
        page - 1,
        limit,
      ) as { gpDatasets: GpStatDataset[], totalCount: number, hasMore: boolean };
      if (!inputSearch) {
        if (!knownPages.includes(page)) {
          setKnownPages([...knownPages, page]);
          const newAllLayersOrDatasets = (allLayersOrDatasets as GpStatDataset[])
            .concat(gpDatasets).filter((el) => el);
          setallLayersOrDatasets(newAllLayersOrDatasets);
        }
      } else {
        const newAllLayersOrDatasets = (allLayersOrDatasets as GpStatDataset[])
          .concat(gpDatasets).filter((el) => el);
        setallLayersOrDatasets(newAllLayersOrDatasets);
      }
      const uNameKeys = gpDatasets.map((el) => el.uNameKey);
      setUnames(uNameKeys);
      setlayersOrDatasets(gpDatasets);
      setTotalCount(totalCount);
      setIsLastPage(!hasMore);
    }
  };

  useEffect(() => {
    if (!onSideLoad) {
      if (page === 1) {
        void fetchWrapper();
      } else {
        setPage(1);
      }
    }
  }, [onSideLoad, aclDataset, aclLayers, inputSearch]);

  useEffect(() => {
    if (!onSideLoad) {
      void fetchWrapper();
    }
  }, [page]);

  const layersMapOrSchedulesDb = activeProject?.projectType.model === ProjectType.GPBIProject
    && datasetInProject
    ? datasetInProject.flat()
    : layersMapInProject;

  const schedules = allLayersOrDatasets.flatMap((el) => [...el.schedules]);
  const flatLayersList = composeLayersList(allLayersOrDatasets, layersMapOrSchedulesDb, schedules);

  const aclLayersOrDatasets = ((activeProject.projectType.model === ProjectType.GPBIProject
    ? aclDataset : aclLayers) as (GpdsScheduleDb | GpLayerMap)[])
    .filter((el) => uNames.find((u) => el.uName.split('_', 3).join('_') === u));

  const flatAclFirstView = composeLayersList(layersOrDatasets, aclLayersOrDatasets, schedules);
  const flatAclOtherView = composeLayersListSecond(layersOrDatasets, aclLayersOrDatasets);

  const onBodyLoad = layerDatasetSchedulesPending || aclRequestStatusPending;

  const fetchLastPage = () => {
    setPage(Math.ceil(totalCount / PROJECTS_PAGE_SIZE_COUNT));
  };

  return {
    flatLayersList,
    flatAclFirstView,
    flatAclOtherView,
    activeProject,
    onBodyLoad,
    onSideLoad,
    handlePageChange,
    fetchLastPage,
    page,
    isLastPage,
    totalCountLoaded,
    setInputSearch,
  };
}

export default useProjectLayers;
