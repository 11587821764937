import { Stack, Typography } from '@mui/material';
import {
  GpuiLayer,
  GpLayer,
  GpLayerMap,
  GpStatDataset,
  GpuiDataset,
  GpdsSchedule,
  GpSchedule,
  GpRecipeValue,
  GpCommercialServiceValue,
} from '../../../../../api/types';
import styles from '../CardBody.module.scss';
import Schedule from '../common/Schedule';
import Value from '../common/Value';

export const renderLayerListItem = (layerOrDataset: GpuiLayer, layers: GpLayer[]) => {
  const item = (layerOrDataset.layer[0] as GpLayerMap);
  if (!item || !item?.uName) return 'BROKEN-L[0]';

  const schedule = layers.flatMap((l) => l.schedules)
    .find((s) => s.layer?.id === item.id);

  const title = (schedule ? schedule.title : 'BROKEN-SCHEDULE');
  const territory = layers
    .find((l) => l.uNameKey === item.uName.split('_', 3).join('_'))?.territory.name || 'BROKEN-TERRITORY';

  return (
    <>
      <Typography className={styles.listItemTitle} variant="H3card" color="text.primary">
        { layerOrDataset.n }
      </Typography>
      <Typography variant="H3card" className={styles.listItemInfoText}>
        { title }
      </Typography>
      <Typography variant="textCard" className={styles.listItemInfoText}>
        { item.uName }
      </Typography>
      <Typography variant="textCard" className={styles.listItemInfoText}>
        { territory }
      </Typography>
    </>
  );
};

export const renderDatasetListItem = (layerOrDataset: GpuiDataset, datasets: GpStatDataset[]) => (
  <>
    <Typography className={styles.listItemTitle} variant="textCard" color="text.primary">
      { layerOrDataset.name }
    </Typography>
    <Typography variant="textCard" className={styles.listItemInfoText}>
      {
        datasets.flatMap((l) => l.schedules)
          .find((s) => s.dataset.id === layerOrDataset.ds[0].id)?.title
      }
    </Typography>
    <Typography variant="textCard" className={styles.listItemInfoText}>
      {
        layerOrDataset.ds[0].uName
      }
    </Typography>
    <Typography variant="textCard" className={styles.listItemInfoText}>
      {
        datasets.find((l) => l.uNameKey === layerOrDataset.ds[0].uName.split('_', 3).join('_'))?.territory.name
      }
    </Typography>
  </>
);

export const renderLayers = (
  layersOrDatasets: GpuiLayer[] | GpuiDataset[],
  products: (GpLayer | GpStatDataset)[],
) => [...layersOrDatasets]
  .splice(0, 2)
  .map((layerOrDataset, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Stack direction="row" gap="5px" key={index}>
      <Typography variant="H3card" color="text.primary">{ `${index + 1}.` }</Typography>
      <Stack className={styles.listItemWrapper}>
        {
          'n' in layerOrDataset
            && renderLayerListItem(layerOrDataset, (products.filter((p) => p.__typename === 'GPLayer') as GpLayer[]))
        }
        {
          'name' in layerOrDataset
            && renderDatasetListItem(
              layerOrDataset,
              (products.filter((p) => p.__typename === 'GPStatDataset') as GpStatDataset[]),
            )
        }
      </Stack>
    </Stack>
  ));

export const renderSchedules = (schedules?: GpSchedule[] | GpdsSchedule[] | null) => {
  if (schedules && schedules.length > 0) {
    return schedules.slice(0, 3).map((schedule, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Schedule schedule={schedule} index={index} key={index} />
    ));
  }
  return null;
};

export const renderRecipeValues = (recipeValues?: GpRecipeValue[] | null) => {
  if (recipeValues && recipeValues.length > 0) {
    return recipeValues.slice(0, 5).map((value, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Value value={value} index={index} key={index} />
    ));
  }
  return null;
};

export const renderServiceValues = (serviceValues: GpCommercialServiceValue[] | null) => {
  if (serviceValues && serviceValues.length > 0) {
    return serviceValues.slice(0, 5).map((value, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Value value={value} index={index} key={index} />
    ));
  }
  return null;
};
