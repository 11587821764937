import {
  Badge, Box, IconButton, Typography,
} from '@mui/material';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import Loader from '../Loader/Loader';
import styles from './userSection.module.scss';

interface CartIconProps {
  onLoad: boolean,
  disabled: boolean,
  count: number,
  onClick: () => void,
}

function CartIcon({
  onLoad, disabled, onClick, count,
}: CartIconProps) {
  return (
    <Box className={styles.cartIcon}>
      {onLoad
        ? (
          <Box width="38px" right="50%" position="relative">
            <Loader id="progress-cart-icon" />
          </Box>
        )
        : (
          <Box className={styles.box}>
            <Badge color="primary" className={styles.badge}>
              <Typography variant="descriptionCard">
                {count}
              </Typography>
            </Badge>
            <IconButton data-testid="cart" onClick={onClick} disabled={disabled} className={styles.cartIconButton}>
              <ShoppingBagOutlinedIcon sx={{ margin: '0 auto', color: '#fff' }} />
            </IconButton>
          </Box>
        )}
    </Box>
  );
}

export default CartIcon;
