import { FC } from 'react';
import { Box } from '@mui/material';
import styles from './HorizontalCard.module.scss';

interface CardProps {
  sideBodyLeft?: React.ReactNode,
  body?: React.ReactNode,
  sideBodyRight?: React.ReactNode,
}

const HorizontalCard: FC<CardProps> = ({ body, sideBodyLeft, sideBodyRight }) => (
  <Box className={styles.cardWrapper}>
    <Box className={styles.sideBodyLeft}>
      { sideBodyLeft }
    </Box>
    <Box className={styles.body}>
      { body }
    </Box>
    <Box className={styles.sideBodyRight}>
      { sideBodyRight }
    </Box>
  </Box>
);

export default HorizontalCard;
