import {
  Box, Grid, Paper, Popper, Stack, Typography,
} from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { useState, MouseEvent } from 'react';
import ProceedButton from '../ProceedButton/ProceedButton';
import styles from './RecipeCard.module.scss';
import { createSideMarkup } from '../../utils/functions';

interface RecipeCardProps {
  title: string;
  body: string;
  timeSpend: string;
  views?: string;
}

function RecipeCard({
  title, body, timeSpend, views,
}: RecipeCardProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMouseOver = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const popperId = open ? 'simple-popper' : undefined;

  return (
    <Paper className={styles.card} elevation={0}>
      <Typography
        variant="accentTitle1"
        onMouseOver={handleMouseOver}
        onMouseLeave={() => setAnchorEl(null)}
        className={styles.title}
        sx={{ fontWeight: '600' }}
        data-testid="recipeCardTitle"
      >
        {title}
      </Typography>

      <Popper id={popperId} open={open} anchorEl={anchorEl} placement="top">
        <Box className={styles.tooltip}>
          <Typography
            className={styles.primary}
            color="text.primary"
            variant="subtitle1"
          >
            {title}
          </Typography>
        </Box>
      </Popper>

      <Box flexGrow={1} sx={{ overflow: 'hidden' }}>
        <div
          className={`${styles.desc} cms-styles cms-main`}
          dangerouslySetInnerHTML={createSideMarkup(body)}
        />
      </Box>
      <Stack className={styles.info}>
        <Grid container className={styles.bottom}>
          <Grid item>
            <Typography variant="subtitle3" component="span">Работы на </Typography>
            <Typography variant="subtitle3" component="span" color="primary">{timeSpend}</Typography>
          </Grid>

          {
            views && (
              <Grid item container alignItems="center">
                <PersonOutlineIcon sx={{ color: 'text', fontSize: '1.7rem' }} />
                <Typography variant="subtitle3" color="primary.dark">{views}</Typography>
              </Grid>
            )
          }
        </Grid>

        <Grid item>
          <ProceedButton />
        </Grid>
      </Stack>
    </Paper>
  );
}

export default RecipeCard;
