import {
  Box, Stack, Typography,
} from '@mui/material';
import { useObservable } from '@ngneat/react-rxjs';
import { useTranslation } from 'react-i18next';
import {
  activeRecipeTitle$, activeRecipeUpdatePeriod$, recipeUpdatePeriods$,
  scheduleWithLayerRequestStatus$,
} from '../../../state/geoData/recipes.repository';
import CreateProjectButton from './ui/CreateProjectButton';
import TerritoriesAutocompleteWrapper from './TerritoriesAutocompleteWrapper';
import RecipeLayerList from './RecipeLayerList';
import SelectWithAutocomplete from './ui/SelectWithAutocomplete';
import { setActiveRecipeUpdatePeriod } from '../../../state/geoData/recipes.effects';
import Loader from '../../../components/Loader/Loader';

const ChooseLayers = () => {
  const [activeRecipeTitle] = useObservable(activeRecipeTitle$);
  const { t } = useTranslation('common');
  const [updatePeriods] = useObservable(recipeUpdatePeriods$);
  const [activeUpdatePeriod] = useObservable(activeRecipeUpdatePeriod$);
  const [scheduleWithLayerRequestStatus] = useObservable(scheduleWithLayerRequestStatus$);

  return (
    <Stack height="100%">
      <Typography variant="h4" color="#fff" sx={{ maxWidth: '450px' }}>{activeRecipeTitle}</Typography>

      <Typography variant="title2" color="#fff" component="h6" sx={{ mt: 3 }}>
        {t('recipe.city')}
        {': '}
      </Typography>
      <Stack mt={1} direction="row" justifyContent="space-between">
        <TerritoriesAutocompleteWrapper />
      </Stack>

      <Typography variant="title2" color="#fff" component="h6" sx={{ mt: 3 }}>
        {t('recipe.period')}
        {': '}
      </Typography>
      <Stack mt={1} direction="row" justifyContent="space-between">
        <SelectWithAutocomplete
          id="recipe-period-select"
          options={updatePeriods}
          value={activeUpdatePeriod}
          setValue={(value) => setActiveRecipeUpdatePeriod(value)}
          noOptionsText={t('search.nothing_is_here')}
        />
      </Stack>

      {scheduleWithLayerRequestStatus.value === 'pending'
        ? (
          <Box width="50%" top="50%" position="relative">
            <Loader id="progress-choose-layers" />
          </Box>
        )
        : (
          <>
            <Stack mt={2} mb={4} direction="row" justifyContent="space-between" alignItems="start">
              <RecipeLayerList />
            </Stack>

            <Stack direction="row" justifyContent="center" mt="auto" pb="40px">
              <CreateProjectButton />
            </Stack>
          </>
        )}
    </Stack>
  );
};

export default ChooseLayers;
