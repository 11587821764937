import { useObservable } from '@ngneat/react-rxjs';
import { useEffect, useState } from 'react';
import { activeAccountId$ } from '../../../../../state/context/auth.repository';

import { MARKETPLACE_PAGE_SIZE_COUNT } from '../../../../../utils/constants';
import { getLayerDatasetSchedule } from '../../../../../state/project/project.actions';
import { availableLayersOrDataSet$ } from '../../../../../state/acl/acl.repository';
import {
  GpLayer, GpStatDataset,
  GpdsScheduleDb, GpLayerMap,
} from '../../../../../api/types';
import { activeAclTypesIds$ } from '../../../../../state/filters/filters.repository';
import { ProductTypeId } from '../../../../../models/product';
import { composeLayersList, composeLayersListSecond } from '../LayersHelpers';
import usePageAndSearch from '../../../../../hooks/usePageAndSearch';

function filterSchedulesByProductType(
  schedules: (GpdsScheduleDb | GpLayerMap)[],
  productTypesIds: string[],
) {
  return schedules.filter((s) => {
    if (!productTypesIds.length
      || (productTypesIds.includes(ProductTypeId.Layer) && s.__typename === 'GPLayerMap')
      || (productTypesIds.includes(ProductTypeId.StatDataset) && s.__typename === 'GPDSScheduleDB')) {
      return true;
    }
    return false;
  });
}

function useLayers() {
  const [acl] = useObservable(availableLayersOrDataSet$);
  const [activeAclTypesIds] = useObservable(activeAclTypesIds$);
  const [activeAccountId] = useObservable(activeAccountId$);
  const [products, setProducts] = useState<(GpLayer | GpStatDataset)[]>([]);

  const [isLastPage, setIsLastPage] = useState(true);
  const [totalCountLoaded, setTotalCountLoaded] = useState(false);

  const {
    page,
    pageCount,
    inputSearch,
    handlePageChange,
    setPage,
    setPageCount,
    setInputSearch,
  } = usePageAndSearch();

  const limit = MARKETPLACE_PAGE_SIZE_COUNT;

  async function setLayerDatasetSchedule() {
    const filteredSchedules = filterSchedulesByProductType(acl, activeAclTypesIds);

    const {
      schedules,
      totalCount,
      hasMore,
    } = await getLayerDatasetSchedule(filteredSchedules, inputSearch, page - 1, limit);
    setPageCount(Math.ceil(totalCount / limit));
    setIsLastPage(!hasMore);
    if (schedules && schedules.length) {
      setProducts(schedules);
    }
  }

  useEffect(() => {
    setProducts([]);
  }, [activeAccountId]);

  useEffect(() => {
    if (page === 1) {
      void setLayerDatasetSchedule();
    } else {
      setPage(1);
    }
  }, [inputSearch, JSON.stringify(acl), JSON.stringify(activeAclTypesIds)]);

  useEffect(() => {
    void setLayerDatasetSchedule();
  }, [page]);

  useEffect(
    () => {
      if (pageCount > 0) {
        setTotalCountLoaded(true);
      }
    },
    [pageCount],
  );

  const schedules = products.flatMap((el) => [...el.schedules]);
  const flatAclFirstView = composeLayersList(products, acl, schedules);
  const flatAclOtherView = composeLayersListSecond(products, acl);

  const fetchLastPage = () => {
    setPage(Math.ceil(pageCount));
  };

  return {
    page,
    isLastPage,
    totalCountLoaded,
    flatAclFirstView,
    flatAclOtherView,
    handlePageChange,
    setInputSearch,
    fetchLastPage,
  };
}

export default useLayers;
