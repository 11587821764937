import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, Typography,
} from '@mui/material';
import { useObservable } from '@ngneat/react-rxjs';
import {
  activeTerritoriesIds$,
  activeUpdatePeriodIds$,
  activeProductTypesIds$,
  addActiveTerritory,
  addActiveUpdatePeriod,
  addActiveProductTypes,
  removeActiveTerritory,
  removeActiveUpdatePeriod,
  removeActiveProductTypes,
  territory$,
  updatedPeriod$,
  productTypes$,
} from '../../state/filters/filters.repository';
import { productsTotalCount$, totalCountProductRequestPending$ } from '../../state/geoData/products.repository';

import FilterGroup from '../FilterGroup/FilterGroup';
import { IFilter } from '../../models/filters';
import { sendMetricReachGoal } from '../../utils/functions';
import SideHome from '../../feature/Side/pages/SideHome/SideHome';
import styles from './MarketplaceFilters.module.scss';
import TotalCount from '../../components/TotalCount/TotalCount';

function MarketplaceFilters() {
  const [territories] = useObservable(territory$);
  const [activeTerritoriesIds] = useObservable(activeTerritoriesIds$);
  const [updatePeriods] = useObservable(updatedPeriod$);
  const [activeUpdatePeriodIds] = useObservable(activeUpdatePeriodIds$);
  const [productTypes] = useObservable(productTypes$);
  const [activeProductTypesIds] = useObservable(activeProductTypesIds$);
  const [productsTotalCount] = useObservable(productsTotalCount$);
  const [totalCountProductRequestPending] = useObservable(totalCountProductRequestPending$, { initialValue: true });

  const [openTerritories, setOpenTerritories] = useState(!!activeTerritoriesIds.length);
  const [openUpdatePeriods, setOpenUpdatePeriods] = useState(!!activeUpdatePeriodIds.length);
  const [openProductTypes, setOpenProductTypes] = useState(!!activeProductTypesIds.length);

  const { t } = useTranslation('common');

  const handleTerritoriesClick = () => {
    setOpenTerritories(!openTerritories);
    sendMetricReachGoal('territoriesFilter');
  };

  const handleUpdatePeriodsClick = () => {
    setOpenUpdatePeriods(!openUpdatePeriods);
    sendMetricReachGoal('updatePeriodsFilter');
  };

  const handleProductTypesClick = () => {
    setOpenProductTypes(!openProductTypes);
    sendMetricReachGoal('productTypesFilter');
  };

  const handleTerritoryFilterChange = (active: boolean, id: string) => {
    if (active) {
      addActiveTerritory(id);
    } else {
      removeActiveTerritory(id);
    }
  };

  const handleUpdatePeriodFilterChange = (active: boolean, id: string) => {
    if (active) {
      addActiveUpdatePeriod(id);
    } else {
      removeActiveUpdatePeriod(id);
    }
  };

  const handleProductTypesFilterChange = (active: boolean, id: string) => {
    if (active) {
      addActiveProductTypes(id);
    } else {
      removeActiveProductTypes(id);
    }
  };

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.sideHome}>
        <SideHome />
      </Box>
      <Box className={styles.filterHeaderWrapper}>
        <Typography variant="title2" data-testid="filtersTitle">
          {t('layer.filters').toUpperCase()}
        </Typography>
        <TotalCount pending={totalCountProductRequestPending} totalCount={productsTotalCount} />
      </Box>

      <FilterGroup
        title={t('layer.filter_territory')}
        open={openTerritories}
        onClick={handleTerritoriesClick}
        dataTestId="territoriesFilter"
        filters={
          territories.map<IFilter>((t) => ({
            id: t.id,
            title: t.name,
          }))
        }
        activeFiltersIds={activeTerritoriesIds}
        onFilterChange={handleTerritoryFilterChange}
        filterType="territories"
      />

      <FilterGroup
        title={t('layer.filter_product_type')}
        open={openProductTypes}
        onClick={handleProductTypesClick}
        dataTestId="productTypesFilter"
        filters={
          productTypes.map<IFilter>((p) => ({
            id: p.id,
            title: p.name,
          }))
        }
        activeFiltersIds={activeProductTypesIds}
        onFilterChange={handleProductTypesFilterChange}
        filterType="productType"
      />

      <FilterGroup
        title={t('layer.filter_update_period')}
        open={openUpdatePeriods}
        onClick={handleUpdatePeriodsClick}
        dataTestId="updatePeriodsFilter"
        filters={
          updatePeriods.map<IFilter>((p) => ({
            id: p.id,
            title: p.title,
          }))
        }
        activeFiltersIds={activeUpdatePeriodIds}
        onFilterChange={handleUpdatePeriodFilterChange}
        filterType="updatePeriod"
      />
    </Box>
  );
}

export default MarketplaceFilters;
