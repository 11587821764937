import { ListItemButton } from '@mui/material';
import { useState } from 'react';
import { ChevronRight } from '@mui/icons-material';
import styles from './RubricFilter.module.scss';
import RubricFormContolLabel from './RubricFormControlLabel';
import { GpRubric } from '../../../../api/types';

interface LeafParentRubricProps {
  filter: GpRubric,
  checked: boolean,
  indeterminate: boolean | undefined,
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void,
}

function LeafParentRubric({
  filter,
  checked,
  indeterminate,
  onClick,
  onChange,
}: LeafParentRubricProps) {
  const [isHover, setIsHover] = useState<boolean>(false);

  return (
    <ListItemButton
      onMouseEnter={() => { setIsHover(true); }}
      onMouseLeave={() => { setIsHover(false); }}
      className={styles.listItemButton}
      onClick={onClick}
    >
      <RubricFormContolLabel
        filter={filter}
        checked={checked}
        indeterminate={indeterminate}
        onChange={onChange}
        isHover={isHover}
      />
      <ChevronRight className={styles.expandIcon} />
    </ListItemButton>
  );
}

export default LeafParentRubric;
