import { Stack } from '@mui/material';
import { FC } from 'react';
import HorizontalCardLarge from '../../../blocks/Card/HorizontalCardLarge';
import styles from './SecondViewAcl.module.scss';
import { FlatLayersListItemSecond } from '../../ModalProjectTabs/hooks/models';
import CardHeader from '../../../blocks/Card/components/CardHeader/CardHeader';
import CardTitle from '../../../blocks/Card/components/CardTitle/CardTitle';
import CardBody from '../../../blocks/Card/components/CardBody/CardBody';

interface AclSchedulesProps {
  layers: FlatLayersListItemSecond[];
  addButton?: boolean;
}

interface CardProps {
  layer: FlatLayersListItemSecond;
  addButton?: boolean;
}

const Card:FC<CardProps> = ({ layer, addButton }) => {
  const header = (
    <CardHeader
      productType={layer.layerType}
    />
  );

  const title = (
    <CardTitle
      title={layer.layerTitle}
      annotationAcl={layer.layerAnnotation}
      territoryAcl={layer.territoryName}
    />
  );

  const body = (
    <CardBody
      addButton={addButton}
      aclSchedules={layer.schdules}
    />
  );

  return (
    <HorizontalCardLarge
      header={header}
      title={title}
      body={body}
    />
  );
};

const SecondViewAcl:FC<AclSchedulesProps> = ({ layers, addButton }) => {
  const layerList = layers.map((el) => (
    <Card layer={el} addButton={addButton} />
  ));

  return (
    <Stack className={styles.wrapper}>
      { layers.length > 0
        ? layerList
        : null}
    </Stack>
  );
};

export default SecondViewAcl;
