import { FC, useCallback } from 'react';
import { Stack } from '@mui/material';
import { generatePath, useNavigate } from 'react-router-dom';
import { ProjectCardProps } from './ProjectCardProps';
import { ProjectType } from '../../../../../../models/project';
import Card from '../../../../../../blocks/Card/VerticalCard';

import styles from './ProjectCard.module.scss';
import { setActiveProject } from '../../../../../../state/project/project.actions';
import CardHeader from '../../../../../../blocks/Card/components/CardHeader/CardHeader';
import CardTitle from '../../../../../../blocks/Card/components/CardTitle/CardTitle';
import CardBody from '../../../../../../blocks/Card/components/CardBody/CardBody';

const ProjectCard:FC<ProjectCardProps> = ({
  projectType,
  title,
  updateDate,
  layersOrDatasets,
  id,
  products,
}) => {
  const navigate = useNavigate();
  const handleProjectClick = useCallback(async (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement;
    if (target.closest('[class^=ProjectCard]')) {
      await setActiveProject(id);
      if (projectType.model === ProjectType.GPProject) {
        navigate(generatePath('/map/:projectId', { projectId: id }));
      } else {
        navigate(generatePath('/bi/:projectId', { projectId: id }));
      }
    }
  }, [id]);

  const header = (
    <CardHeader
      typeName={projectType.name}
      typeId={projectType.id}
      isProject
      projectId={id}
      projectName={title}
    />
  );

  const cardTitle = (
    <CardTitle
      title={title}
      updateDate={updateDate}
    />
  );

  const body = (
    <CardBody
      layersOrDatasets={layersOrDatasets}
      products={products}
      typeId={projectType.id}
    />
  );

  return (
    <Stack data-testid={`cardProjectId:${id}`} className={styles.projectCard} onClick={(event) => handleProjectClick(event)}>
      <Card
        header={header}
        title={cardTitle}
        body={body}
      />
    </Stack>
  );
};

export default ProjectCard;
