import { useObservable } from '@ngneat/react-rxjs';
import Loader from '../../components/Loader/Loader';
import useComponentWillMount from '../../hooks/useComponentWillMount';
import {
  activePageIsLandingToc$,
  activePageRoute$,
  activePageText$,
  activePageTitle$,
  pagesRequestPending$,
  resetPageStore,
} from '../../state/pages/pages.repository';
import LandingToc from './components/HelpLandingToc/HelpLandingToc';
import NotLandingToc from './components/HelpNotLandingToc/HelpNotLandingToc';

const Help = () => {
  const [activePageIslandingToc] = useObservable(activePageIsLandingToc$);
  const [pageRequestPending] = useObservable(pagesRequestPending$, { initialValue: true });
  const [pageTitle] = useObservable(activePageTitle$);
  const [pageText] = useObservable(activePageText$);
  const [pageRoute] = useObservable(activePageRoute$);

  useComponentWillMount(() => {
    resetPageStore();
  });

  if (pageRequestPending) {
    return <Loader id="progress-help" />;
  }

  return activePageIslandingToc
    ? (
      <LandingToc
        title={pageTitle}
        text={pageText}
      />
    )
    : (
      <NotLandingToc
        title={pageTitle}
        text={pageText}
        route={pageRoute}
      />
    );
};

export default Help;
