import { CircularProgress } from '@mui/material';
import styles from './Loader.module.scss';

interface LoaderProps {
  id: string;
  className?: string;
}

const Loader: React.FC<LoaderProps> = ({ id, className }: LoaderProps) => (
  <CircularProgress data-testid={id} className={ className ? styles[className] : styles.loader} />
);

export default Loader;
