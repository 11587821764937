import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './ProjectTypeChip.module.scss';

export interface ProjectTypeChipProps {
  projectType: string;
}

export enum ProjectTypeChipType {
  'GPProject' = 'geo',
  'GPBIProject' = 'bi',
}

export const ProjectTypeChip = (props: ProjectTypeChipProps) => {
  const { t } = useTranslation('common');
  const { projectType } = props;

  return (
    <Typography className={styles[projectType]} fontWeight="600" fontSize="14" variant="body2">
      {t(`project.type_name.${projectType}`)}
    </Typography>
  );
};
